.modalFeature{
    .modal-content {
        border: 0;
        border-radius: 15px;
        background-color: #f7f7f7;
    }.botBtn {
        display: inline-block;
        text-align: center;
    }
    .btns-do-not-leave {
        font-size: 11px !important;
        padding: 0 13px !important;
        background: white !important;
        height: 32px !important;
        border-radius: 30px !important;
        color: #5a5a5a !important;
        text-transform: uppercase;
        margin: 0 5px 8px;
    }
    .h3_Title {
        font-size: 18px!important;
        color: #1d6091!important;
    }
}
