.save-list-dropdown {
  width: 100%;
  .save-list-menu {
    max-height: 420px;
    margin-top: 10px;
    padding-bottom: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px !important;

    .ant-row {
      white-space: initial;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 1024px) {
      //width: 225px;
    }

    .save-list-menu-title {
      padding: 10px 0 12px 20px;
      span {
        font-size: 17px;
        color: #66b3ea;
        font-weight: 600;
        font-family: 'Roboto';
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
    .save-list-submenu {
      font-family: 'Roboto';
      color: #4f4f4f;
      padding: 2px 10px 2px 20px;
      font-size: 13px;
      font-weight: 400;
      @media (max-width: 768px) {
        font-size: 12px !important;
      }

      .submenu-container-text {
        padding-left: unset;
        padding-right: unset;
      }

      .submenu-container-icon {
        text-align: right;
        padding-left: unset;
        padding-right: unset;
      }
    }

    .save-list-no-content {
      font-family: 'Roboto';
      color: #4f4f4f;
      display: grid;
      padding: 20px 15px !important;
      font-size: 13px !important;
      font-weight: 400 !important;
      @media (max-width: 768px) {
        font-size: 12px!important;
      }
      span {
        padding-left: unset;
        padding-right: unset;
      }
    }
  }
  .save-list-section-hide{
    display: none;
  }
  .save-list-notLogged{
    margin-top: 10px !important;
    border-radius: 15px !important;
    max-height: 420px;
    padding: unset;
    .save-list-loginPrompt{
      .survey-container{
        margin: unset;
        width: 100%;
        .survey-close-icon{
          display: none;
        }
      }
    }
  }
}
