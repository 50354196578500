// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import '../../node_modules/antd/dist/antd.css';
// _variables.scss

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700');
//@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=VT323');
@import './mixins/mixins.scss';
@import "./variables.scss";

.bg-ssf-blue         { background-color: $ssf-blue            !important; }
.bg-ssf-blue-d1      { background-color: $ssf-blue-d1         !important; }
.bg-ssf-blue-d2      { background-color: $ssf-blue-d2         !important; }
.bg-ssf-blue-d4      { background-color: $ssf-blue-d4         !important; }
.bg-ssf-blue-l1      { background-color: $ssf-blue-l1         !important; }
.bg-ssf-blue-l2      { background-color: $ssf-blue-l2         !important; }
.bg-ssf-blue-l3      { background-color: $ssf-blue-l3         !important; }
.bg-ssf-blue-l4      { background-color: $ssf-blue-l4         !important; }
.bg-ssf-blue-50      { background-color: #d6ecf3              !important; }
.bg-ssf-blue-modal-bg      { background-color: #56a9e8              !important; }

.bg-ssf-gray-l2      { background-color: $ssf-gray-l2         !important; }
.bg-ssf-gray-l3      { background-color: $ssf-gray-l3         !important; }
.bg-ssf-gray-l4      { background-color: $ssf-gray-l4         !important; }
.bg-ssf-gray-l5      { background-color: $ssf-gray-l5         !important; }
.bg-ssf-gray-l7      { background-color: $ssf-gray-l7         !important; }
.bg-ssf-gray-l9      { background-color: $ssf-gray-l9         !important; }

.bg-ssf-orange-l1    { background-color: $ssf-orange-l1       !important; }
.bg-ssf-orange-l1-o  { background-color: rgba($ssf-orange-l1, .1)  !important; }
.bg-ssf-orange-l2    { background-color: $ssf-orange-l2       !important; }
.bg-ssf-gray-l1      { background-color: $ssf-gray-l1         !important; }
.bg-white-o10        { background-color: rgba($white, 0.1)    !important; }
.bg-black-o05        { background-color: rgba($gray-500, 0.1) !important; }
.bg-black-o10        { background-color: rgba($gray-500, 0.2) !important; }
.bg-black-o20        { background-color: rgba($black, 0.2) !important; }
.bg-black-o40        { background-color: rgba($black, 0.4) !important; }
//.bg-black-o05:hover, .bg-black-o10:hover  { background-color: rgba($white, 1) !important; transition: .25s; }

.text-info           { color: #1a7166                         !important; }
.text-ssf-blue       { color: $ssf-blue                       !important; }
.text-ssf-blue-d2    { color: $ssf-blue-d2                    !important; }
.text-ssf-green-d1   { color: $ssf-green-d1                   !important; }
.text-ssf-red-d1     { color: $ssf-red-d1                     !important; }
.text-ssf-red-d2     { color: $ssf-red-d2                     !important; }
.text-ssf-orange     { color: $ssf-orange                     !important; }
.text-ssf-orange-d1  { color: $ssf-orange-d1                  !important; }
.text-ssf-orange-l1  { color: $ssf-orange-l1                  !important; }
.text-ssf-gray       { color: $ssf-gray                       !important; }
.text-ssf-gray-l1    { color: $ssf-gray-l1                    !important; }
.text-ssf-gray-l6    { color: $ssf-gray-l6                    !important; }
.text-yellow         { color: yellow                         !important; }
.text-green          { color: #8CB8B2                         !important; }
.wealth-black          { color: #101828                         !important; }


.border-gray-1        { border-color: $gray-100      !important; }
.border-gray-2        { border-color: $gray-200      !important; }
.border-gray-4        { border-color: $gray-400      !important; }
.border-gray-5        { border-color: $gray-500      !important; }
.border-ssf-blue-d2   { border-color: $ssf-blue-d2   !important; }
.border-ssf-orange-d1 { border-color: $ssf-orange-d1 !important; }
.border-black-o20     { border-color: rgba($black, 0.2) !important; }

/*
Y nav bar #0084CF
Y bottom background #F4F9FD
Y SORT / FILTER Buttons #005383
Y Security Name #005383
Price No Change
Select Button
  Text  No change (i.e. #f77a08)
  Border  No change
  background  #005383
  On Hover  No change
Clear Button
  Background  Same as its border
Y DateRange Selector  #6A9C65
Y Cumulative return (main ticker) #6A9C65
Y Return (Icon, label, data, % sign)  rgb(0, 100, 0);
Y Risk (Icon, label, data, % sign)  rgb(139, 0, 0);
Y Fee, Liquidity, Asset icons #005383
Y Background of cumulative return chart #F3F7F2
Y Background of icons #F4F9FD
*/
/*
  #ffffff - Navbar,
  #F3F3F3 - Search, Sort, Select
  #EFF5FB - Card list (blue lighter)
  #E9F5FB - Cards

  rgba(73, 80, 87, 0.8) - Search, Min Inv, Tick(B), Cat,
  #2D9CDB - Card

  #495057 - Search Placeholder
  #1A62BA - Years, View Name, SecName
  rgba(73, 80, 87, 0.8)
*/




// Body Background
$bg-body:          #f3f6f8;
$bg-leftbar-dark:  #313541;
$bg-leftbar-menu-color: #7c8e9a;

$font-one: 'Open Sans', 'Roboto', sans-serif;
$font-two: 'Montserrat', sans-serif;
$font-three: 'Roboto Condensed', sans-serif;
$font-four: 'VT323', monospace;

.font-one { font-family: $font-one !important; }
.font-two { font-family: $font-two !important; }
.font-three { font-family: $font-three !important; }
.font-four { font-family: $font-four !important; }

body {
  // padding-top: 45px;
  padding-top: 39px;
  padding-top: 58px;
  overflow-x: hidden;
  //font-family: $font-one;
  background-color: $ssf-gray-l7;
  footer { background-color: #1A1E21; }

  img[src^="https://p1.zemanta.com/"] {
    display: none !important;
    height: 0 !important;
    width: 0 !important;
  }
}
body.main {
  //transition: padding-top .5s;
  &.ht0 {
    padding-top: 245px;
    &.ht1 { padding-top: 273px; }
    &.ht1.ht2 { padding-top: 403px; }
  }
  &.ht1 {
    padding-top: 383px;
  }
  &.ht1.ht2 {
    padding-top: 513px;
  }
  //.sm section.bg-ssf-blue-l3 {border-bottom: 1px solid #dee2e6!important;}
  &.ht5 {
    padding-top: 120px;
  }
}

body.home {
  padding-top: 0;
  background-color: $ssf-blue-d2;
  /*background-color: #606060;
  background-image: url('/assets/images/buried.png');*/
  transition: padding-top 0s;
}

html, body, #root {
  height: 100%;
}

main {
  flex: 1 0 auto;
}

.popover {
  font-family: $font-one !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  //font-family: $font-secondary;
  line-height: 1.1;
}

.h1, h1 { font-size: 3.5rem !important; }
.h1p5   { font-size: 2.25rem !important; }
.h2, h2 { font-size: 1.6rem !important; }
.h3, h3 { font-size: 1.2rem !important; }
.h4, h4 {
  &.recharts-text{
    @include respond(tab){
      font-size: 0.8rem !important
    }
  }
    font-size: 1rem !important;
  }
.h5, h5 {
  &.recharts-text{
    @include respond(tab){
      font-size: 0.6rem !important
    }
  }
  font-size: 0.875rem !important;
}
.h6, h6 {
  &.recharts-text{
    @include respond(tab){
      font-size: 0.5rem !important
    }
  }
  font-size: 0.75rem !important;
}
.h7     { font-size: 0.7rem !important; }
.fs-13   { font-size: 0.8125rem !important; }
.fs-17   { font-size: 1.06rem !important; }

p {
  line-height: 1.6;
}

.xsmall { font-size: 70%; }

* {
  outline: none !important;
}

a {
  &:hover {
    outline: 0;
    text-decoration: none;
  }
  &:active {
    outline: 0;
    text-decoration: none;
  }
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}

.text-overflow-ellipse-line-2 {
  padding: 1px 1px 2px 1px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2 !important;
}
.text-overflow-ellipse-line-1 {
  padding: 1px 1px 2px 1px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 1 !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
}
.text-overflow-ellipse-line-3 {
  padding: 1px 1px 2px 1px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 3 !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
}
.recharts-tooltip-wrapper {
  z-index: 5;
}
.ssf-tooltip { padding: 4px 6px !important; }
.ssf-tooltip-custom {
  margin: 0px;
  padding: 2px 4px !important;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
  color: #000;
  font-size: 12px;
  .label {
    color: #666;
  }
  .value {
    margin-left: 6px;
  }
}
.custom-tooltip {
  margin: 0px;
  padding: 2px 4px !important;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
  color: #000;
  font-size: 12px;
  .label {
    color: #666;
  }
  .value {
    margin-left: 6px;
  }
}
.recharts-label.ssf {
  font-size: 11px !important;
  font-family: sans-serif;
}
.recharts-bar-rectangle path {
  //shape-rendering: crispedges;
  shape-rendering: optimizeSpeed;
}
.draggable .recharts-wrapper {
  cursor: unset !important;
}

.themeChart .recharts-default-legend {
  font-size: 10px;
  line-height: 1.3;
  .recharts-legend-item {
    width: 50%;
    text-align: left;
    margin-right: 0 !important;
    text-transform: uppercase;
    color: #888;
  }
}

.lh-90  { line-height: 0.9  !important; }
.lh-100 { line-height: 1    !important; }
.lh-125 { line-height: 1.25 !important; }

.progress {
  height: 0.4rem !important;
  margin: 0.4rem 0;
}
.bg-progress {
  background-color: $progress-bg;
}
.progress-sm {
  height: 0.25rem !important;
}

.progress.score {
  height: 25px !important;
  margin: 0;
  background-color: #eaeaea;
  .progress-bar {
    background-color: #a2a7ad;
    color: $white;
    font-weight: 600;
    text-align: left;
  }
}

.progress-marker {
  padding: 2px 0;
  position: relative;
  .marker {
    position: absolute;
    height: 100%;
    background: $gray-600;
    width: 1px;
    top: 0px;
    left: 49.5%;
  }
}

.tooltip {
  font-family: $font-one !important;
  font-size: 0.7rem !important;
  line-height: 1.1;
  &.w-sm .tooltip-inner {
    max-width: 160px;
  }
  &.show {
    opacity: 0.8;
  }
}

.text-muted-50 { opacity: 0.6; }

.text-gray-1 { color: gray("100"); }
.text-gray-2 { color: gray("200"); }
.text-gray-3 { color: gray("300"); }
.text-gray-4 { color: gray("400"); }
.text-gray-5 { color: gray("500"); }
.text-gray-6i { color: gray("600"); }
.text-gray-6 { color: $ssf-blue; }
.text-gray-7 { color: gray("700"); }
.text-gray-8 { color: gray("800"); }
.text-gray-9 { color: gray("900"); }
.text-gray-20 { color : $ssf-gray-20; }

.text-gray-5.active { color: $ssf-blue !important; }
.text-info { color: $ssf-green-l1 !important; }
.text-blue-info { color: $tag-blue !important; }
/*.navbar-dark {
  background-color: #606060 !important;
  background-image: url('/assets/images/buried.png');
}*/
.navbar-dark .form-control {
  border-color: $white;
}

.selected-card {
  border-left: 6px solid $ssf-blue;
  padding: 0 2px 0 6px;
}

::placeholder { color: gray("400") !important; opacity: 1; } /* Chrome, Firefox, Opera, Safari 10.1+ */
:-ms-input-placeholder { color: gray("400") !important; }    /* Internet Explorer 10-11 */
::-ms-input-placeholder { color: gray("400") !important; }   /* Microsoft Edge */

.nav-scroller .nav-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  color: gray("100");
  cursor: pointer;
}

.nav-scroller .nav {
  background-color: rgba($white, 0.25);
}
.nav-scroller ul.nav {
  border: 1px solid transparent;
  border-width: 1px 0;
}

.nav-underline .nav-link:hover {
  color: $white;
}

.nav-underline .active {
  font-weight: 500;
  color: $white;
}

.nav-plain .nav-link {
  color: $ssf-orange-d1 !important;
  font-size: 1.2rem;
  line-height: 1.1;
  border-bottom: 3px solid transparent;
  padding: 6px 2px 2px;
  &.active {
    color: $gray-900 !important;
    border-bottom-color: $ssf-orange-d1;
  }
}

.select {
  position: relative;
  padding: 12px 12px 36px;
  h4 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
/*
.nav-scroller {
  display: inline-flex;
  min-width: 100px;
  .nav {
    margin-left: -400px;
    transition: 1s;
    z-index: 2;
  }
  .nav.d-inline-flex {
    margin-left: 40px;
  }
  .btn {
    min-width: 100px;
    z-index: 5;
  }
}
*/
.dropdown-menu {
  padding: .5rem 0;
  font-size: .875rem;
  color: gray("600");
}
.nav-scroller .dropdown-menu {
  margin-top: -1px;
  margin-left: 5px;
}
.dropdown-item, .dropdown-header {
  padding: 2px .5rem;
}
.badge.badge-info {
  font-size: 11px !important;
}
h6.btn.btn-outline-info {
  padding: 3px 4px;
  font-size: 11px !important;
  font-weight: 700;
  line-height: 1;
}

.rounded-bottom-left { border-bottom-left-radius: 0.25rem !important; }
.rounded-bottom-right { border-bottom-right-radius: 0.25rem !important; }

.btn.broker {
  font-size: .9rem;
  font-weight: bold;
  line-height: 1.25;
  color: $white;
  border: none;
  white-space: normal;
  min-height: 48px;
  //&.active {
  //  color: gray("700");
  //  background-color: $ssf-orange-l2 !important;
  //}
  &:hover, &:focus, &.focus {
    text-decoration: none;
  }
}

.btn.broker {
  position: relative;
  .tick {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(15%, -25%);
    color: $green;
    background-color: #fff;
    height: 14px;
    width: 14px;
    line-height: 14px;
    border-radius: 75%;
  }
}

.login-form {
  font-size: 0.75rem !important;
  .form-group {
    margin-bottom: .5rem;
    label {
      color: gray('600');
      margin-bottom: 0;
    }
  }
}

.profile-form {
  font-size: 0.875rem !important;
  .form-group {
    label {
      color: gray('600');
      margin-bottom: .25rem;
    }
  }
}

.bg-warning { background-color: $ssf-orange !important; }
.text-warning { color: $ssf-orange !important; }
.border-gray-3  { border-color: gray("300") !important; }

.zi-5 { z-index: 5; position: absolute; }
.zi-5 { top: 0; left: 0; }
.home a.bg-light:hover { background-color: $white !important; }

$h-4-5: 2.25rem;
.mb-4-5, .my-4-5 { margin-bottom:  $h-4-5 !important; }
.mt-4-5, .my-4-5 { margin-top:     $h-4-5 !important; }
.pb-4-5, .py-4-5 { padding-bottom: $h-4-5 !important; }
.pt-4-5, .py-4-5 { padding-top:    $h-4-5 !important; }
$h-2p5: .75rem;
.pb-2p5, .py-2p5 { padding-bottom: $h-2p5 !important; }
.pt-2p5, .py-2p5 { padding-top:    $h-2p5 !important; }
.pl-2p5, .px-2p5 { padding-left:   $h-2p5 !important; }
.pr-2p5, .px-2p5 { padding-right:  $h-2p5 !important; }

.col-4p5 {
  -ms-flex: 0 0 37.5%;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.mr-lf-10 {
  margin-left: 10px;
}

.search-icon {
  position: absolute;
  right: 20px;
  // line-height: 70px;
  top: 50%;
  transform: translateY(-50%);
  // width: 70px;
  z-index: 5;
  opacity: 0.2;
  transition: line-height .5s, width .5s, font-size .5s;
  &.active {
    opacity: 0.75;
    cursor: pointer;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .search-box {
    .clear-icon {
      line-height: 35px;
      width: 15px;
      text-align: center;
      right: 35px;
      font-size: 15px !important;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .search-box {
    .clear-icon {
      line-height: 35px;
      width: 15px;
      text-align: center;
      right: 40px;
      font-size: 15px !important;
    }
  }
}

@media only screen and (min-device-width: 1024px) {
  .search-box {
    .clear-icon {
      line-height: 35px;
      width: 20px;
      text-align: center;
      right: 50px;
      font-size: 13px !important;
    }
  }
}

.clear-icon {
  position: absolute;
  right: 50px;
  z-index: 9;
  opacity: 0.2;
  font-size: 15px;
  transition: line-height .5s, width .5s, font-size .5s;
  &.active {
    opacity: 1;
    cursor: pointer;
  }
}

.search-icon.sm {
  right: 1rem;
  line-height: 50px;
}

.search-box {
  display: flex;
  align-items: center;
  input {
    font-size: 2.5rem;
    height: 4.5rem;
    padding-right: 4.5rem;
    font-weight: 700;
    transition: font-size .5s, height .5s;
  }
  .form-control { background-color: $ssf-gray-l3; }
  .form-control:focus {
    background-color: $white;
    /* border-color: $ssf-orange-d1;
    box-shadow: none; */
  }
}
.dynamic {
  &.e1 { height: 144px; }
  &.e2 { height: 70px; }
  transition: height .5s;
}
.sm .dynamic {
  &.e1 { height: 55px; }
  &.e2 { height: 34px; }
  .search-box {
    input {
      font-size: 1rem;
      height: 2.25rem;
      padding-right: 3.5rem;
      font-weight: normal;
    }
    .search-icon {
      line-height: 35px;
      font-size: 1rem;
      width: 30px;
      opacity: 0.75;
    }
  }
  .btn-sm, .nav-link { line-height: 1; }
}

@import "./modal.scss";
@import "./buttons.scss";
@import "./icons.scss";
@import "./animation.scss";
@import "./marquee.scss";
@import "./investments.scss";
@import "./navs.scss";

.border-top.border-dashed { border-top-style: dashed !important; }
.border-bottom.border-dashed { border-bottom-style: dashed !important; }
.display-grid-center-range {
  align-items: center;
  display: grid;
  // height: 70px;
}
.irs-from, .irs-to, .irs-single {
  // background: $ssf-blue-d3;
  color: #494949 !important;
  font-weight: 600;
  background: #dbdbdb;
  padding: 1px 10px;
  border-radius: 10px;
}
.irs-from::after, .irs-to::after, .irs-single::after {
  // border-color: $ssf-blue-d3 transparent transparent;
  border-color: #dbdbdb transparent transparent;
}
.irs-bar {
  height: 4px;
  background: $tag-blue;
  border-radius: 10px;
}
.irs-slider {
  background: $tag-blue;
  width: 3px;
  height: 10px;
  cursor: ew-resize;
}
.irs-line {
  height: 4.5px;
  width: 96%;
  overflow: hidden;
  margin: 0 auto;
  > * {
    background: none;
    // background-color: gray("400");
    background-color: #fff;
    height: 4.5px !important;
  }
  .irs-line-left { border-radius: 15px 0 0 15px; }
  .irs-line-right { border-radius: 0 15px 15px 0; }
}
.irs-min, .irs-max {
  background-color: gray("400");
  visibility: hidden !important;
}

.collapsing {
  transition: height .35s ease;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}

.form-control:focus {
  border-color: $gray-400;
  outline: 0;
  box-shadow: 0 0 0 2px rgba($gray-400, 0.4);
  &.form-control-lg {
    box-shadow: 0 0 0 .2rem rgba($gray-400, 0.4);
  }
}

div.editable {
  border: 1px dashed rgba(255, 255, 255, 0.5);
  color: $gray-800;
  line-height: 1.2;
}

.overlay {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($white, 0.75);
  z-index: 2;
  cursor: pointer;
  &.active {
    display: block;
  }
  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    padding: 12px;
    color: $gray-600;
    background-color: $ssf-gray-l5;
    transform: translate(-50%,-50%);
  }
}

.icon-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed !important;
}

.p-2s {
  padding-bottom: 0.2rem !important;
}

.table-sm th,
.table-sm td {
  padding: 0.6rem 0.4rem;
}

.w-80 {
  width: 80% !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.ps__thumb-y {
  width: 4px;
  background-color: #999;
}
.ps__rail-y {
  width: 6px;
}
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #888;
  width: 4px;
}

.badge-info {
  color: #fff;
  background-color: #1a7166;
}

.flipster {
  .flipster__item--past-2, .flipster__item--future-2 {
    opacity: 0;
    visibility: hidden;
  }
  .flipster__item:not(.flipster__item--current) h6 {
    visibility: hidden;
  }
  .flipster__item img {
    border-radius: 4px;
  }
}

.star-badge {
  background: #202529;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  border-radius: 2px;
  transition: all 0.3;
}
.star-badge:before,
.star-badge:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  background: #202529;
}
.star-badge:before {
  transform: rotate(30deg);
}
.star-badge:after {
  transform: rotate(60deg);
}

.view_res_funds_details_btn {
  .active {
    color: #fa7805;
    border-color: #fa7805;
  }
  i {
    margin: 0;
    font-size: 24px;
    color: #e5e5e5;
  }
  img {
    width: 21px;
    height: 21px;
    // padding: 3px;
    // border: 1px solid #fa7805;
    // border-radius: 50px;
  }
}

.relevance-modal-body {
  overflow-y: scroll !important;
}

@media only screen and (max-width: 768px) {
  .relevance-modal-body{
    .viewChartsColumn {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      // min-height: 88px;

      .viewDropdownContainer {
        width: 100%;
        // min-height: 69px;

        .viewDropdownWrapper {
          width: 50%;
          min-width: 200px;
          margin: 0 auto;
          .viewBtn {
            width: 80%;
            text-align: center;
            justify-content: center;
            margin: 5px auto;
            line-height: normal !important;
            font-size: 14px !important;
          }
          .viewDropDownList {
            min-width: 160px !important;
            .viewDropDownList-Child {
              button {
                font-size: 14px !important;
              }
              .dynamic-chart-wrap {
                border-bottom: 2px solid #efefef !important;
              }
            }
          }
        }
        .view-text-container {
          // width: 130px !important;
          .slick-slider {
            width: 136px;
            width: 160px;
            .slick-slide {
              margin: 0 2px;
            }
            .slick-list .slick-track {
              display: flex;
              align-items: center;
            }
            .slick-arrow {
              width: 24px;
              height: 24px;
              font-size: 20px;
              color: #4a4a4a;
              line-height: 1;
            }
            .slick-disabled {
              color: #bababb;
            }
            .slick-prev {
              left: -24px;
            }
            .slick-next {
              right: -24px;
            }
            .slick-dots {
              bottom: -33px;
              ul {
                height: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                padding: 0;
                li {
                  width: 8px;
                  height: 8px;
                  margin: 0 5px;
                  padding: 0;
                  button {
                    font-size: 0;
                    line-height: 0;
                    width: 8px;
                    height: 8px;
                    padding: 0;
                    &:before {
                      font-size: 8px;
                      line-height: 8px;
                      width: 8px;
                      height: 8px;
                    }
                  }
                }
              }
            }
          }
          // margin-bottom: 0;
          // margin-top: 3px;
          .view-text-label {
            font-size: 16px !important;
            line-height: normal;
            overflow-wrap: break-word;
          }
        }
        .view-sub-title-container {
          width: 50%;
          min-width: 200px;
          min-height: 33px;
          margin: 0 auto;
          h6 {
            font-size: 13px !important;
            line-height: normal;
          }
          button {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px !important;
          }
          .dropdown-menu {
            min-width: unset !important;
          }
        }
        .static-chart-list-wrap {

        }
        .dropdown-item-header {
          font-weight: 700;
          color: #1b8be0;
          cursor: none;
          pointer-events: none;
        }
        .divider {

        }
        .dropdown-item {
          &:hover {
            color: #212529 !important;
            background-color: #dff1ff !important;
          }
          &:focus {
            color: #212529 !important;
            background-color: #dff1ff !important;
          }
        }
      }
    }
  }
}

.d3Tooltip {
  display: none;
  position: fixed;
  z-index: 9999;
  margin: 0px;
  padding: 2px 4px !important;
  white-space: nowrap;
  font-size: 12px;
  color: #000;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  transition: all 0.3s;
  .label {
    // color: #666;
  }
  .value {
    // margin-left: 6px;
  }
}

.mobile-d3Tooltip {
  display: none;
  width: max-content;
  margin: 0 auto;
  padding: 4px 6px !important;
  white-space: nowrap;
  font-size: 12px;
  color: #000;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  .label {
    // color: #666;
  }
  .value {
    // margin-left: 6px;
  }
}

.download-pdf-btn {
  background: transparent;
  border-color: transparent;
  &:hover, &:focus, &:active {
    background: transparent !important;
    border-color: transparent !important;
    color: #56a9e8 !important;
  }
}

.truncated-name-card {
  width: 100%;
  height: 100%;
  max-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .sponsored-logo-tag {
    position: unset;
    width: 70px;
    line-height: 1;
    height: 15px;
    // height: max-content;
    // width: max-content;
    margin-bottom: 0.25rem !important;
    padding: 3px 5px;
    // line-height: normal;
    font-family: 'Roboto';
    font-size: 10px;
    color:#ffffff;
    text-align: center;
    border-radius: 3px;
    background-color:#4a4a4a;
  }

  .funds-image-holder {
    padding: 0 !important;
    margin-bottom: 0.25rem !important;
  }

  .funds-name {
    padding: 0 !important;
  }
}

.search-clear-icon {
  position: absolute;
  right: 20px;
  z-index: 9;
  opacity: 0.2;
  font-size: 15px;
  transition: line-height .5s, width .5s, font-size .5s;
  &.active {
      opacity: 1;
      cursor: pointer;
  }
  &.inactive {
    opacity: 0.5;
    pointer-events: none;
  }
}

/* expand-collapse button css*/
.react-expand-collapse__content {
  position: relative;
  overflow: hidden;
  padding-right: 4px;
}

.react-expand-collapse__body {
  display: inline;
}

/* expand-collapse button*/
.react-expand-collapse__button {
  color: #22a7f0;
  position: absolute;
  bottom: 0;
  right: 2px;
  background-color: #fff;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.react-expand-collapse__button:before {
  content: '';
  position: absolute;
  top: 0;
  left: -20px;
  width: 20px;
  height: 100%;
  background: linear-gradient(to right, transparent 0, #fff 100%);
}

/* expanded state */
.react-expand-collapse--expanded .react-expand-collapse__button {
  padding-left: 5px;
  position: relative;
  bottom: auto;
  right: auto;
}

.react-expand-collapse--expanded .react-expand-collapse__button:before {
  content: none;
}
