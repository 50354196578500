$background: #f7f7f7;
$border: #b7b7b7;
$text-color: #4a4a4a;
$text-color-highlighted: #56a9e8;
$fontRoboto: 'Roboto';
$fontOpenSans: 'Open Sans';
.transfer-acc-popup{
    .spinner-wrapper{
        background-color: transparent !important;
    }
    max-width: 760px !important;
    .modal-content{
        border-radius: 40px;
        border:0;
        padding:20px;
    }
    .header-container{
        width: 100%;
        float: left;
        position: relative;
        text-align: center;
        
    }
    .header-name{
        margin-bottom: 10px;
        width: 100%;
        float: left;
        font-size: 1.875rem !important;
        font-weight: normal;
        line-height: 1.18;
    }
    .closeButton{
        position: absolute;
        right: 0;
        top: -9px;
        font-size: 23px;
        font-weight: 100;
        opacity: .5;
        cursor:pointer;
    }
    .label{
        width:100%;
        margin: 0;
        font-size: 0.875rem !important;
        color: #4a4a4a;
    }
    .transfer-acc-input{
        width:100%;
        text-overflow: ellipsis;
        max-width: 327px;
        overflow: hidden;
        // height: auto;
        min-height:40px;
        padding: 7px 0;
        font-size: 1rem !important;
        color: #4a4a4a;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid;
        border-bottom-color: #4a4a4a;
        transition: 0.2s ease all;
        &:focus{
            box-shadow: none !important;
            outline: 0px !important;
            border-bottom-color: #4a4a4a !important;
        }
        &.btn{
            height: 40px;
        }
    }
    .transfer-acc-dropdown-input {
        max-width: 300px;
    }
    .transfer-acc-dropdown{  
        width: 500px;
        max-height: 90vh;
        overflow-y: auto;
        .transfer-acc-dropdown-items{
            white-space: normal;
        }
    }

    .dropdown-element .dropdown-wrapper .dropdown-toggle {
        width: 100%;
        min-height: 34px;
        text-align: left;
    }
    .add-btn{
        align-self: flex-end;
        width: 150px;
        height: 40px;
        border-radius: 24px;
        padding: 0 18px;
        border: solid 1px #56a9e8;
        background: none;
        font-size: 14px;
        font-family: "Roboto";
        color: #56a9e8;
        text-transform: uppercase;
        font-weight: 500;
    }
    .submit-btn{
        margin: 0 5px;
    }
    .ticker-container{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 20px;
        background: #7c7c7c1f;
        align-items: center;

        input{
            max-width: 240px;
        }
    }
    .submit-container{
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 20px 0 0 0;
        button{
            cursor: pointer;
        }
    }
    .delbtn{
        float:right;
        position: absolute;
        top:28px;
        right:-20px;
      }
    .error-field{
          font-size: 0.8rem;
          bottom: 12px;
          color:#a5685f;
    }
    .hor-line{
        border-top: 1px solid #ccc;
    }
    .transferSelect, .transferSelect.ant-select-focused{
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
        padding-top: 8px !important;
        outline: none !important;
        &:focus{
            outline: none !important;
        }
        .ant-select-selection-search-input{
            padding-right: 6px;
        }
        .ant-select-selector{
          border: none !important;
          border-bottom: 1px solid #000 !important;
          outline: none !important;
          box-shadow: none !important;
        }
        &:after {
            -webkit-animation: none !important;
            -moz-animation: none !important;
            -o-animation: none !important;
            -ms-animation: none !important;
            animation: none !important;
        }
      }
}
