$c1 : #56a9e8;
$c2 : #4a4a4a;
$c3 : #bababb;
$c4 : #1e5f91;
$c5 : #f7f7f7;
$c6 : #343a40;
$c7 : #0056b3;
$c8 : #212529;

body.accounts {
  background: #F9FAFC !important;
  background: $c5 !important;


  #investment {
    .nav-justified .nav-link {
      cursor: pointer;
      &:not(.active) {
        color: inherit;
        background-color: rgba(255, 255, 255, 0.5);
      }
      &.active {
        color: #fff;
      }
    }
  }
  .row.compact {
    margin-left: -.25rem;
    margin-right: -.25rem;
    &> [class*="col-"] {
      padding-left: .25rem;
      padding-right: .25rem;
    }
  }

  .bg-wave1 {
    background: no-repeat bottom url('/assets/images/wave-1.png') transparent;
    background-size: 100%;
  }
  .bg-wave2 {
    background: no-repeat bottom url('/assets/images/wave-2.png') transparent;
    background-size: 100%;
  }


  .tx-c1 { color: $c1; }
  .tx-c2 { color: $c2; }
  .tx-c3 { color: $c3; }
  .fw-b  { font-weight: bold; }
  .fw-n  { font-weight: normal; }

  .fs-l1 {
    font-size: 1.75rem !important;
  }
  .fs-m1 {
    font-size: 1rem !important;
  }
  .fs-s1 {
    font-size: .875rem !important;
  }
  .nav-pills .nav-link.active {
    background-color: $c4;
  }
  .header.dropdown, .btn-link {
    font-size: .875rem !important;
    color: $c1;
    font-family: 'Roboto', sans-serif !important;
  }
  .sfmodal-comb-drpdwn {
    font-size: 1rem !important;
    color: $c8;
    font-family: inherit !important;

    &:hover {
      color: $c7 !important;
    }
  }
  .sfmodal-date, .sfmodal-unit {
    font-size: .75rem !important;
    color: $c6;
    font-family: inherit !important;

    &:hover {
      color: $c7 !important;
    }
  }
  .mx-0p5 {
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }

  .bg-c5 { background-color: $c5 !important; }

  .ps-wrap {
    margin-right: -10px;
    padding-right: 10px;
    position: relative;
    height: 511px;
    .ps__rail-y { width: 4px; }
    .ps__thumb-y { right: 0px; }
  }
}
