.addMoney {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .close {
    position: absolute;
    top: -10px;
    right: -4px;
    font-size: 18px;
    opacity: .5;
    cursor: pointer;
  }

  .header {
    margin-top: 14px;
    font-size: 22px;
    font-family: 'Open Sans', serif;
    color: #2f2f2f;
    margin-bottom: 16px;
  }

  .beforeAdd {
    display: flex;
    align-items: center;
    flex-direction: column;

    .addMoneyInput {
      border: none;
      border-bottom: 1px solid #666666;
      color: #1A1E21;
      font-weight: 700;
      font-size: 22px;
      width: 70%;
      padding: 4px 20px;
      text-align: center;
      margin-bottom: 26px;
    }

    .confirm {
      background-color: #56a8e8;
      font-family: 'Open Sans', serif;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      width: 200px;
      border: none;
      text-transform: uppercase;
      padding: 4px 10px;
      border-radius: 29px;
      height: 40px;
      cursor: pointer;
      &:disabled{
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }
  .errorWithdrawal{
    margin-bottom: 4px;
    margin-top: -20px;
    color: #a5685f;
  }

  .afterMoney {
    display: flex;
    align-items: center;
    justify-content: center;
    .div{
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
    .confirm {
      background-color: #56a8e8;
      font-family: 'Open Sans', serif;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      width: 200px;
      border: none;
      text-transform: uppercase;
      padding: 4px 10px;
      border-radius: 29px;
      height: 40px;
      cursor: pointer;
    }
    .headerBold {
      text-align: center;
      color: #1A1E21;
      font-weight: 700;
      font-size: 22px;
    }

  }

}
.investmentPrice{
  font-family: "Open Sans", Roboto, sans-serif;
  font-size: 0.875rem !important;
  text-align: center;
  color: #6e757c;
  margin-bottom: 0px;
  line-height: 21px;
  font-weight: 400;
  // @media (min-width: 1920px) and (max-width: 4000px) {
  //   font-size: 24px !important;
  // }
}
.investmentPriceText{
  margin: 0 0 10px 0;
  font-family: "Open Sans", Roboto, sans-serif;
  font-size: 0.75rem;
  text-align: center;
  line-height: 1 !important;
  color: #343a40;
  font-weight: 400;
  // @media (min-width: 1920px) and (max-width: 4000px) {
  //   font-size: 13px;
  //   margin: 6px 0 0;
  // }
}
.radioInput {
  font-family: "Open Sans";
  font-size: 12px !important;
  @media (min-width: 1920px) and (max-width: 4000px) {
    font-size: 14px !important;
    letter-spacing: -0.35px;
  }
}
