@import "../../BuildPortfolio/colors";
// Configuration settings for PDF
$dark-blue: #101828;
$light-blue: #101828;

$lvl1-header-font-family: 'Inter', sans-serif;
// $lvl1-header-font-color: #1d6091;
$lvl1-header-font-size: 2.2rem;

$lvl2-header-font-family: 'Inter', sans-serif;
// $lvl2-header-font-color: #1d6091;
$lvl2-header-font-size: 1.6rem;
$lvl3-header-font-size: 1.4rem;

$body-font-family: 'Inter', sans-serif;
$body-font-color: #989898;
$body-font-size: 1.2rem;

$chart-label-grid-color: #eaeaea;

$pg-px: 12mm;

.excludeTickerStyle {
  margin-top: 1em;
  font-size: 1.1rem;
  font-weight: 600;
  color: #4a4a4a;
}

.titleH4 {
  font-size: $lvl1-header-font-size !important;
  font-family: $lvl1-header-font-family;
  color: $dark-blue;
  margin-top: 0;
  margin-bottom: 15px;
}

.subsectionH5 {
  font-size: $lvl3-header-font-size !important;
  font-family: $lvl2-header-font-family;
  color: $dark-blue;
  margin-top: 8px;
  margin-bottom: 8px;
}

.card {
  background: white !important;
  display: inline-flex;
  border: 1px solid #f5f5f5;
  flex-direction: column;
  margin: 10px;
  border-radius: 4px;
  min-width: 250px;
  width: 252px;

  .relevanceChart {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .topCard {
    height: 100px !important;

    .short_name {
      padding: 4px 0;
      font-size: 12px !important;
    }
  }
}

.cardGrayBackground {
  background: #f6f6f6 !important;
  margin-left: 0px;
}

.cardBlueBorder {
  border: 4px solid #dff1ff;
  width: 258px;
}

.otherResultCards {
  background: #f6f6f6;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  padding: 25px 0;
}

.keyFactsWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .border {
    margin: 0 20px;
    border-right: 2px solid #dff1ff;
    max-height: 30px;
  }

  .keyFacts {
    display: flex;
    .expense {
      display: flex;
      p{
        margin: 0;
      }
      p:nth-child(2){
        margin-left: 20px;
        color: $dark-blue;
      }
    }
    .aum {
      display: flex;
      p{
        margin: 0;
      }
      p:nth-child(2){
        margin-left: 20px;
        color: $dark-blue;
      }
    }
    .underIndex {
      display: flex;
      position: relative;
      width: 500px;
      p{
        margin: 0;
      }
      p:nth-child(2){
        margin-left: 20px;
        color: $dark-blue;
        max-width: 310px;
        position: absolute;
        left: 180px;
      }
    }
  }
}

.titleText {
  font-size: 1.8rem !important;
  font-family: $lvl1-header-font-family;
  color: $dark-blue;
  margin-top: 40px;
  margin-bottom: 15px;
}

.replacementTable {
  min-width: 100%;

  .row {
    border-top: 1px solid #dff1ff;
    border-bottom: 1px solid #dff1ff;
  }

  .index {
    font-family: $lvl2-header-font-family;
    min-width: 40px;
    width: 40px;
    padding: 0 4px;
    color: #d0d0d0;
    font-size: 22px;
    font-weight: 500;
  }

  .current, .replacement {
    min-width: 350px;
    padding: 10px 0;
    font-size: 1.4rem;
    color: $dark-blue;
    font-weight: 500;
    font-family: $lvl1-header-font-family;
  }

  .swap {
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $chart-label-grid-color;
  }


}

.existingHoldingWrapper {
  display: flex;
  margin: 0 0 22px 0;
  flex-direction: column;
  h5{
    margin-bottom: 16px;
  }
  div span {
    font-family: $lvl2-header-font-family;
    font-weight: 500;
  }

  p {
    margin: 0;
  }

}

.tickerCardSmall {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 0;

  .sponsorLogo {
    max-height: 30px;
    height: 24px;
    width: auto;
  }

  .name, .ticker {
    margin: 8px 0;
    font-size: 18px;
    font-family: $lvl2-header-font-family;
  }

  .ticker {
    margin: 0;
  }
}

.suggestedReplacementWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 620px;
}

.tickerLogo {
  height: 30px;
  width: auto;
  margin: 0 30px;
}
.noReplaceText{
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: $lvl2-header-font-family;
}
.pageBreak{
  page-break-before: always;
  page-break-after: always;
  display: block;
}
@media print {
  .pageBreak{
    page-break-before: always;
    page-break-after: always;
    display: block;
  }
}
