
.SFM-v2-container {
	.SFM-v2-wrapper {

	}
}

.combine_no_data{
	position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: 1;
	justify-content: center;
    display: flex;
    align-items: center;
	span{
		font-size: 18px;
    	font-weight: 600;
	}
}

.SFM-connect-broker-btn {
	padding: 8px 8px !important;
	width: 100%;
	font-size: 10px !important;
	word-break: break-word;
	white-space: break-spaces;
	vertical-align: middle;
}
.enableOnlyActTab {
	.ant-tabs-top-bar, .ant-tabs-card-bar {
		.ant-tabs-nav-container {
			.ant-tabs-nav-wrap {
				.ant-tabs-nav {
					width: 100%;
					.ant-tabs-tab {
						width: 100% !important;
						.SFM-v2-tab-head-wrap {
							.SFM-v2-tab-head-title {

							}
							.SFM-v2-tab-sub-head {
							}
						}
						&:last-child {
						}
					}
					.ant-tabs-tab-active {
						background-color: #6c6c6c !important;
						border-color: #6c6c6c !important;
						.SFM-v2-tab-head-wrap {
							color: #fff !important;
							.SFM-v2-tab-head-title {
							}
							.SFM-v2-tab-sub-head {
							}
						}
					}
				}
			}
		}
	}
}

.SFM-v2-tabs {
	// min-height: 557px;
	font-size: unset !important;
	.sfm-combine-tab-wrapper {
		.sfm-impact-window-wrapper {
			margin: 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			border: 2px solid #707070;
			.icon {
				width: auto;
				height: 68px;
				margin-bottom: 25px;
			}
			.title {
				margin-bottom: 25px;
				font-family: 'Roboto';
				font-size: 26px;
				font-weight: 500;
			  font-stretch: normal;
			  font-style: normal;
			  line-height: 1.18;
			  letter-spacing: normal;
			  text-align: center;
				color: #1e5f91;
			}
			.sub-title {
				margin-bottom: 20px;
				font-family: 'Open Sans';
		    font-size: 26px;
		    font-weight: normal;
		    font-stretch: normal;
		    font-style: normal;
		    line-height: 1.38;
		    letter-spacing: normal;
		    text-align: center;
		    color: #1e5f91;
			}
			.primary-button {
				width: 265px;
				font-family: 'Open Sans';
				font-size: 14px;
		    font-weight: normal;
		    font-stretch: normal;
		    font-style: normal;
		    line-height: 1.38;
		    letter-spacing: 0.19px;
		    text-align: center;
		    color: #ffffff;
		    background: #1d6091;
		    border: 1px solid #1d6091;
		    border-radius: 30px;
		    padding: 12px 40px;
		    text-transform: uppercase;
				transition: all 0.3s;
				cursor: pointer;
				&:hover {
					color: #1d6091;
					background: #fff;
				}
			}
		}
	}
	.sfm-act-tab-container {
		min-height: 438px;
		.sfm-act-tab-acc-balance-wrapper {
			position: absolute;
	    top: 12px;
	    font-family: $primary-font;
	    font-size: 10px;
	    font-weight: normal;
	    font-stretch: normal;
	    font-style: normal;
	    line-height: 1.4;
	    letter-spacing: -0.1px;
	    text-align: center;
	    color: $light-charcoal-black;
			.fa-wallet {
				margin-right: 4px;
			}
			.wallet-text {
				margin-right: 4px;
			}
			.fa-plus-circle {
				color: $primary-color;
				cursor: pointer;
			}
		}
		.custom-ant-checkbox {
			.ant-checkbox-inner {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				border-color: $orange-color !important;
				&:after {
					top: 45%;
					left: 19%;
					width: 5.714286px;
			    height: 11.142857px;
				}
			}
			.ant-checkbox-checked {
				&:after {
					border-radius: 50%;
					border-color: $orange-color !important;
				}
				.ant-checkbox-inner {
					width: 20px;
					height: 20px;
					border-radius: 50%;
					background-color: $orange-color !important;
					border-color: $orange-color !important;
					&:after {
						top: 45%;
						left: 19%;
						width: 6.714286px;
						height: 13.142857px;
						border-color: #ffffff !important;
					}
				}
			}
			&:hover, &:focus {
				border-color: $orange-color !important;
				.ant-checkbox-inner {
					border-color: $orange-color !important;
				}
			}
			.ant-checkbox {
				width: 20px;
				height: 20px;
				.ant-checkbox-input {
					width: 20px;
					height: 20px;
				}
			}
		}
		.danger-checkbox{
			left: 7px;
			color: #943131;
			font-size: 13px;
			font-family: 'Roboto';
			font-weight: 600;
			&:hover, &:focus{
			  .ant-checkbox-inner{
				border-color: #e26b02 !important;
			  }
			}
			.ant-checkbox-inner{
			  border-radius: 5px;
			  border: 1px solid #e26b02;
			}
			.ant-checkbox-checked{
			  .ant-checkbox-inner{
				background-color: #e26b02 !important;
				border-color: #e26b02 !important;
				&::after{
				  border-color: #ffffff !important;
				}
			  }
			}
		}
		.ant-radio {
			input[type='radio'] {
				width: 100% !important;
			}
			.ant-radio-input {
				width: 100% !important;
			}
		}
	}
	.SFM-v2-tab-head-wrap {
		font-weight: 600;
		text-align: center;
		color: #fff;
		.SFM-v2-tab-head-title {
		}
		.SFM-v2-tab-sub-head {
			font-size: 0.7rem;
			margin-top: -4px;
			text-transform: capitalize;
		}
	}
	.draggable.recharts-responsive-container.recharts-wrapper.recharts-surface {
		.recharts-label, tspan {
			font-size: 11px !important;
			font-family: sans-serif;
		}
		.recharts-cartesian-axis-tick-value {
			tspan {
				font-family: 'Roboto';
				font-size: 11px !important;
			}
		}
	}
	.recharts-wrapper {
		.recharts-label, tspan {
	    font-size: 11px !important;
	    font-family: sans-serif;
		}
		.recharts-cartesian-axis-tick-value {
			tspan {
				font-family: 'Roboto';
				font-size: 11px !important;
			}
		}
	}
	.sfm-modal-footer {
		.ant-btn {
			height: unset !important;
		}
	}
	.ant-tabs-content, .ant-tabs-top-content, .ant-tabs-bottom-content {
		min-height: 496px;
		.itemcard-disabled {
			background: #e3e2e2 !important;
			background-color: #e3e2e2 !important;
			.progress-wrapper {
				background: #e3e2e2 !important;
				background-color: #e3e2e2 !important;
				.progress-box .score {
					background: #dddddd !important;
					background-color: #dddddd !important;
				}
			}
			.itemcard-disabled-text {
				padding: 4px 8px;
				font-family: $primary-font;
		    font-size: $font-size-12px;
		    letter-spacing: 0.24px;
		    text-align: left;
		    color: #4a4a4a;
			}
			.selected-card {
				background: #e3e2e2 !important;
				background-color: #e3e2e2 !important;
			}
		}
	}
	.ant-tabs-top-bar, .ant-tabs-card-bar {
		margin: 0;
		background-color: transparent;
    border-color: transparent;
    box-shadow: none;
		.ant-tabs-nav-container {
			.ant-tabs-nav-wrap {
				.ant-tabs-nav {
					width: 100%;
					div{
						// width: 100%;
						&:first-child:nth-last-child(n + 3), &:first-child:nth-last-child(n + 3) ~ .ant-tabs-tab {
							width: calc(100%/3);
						}
						&:first-child:nth-last-child(n + 4), &:first-child:nth-last-child(n + 4) ~ .ant-tabs-tab {
							width: calc(100%/4);
						}
					}
					.ant-tabs-tab {
						// width: calc(100%/4);
				    margin: 0;
						color: #fff;
				    text-transform: uppercase;
				    border: 0;
						border-radius: 0;
				    border-right: 1px solid #797c7e;
				    background-color: #6c6c6c;
						.SFM-v2-tab-head-wrap {
							color: #fff;
							font-weight: 600;
							line-height: 1.4;
							.SFM-v2-tab-head-title {

							}
							.SFM-v2-tab-sub-head {
							}
						}
						&:last-child {
							// border-right-color: #6c6c6c;
							border-right-color: transparent;
						}
					}
					.ant-tabs-tab-active {
						background-color: transparent;
				    border-color: transparent;
				    box-shadow: none;
						.SFM-v2-tab-head-wrap {
							color: #05487d;
							font-weight: 600;
							.SFM-v2-tab-head-title {
							}
							.SFM-v2-tab-sub-head {
							}
						}
					}
				}
			}
		}
	}
	.actionBS-group{
		background-color: #b7b7b7;
		border-radius: 3px!important;
		.actionBS{
			background-color: #b7b7b7;
			color: #919191;
			height: 30px;
			display: flex;
			align-items: center;
			border: none;
			font-weight: 600;
			font-size: 13px;
			font-family: 'Roboto', serif;
			padding: 2px 14px;
			&:focus{
				box-shadow: none!important;
			}
			&:active{
				background-color: #b7b7b7!important;
				color: #919191!important;
				border: none!important;
			}
			&:hover{
				z-index: 0!important;
			}
		}
		.actionBSActive{
			background-color: white;
			color: #1a7166;
			box-shadow: 0px 0px 4px 0 #1A1E2130!important;
			z-index: 1;
			border-radius: 3px!important;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
			&:focus{
				box-shadow: 0px 0px 4px 0 #1A1E2130!important;
			}
			&:active{
				background-color: white!important;
				color: #1a7166!important;
				border: none!important;
			}
			&:hover{
				z-index: 3!important;
			}
		}
		.actionBSActiveSell{
			color: #943131;
			&:active{
				color: #943131!important;
			}
		}
	}
	.add-money{
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: start;
		align-self: start;
		padding: 6px;
		div{
			div{
				display: flex;
				padding-bottom: 4px;
				span{
					font-weight: 600;
				}
				span:first-child{
					font-size: 16px;
					color: #858585;
				}
				span:last-child{
					font-size: 16px;
					color: #4a4a4a;

				}
			}
		}

		.add-money-btn{
			cursor: pointer;
			color: #ce6015;
			text-transform: uppercase;
			font-size: 12px;
			text-decoration: underline;
		}
	}

}

.add-money-popup-sfm{
	max-width: 430px !important;
	.modal-content {
		border: 0;
		border-radius: 25px;
		background-color: white;
	}
	.modal-body {
		padding: 1.5rem;
	}
}
.combineDropdownContainer{
	border-radius: 10px;
	overflow: hidden;
	z-index: 11111;
	max-width: 220px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	.MenuWrapper{
		position: relative;
		max-height: 220px;
		overflow-y: auto;
		max-width: 220px;
		.myPfItems{
			padding: 0!important;
			display: flex;
			max-width: 220px;
			height: 20px!important;
			font-size: 12px;
			transition-duration: 0s;
			margin: 0;
			margin-left: 0px!important;
			margin-bottom: 0!important;
			align-items: center;
			justify-content: space-between;
			p{
				margin: 0;
				padding-left: 10px;
				padding-right: 10px;
				width: 100%;
				color: #1e1e1e !important;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				transition-duration: 0s;
			}
			&:hover{
				transition-duration: 0s;
				color:#1e1e1e !important;
				background-color: #dff1ff !important;
			}
		}
		.ant-menu-submenu{
			height: 20px!important;
			padding: 0;
			margin: 0;
			font-size: 12px;
			.ant-menu-submenu-title{
				height: 20px!important;
				padding-left: 10px;
				margin: 0;
				font-size: 12px;
				line-height: 1.6;
				color:#1e1e1e !important;
			}
			&:hover{
				transition-duration: 0s;
				color:#1e1e1e !important;
				background-color: #dff1ff !important;
			}
		}

		li:first-child{
			margin-top: 6px;
			//margin-bottom: 10px!important;
		}
		li:last-child{
			margin-bottom: 6px!important;
		}
		&::-webkit-scrollbar {
			width: 4px !important;
			border-radius: 6px;
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			width: 4px !important;
			border-radius: 6px;
			background-color: $in-active-color ;
		}
	}
}
.SubMenuWrapper{
	border-radius: 10px!important;
	z-index: 11111;
	max-height: 220px;
	max-width: 220px;
	overflow: hidden;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
	ul{
		max-height: 150px!important;
		overflow: scroll;
		z-index: 11122;
		border-radius: 10px!important;
		.myPfItems{
			padding: 0!important;
			display: flex;
			height: 20px!important;
			font-size: 12px;
			max-width: 220px;
			transition-duration: 0s;
			margin: 0;
			margin-left: 0px!important;
			margin-bottom: 0!important;
			align-items: center;
			justify-content: space-between;
			p{
				margin: 0;
				padding-left: 10px;
				padding-right: 10px;
				width: 100%;
				color: #1e1e1e !important;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				transition-duration: 0s;
			}
			&:hover{
				transition-duration: 0s;
				color:#1e1e1e !important;
				background-color: #dff1ff !important;
			}
		}
		li:first-child{
			margin-top: 6px;
			//margin-bottom: 10px!important;
		}
		li:last-child{
			margin-bottom: 6px!important;
		}
		&::-webkit-scrollbar {
			width: 4px !important;
			border-radius: 6px;
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			width: 4px !important;
			border-radius: 6px;
			background-color: $in-active-color ;
		}
	}

	.ant-menu-submenu{
		height: 20px!important;
		padding: 0;
		margin: 0;
		font-size: 12px;
		.ant-menu-submenu-title{
			height: 20px!important;
			padding-left: 10px;
			margin: 0;
			font-size: 12px;
			line-height: 1.6;
			color:#1e1e1e !important;
		}
		&:hover{
			transition-duration: 0s;
			color:#1e1e1e !important;
			background-color: #dff1ff !important;
		}
	}


}
.ddButtons {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	color: inherit;
	margin-top: 2px;
	display: flex;
	max-width: 220px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	&:hover{
		text-decoration: underline;
	}
}
.sub-title-models{
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: default;
}

.ant-menu-submenu.ant-menu-submenu-popup{
		#sponsorSearch {
			font-size: 12px!important;
		}
	.customSubmenuSearch {
	  padding:0px 12px!important;
		margin:5px 0px!important;

		&:hover{
			background:none!important;
		}
	  .ant-input-affix-wrapper{
	    border:none!important;
	    border-bottom:1px solid #000000!important;
	    padding-left:0px!important;
	  }
	}

	.customSubmenuLi {
	  padding:0px 12px!important;
		margin:0px!important;
	  height:auto!important;
	  line-height:inherit!important;
		font-size: 12px!important;
		color: #1e1e1e !important;

		&:hover{
			background:#dff1ff!important;
		}
	  p{
	    margin-bottom:0px!important;
			white-space: nowrap !important;
	    width: 200px;
	    overflow: hidden!important;
	    text-overflow: ellipsis !important;
			font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'!important;
	  }
	}
}
