

.premium-feature-process-modal-backdrop {
  display: block !important;
}

.premium-msg-modal {
  max-width: 630px !important;

  .modal-content {
    border: 0;
    border-radius: 15px;
    background-color: white;
    h1{
      color:red;
    }
  }
  .modal-body {
    padding: 3.5rem;
  }
}

@include respond(tab) {
  .premium-feature-process-modal {
    width: 80%;
  }
}

@include respond(phone) {
  .premium-feature-process-modal {
    width: 95%;
  }
}

.premium-feature-process-modal {
  max-width: 936px !important;

  .modal-content {
    border: 0;
    border-radius: 15px;
    background-color: #fff;
  }
  .modal-body {
    padding: 3.5rem;
  }
}

.fs-1 {
  font-size: 1rem !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left !important;
}

.justify-content-left {
  justify-content: left !important;
}

.premium-feature-process-modal-container {
  div.site-collapse-custom-collapse{
    div.ant-collapse-header{
      color: #7D7D7D;
      padding-left: 0px;
      width: 35%;
      span{
        svg{
          font-size: 15px !important;
        }
      }
    }
    div.ant-collapse-content-box{
      padding-left: 0px !important;
      padding-top: 0px !important;
      p{
        color: #7d7d7d !important;
      }
    }
    div.ant-collapse-item.site-collapse-custom-panel{
      border: #fff !important;
    }
    p{
      margin-bottom: 0px !important;
    }
  }
  .premium-feature-completed-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 9;
    font-size: 1.5rem;
    color: #1E5F91;
    cursor: pointer;
  }

  .premium-feature-completed-check {
    font-size: 6rem;
    text-align: center;
    color: #fff;
  }

  .header-wrapper {
    h1 {
      font-size: 1.7rem !important;
      // font-size: 2rem !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #1E5F91;
      font-family: 'Roboto'
    }
    h2 {
      font-size: 13px !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: #1E5F91;
    }
    p{
      font-family: 'Open Sans';
      font-size: 14px;
      color: #4a4a4a;
      margin-bottom: 15px;
    }
  }
  .premium-feature-title-text {
    padding: 0 30px;
    font-size: 32px;
    font-weight: 500;
    font-family: $secondary-font;
    line-height: 1.2;
    text-align: center;
    color: $label-black-color-2;
    .learn-more-text {
      color: $primary-color;
      cursor: pointer;
    }
  }
  .content-wrapper {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #1E5F91;
    margin-bottom: 1px;
  }

  .cta-wrapper {
    flex-direction: row;
    align-items: normal;
    .btn {
      // width: 140px !important;
      width: auto !important;
      font-size: 12px;
      font-weight: normal;
      padding: 13px 31px !important;
      line-height: 1;
      color: white;
      background-color: #1E5F91;
      border: 1px solid #1E5F91;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

@include respond(phone){

  .premium-msg-modal {
    .modal-content {

    }
    .modal-body {
      padding: 1rem;
    }
  }

  .premium-feature-process-modal {
    .modal-content {

    }
    .modal-body {
      padding: 1rem;
    }
  }

  .premium-feature-process-modal-container {
    .premium-feature-completed-close {
      right: 7px !important;
      top: 7px !important;
      font-size: 1.2rem !important;
    }

    .premium-feature-completed-check {

    }

    .header-wrapper {
      h1 {
        font-size: 1.1rem !important;
      }
      h2 {
        font-size: 13px !important;

      }
    }
    .content-wrapper {

    }
    .cta-wrapper {
      .btn {
        line-height: 1.2;
        font-size: 1rem !important;
        &:first-child {
          margin-right: 0.8rem;
        }
      }
    }
  }
}

$secondary-blue: #1e5f91;

.premium-feature-sub-heading{
  color: $secondary-blue;
  .fas{
    font-weight: 600;
    font-size: 12px;
    margin-left: 6px;
    &:hover{
      cursor: pointer;
    }
  }
}

.premium-msg-modal-sm-light {
  max-width: 560px !important;
  .premium-feature-sub-text {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: $secondary-blue;
    margin-bottom: 15px;
    .fas{
      font-weight: 600;
      font-size: 12px;
      margin-left: 6px;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .premium-feature-main-heading {
    color: $secondary-blue !important;
  }
  .close-light {
    color: $secondary-blue !important;
  }
  .modal-content {
    border: 0;
    border-radius: 15px;
    background-color: #f7f7f7;
  }
  .modal-body {
    padding: 3.5rem;
  }
}

.premium-msg-plan-details{
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: $secondary-blue;
  font-family: 'Open Sans';
  h4{
    color: $secondary-blue;
  }
  ul{
    height: 182px;
    overflow-y: scroll;
    list-style-type: none;
    padding-left: 20px;
    padding-right: 6px;
    &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 13px;
      background-color: transparent;
      margin-left: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;;
      background-color: #dbdbdb !important;
    }
  }
  ul li{
    line-height: 2;
    color: #4a4a4a;
    font-size: 14px;
    &::before {
      content: "\2022";
      color: $secondary-blue;
      font-weight: bold;
      display: inline-block;
      width: 0.5em;
      margin-left: -1em;
      font-size: 20px;
      height: 27px;
      float: left;
      margin-top: -5px;
    }
  }
  .terms-condition-select {
    span{
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: normal;
      color: $secondary-blue;
    }
    .terms-text {
      text-decoration: underline;
    }
  }
}

.premium-msg-raf{
  margin-top: 10px;
  margin-bottom: 0px;
  color: $secondary-blue;
  a{
    text-decoration: none;
    cursor: pointer;
    color: $secondary-blue;
    border-bottom: 1px solid $secondary-blue;
    &:hover{
      color: $secondary-blue;
    }
  }
}

.button-blue{
  .secondary-btn{
    background-color: $secondary-blue !important;
    color: #ffffff !important;
    border: 1px solid $secondary-blue !important;
  }
}

.payment-status-modal{
  .payment-message-image{
    img{
      width: 200px;
      margin-bottom: 30px;
    }
  }
  .payment-status-heading {
    h1{
      font-size: 35px !important;
      margin-bottom: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
    }
    .payment-success{
      color: $secondary-blue;
    }
    .payment-failure{
      color: #5e5e5e;
    }
  }
  .premium-feature-sub-text{
    color: rgba(0, 0, 0, 0.65);
  }
}

@include respond(phone){
  .premium-msg-plan-details{
    ul li{
      font-size: 12px !important;
    }
  }
}

.print-selection-preview-modal {
  width: 75vw;
  min-width: 800px;
  max-width: 1060px;
  .close-light {
    color: $secondary-blue !important;
  }
  .modal-content {
    border: 0;
    border-radius: 15px;
    height: 90vh;
    max-height: 800px;
    background-color: #f7f7f7;
  }
  .modal-body {
    height: 100%;
    width: 100%;
    padding: 30px 50px;
  }
}
