@import "./colors";
@import "./Elements/index";
@import "./Pages/BuildFromScratch/index";
@import "./Pages/OnBoardScreen/index";
@import "./Pages/AssetAllocation/index";
// @import "./header";
@import "./responsive";

.ant-tabs-tab-prev, .ant-tabs-tab-next {
  z-index: 0 !important;
}

.grey-bg {
  background-color: $tag-grey-bg-color !important;
  border: 1px solid $tag-grey-bg-color !important;
}

.blue-bg {
  background-color: $tag-blue-bg-color !important;
  border: 1px solid $tag-blue-bg-color !important;
}

.ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-submenu-arrow {
  display: flex;
  align-items: center;

  .anticon-right {
    margin-right: 0 !important;
  }
}

.ant-layout {
  background: #f7f7f7 !important;
}

.build-portfolio-content-wrapper {
  background: #f7f7f7 !important;
  position: relative;
  margin-top: 0rem !important;
  .donut-chart-container {
    position: relative;
    .donut-chart-center-label {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      // position: absolute;
      // top: 39%;
      // left: 25%;
      // width: 50%;
      // min-width: 70px;
      // height: 2rem;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // z-index: 2;
      text-align: center;

      .text {
        width: 50%;
        min-width: 70px;
        font-size: $font-size-12px;
      }
    }
  }
}
