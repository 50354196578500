//
// Variables
// --------------------------------------------------
@import "../layouts/WebDashboard/variables";

//## Font, line-height, and color for body text, headings, and more.
$default-font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial',
  sans-serif;
$font-awesome-pro: 'Font Awesome 5 Pro';

$font-s: 0;
$font-xs: 1rem;
$font-xs-s: 1.1rem;
$font-xs-m: 1.2rem;
$font-sm: 1.4rem;
$font-sm-m: 1.5rem;
$font-lg: 1.6rem;
$font-xl: 1.8rem;
$font-xl-s: 2rem;
$font-xl-ls: 2.1rem;
$font-xl-l: 2.2rem;
$font-xxl: 2.4rem;
$font-xxl-ll: 2.5rem;
$font-xxl-l: 2.8rem;
$font-xxxl: 3rem;

$font-sm-s: 14px;
$font-lg-m: 16px;
$font-xs-s-px: 11px;
$font-sm-m-px: 15px;
$font-lg-l: 25px;
$font-xl-ls-px: 21px;
$font-xl-px: 18px;
$font-xxl-l-px: 28px;
$font-xs-m-px: 12px;
$font-xs-px: 10px;
$font-xl-ss-px: 19px;
$font-lg-l-px: 17px;
$font-xs-m-px: 12px;
$font-xs-mm-px: 13px;
$font-xxxxlll-px: 42px;
$privacy-fontsize: 4rem;
$html-fontsize: 10px;

$font-weight-thin: 100;
$font-weight-thin-italic: 100;
$font-weight-light: 300;
$font-weight-light-italic: 300;
$font-weight-regular: 400;
$font-weight-regular-italic: 400;
$font-weight-medium: 500;
$font-weight-medium-italic: 500;
$font-weight-bold: 700;
$font-weight-bold-italic: 700;
$font-weight-black: 900;
$font-weight-black-italic: 900;

$body-width: 1080px;

$body-base-size: $font-lg;
$body-background-color: #fff;
$body-font-color: #333333;
$body-line-height: 1.45;
$default-font-weight: 400;
$label-font-weight: 200;
$semibold-font-weight: 500;
$bold-font-weight: 700;

$main-max-width: 61.25em;
$main-padding: 0 22px;
$main-padding-767: 0 25px;
$main-padding-320: 0 15px;

//-------------------------------
//		Container definition
//-------------------------------
$container-width: 100%;
$container-largewidth: 1080px;
$container-xlwidth: 1150px;

// ===============================
//      Color definition
// ===============================
$primary-color: #f36300;
$primary-color-hover: #ce6015;

$secondary-color: #000;
$secondary-color-hover: #333;

$tertiary-color: #3ea0ea;
$tertiary-color-hover: #0073b9;

$footer-link-color: #0070c9;

$gray20: #333;
$black: #000;
$white: #fff;
$blue: #08c;

$h2-fontsize: $font-xxl;
$h2-lineheight: 1.08654;
$h2-weight: 200;
$h2-letter-spacing: 0;

$h4-fontsize: $font-xl-l;
$h4-letter-spacing: 0;

$h5-fontsize: $font-xl;
$h5-letter-spacing: 0;

$p-fontsize: $font-lg;
$p-lineheight: 1.5;
$p-margin: 0 0 1em;

$p-legal-fontsize: $font-sm;
$p-legal-fontcolor: #737373;
$autofill-disabled: #bbbbbb;
//---------------------------------
// Footer variable definitions
//---------------------------------
$footer-background-color: #f2f2f2;
$footer-wrapper-padding: 18px 22px;
$global-footer-fontsize: $font-xxl;
$global-footer-padding: 50px 0 70px;
$global-footer-fontcolor: #bfbfbf;

//---------------------------------
// Profile variable definitions
//---------------------------------
