.relInsight-wrapper {
  .skeleton-container {
    width: 100%;
    height: 355px;
    padding: 10px 18px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    .skeleton-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      .skeleton-Avatar {
        width: 100px;
        height: 100px;
        margin-right: 12px;
        .ant-skeleton-avatar {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .relInsight-wrapper__item {
    display:none !important;
  }
  .relInsight-wrapper__item:nth-child(-n+3) {
    display:flex !important;
  }
}
