$primary-color: #56a9e8;
.register-magnifi-opt-2 {
  overflow: hidden;
  .left-side-panel-container.different_advisor{
    padding: 0 6%;
    .list_content{
      padding-top: 5rem;
      width: 100%;
      .span_head{
        font-size: 1.7rem !important;
        margin-top: 4rem;
        font-weight: 400;
        color: #fff;
      }
    }
    @media only screen and (max-width: 500px) {
      .list_content{
        display: none;
      }
  }
}

  .advisor_different_layout.magnifi-auth-design-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1{
      font-size: 22px !important;
      color: $primary-color;
      font-weight: 600;
    }
    .body-wrapper .element-box{
      padding-bottom: 0;
      padding-top: 0.8rem;
      .input-area{
        font-size: 0.8rem !important;
      }
    }
    .last-element-box{
      padding-bottom: 0.8rem !important;
    }
  }

  .register-magnifi-auth-design {
    max-width: 75% !important;

    .header-area {
      padding: 0;
    }
    .trading-benefits {
      display: block;
      margin: 20px 0px 20px;

      h1 {
        color: $primary-color;
        font-size: 1.2rem !important;
        font-weight: normal;
      }

      h2 {
        padding: 10px 0;
        color: #4a4a4a;
        font-size: 1rem !important;
        font-weight: normal;
      }
      ul {
        color: #4a4a4a;
        font-size: 0.8rem;
        padding: 0px 15px;
        line-height: 1rem;

        li {
          padding-left: 0px;
          margin-bottom: 10px;
        }
      }
    }
    .skip {
      color: $primary-color;
      padding: 12px 0 0 0;
      cursor: pointer;
      text-align: center;
    }
  }

  .free-trial {
    margin-bottom: 20px;
  }

  .cta-wrapper .change-sub {
    color: $primary-color;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
  .cta-wrapper .btn {
    font-size: 20px;
    font-weight: normal;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    padding: 0.75rem;
    border-radius: 60px;
    margin-bottom: 0;
  }

  .registration-btn-wrapper {
    width: 100%;
    margin: 0;

    .signup-go-back {
      border: none;
      background: none;
      padding: 0px;
    }
    .gutter-row {
      padding: 0;
      &:first-child {
        margin-right: 6px;
      }
      .cta-btn {
        height: 100%;
        min-height: 50px;
        font-size: 15px;
        line-height: 1.2;
      }
      @media only screen and (min-width: 1440px) {
        .cta-btn {
          font-size: 16px !important;
          line-height: 1.2;
          padding: 0;
        }
      }
      @media only screen and (min-width: 1280px) and (max-width: 1439px) {
        .cta-btn {
          font-size: 16px !important;
          line-height: 1.2;
          padding: 0;
        }
      }
      @media only screen and (max-width: 1279px) and (min-width: 1024px) {
        .cta-btn {
          font-size: 14px !important;
          line-height: 1.2;
          padding: 0;
        }
      }
      @media only screen and (max-width: 1023px) and (min-width: 768px) {
        .cta-btn {
          font-size: 10px !important;
          line-height: 1.2;
        }
      }
      @media only screen and (max-width: 767px) and (min-width: 320px) {
        .cta-btn {
          font-size: 1em !important;
          line-height: 1.2;
        }
      }
    }
  }

  .magnifi-auth-design-container {
    padding: 0;
    width: 100%;
    height: 100%;
    max-width: 100% !important;
    padding: 0 13%;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    overflow: hidden;
    overflow-y: auto !important;
  }
  .magnifi-auth-design-container .header-area .header-label {
    font-size: 1.875rem !important;
    font-weight: normal;
    line-height: 1.18;
  }

  .magnifi-auth-design-container .body-wrapper .element-box {
    padding-bottom: 1.75rem;
  }
  .magnifi-auth-design-container .body-wrapper .element-box .label {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.25;
    margin-bottom: 0.5rem;
  }

  .toggle-switch-wrapper .toggle-switch {
    margin-bottom: 0.5rem;
  }

  .magnifi-auth-design-container .body-wrapper .element-box .input-area {
    font-size: 20px;
    font-weight: normal;
    // line-height: 1.25;
    margin-top: 1px;
    &.placeholder {
      color: #ced4da;
    }
  }
  .magnifi-auth-design-container .bg-dummy {
    background-color: #ccc;
  }

  .or-partition {
    font-weight: normal;
    color: #979797;
    margin: 4px 0;
    font-size: 0.9375rem !important;
  }
  .fs-20 {
    font-size: 16px !important;
  }
  .dropdown-element .dropdown-wrapper .dropdown-toggle::after {
    top: 0.8em;
  }
  .magnifi-auth-design-container .body-wrapper .are-you-other {
    &,
    &:hover,
    &:focus {
      top: 30px !important;
    }
  }

  &.magnifi-auth-page-wrapper {
    height: auto;
    min-height: 100%;
  }
  &.magnifi-auth-page-wrapper .right-side-panel-container {
    flex-direction: column;
    justify-content: flex-start;
  }

  // &.magnifi-auth-page-wrapper .left-side-panel-container {
  //   align-items: flex-start;
  // }

  @media only screen and (max-width: 991px) {
    .register-magnifi-auth-design {
      margin-top: 0;
    }
  }

  .h1 {
    font-size: 50px !important;
    line-height: 1;
  }
  .left-side-panel-container {
    h1,
    .h4 {
      color: #ffffff;
      font-weight: normal;
      line-height: 1.2;
    }
    h1 {
      font-size: 35px !important;
      font-weight: 400;
      margin-bottom: -15%;
      font-family: 'Roboto';
    }
    .h4 {
      font-size: 20px !important;
      font-family: 'Open Sans';
    }
  }

  .carousel {
    margin-top: 21%;
    padding: 0 5%;
    .carousel-inner {
      margin-top: 15%;
    }
    .carousel-indicators.dots {
      position: absolute;
      bottom: -72px;
      li {
        height: 13px;
        width: 13px;
        border-radius: 50%;
        margin: 0 6px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .carousel-indicators {
    bottom: -52px !important;
  }
}

.typeahead-field {
  height: 20px;
  width: 100%;
  border: 0px;
}
