.modalFeature {
  .modal_title_box {
    margin-bottom: 0px;
  }
  .premium-msg-modal .modal-body {
    padding: 2rem !important;
  }
  .modal-content {
    border: 0;
    border-radius: 15px;
    background-color: #f7f7f7;
    .h3_Title {
      color: #434343;
      font-size: 18px;
    }
  }
  .botBtn {
    width: 100%;
    display: block;
    text-align: center;
    .btns-do-not-leave {
      font-size: 11px !important;
      padding: 0 13px !important;
      background: white !important;
      height: 32px !important;
      border-radius: 30px !important;
      color: #5a5a5a !important;
      text-transform: uppercase;
      margin: 0 5px 12px;
    }
    .btns-join-pop {
      font-size: 14px !important;
      padding: 0 13px !important;
      background: white !important;
      height: 40px !important;
      border-radius: 30px !important;
      color: #5a5a5a !important;
      text-transform: uppercase;
      margin: 0 5px 20px;
      width: 100%;
      word-break: break-all;
      white-space: normal;
      @media only screen and (max-width: 767px) {
        font-size: 10px !important;
      }
    }
  }
  .botBtnSecurity {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .btns-join-pop {
      font-size: 14px !important;
      padding: 0 13px !important;
      background: white !important;
      height: 40px !important;
      // border-radius: 30px !important;
      color: #5a5a5a !important;
      text-transform: uppercase;
      margin: 10px;
      // width: 100%;
      word-break: break-all;
      white-space: normal;
      @media only screen and (max-width: 767px) {
        font-size: 10px !important;
      }
    }
  }
}
