$indicator-color: #d6d6d6;
$in-active-color: #dbdbdb;
.container{
  display: flex;
  justify-content: center;
  .left{
    width: 42%;
    margin: 0 12px;
    padding-right: 32px;
    .headBlock{
      display: flex;
      flex-direction: column;
      h1{
        color: #4a4a4a!important;
        font-weight: 300!important;
        font-size: 60px!important;
        font-family: 'Roboto', serif;
        margin: 0;
        margin-left: -3px;
        margin-bottom: 4px;
      }
      p{

        font-size: 20px;
        color: #4a4a4a;
        line-height: 1.45;
        font-family: 'Roboto', serif;
      }
    }
    .selectionContainer{
      display: flex;
      flex-wrap: wrap;
      .leftSelection{
        h3{
          font-family: 'Roboto', serif !important;
          font-size: 30px!important;
          font-weight: 400!important;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.23;
          letter-spacing: -.36px;
          color: #4a4a4a;
          margin-bottom: 0;
          margin-left: -2px;
        }
        p{
          font-family: 'Open Sans',serif!important;
          margin-bottom: 20px!important;
          font-size: 13px!important;
          color: #545454!important;
          margin-top: -3px;
          margin-left: -3px;
          font-weight: 400!important;
        }
      }
      .rightSelection{
        width: 50%;
      }
    }

  }
  .right{
    //width: 40%;
    .videoBlock{
      width: 100%;
      height: 250px;
    }
    .overView{
      padding-top: 65px;
      
      .itemContainer{
        display: flex;
        .item{
          display: flex;
          img{
            height: 72px;
          }
          div{
            display: flex;
            flex-direction: column;
            padding-left: 10px;
            justify-content: space-between;
          }
        }
      }
    }
  }
  .ddButtons {
    height: 45px;
    border: 2px solid #5eade9;
    font-family: 'Roboto', sans-serif;
    border-radius: 24px;
    font-weight: 700;
    font-size: 16px;
    color: #5eade9;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px 10px 16px;
    width: 240px;
    cursor: pointer;
  }
  .ddButtonsDisabled{
      border-color: $indicator-color !important;
      cursor: not-allowed;

  }

}
.itemLable{
  font-family: 'Roboto', serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: normal;
  color: #4a4a4a;
  white-space: nowrap;
  height: 17px;
  padding-left: 0;
  margin-bottom: 10px;
}
.itemCount{
  font-family: 'Roboto', serif;
  font-size: 48px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  color: #4a4a4a;
  padding-left: 0;
}
.subHeading{
    font-family: 'Roboto', serif !important;
    font-size: 30px!important;
    font-weight: 400!important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: -.36px;
    color: #4a4a4a;
    margin-bottom: 0;
    margin-left: -2px;

}
.subText{
  font-family: 'Open Sans',serif!important;
  margin-bottom: 20px!important;
  font-size: 13px!important;
  color: #545454!important;
  margin-top: -3px;
  margin-left: -3px;
  font-weight: 400!important;
}
.coloredBold{
  color: #1d6091!important;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  &:hover{
    //background-color: #dff1ff!important;
    color: #1d6091!important;
  }
}
.divider{
  width: 100% !important;
  height: 1px!important;
  margin: 10px 0!important;
  background: $in-active-color !important;
  &:hover{
    background-color: white!important;
  }
}
.noHighlight{
  &:hover{
    background-color: white!important;
  }
}



.portfolioDDContainer{
  border-radius: 20px;
  overflow: hidden;
  background: white!important;
  width: 230px;
  z-index: 10;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  .portfolioMenuWrapper{
    position: relative;
    border-radius: 20px;
    max-height: 220px;
    overflow-y: auto;
    .onSearchMenuContainer,.onSearchMenuContainerUP{
      height: 58px!important;
      display: flex;
      align-items: center;
      margin: 0;
      margin-bottom: 0!important;
      color:#1e1e1e !important;
      &:hover:not(.inputContainer){
        color:#1e1e1e !important;
        background-color: #dff1ff !important;
      }
      div{
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        justify-content: space-between;
        width: 100%;
        //color:#1e1e1e !important;
        &:hover{
          color:#1e1e1e !important;
          //background-color: #dff1ff !important;
        }
      }
      .onSearchMenu{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .resultSearchIcon{
          display: flex;
          color: #1e1e1e !important;
          opacity: 0.4;
          padding-right: 26px;
        }
        .resultDetail{
          width: 100%;
          p{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0!important;
            margin-left: 0!important;
          }
          p:nth-child(2){
            margin-top: 4px;
            font-size: 12px;
          }
        }

      }
    }
    .onSearchMenuContainerUP{
      height: 32px!important;
    }

    .inputContainer{
      background-color:white!important;
      margin: 10px 0;
      margin-bottom: 10px!important;
      &:hover{
        background-color: white!important;
        background: white!important;
      }
      .input{
        padding-left: 0!important;
        border: none!important;
        border-bottom: 1px solid #1e1e1e!important;
        box-shadow: none!important;
        border-radius: 0!important;
        &:focus{
          box-shadow: none!important;
        }
      }
    }
    .myPfItems{
      padding: 0!important;
      height: 32px!important;
      display: flex;
      margin: 0;
      margin-left: 0px!important;
      margin-bottom: 0!important;
      align-items: center;
      justify-content: space-between;
      .myPfItemsDetail{
        width: 80%;
        height: 100%;
        display: flex;
        padding-left: 16px;
        align-items: center;
        .resultSearchIcon{
          margin-left: -2px;
          margin-right: 8px;
          color: #1e1e1e !important;
          opacity: 0.4;
        }
        p{
          margin: 0;
          //margin-left: 8px;
          color: #1e1e1e !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:hover{
          color:#1e1e1e !important;
          background-color: #dff1ff !important;
        }
      }
      .deleteIcon{
        padding: 4px;
        width: 44px!important;
        height: 32px;
        display: flex!important;
        color: #b7b7b7;
        align-items: center;
        justify-content: center;
        background: white!important;
        background-color: white!important;
        &:hover{
          color: #a5685f!important;
          background-color: #f1e8e7 !important;
          background: #f1e8e7!important;
        }
      }
    }
    //li:not(.noHighlight):not(.inputContainer){
    //  display: flex;
    //  align-items: center;
    //  height: 32px;
    //  margin: 0;
    //  color:#1e1e1e !important;
    //  &:hover{
    //    color:#1e1e1e !important;
    //    background-color: #dff1ff !important;
    //  }
    //  div{
    //    max-width: 100%;
    //    white-space: nowrap;
    //    overflow: hidden;
    //    text-overflow: ellipsis;
    //    justify-content: space-between;
    //    width: 100%;
    //    //color:#1e1e1e !important;
    //    &:hover{
    //      color:#1e1e1e !important;
    //      //background-color: #dff1ff !important;
    //    }
    //  }
    //
    //  .menuItem{
    //
    //  }
    //}
    li:first-child{
      margin-top: 10px;
      //margin-bottom: 10px!important;
    }
    li:last-child{
      margin-bottom: 10px!important;
    }
    &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px !important;
      border-radius: 6px;
      background-color: $in-active-color ;
    }
  }
}

.heading{
  color: #4a4a4a!important;
  font-weight: 300!important;
  font-size: 60px!important;
  font-family: 'Roboto', serif;

}
.plusIcon{
  border-radius: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  width: 30px;
  justify-content: center;
  &:hover{
    background-color: #dff1ff !important;
  }
}
.popupClass{
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 20px!important;
  background: transparent!important;
  &::before{
    position: unset!important;
    width: 0px!important;
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
  }
  ul:first-child {
    margin: 0 !important;
    background: #fff;
    margin-left: 2px !important;
    border-radius: 10px;
    width: 100%;
    padding: 4px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 250px;
    div{
      height: 32px;
      cursor: pointer;
      width: 100%;
      display: flex;
      padding: 0 20px;
      margin: 0;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover{
        color: #1A1E21!important;
        background-color: #dff1ff !important;
      }
    }
    &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px !important;
      border-radius: 6px;
      background-color: $in-active-color ;
    }
  }
}

.noHighlight{
  margin-top: 10px;
  margin-bottom: 10px!important;
  &:hover{
    background-color: white!important;
  }
}
.subMenu{
  height: 32px!important;
  display: flex;
  align-items: center;
  height: 32px;
  margin: 0;
  //margin-bottom: 0!important;
  color:#1e1e1e !important;
  &:hover{
    color:#1e1e1e !important;
    background-color: #dff1ff !important;
  }
  div{
    height: 32px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: space-between;
    width: 100%;
    //color:#1e1e1e !important;
    &:hover{
      color:#1e1e1e !important;
      //background-color: #dff1ff !important;
    }
  }

  .menuItem{

  }

}



.noResult{
  user-select: none!important;
  opacity: .5!important;
  background-color: white!important;
  cursor: not-allowed!important;
  color: #1A1E21!important;;
  height: 32px!important;
  &:hover{
    background-color: white!important;
  }
}
