@import '../../PortfolioReportV2/_portfolio-reports';

$grey-color: #f5f5f5;
$highlight-color: #def0fe;

// Padding for the portfolio card, change this to
// automatically adjust position of the fee resk and return
$card-offset: 12px;

$wrapper-padding: 25px;

.cover {
  background: linear-gradient(180.03deg, #457f8f 0.03%, #1e4854 74.81%);
  padding: 0 4rem;

  .paragraph {
    color: #fff;
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
	width: 80%;
  }
}

.mdl-enh-wrapper {
  // General Styles
  .grey-bg {
    background: $grey-color;
  }

  .highlight-bg {
    background: $highlight-color;
  }

  .white-bg {
    background: #ffffff;
  }

  .mdl-enh-rep-sub-header {
    h4 {
      font-family: $lvl1-header-font-family;
      font-size: $lvl2-header-font-size !important;
      color: $dark-blue;
      margin-bottom: 30px;
    }
  }

  .sel-rep-section {
    .sel-rep-wrapper {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .pf-card-wrapper {
    display: flex;
    flex-direction: row;
    .pf-section-wrapper {
      display: flex;
      flex-direction: column;
    }
    .pf-heading {
      margin-bottom: 20px;
      h5 {
        font-family: $lvl1-header-font-family;
        font-size: $body-font-size !important;
        color: $dark-blue;
        margin-bottom: 0px;
      }
    }
    .pf-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: $card-offset;
      border-radius: 8px;
      .pf-section-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .pf-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .pf-name {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          color: $dark-blue;
          align-self: center;
          width: 100%;
          padding: 0px 20px;
          .md-sponsor {
            height: 20px;
            margin-bottom: 10px;
          }
          .sponsor-card-name {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-word;
          }
          .md-category {
            font-size: 12px;
            color: $body-font-color;
            margin-top: 7px;
          }
        }
        .frr-wrapper {
          display: flex;
          flex-direction: row;
          &.adjust-offset {
            margin-top: -$card-offset;
            margin-right: -$card-offset;
          }
          .attr-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 95px;
            height: 140px;
            &.highlight {
              background: $highlight-color;
            }
            .name {
              font-size: 12px;
            }
            i {
              font-size: 14px;
              margin-top: 2px;
              margin-bottom: 2px;
            }
            .value {
              font-size: 13px;
            }
          }
        }
      }
      .allocation-details {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 10px 0px;
        .model-chart {
          display: flex;
          padding: 0px 20px;
          .legend-section {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 30px;
            .legend-with-header {
              display: flex;
              flex-direction: column;
              .legend-label {
                font-size: 16px;
                margin-bottom: 10px;
                span {
                  margin-right: 15px;
                }
                .overlap-label {
                  color: $dark-blue;
                }
              }
            }
            .total-overlap {
              font-size: 13px;
              text-align: left;
              font-family: $lvl2-header-font-family;
              color: $dark-blue;
              font-weight: 600;
              margin-bottom: 15px;
            }
            .single-legend {
              display: flex;
              font-size: 12px;
              // font-family: $lvl2-header-font-family;
              font-weight: 500;
              color: #999999;
              //margin-right: 20px;
              align-items: baseline;
              .legend-content {
                display: flex;
                flex-direction: column;
                margin-top: -1px;
                span {
                  text-align: left;
                  line-height: 1;
                }
                .legend-value {
                  font-size: 15px;
                  color: $dark-blue;
                }
                .overlap {
                  margin-top: 4px;
                }
              }
              .legend-color {
                width: 10px;
                height: 10px;
                display: block;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }

  .holdings-section-wrapper {
    .holdings-sponsor {
      display: flex;
      flex-direction: column;
      max-width: 220px;
      img {
        height: 15px;
      }
      span {
        font-family: $lvl1-header-font-family;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.2;
      }
      .holdings-pf {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .holdings-table {
    thead {
      tr {
        padding: 10px 0px;
        background: #ffffff;
        &.current {
          background: $grey-color;
        }
        &.enhanced {
          background: $highlight-color;
        }
        &.others {
          color: $dark-blue;
        }
        th {
          font-size: 14px;
          font-weight: 600;
          padding: 10px 0px;
          &:first-child {
            padding-left: 20px;
            border-radius: 8px 0 0 8px;
          }
          &:last-child {
            border-radius: 0 8px 8px 0;
            padding-right: 20px;
          }
        }
      }
    }
    tbody {
      font-family: $lvl1-header-font-family;
      background: #ffffff;
      tr {
        height: 50px;
        overflow: hidden;
        &:first-child {
          td {
            padding-top: 8px;
          }
        }
        &:last-child {
          td {
            &:first-child {
              border-radius: 8px 0 0 8px;
              padding-bottom: 15px;
            }
            &:last-child {
              border-radius: 0 8px 8px 0;
              padding-bottom: 15px;
            }
          }
        }
        td {
          padding-bottom: 8px;
          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            font-size: 16px;
            font-weight: 500;
            padding-right: 20px;
          }
        }
      }
    }
  }

  .other-rep-section {
    background: $grey-color;
    padding: $wrapper-padding;
    border-radius: 8px;
    .other-rep-wrapper {
      margin-bottom: $wrapper-padding;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .profile-initial-icon {
    display: flex;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    background-color: #cdd4db;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 14px;
    align-items: center;
    justify-content: center;
  }

  .no-replacements {
    margin: 20px 0px;
  }
}
