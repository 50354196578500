$background: #f7f7f7;
$border: #b7b7b7;
$text-color: #4a4a4a;
$text-color-highlighted: #56a9e8;
$fontRoboto: 'Roboto';
$fontOpenSans: 'Open Sans';
$error: #A5685F;
$charcoal-black: #4A4A49;
$light-blue-color: #6BA7E3;

.container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  color: $text-color;
  background: $background;
  p {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-family: $fontRoboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
}

.parserHtmlWrapper {
  p {
    margin: 0 !important;
  }
}

.name {
  font-family: $fontRoboto;
  font-size: 29px;
  padding-bottom: 20px;
  text-transform: capitalize;
}

.subHeader {
  padding: 4px 0;
  font-family: $fontRoboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  // margin-bottom: 20px;
  // margin-bottom: 5px;
  color: $text-color;
}

.update {
  height: 30px;
  border-radius: 24px;
  border: solid 1px $text-color-highlighted;
  color: $text-color-highlighted;
  display: flex;
  align-items: center;
  background: transparent;
  width: auto;
  padding: 0 14px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: $fontRoboto;
  font-size: 14px;
  margin-bottom: 0;
  &:hover{
    cursor: pointer;
  }
  a {
    color: $text-color-highlighted !important;
  }
}

.uploadContainer {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
    p {
      font-family: $fontRoboto;
      font-size: 16px;
      font-weight: 500;
    }
  .currentLogo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
      max-height: 40px;
      height: 40px;
      width: auto;
    }
    .totalProposals{
      font-size:30px;
      font-family: $fontRoboto;
      font-weight: 700;
    }
  }
}
.error{
  padding-top: 10px;
  color: $error;
}

.divider {
  min-width: 100%;
  height: 1px;
  //margin:47px 0;
  margin: 50px 0;
  background-color: $text-color;
  opacity: 0.4;
}

.disclosuresContainer {
  margin-top: 30px;
  .label {
    font-family: $fontRoboto;
    font-size: 16px;
    font-weight: 500;
  }
  .editIconContainer {
    width: 100%;
    margin-bottom: 15px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    button {
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: inherit;
      color: $charcoal-black;
      .anticon {
        font-size: inherit;
      }
    }
  }

  .antTypography {
    height: 84px;
    background: #FFFFFF;
    border: 1px solid #D9DAD9;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 8px 15px;
    overflow: hidden;
    overflow-y: scroll;
    .renderDisclosure {
      p {
        margin: 0 !important;
      }
    }
    &::-webkit-scrollbar {
      width: 15px !important;
      background-color: transparent;
      border-left: 1px solid #fff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 25px;
      background-color: #d2d2d2;
      border: 5px solid transparent;
      background-clip: content-box;
    }
  }

  .disclosuresWrapper {
    .editButton {

    }

    .btnWrapper {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      button {
        width: auto;
        height: 30px;
        padding: 0 14px;
        font-family: $fontRoboto;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        text-transform: uppercase;
        color: $text-color-highlighted;
        background: transparent;
        border-radius: 24px;
        border: solid 1px $text-color-highlighted;
      }
    }

    .btnMargin{
      margin-right: 10px;
      border: unset!important;
      box-shadow: unset!important;
      -webkit-box-shadow: unset!important;
      border-radius: unset!important;
      letter-spacing: 1px;
      text-transform: unset!important;
    }
  }
}
