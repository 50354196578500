$secondary-blue: #1e5f91;
$in-active-color: #dbdbdb;
.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .headerBox{
    display: flex;
    width: 100%;
    height: 86px;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    h2{
      color: $secondary-blue!important;
      font-weight: 600!important;
    }
    p{margin: 0}
  }
  .body{
    margin: 30px 0;
    display: flex;
    height: auto;
    max-height: 580px;
    overflow: hidden;
    width: 100%;
    .left{
      padding-right: 6px;
      border: none;
      width: 50%;
      border-radius: 10px;
      overflow: auto;
      align-items: flex-start;
      display: flex;
      justify-content: center;
      .image{
        overflow: hidden;
        border-radius: 10px;
        width: 100%;
        height: auto;
        min-width: 100%;
        background: #fff;
        min-height: 100%;
      }
      &::-webkit-scrollbar {
        width: 4px !important;
        border-radius: 6px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        width: 4px !important;
        border-radius: 6px;
        background-color: $in-active-color ;
      }
    }
    .right{
      width: 50%;
      display: flex;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 4px !important;
        border-radius: 6px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        width: 4px !important;
        border-radius: 6px;
        background-color: $in-active-color ;
      }
      .table{
        width: 100%;
        padding: 0 8px;
        .row:first-child{
          color: $secondary-blue;
          font-weight: 600;
          font-size: 22px;
          .checkbox{
            padding: 0;
            font-size: 12px;
            font-weight: 300;
            text-decoration: underline;
            &:hover{
              cursor: pointer;
              background-color: inherit;
            }
          }
          .pageName{
            &:hover{
              cursor: default;
              background-color: inherit;
            }
          }
        }
        .row{

          display: flex;
          justify-content: space-between;
          align-items: stretch;
          height: 40px;
          width: 100%;
          .pageName{
            padding-left: 20px;
            width: 80%;
            display: flex;
            align-items: center;
            border-radius: 6px;
            overflow: hidden;
            span{
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              vertical-align: center;
              text-overflow: ellipsis;
            }
            &:hover{
              cursor: pointer;
              background-color: #e0e0e0;
            }
          }
          .active{
            font-weight: 600;
            color: $secondary-blue;
          }
          .checkbox{
            width: 20%;
            cursor: pointer;
            height: 40px;
            border-radius: 6px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            &:hover{
              background-color: #e0e0e0;
            }
            input{
              cursor: pointer;
              width: 18px;
              border-radius: 10px;
              height: 18px;
              &:hover{
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .footer{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .save{
      border: none;
      background-color: $secondary-blue!important;
      color: white;
      text-transform: capitalize;
      box-shadow: none;
      border-radius: 30px;
      height: 40px;
      padding: 0 70px;
      &:disabled{
        opacity: 0.8;
        cursor: not-allowed;
      }
    }
  }
  .close{
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 9;
    font-size: 1rem;
    color: $secondary-blue;
    cursor: pointer;
    border: 1px solid $secondary-blue;
    padding: 2px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }
}