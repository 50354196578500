.no-right-border {
  border-right: 0px!important;
}
.archiveSearch{
  height:39px;
  width:62vw;
  background:none;
  border-radius:26px;
  -webkit-border-radius:26px;
  border:2px solid #56a9e8;
  margin:auto;
  position:relative;
  margin-top:2vh;
  display: flex;
  align-items: center;

  input{
    font-weight: 500;
    font-size: 18px;
    height:39px;
    background:none;
    border:none;
    width:100%;
    padding:0px 50px;
    color:#56a9e8!important;
  }
  input::placeholder {
    color:#56a9e8!important;

  }
  input::-webkit-placeholder {
    color:#56a9e8!important;

  }
  input:-ms-input-placeholder {
    color:#56a9e8;

  }
  input::-ms-input-placeholder {
    color:#56a9e8;
  }
  i{
    font-size: 22px;
    cursor: pointer;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: -0.24px;
    color: #56a9e8 !important;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    align-items: center;
    justify-content: center;
    display: flex;
    opacity: 1;
    position:absolute;
    right:10px;
    top:5px;
  }
  .clear-icon{
    margin-right: 76px;
  }
}
.ArchiveTable.advisor{
  .ant-table-thead tr:last-child{
    display:none!important;
  }
}
.ArchiveTable{
  .ant-table-thead tr:first-child{
    border:none!important;
  }
  // .ant-table-thead tr:last-child{
  //   display:none!important;
  // }
  th.acc-details-header{
    background:#e7f3fc;
    color:#4a4a4a!important;
    padding:0.5rem !important;
  }
  th.th-grouped {
    border-bottom: solid!important;
    border-bottom-color: grey!important;
    border-bottom-width: thin!important;
  }
  .ant-table-row{
    td{
      padding:0.5rem !important;
    }
  }
  td.colLastName{
    padding-left: 0px!important;
    padding:8px!important;
  }
  .ant-table-body{
        max-height: calc(63vh)!important;
  }
}
.archiveTitle{
  position:relative;
  a{
    position: absolute;
    top: 7px;
    left: 0px;
    img{
      height: 26px;
    }
  }
}
.archiveWrapper{
  max-width:94%!important;
  width:94%!important;
  padding-top: 3vh!important;
  padding-bottom: 3vh!important;
}
.archiveWrapper{
  .fa-search {
    //margin-right: 42px!important;
  }
}

.downloadPDF{
  	cursor: pointer;
}
.attributes{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  .key{
    color: #424242;
    margin: 0;
    display: inline-block;
    padding: 0;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }
}

.ant-table-content {
  table {
    table-layout: fixed!important;
  }
}

.react-datepicker__time-container, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
  width:100px!important;
}
.ant-table-filter-dropdown.archiveFilters .react-datepicker-popper {
    width: 343px !important;
}


@media only screen and (max-width: 1280px) {
  .ArchiveTable{
    th, td{
      font-size:0.8rem!important;
    }
  }
}
