
.no-funds-found-wrapper {
	width: 100%;
	height: 100%;
	background: transparent !important;
	border: 0 !important;
	.ant-card-body {
		width: 100%;
		height: 100%;
		padding: 0 !important;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
