$in-active-color: #dbdbdb;
.customMPCardContainer{
  height: 487px!important;
  min-height: 487px!important;
  .customMPCard{
    background: white;
    padding: 20px;
    height: 100% !important;
    width: 100% !important;
    position: relative;
    .selectionContainer{
    }
    .noSelectionContainer{
      display: flex;
      height: 100%;
      background: #f7f7f7;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .plus{
        margin-bottom: 30px;
        height: 50px;
        width: auto;
      }
      .ddContainer{
        width: 100%;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        .sponsored,.myPortfolio{
          max-height: 35px;
          border: 1px solid #1e1e1e;
          font-family: 'Roboto', sans-serif;
          border-radius: 20px;
          margin: 10px;
          padding: 10px 14px 10px 16px;
          width: 240px;
          &:hover{
            cursor: pointer;
          }
          .buttons-item-label{
            display: flex;
            align-items: center;
            span{
              padding-left: 8px;
            }
          }
        }
      }

    }
  }
}
.portfolioDDContainer{
  border-radius: 20px;

  background: white!important;
  width: 230px;
  overflow: hidden;
  z-index: 10;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  .portfolioMenuWrapper{
    position: relative;
    border-radius: 20px;
    max-height: 220px;
    overflow-y: auto;
    .onSearchMenuContainer,.onSearchMenuContainerUP{
      height: 58px!important;
      .onSearchMenu{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .resultSearchIcon{
          display: flex;
          color: #1e1e1e !important;
          opacity: 0.4;
          padding-right: 26px;
        }
        .resultDetail{
          width: 100%;
          p{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0!important;
          }
          p:nth-child(2){
            margin-top: 4px;
            font-size: 12px;
          }
        }

      }
    }
    .onSearchMenuContainerUP{
      height: 32px!important;
    }

    .inputContainer{
      background-color:white!important;
      .input{
        padding-left: 0!important;
        border: none!important;
        border-bottom: 1px solid #1e1e1e!important;
        box-shadow: none!important;
        border-radius: 0!important;
        &:focus{
          box-shadow: none!important;
        }
      }
    }
    li:not(.noResult){
      display: flex;
      align-items: center;
      height: 32px;
      margin: 0;
      margin-bottom: 0!important;
      color:#1e1e1e !important;
      &:hover{
        color:#1e1e1e !important;
        background-color: #dff1ff !important;
      }
      div{
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        justify-content: space-between;
        width: 100%;
        color:#1e1e1e !important;
        *,*::before,{
          &:hover{
            color:#1e1e1e !important;
            background: #1e1e1e !important;

          }
        }
      }

      .menuItem{

      }
    }
    li:first-child{
      margin-top: 10px;
      margin-bottom: 10px!important;
      &:hover{
        background-color: white!important;
      }
    }
    li:last-child{
      margin-bottom: 10px!important;
    }
    &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px !important;
      border-radius: 6px;
      background-color: $in-active-color ;
    }

  }

}

.subMenu{
  display: flex;
}
.popupClass{
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 20px!important;
  background: transparent!important;
  box-shadow: none!important;
  &::before{
    position: unset!important;
    width: 0px!important;
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
  }
  ul:first-child {
    margin: 0 !important;
    background: #fff;
    margin-left: 2px !important;
    border-radius: 10px;
    width: 100%;
    padding: 4px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 250px;
    div{
      height: 32px;
      cursor: pointer;
      width: 100%;
      display: flex;
      padding: 0 20px;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover{
        background-color: #dff1ff !important;
      }
    }
    &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px !important;
      border-radius: 6px;
      background-color: $in-active-color ;
    }
  }
}
.noHighlight{
  margin-top: 10px;
  margin-bottom: 10px!important;
  &:hover{
    background-color: white!important;
  }
}

.noResult{
  user-select: none!important;
  opacity: .5!important;
  background-color: white!important;
  cursor: not-allowed!important;
  color: #1A1E21!important;;
  height: 32px!important;
  &:hover{
    background-color: white!important;
  }
}
