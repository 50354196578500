
.calc-slider-container {
  height: 105px;
  padding: 16px 0;

  .label-wrapper {
    line-height: 2;
    .label-holder {
      font-family: $secondary-font;
      font-size: $font-size-18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: $label-black-color;
    }
    .icon-holder {
      line-height: inherit;
      text-align: right;
      .anticon {
        margin-right: 2px;
        font-size: $font-size-20px;
        transform: scaleX(-1);
        // vertical-align: 0.2em;
      }
    }
  }
  .slider-wrapper {
    .slider-element {
      .ant-slider-rail {
        height: 2px;
        background-color: #d6d6d6;
      }
      .ant-slider-handle {
        border: solid 2px #4a4a4a;
      }
      .ant-slider-track {
        height: 2px;
        background-color: #56a9e8;
      }
      .ant-slider-step {
        height: 2px;
      }
    }
    .input-number-element {

    }
    .number-element {
      font-family: $primary-font;
      font-size: $font-size-18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: $label-black-color;
      text-align: right;
    }
  }
}

.linear-calc-slider-container {
  height: auto;
  padding: 0;
  display: flex;
  justify-content: start;
  align-items: center;

  .label-holder {
    margin-right: 30px;
    font-family: $secondary-font;
    font-size: $font-size-18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $label-black-color;
    cursor: pointer;
  }
  .icon-holder {
    line-height: inherit;
    text-align: right;
    font-family: $primary-font;
    font-size: $font-size-12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    .anticon {
      margin-right: 2px;
      vertical-align: 0.2em;
      transform: scaleX(-1);
    }
    .lock {
      color: $lock-color;
    }
    .un-lock {
      color: $label-black-color;
    }
  }

  .slider-wrapper {
    width: 250px;
  }
  .number-wrapper {
    margin: 0 10px;
    .number-element {
      font-family: $primary-font;
      font-size: $font-size-12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: $label-black-color;
    }
  }
}
