$background: #f7f7f7;
$border: #b7b7b7;
$text-color: #4a4a4a;
$text-color-highlighted: #56a9e8;
$fontRoboto: 'Roboto';
$fontOpenSans: 'Open Sans';
$error:#A5685F;
.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: $background;
  color: $text-color;
}

.name {
  font-family: $fontRoboto;
  font-size: 30px;
  padding-bottom: 20px;
  text-transform: capitalize;
}

.subHeader {
  font-size: 22px;
  font-family: $fontRoboto;
  margin-bottom: 10px;
}

.update {
  height: 30px;
  border-radius: 24px;
  border: solid 1px $text-color-highlighted;
  color: $text-color-highlighted;
  display: flex;
  align-items: center;
  background: transparent;
  width: auto;
  padding: 0 14px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: $fontRoboto;
  font-size: 14px;
  margin-bottom: 0;
  &:hover{
    cursor: pointer;
  }
  a {
    color: $text-color-highlighted !important;
  }
}
.emailLabel{
  font-size: 14px;
  font-family: $fontOpenSans;
  font-weight: 600;
}
.btnIcon {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 95px;
  height: 26px;
  border-radius: 24px;
  padding: 0 18px;
  border: solid 1px $text-color-highlighted;
  background: none;
  font-size: 14px;
  font-family: $fontOpenSans;
  color: $text-color-highlighted;
  text-transform: uppercase;
  font-weight: 500;
  &:disabled{
    color: $text-color;
    border: solid 1px $text-color;
    opacity: 0.7;
    cursor: not-allowed;
    span{
      color: $text-color;
    }
  }
  span{
    font-size: 12px;
  }
}
.uploadContainer {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
    p {
      font-family: $fontRoboto;
      font-size: 16px;
    }
  .currentLogo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
      max-height: 40px;
      height: 40px;
      width: auto;
    }
  }
}
.error{
  padding-top: 10px;
  color: $error;
}

.recentAlertBox{
  position: relative;
  padding-bottom: 20px;
  .recentAlertContainer{
    overflow-y: auto;
    max-height: 360px;
    min-height: 100px;
    &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px !important;
      border-radius: 6px;
      background-color: #DBDBDB ;
    }
  }
  .archiveAlertContainer{
    overflow-y: auto;
    max-height: 380px;
    min-height: 100px;
    &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px !important;
      border-radius: 6px;
      background-color: #DBDBDB ;
    }
  }
  .formGroupPanel{
    padding: 20px 0px;
    border-bottom: 1px solid #d5d5d5;
    margin-bottom: 0px;
    &:last-child{
      border-bottom: 0px;
    }
    .mainTitleLable{
      font-size: 22px;
      color: #898989;
    }
    .pending{
      color: #3499e4;
    }
    .common{
      color: #1D6091;
    }
    .error{
      color: #a56258;
    }
    .success{
      color: #2a6e60;
    }
    .Lable{
      font-size: 14px;
      width: 100%;
      padding-right: 70px;
      position: relative;
      margin-bottom: 0px;
      span{
        position: absolute;
        right: 0;
        color: #56a9e8;
        font-size: 14px;
      }
    }
    .receivedtime{
      font-size: 13px;
      color: #a2a1a1;
    }
  }
  
}
.emailAlertBtn{
  position: absolute;
  top:5px;
  right:0;
  span{
    color: #4a4a4a;
    font-weight: 500;
  }
}