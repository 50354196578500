$background: #f7f7f7;
$border: #b7b7b7;
$text-color: #4a4a4a;
$text-color-highlighted: #56a9e8;
$fontRoboto: 'Roboto';
$fontOpenSans: 'Open Sans';
.broker {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: $background;
  color: $text-color;
}
.container {
  display: flex;
  flex-direction: column;
}
.accountProvider {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  padding: 0 0 8px 0;
}

.subHeader {
  font-size: 22px;
  font-family: $fontRoboto;
  text-transform: capitalize;
}

.subHeaderValue {
  color: $text-color-highlighted;
  font-weight: bold;
  cursor: pointer;
  padding: 0 0 0 14px;
}

.formGroup {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  padding: 8px 0;

  .key {
    font-family: $fontRoboto;
    text-align: left;
    color: $text-color;
  }

  .value {
    font-family: $fontOpenSans;
    text-align: right;
    font-weight: bold;
  }
}

.update {
  align-self: flex-end;
  height: auto;
  border-radius: 24px;
  border: solid 1px $text-color-highlighted;
  display: flex;
  align-items: center;
  width: auto;
  padding: 0 14px;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 30px;
  font-family: $fontRoboto;
  font-size: 14px;
  a {
    color: $text-color-highlighted !important;
  }
}
.deleteConfirmModal{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    margin: 20px 0;
    font-size: 30px;
    color: $text-color;
    text-align: center;
  }
  .update {
    width: auto;
    height: auto;
    font-weight: 500;
    padding: 0 18px;
    background: none;
    font-family: $fontRoboto !important;
    font-size: 14px!important;
    display: flex;
    align-self: center;
    align-items: center;
  }
}
.divider {
  min-width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: $text-color;
  opacity: 0.4;
}

.brokerModal {
  text-align: center;
  display: flex;
  flex-direction: column;
  background: white;
  align-items: center;
  justify-content: center;
  .title {
    margin: 20px 0;
    font-size: 30px;
    color: $text-color;
    text-align: center;
  }

  .brokerButton {
    border: none;
    background: white;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-family: $fontOpenSans;
    color: $text-color-highlighted;
    width: 160px;
  }

}
.deleteBroker{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .deleteIcon{
    color: $text-color-highlighted;
    padding: 0;
  }
  .deleteIcon:hover{
    color: $text-color-highlighted;
  }
}
