.delete_page_warpper{
    .secondary-btn{
        background-color: #fff !important;
        color: #707070 !important;
        border: solid 1px #707070 !important;
    }
    .delete_auth_cotainer{
        max-width: 400px !important;
    }
    .header_area_delete{
        padding-bottom: 0.6rem !important;
        font-family: 'Open Sans';
        font-weight: 600;
        line-height: 1.35;
        letter-spacing: normal;
        text-align: left;
        .header_label{
            font-size: 1.4rem;
            color: #56a9e8;
        }
    }
     
    .warning_content{
        font-size: 16px;
        color: #979797;
        font-weight: 600;
        font-family: 'Open Sans';
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        padding: 0;
        margin: 0;
    }
    .inner_desc_content{
        margin-bottom: 2rem;
    }
    .sparate{
        margin-bottom: 10px;
    }
    .delet_btn{
        font-weight: 700 !important;
        font-size: .8rem !important;
    }

    .delet_btn:focus{
        box-shadow: none !important;
    }
    .delet_btn:hover{
        box-shadow: none !important;
    }
}

.unregister_toastFail {
    background-color: #f8d7da !important;
    color: #721c24 !important;
    .ant-notification-notice-message {
        display: none;
    }
}
.unregister_toastSuccess {
    background-color: #d4edda !important;
    color: #155724;
    .ant-notification-notice-message {
        display: none;
    }
}