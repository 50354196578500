.accounts-alert-feeds-container {
	padding: 10px;
	border: 1px solid #ced4da;
	border-radius: 8px;
	background-color: rgba(27, 139, 224, 0.25);
	background-image: url($magnifi-icon-blue-backdrop);
  background-repeat: no-repeat;
  background-position-x: 94%;
	background-size: 32% auto;
  background-position-y: center;
	transition: ease-in-out 0.4s;

	.accounts-alert-feeds-wrapper {
		padding-bottom: 15px;
		font-family: $primary-font;
		cursor: pointer;
		.alert-title {
			font-family: inherit;
			font-size: $font-size-20px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.35;
			letter-spacing: normal;
			text-align: left;
			color: inherit;
		}
		.alert-desc {
			font-family: inherit;
			font-size: $font-size-13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.38;
			letter-spacing: normal;
			text-align: left;
			color: $label-black-color;
		}
		&:last-child {
			padding-bottom: 0;
		}
	}

	.accounts-alert-success {
		color: $light-green-color;
	}

	.accounts-alert-error {
		color: $light-red-color;
	}

	.accounts-alert-warning {

	}

	.accounts-alert-info {

	}

}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
	.accounts-alert-feeds-container {
		background-size: 32% auto;
		.accounts-alert-feeds-wrapper {
			.alert-title {
				font-size: $font-size-18px;
			}
			.alert-desc {
				font-size: 10px;
			}
		}
	}
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) {
	.accounts-alert-feeds-container {
		background-size: 32% auto;
		.accounts-alert-feeds-wrapper {
			.alert-title {
				font-size: $font-size-16px;
			}
			.alert-desc {
				font-size: 11px;
			}
		}
	}
}

@media only screen and (min-width: 1440px) and (max-width: 1919px) {
	.accounts-alert-feeds-container {
		background-size: 32% auto;
		.accounts-alert-feeds-wrapper {
			.alert-title {
				font-size: $font-size-20px;
			}
			.alert-desc {
				font-size: 13px;
			}
		}
	}
}

@media only screen and (min-width: 1920px) and (max-width: 2559px) {
	.accounts-alert-feeds-container {
		background-size: 32% auto;
		.accounts-alert-feeds-wrapper {
			.alert-title {
				font-size: $font-size-20px;
			}
			.alert-desc {
				font-size: 1rem;
			}
		}
	}
}

@media only screen and (min-width: 2560) {
	.accounts-alert-feeds-container {
		background-size: 32% auto;
		.accounts-alert-feeds-wrapper {
			.alert-title {
				font-size: $font-size-20px;
			}
			.alert-desc {
				font-size: 1rem;
			}
		}
	}
}
