@import "./variables";

/******* pages style import ***********/
@import "./AccountsPage/index";
@import "./SearchResultsV3/index";
@import "./PasswordReset/index";
@import "./BuilderReport/index";
@import "./PortfolioReport/portfolio-reports";
@import "./PortfolioReportV2/portfolio-reports";
@import "./BuildPortfolio/index";
@import "./ClientPortfolio/index";
@import "./ClientPortfolioV2/index";
@import "./Enhancer/index";
@import "./EnhancerInterimReport/RecommendationReport/index";
@import "./EnhancerInterimReport/ModelEnhancementReport/index";
@import "./SFM/index";
@import "./AccountsPageV2/index";
@import "./Login/SelectDomainPopup/index";
@import "./QuickTrade/index";

@import "./header";
@import "./responsive";

.client-portfolio-upload-modal {
  max-width: 660px !important;
  .modal-content {
    width: 100%;
    .ant-tabs {
      .ant-tabs-bar {
        margin-bottom: 25px;
        border-bottom: 0 !important;
        .ant-tabs-nav-container {
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-scroll {
              text-align: center;
              .ant-tabs-nav {
                &:after {
                  border-bottom: 3px solid #f0f0f0;
                }
                .ant-tabs-tab-active {
                  color: $dark-active-color !important;
                }
                .ant-tabs-tab {
                  color: #9f9f9f;
                  &:hover {
                    color: #9f9f9f;
                  }
                }
                .ant-tabs-ink-bar {
                  background: $dark-active-color !important;
                }
              }
            }
          }
        }
      }
      .ant-tabs-content {
        .ant-tabs-tabpane {
          .client-upload-modal-container {
            display: flex;
            /* padding: 5px 0; */
            clear: both;
            justify-content: space-between;
            align-items: center;
            .client-upload-modal-wrapper {
              /* float: right; */
              /* margin-bottom: 10px; */
              height: 32px;
              clear: both;
              display: block;
              align-items: center;
              display: flex;
              .client-upload-modal-btn {
                position: relative;
                width: 146px;
                height: 32px;
                padding: 7px 15px;
                font-size: 12px;
                color: #1E5F91;
                border-radius: 30px;
                border-color: #1E5F91;
                border: 1px solid #1E5F91;
                background-color: transparent;
                input {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 32px;
                  opacity: 0;
                  z-index: 99;
                  cursor: pointer;
                }
              }
              #multiple-upload-tooltip {
                margin-left: 10px;
                color: $dark-active-color !important;
              }
            }
            .dld-sample {
              margin-left: auto;
              font-size: 12px;
              font-weight: 400;
              color: #1E5F91;
              text-align: right;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.ant-tabs-tab-prev, .ant-tabs-tab-next {
  z-index: 0 !important;
}

.grey-bg {
  background-color: $tag-grey-bg-color !important;
  border: 1px solid $tag-grey-bg-color !important;
}

.blue-bg {
  background-color: $tag-blue-bg-color !important;
  border: 1px solid $tag-blue-bg-color !important;
}

.ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-submenu-arrow {
  display: flex;
  align-items: center;

  .anticon-right {
    margin-right: 0 !important;
  }
}

.ant-layout {
  background: $bg-color-light !important;
}

.magnifi-tooltip-wrapper {
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      &:before, &:after {
        background-color: #4A4A4A !important;
      }
    }
    .ant-tooltip-inner {
      width: 100%;
      max-width: 280px;
      min-width: 100px;
      height: auto;
      min-height: auto;
      font-family: 'Roboto';
      font-size: 10px;
      letter-spacing: 0.3px;
      text-align: center;
      color: #ffffff;
      border-radius: 0.25em;
      word-wrap: normal;
      background-color:#6c6c6c !important;
    }
  }
}

.custom-ticker-search-box-wrapper {
  width: auto;
  .plus-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 10px auto 5px auto;
    padding: 10px;
    line-height: 1;
    border-radius: 50%;
    border: solid 1px $other-grey-color-2;
    .anticon {
      font-size: $font-size-16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.88;
      letter-spacing: normal;
      text-align: left;
      color: $other-grey-color-2;
    }
  }
  .ant-select {
    width: 100%;
    margin-bottom: 10px;
    box-shadow: none;
    border-radius: 0;
    .ant-select-selector {
      height: 30px;
      padding: 0 !important;
      border: 0 !important;
      background: transparent !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      border-bottom: 1px solid #c5c5c5 !important;
      .ant-select-selection-search {
        left: 0;
      }
      input {
        height: 30px;
        padding: 5px 5px 5px 0px !important;
      }
      .ant-select-selection-placeholder {
        padding-left: 0;
      }
    }
    .ant-select-arrow {
      right: 0 !important;
      .search-icon {
        right: 0;
        top: 5px;
        font-size: 20px;
      }
    }
  }
  .custom-ticker-search-box-dropdown {
    // height: 120px;
    div[role="listbox"] + div {
      &::-webkit-scrollbar {
        width: 4px !important;
        border-radius: 6px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        width: 4px !important;
        border-radius: 6px;
        background-color: $in-active-color ;
      }
    }
  }
}

.no_account_bar{
  height: 30px;
  width: 100%;
  background: #56a9e8;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  a{
    font-size: 15px !important;
    position: relative;
    color: #fff !important;
    text-decoration: underline !important;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
    font-variant: tabular-nums !important;
    line-height: 1.5715 !important;
    text-align: center;
    display: block;
      .span1{
          margin-left: 8px;
          text-align: center;
          position: relative;
          width: 12px;
          height: 12px;
          display: inline-block;
      }
      .upload_icon{
        font-size: 20px;
        margin-right: 6px;
        position: absolute;
        left: -25px;
        top: 2px;
      }
      .span1::after {
        content: "";
        /* animation properties */
        animation-name: my-animation;
        animation-duration: .6s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        /* other properties */
        width: 10px;
        height: 10px;
        border-radius: 100%;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }

      @keyframes my-animation {
        from {
          background-color: #fff;
          width: 10px;
          height: 10px;
          top:2px;
        }
        to {
          background-color: #fff;
          width: 3px;
          height: 3px;
          top:6px;
        }
      }
  }
  a:hover{
    color: #fff;
  }
  .anticon-close-circle:hover{
    cursor: pointer;
  }
}

.web-dashboard-content-wrapper-height-1{
  height: calc(100vh - 60px);
  .contentWrapper{
    height: calc(100vh - 60px);
  }
}
.web-dashboard-content-wrapper-height-2{
  height: calc(100vh - 95px);
  .contentWrapper{
    height: calc(100vh - 95px) !important;
    top: 95px !important
  }
}

.web-dashboard-content-wrapper {
  overflow-y: auto;
  background: $bg-color-light !important;
  position: relative;
  margin-top: 0rem !important;

  background: #F9FAFC !important;
  background: $bg-color-light !important;
  &::-webkit-scrollbar {
    width: 4px !important;
    border-radius: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px !important;
    border-radius: 6px;
    background-color: $in-active-color ;
  }
}

.popup-overlay{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0,0,0,.45);
  display: flex;
  align-items: center;
  justify-content: center;

  .model-popup{

    &.md-popup{
      width:600px;
    }

      position: relative;
      background-color: #fff;
      background-clip: padding-box;
      border: 0;
      border-radius: 2px;
      -webkit-box-shadow: 0 3px 6px -4px rgba(0,0,0,.12);
      box-shadow: 0 3px 6px -4px rgba(0,0,0,.12);
      pointer-events: auto;
      border-radius: 30px;
      width: 470px;
      transform-origin: 643.5px -7.5px;

      .popup-cross{
        position:absolute;
        right:30px;
        top:30px;
        color:#777777;

        svg, i{font-size:20px;}
      }

      h1{
          margin: 20px 0;
          font-size: 30px;
          color: #4a4a4a;
          text-align: center;
          font-size: 2rem!important;
          margin-top: 4rem;
          line-height: 2.5rem;
      }
      .model-body{
          padding:1rem;
      }
  }
}

.popup-btn{
    background:#5FB0EA;
    font-size: 1rem;
    font-weight:500;
    padding:1rem 2rem;
    border-radius:2rem;
    margin-bottom:10px;
    display:inline-block;
    height:auto;
    line-height: 1.3rem;
    text-align:center;
    border:none;
    color:#ffffff;
    text-transform:uppercase;
    min-width:8rem;
    cursor: pointer;
    &.line-btn{
      background:none;
      border:1px solid #5FB0EA;
      color:#5FB0EA;
    }


    &.rejected{
      background:#B68A83;
      color:#ffffff!important;
    }
    &.rejected{
      background:#5FB0EA;
      // background:#B68A83;
      color:#ffffff!important;
    }
    &.approved{
      // background:#447569;
      background:#5FB0EA;
      color:#ffffff!important;
    }
    &.approved-line{
      border:1px solid #447569;
      color:#447569;
      background:none;

      i, svg{
        color: #447569;
        font-size: 1.2rem;
        margin: 0px;
      }
    }
    &.rejected-line{
      border:1px solid #B68A83;
      color:#B68A83;
      background:none;

      i, svg{
        color: #B68A83;
        font-size: 1.2rem;
        margin: 0px;
      }
    }

    i, svg{
      font-size: 10px;
      display: inline-block;
      vertical-align: top;
      margin-top: 0.35rem;
    }
}
.address-proof{
  max-height:300px;
  margin-bottom:30px;
  max-width:100%;
}
.reject-document-textarea{
    padding:0px 1rem;
    margin-bottom:20px;

    textarea{
      resize:none;
      height:15vh;
      border:1px solid #dddddd;
      width:100%;
    }
}
.document-row{
  padding: 10px !important;
  border-radius: 5px;
  margin-bottom: 6px;
  border-bottom: 1px solid #dddddd !important;
  font-size: 0.8rem;
  display: flex!important;
  align-items: center;

    div{
        display: flex;
        align-items: center;
      span{
        text-transform: capitalize;
    }
  }

    button{
        background: #1E5F91;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        float: right;
        display: inline-block;
        font-size: 0.8rem;
        color: #ffffff;
        font-weight: bold;
        height: 36px;
        padding: 0px 15px;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        border:none;
        margin-left:5px;
    }
}

.step-indicator {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.step {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.step-indicator .step-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  color: #e0dfda;
  position: relative;
  line-height: 43px;
  font-size: 20px;
  font-weight: 600;
  border: 3.4px solid #e0dfda;
}

.step.active .step-icon {
  border: 3.4px solid #56a9e8;
  color: #56a9e8;
}

.step span {
  text-align: center;
  position: absolute;
  bottom: -25px;
  color: #c2c2c2;
  font-size: 10px;
  font-weight: bold;
  // overflow: hidden;
  white-space: nowrap;
}
.step .timer{
  bottom: -52px;
  white-space: nowrap;
}

.step.active span {
  color: #56a9e8;
}

.step.step2 span,
.step.step3 span, .step.step4 span, .step.step5 span, .step.step6 span {
  left: 50%;
  transform: translateX(-50%);
}

.indicator-line {
  width: 100%;
  height: 2px;
  background: #e2e1e1;
  flex: 1;
  position: relative;
}

.indicator-line.nextToActive:after {
    background-color: #56a9e8;
    content: "";
    width: 50%;
    height: 2px;
    top: 0;
    position: absolute;
}

.indicator-line.active {
  background: #56a9e8;
}

.set_span_text{
  left: 22px !important;
  right: -40px !important;
}

@media screen and (max-width: 1024px) {
  .step-indicator .step-icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    color: #e0dfda;
    position: relative;
    line-height: 24px;
    font-size: 12px;
    font-weight: normal;
    border: 3px solid #e0dfda;
}
  .step span{
    // display: none;
    font-size: 8px;
  }

  .set_span_text{
    left: 15px !important;
    right: -40px !important;
  }
}

.priceUp {
  // color: #3f7367 !important;
  color: rgb(86, 162, 76) !important;
  // transition: all 2000ms linear;
}

.priceDown {
  // color: #A5685F !important;
  color: rgb(235, 74, 74) !important;
  // transition: all 2000ms linear;
}

.whole_page_just_loading{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0; right: 0;
  background: #f7f7f7;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  span{
    font-size: 20px;
    font-weight: 600;
    color: #adb5bd;
  }
}

.loading-indicator{
  display: none !important;
}

.nav-tabs.reject_tabs_container{
  border-bottom: 1px solid #fff !important;
  .reject_tabs{
    background: transparent !important;
    border-color: #fff #fff #fff !important;
  }

  .reject_tabs.nav-link.active {
    color: unset !important;
    background-color: transparent;
    border-color: #fff #fff #fff !important;
  }
}

.builder_feedback_form{
  position: absolute;
  right: -30px;
  top: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 10px;
  background-color: #efefef;
  z-index: 999;
  .close_icon:hover{
    cursor: pointer;
  }
  textarea{
    border: 0px;
    padding: 10px;
    border-radius: 10px;
    width: 300px;
    min-height: 180px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .textarea_dummy{
    border: 0px;
    padding: 10px;
    border-radius: 10px;
    width: 300px;
    height: 180px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1890ff;
  }
  .feedback_submit{
    background: #1d6091;
    color: #fff !important;
    padding: 5px 15px 6px 15px;
    border-radius: 15px;
    font-size: 12px;
  }
  .feedback_submit.disable{
    background-color: rgb(190, 190, 190);
    color: gray;
  }
}

// Disclosures preview modal
.disclosures-modal-container, .confirm-discard-changes-modal {
  .ant-modal {
    margin: 0;
  }
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 2px !important;
    .ant-modal-body {
      // padding: 24px;
      padding: 0 !important;
      .modal-preview-text-area {
        .quill {
          .ql-container {
            border-bottom: 0;
            .ql-editor {
              height: 358px;
              max-height: 358px;
            }
          }
        }
      }
    }
  }
  .ant-modal-footer {
    padding: 14px 24px;
    border: 1px solid #D9DAD9;
    border-top: 1px solid #807f7f38;
    box-shadow: 1px -1px 5px 1px #807f7f29;
  }
  .modelOkBtn {
    width: 73px;
    padding: 0 14px;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-transform: uppercase;
    color: #ffffff;
    background: $light-blue-color;
    border-radius: 24px;
    border: solid 1px $primary-color;
  }

  .modelCancelBtn {
    font-family: $secondary-font;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 14px;
    color: $light-blue-color;
  }
}

.confirm-discard-changes-modal {
  .ant-modal-content {
    .ant-modal-body {
      margin-bottom: 20px;
      padding: 14px !important;
    }
  }
}

.proposals-notification-class {
  .ant-notification-notice-content {
    .ant-notification-notice-message {
      display: inline-block !important;
    }
  }
}

.antModal {
  border-radius: 20px;
  .ant-modal-content {
    padding: 24px !important;
    border-radius: 20px !important;
  }
}

.enhancer-controls-menu-container {
  width: 100%;
  border: 0 !important;
  background: transparent !important;
  .ant-menu-item {
    height: 48px;
    line-height: 46px;
    align-items: center;
    padding: 0 10px 0 0;
    border: 0 !important;
    box-shadow: none;
    &:hover {
      border: 0 !important;
      box-shadow: none;
    }
    .totum-risk-bar-container {
      height: 100%;
    }
  }
  .ant-menu-overflowed-submenu {
    border: 0 !important;
    .ant-menu-submenu-title {
      padding-left: 0 !important;
    }
  }
}

.enhancer-primary-cta-button {
  position: relative;
  height: 34px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #fff !important;
  border: 0 !important;
  border-radius: 50px !important;
  background-color: #1e5f91 !important;
  //text-transform: lowercase;
  line-height: normal;
  .anticon {
    position: absolute !important;
    top: 6px !important;
    vertical-align: 0 !important;
    line-height: normal !important;
  }
}
// .enhancer-primary-cta-button {
//   position: relative;
//   height: 34px !important;
//   font-size: 14px !important;
//   font-weight: 600 !important;
//   color: #fff !important;
//   border: 0 !important;
//   border-radius: 50px !important;
//   background-color: #1e5f91 !important;
//   //text-transform: uppercase;
//   line-height: normal;
//   .anticon {
//     position: absolute !important;
//     top: 6px !important;
//     vertical-align: 0 !important;
//     line-height: normal !important;
//   }
// }
.reverse-primary-cta-button{
@extend .enhancer-primary-cta-button;
background: transparent !important;
color: #1e5f91 !important;
border:1px solid #1e5f91 !important;
}
.custom-link-button{
    text-decoration: underline;
    font-size: 15px;
    font-weight: 600;
    color: #1F5F91;
    span{
      text-decoration: underline !important;
    }
}

.calendar-invite-modal, .okta-change-password-modal {
  top: 0 !important;
  // height: calc(100vh - 120px) !important;
  height: 100vh !important;
  margin-bottom: 60px;
  margin: 0 !important;
  overflow: hidden;
  .ant-modal {
    top: 0 !important;
    width: 100% !important;
    max-width: 1024px !important;
    height: 100%;
    padding: 0 !important;
    .ant-modal-content {
      height: 100%;
      padding: 4% !important;
      // padding: 50px 40px !important;
      border-radius: 20px !important;
      .ant-modal-body {
        height: 100%;
        padding: 0 !important;
        h3 {
          font-weight: bold;
          text-transform: uppercase;
        }
        .calendar-invite-screen-wrapper {
          display: block !important;
          height: 100%;
          overflow: hidden;
          .meetings-iframe-container {
             min-height: calc(100% - 70px);
            iframe {
              // height: 670px;
              height: calc(100vh - 100px) !important;
            }
          }
        }
        .secondary-btn {
          min-width: 120px;
          font-size: 1rem;
          text-transform: uppercase;
        }
      }
    }
  }
}

.okta-change-password-modal {
  .ant-modal {
    top: 60px !important;
    max-width: 500px !important;
    height: auto;
    .magnifi-auth-design-container {
      padding: 13%;
      min-width: 100%;
      margin: 0px auto;
      .header-area h1 {
        font-size: 2rem !important;
      }
    }
  }
}

.auth-alert-wrapper {
  position: absolute !important;
  bottom: 0;
}

.okta-login-error-message-container {
  padding: 15px 20px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #F4FAFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;

  .okta-login-error-message-head {
    padding-bottom: 10px;
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    display: flex;
    align-items: center;
    .anticon {
      padding-right: 5px;
      font-size: 20px;
      color: #E18E42;
    }
    .success {
      color: #52B8AD;
    }
    .error {
      color: $error-color;
    }
  }
  p {
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin-bottom: 0;
    padding-bottom: 10px;
  }
  button {
    padding: 0;
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-16px;;
    line-height: 19px;
    text-align: center;
    color: #1E5F91;
  }
}
