
.quick-trade-modal-container {
	.quick-trade-modal-wrapper {
		.ant-tabs-nav-wrap {
			display: none !important;
		}
		.item-card-area {
			.broker-loading-area {
				position: relative;
				.overlay {
					i {
						font-size: 35px;
					}
				}
			}
			.add-broker-wrapper {
				// width: 19.7%;
				div {
					.magnifi-broker {
						.add-broker-button {
							margin: 8px 0;
						}
					}
				}
			}
		}
	}
}
