$background : #f7f7f7;
$border : #b7b7b7;
$text-color : #4a4a4a;
$text-color-highlighted : #56a9e8;
$fontRoboto : 'Roboto';
$fontOpenSans: 'Open Sans';
.container{
  display: flex;
  flex-direction: column;
}
.userPlanContainer{
  display: flex;
  flex-direction: column;
  .formGroup{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .type{
      font-family: $fontOpenSans;
      font-weight: 700;
      color: $text-color-highlighted;
      font-size: 14px;
    }
  }
  .planDetails{
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    p{
      margin: 0;
      font-family: $fontOpenSans;
      font-size: 12px;
      text-align: right;
      color: $text-color;
    }
  }
}
.pricingContainer{
  display: flex;
  color: $text-color;
  flex-direction: column;
  .title{
    font-family: $fontRoboto;
    font-size: 30px!important;
    padding-bottom: 20px;
    color: $text-color!important;
  }
  .list{
    font-family: $fontOpenSans;
    padding: 0 1.4em;
    li{
      list-style: disc;
      color: $text-color-highlighted;
      p{
        margin: 10px 0;
        font-size: 14px!important;
        color: $text-color;
      }
    }
  }
  .formGroup{
    height: 30px;
    display: flex;
    font-size: 22px;
    align-items: center;
    justify-content: space-between;
    .type{
      font-family: $fontOpenSans;
      font-weight: 700;
      font-size: 14px;
    }
  }
}
.subHeader {
  font-size: 22px;
  color: $text-color!important;
  font-family: $fontRoboto;
}
.divider{
  min-width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: $text-color;
  opacity: 0.4;
}
.update{
  align-self: flex-end;
  width: auto;
  padding: 0 18px;
  height: auto;
  border-radius: 24px;
  border: solid 1px $text-color-highlighted;
  margin-top: 20px;
  background: none;
  color: $text-color-highlighted;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-family: $fontRoboto;
  cursor: pointer;
}

.planHeader{

  display: flex;
  align-items: center;
  .subHeader{
    cursor: pointer;
    color: $border!important;
  }
  .active{
    color: $text-color!important;
  }
}
.separator{
  height: 18px;
  margin: 0 24px;
  border-radius: 2px;
  border: 1px solid $border;
}
.subtext{
  margin-top: 20px;
  font-family: 'Open Sans',serif;
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 15px;
  p{
    margin: 0;
  }
}