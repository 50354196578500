
.select-domain-popup-container {
  .ant-modal {
    .ant-modal-content {
      border-radius: 15px;
      .ant-modal-body {
        padding: 36px 30px;
        .context-wrapper {
          margin-bottom: 10px;
          h3 {
            font-family: $secondary-font;
            font-size: $font-size-26px !important;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: normal;
            text-align: center;
            color: $primary-color;
          }
          p {
            margin: 0;
            font-family: $secondary-font;
            font-size: $font-size-20px !important;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.32;
            letter-spacing: normal;
            text-align: center;
            color: $label-black-color;
          }
        }
        .btn-wrap {
          flex-wrap: wrap;
          button {
            height: auto;
            min-width: 250px;
            padding: 10px 20px;
            font-family: $secondary-font;
            font-size: $font-size-11px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: 0.65px;
            text-align: center;
            background: #ffffff;
            border-radius: 30px;
            border-color: $primary-color;
            border: 1px solid $primary-color;

            &:first-child {
              margin: 10px 5px 10px 0;
            }
            &:last-child {
              margin: 10px 0;
            }
          }
          .primary-btn {
            text-transform: uppercase;
            color: #ffffff;
            background: $primary-color;
            span {
              text-transform: uppercase;
            }
          }
          .secondary-btn {
            color: $primary-color;
          }
        }
      }
    }
  }
}
