$border: #b7b7b7;
.retail_different_layout{
  background: #f7f7f7 !important;
  .videoplayerContainer{
    width: 80%;
    margin: auto;
  }
  .heading_in_between{
    color: #56a9e8;
    font-size: 1.0rem;
    // padding-left: 0.8rem;
    font-weight: 500;
    position: relative;
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-start;
  }
  .right-side-panel-container{
    // align-items: flex-start !important;
    padding: 0 !important;
    background: #fff;
    height: unset !important;
    min-height: 100vh;
  }
  .left-side-panel-container{
    height: 100vh;
    background: transparent !important;
    // display: block !important;
    align-items: flex-end;
    justify-content: flex-start !important;
    align-items: flex-end !important;
    .parent_row{
      padding: 0 10%;
      height: 100%;
      max-width: 800px;
    }
    .logo-wrapper{
      img{
        margin-top: 1.8rem;
        width: 90% !important;
      }
    }
    h1{
      margin-top: 1.2rem;
      color: #56a9e8 !important;
      margin-bottom: unset !important;
      font-size: 2.1rem !important;
      font-weight: 400 !important;
    }
    .ai_desc{
      color: #606060;
      font-size: 16px;
      font-weight: 600;
      margin-top: 18px;
    }
    .any_questions{
      color: #979797;
      font-size: 14px;
      font-weight: bold;
      margin-top: 18px;
      margin-bottom: 0;
    }
    .get_support{
      color: #606060;
      font-size: 14px;
      font-weight: bold;
      margin-top: 0;
      a{
        color: #606060 !important;
      }
    }
  }
  .form_content{
    height: 100vh;
    width: 100%;
    .max_for_cont{
      max-width: 800px;
    }
    // background: #fff;
    // overflow: hidden;
    // padding-bottom: 20px;
    // -webkit-box-shadow: 0px 2px 9px 0px rgba(140,140,140,0.75);
    // -moz-box-shadow: 0px 2px 9px 0px rgba(140,140,140,0.75);
    // box-shadow: 0px 2px 9px 0px rgba(140,140,140,0.75);
    .magnifi-auth-design-container{
      min-width: unset !important;
      max-width: 800px !important;
      height: 90%;
      .cta-wrapper{
        // padding: 0 0.8rem;
      }
      .body-wrapper .element-box{
        padding-bottom: 0.8rem;
        padding-top: 0.8rem;
      }
    }
    .trading_me.magnifi-auth-design-container{
      height: 70%;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .form_content {
      height: 100%;
    }
    .left-side-panel-container{
      height: unset;
      .parent_row{
        .videoplayerContainer{
          margin: auto;
        }
        .ai_heading, .ai_desc, .video_frame_container, .any_questions, .get_support{
          display: none;
        }
      } 
    }
  }
  @media only screen and (max-width: 1024px) {
    .left-side-panel-container .parent_row{
      padding: 0 5%;
    }
    .form_content {
      border: unset;
      width: 100%;
      .trading_me.magnifi-auth-design-container{
        height: 65%;
      }
    }
  }
}
.bank-details {
  position: relative;
  padding-bottom: 10px !important;

  input {
    position: absolute;
    left: 20px;
    width: auto;
    top: -2px;
  }

  label {
    margin: 0px;
    padding-left: 20px;
  }
}

.custom-add-account {
  margin-bottom: 20px;
  button {
    padding: 3px 23px !important;
    outline: none !important;
    border: 1px solid #56a9e8 !important;
    border-radius: 10px !important;
    right: 0px !important;
  }
}

.register-magnifi-opt-2 {
  .register-magnifi-auth-design {
    max-width: 75% !important;
  }
  .form-control{
    background-color: transparent !important;
  }

  input{
    background: transparent;
  }

  .code-input{
    button{
      background-color: transparent !important;
    }
    input{
      background: transparent;
    }
  }

  .form_input_container_gray{
    background-color: #f7f7f7;
    padding: 0 8px;
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .border_radius_top{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .border_radius_bottom{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 10px;
  }

  .cta-wrapper .btn {
    font-size: 20px;
    font-weight: normal;
    height: auto;
    line-height: 1.5;
    padding: 0.75rem;
    border-radius: 60px;
    margin-bottom: 0;
  }

  .registration-btn-wrapper {
    width: 100%;
    margin: 0;
    .gutter-row {
      padding: 0;
      &:first-child {
        margin-right: 6px;
      }
      .cta-btn {
        height: 100%;
        min-height: 56px;
        font-size: 1.3em;
        line-height: 1.2;
      }
      @media only screen and (max-width: 1279px) and (min-width: 1024px) {
        .cta-btn {
          font-size: 1em !important;
          line-height: 1.2;
        }
      }
      @media only screen and (max-width: 1023px) and (min-width: 768px) {
        .cta-btn {
          font-size: 10px !important;
          line-height: 1.2;
        }
      }
      @media only screen and (max-width: 767px) and (min-width: 320px) {
        .cta-btn {
          font-size: 1em !important;
          line-height: 1.2;
        }
      }
    }
  }

  .magnifi-auth-design-container {
    padding: 0;
    width: 100%;
    max-width: 100% !important;
    padding: 0 10%;
    overflow: hidden;
    overflow-y: scroll;
    @media only screen and (min-width: 1200px) and (max-width: 1265px) {
      .ssf-datepicker__label {
        right: 12px;
      }
    }
  }
  .form-check-input{
    margin-left: -1.5rem !important;
  }
  .btn:focus{
    box-shadow: unset !important;
  }
  .magnifi-auth-design-container .header-area .header-label.main {
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    line-height: 1.18;
  }

  .retail_form_container.header-area .header-label.main {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
    line-height: 1.36;
    letter-spacing: -0.09px;
    color: #56a9e8;
  }

  .retail_form_container .row{
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .retail_form_container.row{
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .start_finding{
    width: 100%;
    text-align: center;
    margin: 15px 0;
    padding-bottom: 1.5rem;
    a{
      color: #939393;
      text-decoration: underline;
    }
  }

  .magnifi-auth-design-container .header-area .header-label.sub{
    font-size: 1.1rem !important;
    font-weight: 600 !important;
    margin-top: 20px;
    text-align: center;
    color: #2e2e2e !important;
    span{
      font-size: 12px;
      color: #cdcdcd;
    }
  }

  .magnifi-auth-design-container .header-area .header-label-sub-gray{
    font-size: 0.9rem !important;
    font-weight: 400 !important;
    color: #99999a;
    text-align: center;
  }


  
  .checkmark_label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  
  .checkmark_label .checkmark {
    position: absolute;
    top: -7px;
    left: 0;
    border-radius: 0 !important;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid #cecece;
  }
  
  .checkmark_label input:checked ~ .checkmark {
      background-color: #ffffff;
      border: 1px solid #56a9e8;
  }
  
  .checkmark_label .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkmark_label input:checked ~ .checkmark:after {
    display: block;
  }
  

  .checkmark_label .checkmark:after {
      left: 4.3px;
      top: 1.5px;
      width: 4.5px;
      height: 8px;
      border: solid #56a9e8;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(
  45deg
  );
      -ms-transform: rotate(45deg);
      transform: rotate(
  45deg
  );
  }




  .magnifi-auth-design-container .header-area.form_title{
    padding-bottom: 10px !important;
  }

  .magnifi-auth-design-container .body-wrapper .element-box {
    position: relative;
    padding-bottom: 0.85rem;
    padding-top: 0.85rem;
  }
  .retail_form_container .element-box .new_label {
    position: absolute;
    z-index: 1;
    top: 33px;
    left: 10px;
    color: rgba(0, 0, 0, 0.38) !important;
  }
  .magnifi-auth-design-container .body-wrapper .element-box .label {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.25;
    margin-bottom: 0.5rem;
  }

  .toggle-switch-wrapper .toggle-switch {
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
  }

  .retail_form_container .element-box .input-area{
    border-radius: 4px !important;
    border: solid 1px rgba(0, 0, 0, 0.12) !important;
    background-color: #fff !important;
    height: 56px !important;
    padding: 10px 10px 0 10px !important;
  }

  .politicaly_exposed_span{
    font-size: 16px;
  }

  .retail_form_container .element-box:focus-within{
    .label.new_label{
      font-size: 10px !important;
      top: 20px;
    }
  }

  .retail_form_container .element-box .label.new_label{
    font-size: 12px !important;
  }

  .retail_form_container .element-box .label.has_value.new_label{
    font-size: 10px !important;
    top: 20px;
  }

  .magnifi-auth-design-container.retail_form_container .body-wrapper .element-box .input-area{
    font-size: 0.8rem !important;
  }


  .magnifi-auth-design-container .body-wrapper .element-box .input-area {
    font-size: 20px;
    font-weight: normal;
    // line-height: 1.25;
    &.placeholder {
      color: #ced4da;
    }
  }
  .magnifi-auth-design-container .bg-dummy {
    background-color: #ccc;
  }

  .retail_form_container .element-box .input-area{
    &::placeholder{
      color: rgba(0, 0, 0, 0.38) !important;
    }
  }

  .or-partition {
    font-weight: normal;
    color: #979797;
    margin: 4px 0;
    font-size: 0.9375rem !important;
  }
  .fs-20 {
    font-size: 16px !important;
  }
  .dropdown-element .dropdown-wrapper .dropdown-toggle::after {
    top: 0.8em;
  }
  .magnifi-auth-design-container .body-wrapper .are-you-other {
    &,
    &:hover,
    &:focus {
      top: 30px !important;
    }
  }

  &.magnifi-auth-page-wrapper {
    height: auto;
    min-height: 100%;
  }
  &.magnifi-auth-page-wrapper .right-side-panel-container {
    flex-direction: column;
    justify-content: flex-start;
  }

  // &.magnifi-auth-page-wrapper .left-side-panel-container {
  //   align-items: flex-start;
  // }

  @media only screen and (max-width: 768px) {
    .magnifi-auth-design-container.retail_form_container .body-wrapper .element-box .label {
      font-size: 10px !important;
    }
    .magnifi-auth-design-container.retail_form_container .body-wrapper .element-box:focus-within{
      .label.new_label{
        font-size: 7px !important;
        top: 20px;
      }
    }
    .magnifi-auth-design-container.retail_form_container .body-wrapper .element-box .label.has_value.new_label{
      font-size: 7px !important;
      top: 20px;
    }
  }

  @media only screen and (max-width: 991px) {
    .register-magnifi-auth-design {
      margin-top: 0;
    }
  }

  .h1 {
    font-size: 50px !important;
    line-height: 1;
  }
  .left-side-panel-container {
    h1,
    .h4 {
      color: #ffffff;
      font-weight: normal;
      line-height: 1.2;
    }
    h1 {
      font-size: 35px;
      font-weight: 400;
      margin-bottom: -15%;
      font-family: 'Roboto';
    }
    .h4 {
      font-size: 20px !important;
      font-family: 'Open Sans';
    }
  }

  .carousel {
    margin-top: 21%;
    padding: 0 5%;
    .carousel-inner {
      margin-top: 15%;
    }
    .carousel-indicators.dots {
      position: absolute;
      bottom: -72px;
      li {
        height: 13px;
        width: 13px;
        border-radius: 50%;
        margin: 0 6px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .carousel-indicators {
    bottom: -52px !important;
  }
}

.typeahead-field {
  height: 20px;
  width: 100%;
  border: 0px;
}

.section-heading {
  padding-bottom: 5px !important;
}

.element-upload-outer {
  position: relative;
}

.document-upload {
  position: absolute;
  right: 0px;
  top: 5px;
  border: 1px solid #56a9e8;
  background: #fff;
  color: #56a9e8;
  text-transform: uppercase;
  font-size: 0.65rem;
  padding: 0.15rem 0.5rem;
  border-radius: 15px;
  height: 22px;
}

.document-upload {
  position: absolute;
  right: 17px;
  top: 5px;
  border: 1px solid #56a9e8;
  background: #fff;
  color: #56a9e8;
  text-transform: uppercase;
  font-size: 0.58rem;
  padding: 0.15rem 0.3rem;
  border-radius: 15px;
  height: 22px;
}

.doc-toltip {
  position: absolute;
  right: 0px;
  top: 8px;
}

.upload-div {
  color: #d1d6dc !important;
}

.file-hidden {
  display: none !important;
}

.terms-and-agreement-label {
  padding-left: 28px;
  margin-top: -23px;
}

.account-terms-text {
  margin-bottom: 1rem;
  font-size: 0.65rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #444444;

  a {
    font-size: 0.65rem;
    color: #56a9e8 !important;
    margin-left: 2px;
    display: inline-block;
    vertical-align: top;
    margin-top: 1px;
  }
}

.account-balance {
  font-size: 14px;
  font-weight: 700;
  color: #1a1e21;
}

.upload_div_new{
  border-radius: 4px !important;
  border: solid 1px rgba(0, 0, 0, 0.12) !important;
  background-color: #fff !important;
  height: 56px !important;
  padding: 10px 10px 0 10px !important;
}

.upload_div {
  border: none;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid #2f2f2f;
  .label-container {
    max-width: 100%;
    p {
      width: 65%;
      opacity: 0.5;
      font-size: 14px;
      text-transform: capitalize;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    label {
    }
  }
  p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
  }
  .holoButton {
    background: white;
    border-radius: 15px;
    border: 1px solid #56a9e8;
    padding: 6px 9px;
    text-transform: uppercase;
    color: #56a9e8 !important;
  }
  input {
    display: none;
  }
}

.req-doc-row {
  display: flex;
  margin: 10px 0;
  flex-direction: column;
  margin-bottom: 30px;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .heading {
      color: #1a1e21;
      font-size: 16px;
      font-weight: 600;
      margin-right: 12px;
    }
    .error {
      color: #6e0303;
    }
  }
  .bottom {
    min-height: 40px;
    margin-top: 10px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    &.fdc {
      flex-direction: column;
      align-items: inherit !important;
      p {
        margin-bottom: 0px;
      }
    }
    .dropdown-wrapper {
      width: 100%;
      .toggle {
        width: 100%;
        background-color: #fff !important;
        border-radius: 0;
        color: #2f2f2f !important;
        border: none;
        text-align: start;
        border-bottom: 1px solid #2f2f2f;
        display: flex;
        box-shadow: none !important;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
      }
      .disabled {
        cursor: not-allowed;
      }
    }
    .hidden_file{
      display: none;
    }
    .upload_div {
      height: 100%;
      display: flex;
      align-items: center;
      width: 100%;
      flex-direction: row-reverse;
      position: relative;
      input {
        display: none;
      }
      .label {
        cursor: pointer;
        margin-left: 20px;
        align-self: flex-end;
        justify-self: flex-end;
      }
    }
  }
}

.upl {
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.upl-file {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  height: 37px;
  // border-bottom: 1px solid black;
  margin-left: 20px;
  padding-right: 76px;
  color: #c2c2c2;
}
.btnfield {
  background: white;
  border-radius: 15px;
  border: 1px solid #56a9e8;
  padding: 3px 9px;
  text-transform: uppercase;
  color: #56a9e8 !important;
  font-size: 0.875rem;
  margin-bottom: 10px;
}
.delbtn {
    float: right;
    position: absolute;
    top: 35px;
    right: 19px;
    background: white;
}

.terms {
  margin-left: 30px !important;
  cursor: pointer;
}

.signature_buttons{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}
.signature_buttons .save_clear_span:first-child {
  margin-right: 12px;
}
.save_clear_span{
  color: #56a9e8;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  line-height: 3px;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  line-height: 3px;
}

.register-magnifi-opt-2 .magnifi-auth-design-container .header-area.form_title.no_padding{
  padding-bottom: 0 !important;
}

.save_clear_span:hover{
  cursor: pointer;
}

.signature-box {
  text-align: center !important;
  width: 100%;
  // border: 1px solid #ddd;
  height: 150px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  height: 200px;
  overflow: hidden;
  margin: 0;
  text-align: center !important;
  float: none !important;
  display: flex;
  justify-content: center;
  background: #f5f5f5;

  > div {
    background: #ffffff;
  }
}
.signatureBtn {
  flex-direction: row !important;
  margin-bottom: 5px !important;
  button {
    font-size: 15px !important;
    padding: 0.5rem !important;
  }
}
.hyper-link {
  cursor: pointer;
}

.saveProfile_toastFail {
  background-color: #f8d7da !important;
  color: #721c24 !important;
  .ant-notification-notice-message {
      display: none;
  }
}
.saveProfile_toastSuccess {
  background-color: #d4edda !important;
  color: #155724;
  .ant-notification-notice-message {
      display: none;
  }
}
