
// custom error state styles
.ant-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.update_docs_btn{
    padding: 2px 50px;
    border-radius: 24px;
    border: 1px solid #56a9e8;
    background: none;
    font-size: 16px;
    font-family: Roboto;
    color: #56a9e8;
    text-transform: uppercase;
    font-weight: 500;
    &:hover{
      cursor: pointer;
    }
}

.magnifi-dashboard-container .dashboard-content-container .custom-scroll.custom_height{
  height: 90vh !important;
}

.upload_doc_container{
  .spinner-wrapper{
    background: transparent !important;
  }
  .primary_secondry_tabs{
    text-align: left;
    .applicant_span{
      font-size: 14px;
      font-weight: 300;
    }
    ul{
      list-style: none;
      padding: 10px 0; 
      li{
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 10px;
        a{
          color: black;
          font-size: 16px;
          font-weight: bold;
        }
        a.active{
          color: #56a9e8;
        }
      }
    }
  }
  h2{
    color: #56a9e8;
    font-size: 20px !important;
  }
  h3{
    color: #56a9e8;
    font-size: 18px !important;
  }
  .file_list_item{
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    background: #fff;
    color: #a1a1a1;
    .child_col{
      padding: 10px;
      text-align: left;
      span{
        font-size: 11px;
      }
      p{
        margin-bottom: 0;
        font-size: 14px;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .remove_file_button{
        color: #56a9e8;
        font-size: 18px;
        font-weight: bold;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
  .dropzone-container{
    width: 100%;
    // padding: 30px 10px;
    height: 120px;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    background: #fff;
    color: #a1a1a1;
    margin-bottom: 5px;
    margin-top: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    .dropzone{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p{
      margin-bottom: 0;
      font-size: 12px;
      .active_link{
        color: #56a9e8;
        text-decoration: underline;
      } 
      .disabled_link{
        color: #a1a1a1;
        &:hover{
          cursor: initial;
        }
      }
    }
    .upload_icon{
      font-size: 30px;
    }
    .upload_icon_active{
      color: #56a9e8;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .tab-content{
    .row{
      padding: 20px 0;
      label{
        margin-bottom: 0;
        font-size: 15px;
        &:hover{
          cursor: pointer;
        }
      }
      .form-group{
        margin-bottom: 5px;
        margin-top: 5px;
        padding: 8px 10px;
        border: 1px solid #d1d1d1;
        border-radius: 6px;
        background: #fff;
        color: #a1a1a1;
        display: flex;
        align-items: center;
        &:hover{
          cursor: pointer;
        }
      }
      .form-check-input{
        position: initial;
        width: unset;
        margin-left: 0;
        margin-top: 0;
        margin-right: 10px;
        height: 12px;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
  .nav-tabs{
    width: 100%;
    border-bottom: 1px solid gray;
    .nav-item{
      // width: 50%;
      .nav-link.active{
        border: 0;
        border-bottom: 3px solid #56a9e8;
        font-weight: bold;
        color: #56a9e8;
        background-color: transparent;
        &:focus{
          border-bottom: 3px solid #56a9e8;
        }
        &:hover{
          border-bottom: 3px solid #56a9e8;
        }
      }
      .nav-link{
        padding: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:focus{
          border-color: transparent;
        }
        &:hover{
          border-color: transparent;
        }
      }
    }
  }
}

.no-result-wrapper .specific-query .suspended_card{
    background-color: #ffffff;
    border-radius: 5px;
    padding: 15px;
    text-align: left;
    margin-top: 20px;
    b {
      font-size: 20px;
      color: #7d7d7d;
    }
    ul {
      width: 100%;
      padding: 0;
      margin: 0;
      li {
        margin: 5px 0;
        font-size: 18px;
        position: relative;
        color: #9d9d9d;
        padding-left: 15px;
        font-weight: 450;
        &:before {
          content: "";
          background-color: #7d7d7d;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          display: inline-block;
          left: 0;
          position: absolute;
          top: 10px;
      }
      }
      &:last-child{
        padding-left: 0;
      }
    }
}

.ucStateContainer {
  width: 50%;
  min-width: 285px;
  max-width: 495px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 60px auto 0;
  .codeWrapper {
    h3 {
      font-family: $primary-font;
      font-size: 3.5em !important;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      // color: $card-actions-background;
    }
  }
  .contentWrapper {
    width: 100%;
    flex: auto;
    .description {
      font-family: $primary-font;
      font-size: 1.3em;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      // color: $text-color-secondary;
      margin-bottom: 16px;
    }
    .imageWrapper {
      width: 100%;
      max-width: 339px;
      height: 380px;
      margin: 0 auto;
      position: relative;
      img {

      }
      .actionsWrapper {
        display: flex;
        justify-content: space-evenly;
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 auto;
        bottom: 34px;
        a {
          img {
            width: 128px;
            height: 38px;
            object-fit: contain;
          }
        }
        button {
          border-radius: 18.5px;
          // background-color: $black-color;
          // border: 0px solid $black-color;
          font-size: 11px;
          font-weight: bold;
          line-height: 3.5;
          letter-spacing: 0.3px;
          text-align: center;
          color: #fff;
          text-transform: uppercase;
          height: auto;
          padding: 0px 30px;
        }
        button:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
}

.errorStateContainer {
  max-width: 395px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
  margin: 0 auto;

  .codeWrapper {
    h3 {
      font-family: $primary-font;
      font-size: 8em;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      // color: $card-actions-background;
    }
  }

  .contentWrapper {
    flex: auto;
    h5 {
      font-family: $primary-font;
      font-size: 2em;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      // color: $title-color;
    }
    .description {
      font-family: $primary-font;
      font-size: 1.3em;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      // color: $text-color-secondary;
      margin-bottom: 16px;
    }
    .actionsWrapper {
      text-align: center;
      button {
        border-radius: 18.5px;
        // background-color: $black-color;
        // border: 0px solid $black-color;
        font-size: 11px;
        font-weight: bold;
        line-height: 3.5;
        letter-spacing: 0.3px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        height: auto;
        padding: 0px 30px;
      }
      button:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

// @media screen and (max-width: @screen-xl) {
//   // laptop
// }
//
// @media screen and (max-width: @screen-lg) {
//   // tablet
// }
//
// @media screen and (max-width: @screen-md) {
//   .errorStateContainer {
//     .codeWrapper {
//       h3 {
//         font-size: 6em;
//       }
//     }
//     .contentWrapper {
//       h5 {
//         font-size: 1.5em;
//       }
//       .description {
//         font-size: 1.5em;
//       }
//     }
//   }
//   .ucStateContainer {
//     .codeWrapper {
//       h3 {
//         font-size: 2em !important;
//       }
//     }
//     .contentWrapper {
//       .description {
//         font-size: 1em !important;
//       }
//     }
//   }
// }
//
// @media screen and (max-width: @screen-sm) {
//   // mobile
// }
//
// @media screen and (max-width: @screen-xs) {
//   // iphone 5s devices
// }
