@import "../Dashboard/index";

$c1 : #56a9e8;
$c2 : #4a4a4a;
$c3 : #bababb;
$c4 : #1e5f91;
$c5 : #f7f7f7;
$c6 : #343a40;
$c7 : #0056b3;
$c8 : #212529;

.open-summary-custom-scroll {
  // height: calc(100vh - 482px) !important;
  height: calc(100vh - 100px) !important;
   padding-bottom: 0 !important;
   padding-left: 10px !important;
   padding-right: 5px !important;
   margin-right: -15px !important;
}

.OpenSummaryPage-id-text {
  font-size: 27px;
  font-weight: normal;
  color: #1d6091;
}

.OpenSummaryPage-view-portfolio-dropdown {
  width: auto;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: -0.28px;
  text-align: left;
  color: #56a9e8;
  border: 0px;
}

.OpenSummaryPage-delete-icon {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: -0.24px;
  text-align: left;
  color: #bababa;
}

.OpenSummaryPage-container {
  .OpenSummaryPage-wrapper {
    .OpenSummaryPage-row {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
    }
    .OpenSummaryPage-col {
      width: 100%;
      // height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .OpenSummaryPage-block {
      width: 100%;
      height: 100%;
      flex: 1;
    }
    .OpenSummaryPage-card-head {
      font-size: 2.35rem !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: -0.8px;
      text-align: left;
      color: #1d6091;
    }
    .OpenSummaryPage-card-title {
      width: 100%;
      margin: 0 !important;
      font-size: 1.25rem !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: -0.8px;
      text-align: left;
      color: #4a4a4a;
    }
    .OpenSummaryPage-card-sub-title {
      font-family: Roboto;
      font-size: 14px !important;
      font-weight: normal !important;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a !important;
    }
    .OpenSummaryPage-card-desc-text {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: -0.24px;
      text-align: left;
      color: #bababa;
    }
    .OpenSummaryPage-card-details-text {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.06;
      letter-spacing: -0.32px;
      text-align: left;
      color: #1d6091;
    }
    .OpenSummaryPage-holdings-item {
      &:hover {
        background: #dff1ff;
      }
    }
    .security-block {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  .primary-cta-button {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: #1e5f91 !important;
    background-color: #ddeefa !important;
    border: 0;
    border-radius: 50px;
    height: 34px;
    color: #fff !important;
    background-color: #1e5f91 !important;
    text-transform: uppercase;
    line-height: normal;
    .anticon {
      position: absolute;
      top: 8px;
      vertical-align: 0;
      line-height: normal;
    }
  }
  .OpenSummaryPage-wrapper {
    width: 100%;
    padding: 0;
    // margin-right: -7px;
    // padding-right: 8px;
    // margin-top: -3px;
  }

  .row.compact {
    margin-left: -.25rem;
    margin-right: -.25rem;
    &> [class*="col-"] {
      padding-left: .25rem;
      padding-right: .25rem;
    }
  }

  .bg-wave1 {
    background: no-repeat bottom url('/assets/images/wave-1.png') transparent;
    background-size: 100%;
    background-position-y: 102%;
  }
  .bg-wave2 {
    background: no-repeat bottom url('/assets/images/wave-2.png') transparent;
    background-size: 100%;
  }

  .tx-c1 { color: $c1; }
  .tx-c2 { color: $c2; }
  .tx-c3 { color: $c3; }
  .fw-b  { font-weight: bold; }
  .fw-n  { font-weight: normal; }

  .fs-l1 {
    font-size: 1.75rem !important;
  }
  .fs-m1 {
    font-size: 1rem !important;
  }
  .fs-s1 {
    font-size: .875rem !important;
  }
  .nav-pills .nav-link.active {
    background-color: $c4;
  }
  .header.dropdown, .btn-link {
    font-size: .875rem !important;
    color: $c1;
    font-family: 'Roboto', sans-serif !important;
  }
  .sfmodal-comb-drpdwn {
    font-size: 1rem !important;
    color: $c8;
    font-family: inherit !important;

    &:hover {
      color: $c7 !important;
    }
  }
  .sfmodal-date, .sfmodal-unit {
    font-size: .75rem !important;
    color: $c6;
    font-family: inherit !important;

    &:hover {
      color: $c7 !important;
    }
  }
  .mx-0p5 {
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }

  .bg-c5 { background-color: $c5 !important; }

  .ps-wrap {
    margin-right: -10px;
    padding-right: 6px;
    position: relative;
    height: 400px;
    .ps__rail-y { width: 4px; }
    .ps__thumb-y { right: 0px; }
  }
}
