// please use variables from layouts/_variables.scss
@import "./Elements/index";
@import "./Pages/OnBoardScreen/index";
@import "./Pages/Models/index";
@import "./Pages/Funds/index";
@import "./Pages/Dashboard/index";
@import "./Pages/Summary/index";
@import "./Pages/OpenSummaryPage/index";

// @import "./header";
@import "./responsive";

.modal-open-sfm .sfp-backdrop {
  display: block !important;
  opacity: 0.4;
  background-color: #060606;
}

.ant-tabs-tab-prev, .ant-tabs-tab-next {
  z-index: 0 !important;
}

.grey-bg {
  background-color: $tag-grey-bg-color !important;
  border: 1px solid $tag-grey-bg-color !important;
}

.blue-bg {
  background-color: $tag-blue-bg-color !important;
  border: 1px solid $tag-blue-bg-color !important;
}

.ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-submenu-arrow {
  display: flex;
  align-items: center;

  .anticon-right {
    margin-right: 0 !important;
  }
}

.ant-layout {
  background: $bg-color-light !important;
}

.accounts-content-wrapper {
  height: calc(100vh - 60px);
  overflow: hidden;
  background: $bg-color-light !important;
  position: relative;
  margin-top: 0rem !important;
  padding-right: 15px;
  padding-left: 15px;

  .holdings-weight-font-size {
    font-size: 0.8rem !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 14px !important;
    letter-spacing: normal;
    text-align: center;
    font-weight: 600;
    color: $label-black-color;
  }
  .holdings-value-font-size {
    font-size: 0.8rem !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 12px !important;
    letter-spacing: normal;
    text-align: center;
    font-weight: 600;
    color: $label-black-color;
  }
}
// .accountv2-sidebar-notification-count {
//   position: absolute;
//   right: 10px;
//   top: 145px;
//   width: 18px;
//   background: $primary-color;
//   border-radius: 50%;
//   height: 18px;
//   text-align: center;
//   font-size: 13px;
//   color: #fff;
// }

// .accountsv2-container {
//   .quick-link-wrapper{
//     a {
//       display: inline-block;
//       color: $primary-color-2;
//       margin-right: 0.7rem;
//       margin-bottom: 0.8rem;
//       img{height:20px;margin-right:0.4rem;}
//     }
//   }
//   // &.magnifi-dashboard-container .dashboard-content-container .custom-scroll {
//   //   height: calc(100vh - 125px) !important;
//   // }
// }
