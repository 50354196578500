$primary-color: #56a9e8;

.register-magnifi-opt-2 {
  overflow: hidden;
  .register-magnifi-auth-design {
    max-width: 75% !important;

    .header-area {
      padding: 0;
    }
    .trading-benefits {
      display: block;
      margin: 20px 0px 20px;

      h1 {
        color: $primary-color;
        font-size: 1.2rem !important;
        font-weight: normal;
      }

      h2 {
        padding: 10px 0;
        color: #4a4a4a;
        font-size: 1rem !important;
        font-weight: normal;
      }
      ul {
        color: #4a4a4a;
        font-size: 0.8rem;
        padding: 0px 15px;
        line-height: 1rem;

        li {
          padding-left: 0px;
          margin-bottom: 10px;
        }
      }
    }
    .skip {
      color: $primary-color;
      padding: 12px 0 0 0;
      cursor: pointer;
      text-align: center;
    }
  }

  .free-trial {
    margin-bottom: 20px;
  }

  .cta-wrapper .auth-provider {

    @media only screen and (max-width: 1096px) {
      flex-direction: column;
    }    

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .item {
      @media only screen and (max-width: 1096px) {
        width: 100%;
      }      
      width: 48%;
      white-space: nowrap;
      padding-bottom: 5px;
    }
  }

  .cta-wrapper .change-sub {
    color: $primary-color;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    margin: 0;
  }

  .cta-wrapper .btn {
    font-size: 20px;
    font-weight: normal;
    height: 44px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    padding: 0.42rem !important;
    border-radius: 60px;
    margin-bottom: 0;
    text-transform: capitalize;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1210px) {
    .cta-wrapper .btn {
      font-size: 16px !important;
    }
  }

  .registration-btn-wrapper {
    width: 100%;
    margin: 0;

    .signup-go-back {
      border: none;
      background: none;
      padding: 0px;
    }
    .gutter-row {
      padding: 0;
      &:first-child {
        margin-right: 6px;
      }
      .cta-btn {
        height: 100%;
        min-height: 50px;
        font-size: 15px;
        line-height: 1.2;
      }
      @media only screen and (min-width: 1440px) {
        .cta-btn {
          font-size: 16px !important;
          line-height: 1.2;
          padding: 0;
        }
      }
      @media only screen and (min-width: 1280px) and (max-width: 1439px) {
        .cta-btn {
          font-size: 16px !important;
          line-height: 1.2;
          padding: 0;
        }
      }
      @media only screen and (max-width: 1279px) and (min-width: 1024px) {
        .cta-btn {
          font-size: 14px !important;
          line-height: 1.2;
          padding: 0;
        }
      }
      @media only screen and (max-width: 1023px) and (min-width: 768px) {
        .cta-btn {
          font-size: 10px !important;
          line-height: 1.2;
        }
      }
      @media only screen and (max-width: 767px) and (min-width: 320px) {
        .cta-btn {
          font-size: 1em !important;
          line-height: 1.2;
        }
      }
    }
  }

  .retail-register-cta-btn-wrapper {
    width: 100%;
    // margin: 20px 0px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;

    .register-trade-later-btn {
      // width: 48%;
      // margin: 0 8px 0 0px;
      margin: 20px 0px;
      text-transform: capitalize;
    }
    .sign-in__googleBtn {
      // width: 48%;
      // margin: 0 0px 0 8px;
      // margin: 20px 0px;
      text-transform: capitalize;
    }
  }

  .magnifi-auth-design-container {
    padding: 0;
    width: 100%;
    height: 100%;
    max-width: 100% !important;
    padding: 0 13%;
    overflow: hidden;
    overflow-y: auto !important;
  }

  .magnifi-auth-design-container .header-area .header-label-advisor {
    font-size: 1.875rem !important;
    font-weight: normal;
    line-height: 1.18;
    color: #56a9e8;
    font-weight: 400;
  }

  .magnifi-auth-design-container .header-area .header-label.main {
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    line-height: 1.18;
  }

  .retail_form_container.row{
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .retail_form_container .row{
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .retail_form_container.header-area .header-label.main {
    font-size: 18px !important;
    font-weight: normal !important;
    line-height: 1.36;
    letter-spacing: -0.09px;
  }
  .magnifi-auth-design-container .header-area .header-label.sub{
    font-size: 1.1rem !important;
    font-weight: 600 !important;
  }

  .magnifi-auth-design-container .header-area .header-label-sub-gray{
    font-size: 0.9rem !important;
    font-weight: 400 !important;
    color: #99999a;
    text-align: center;
  }

  .magnifi-auth-design-container .body-wrapper .element-box {
    position: relative;
    padding-bottom: 1.75rem;
  }
  .retail_form_container .element-box .new_label {
    position: absolute;
    z-index: 1;
    top: 33px;
    left: 10px;
    color: rgba(0, 0, 0, 0.38) !important;
  }
  .magnifi-auth-design-container .body-wrapper .element-box .label {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.25;
    margin-bottom: 0.5rem;
  }

  .toggle-switch-wrapper .toggle-switch {
    margin-bottom: 0.5rem;
  }

  .retail_form_container .element-box .has_value.new_label{
    font-size: 10px !important;
    top: 20px;
    .addition_icon{
      span{
        font-size: 9px !important;
      }
    }
  }

  .retail_form_container .element-box .input-area{
    border-radius: 4px !important;
    border: solid 1px rgba(0, 0, 0, 0.12) !important;
    background-color: #fff !important;
    height: 56px !important;
    padding: 10px 10px 0 10px !important;
  }
  .retail_form_container .element-box:focus-within{
    .new_label{
      font-size: 10px !important;
      top: 20px;
    }
    .new_label.addtion_icon_label{
      .addition_icon{
        span{
          font-size: 9px !important;
        }
      }
    }
  }


  .magnifi-auth-design-container .body-wrapper .element-box .input-area {
    font-size: 20px;
    font-weight: normal;
    // line-height: 1.25;
    &.placeholder {
      color: #ced4da;
    }
  }

  .addtion_icon_label{
    // margin-left: 6px !important;
    .addition_icon{
      position: relative;
      span{
        position: absolute;
        right: -8px;
        top: -1px;
        font-size: 11px;
      }
    }
  }

  .help_note{
    font-size: 12px;
    color: #99999a;
    position: relative;
    span{
      position: absolute;
      left: -10px;
      font-size: 12px;
      top: -3px;
    }
  }

  .retail_form_container .element-box .input-area{
    &::placeholder{
      color: rgba(0, 0, 0, 0.38) !important;
    }
  }
  .magnifi-auth-design-container .bg-dummy {
    background-color: #ccc;
  }

  .or-partition {
    font-weight: normal;
    color: #979797;
    margin: 4px 0;
    font-size: 0.9375rem !important;
  }
  .fs-20 {
    font-size: 16px !important;
  }
  .retail_form_container .dropdown-element .dropdown-wrapper .dropdown-toggle::after {
    top: 1.8em;
    right: 6px;
  }
  .dropdown-element .dropdown-wrapper .dropdown-toggle::after {
    top: 0.8em;
  }
  .magnifi-auth-design-container .body-wrapper .are-you-other {
    &,
    &:hover,
    &:focus {
      top: 30px !important;
    }
  }

  &.magnifi-auth-page-wrapper {
    height: auto;
    min-height: 100%;
  }
  &.magnifi-auth-page-wrapper .right-side-panel-container {
    flex-direction: column;
    justify-content: flex-start;
  }

  @media only screen and (max-width: 991px) {
    .register-magnifi-auth-design {
      margin-top: 0;
    }
  }

  .h1 {
    font-size: 50px !important;
    line-height: 1;
  }
  .left-side-panel-container {
    .contact-info {
      display: flex;
      flex-direction: column;
      color: white;
      justify-content: center;
      align-items: center;     
    }
  }

  .carousel {
    margin-top: 21%;
    padding: 0 5%;
    .carousel-inner {
      margin-top: 15%;
    }
    .carousel-indicators.dots {
      position: absolute;
      bottom: -72px;
      li {
        height: 13px;
        width: 13px;
        border-radius: 50%;
        margin: 0 6px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .carousel-indicators {
    bottom: -52px !important;
  }
}

.typeahead-field {
  height: 20px;
  width: 100%;
  border: 0px;
}

.register-magnifi-opt-2 .retail_form_container .element-box .input-area.disabled{
  background-color: #e1e1e1 !important;
}