// Required
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

// Configuration settings for PDF
$dark-blue : #101828;
$light-blue : #101828;

$lvl1-header-font-family: 'Inter', sans-serif;
$lvl1-header-font-size: 2.2rem;

$lvl2-header-font-family: 'Inter', sans-serif;
$lvl2-header-font-size: 1.6rem;

$lvl3-header-font-family: 'Inter', sans-serif;
$lvl3-header-font-size: 1.4rem;

$body-font-family: 'Open Sans';
$body-font-color: #101828;
$body-font-size: 1.2rem;

$chart-label-grid-color: #b0b0b0;

$table-gray: #f7f7f7;

$pg-px : 12mm;

.reports{
  height: auto;
  .page {
    .page-header, .page-footer {
      background-color: inherit !important;
    }
  }
  .wrap-margin{
    .mb-4{
      margin-bottom: 0px !important;
    }
  }
}

.shared-report {
  padding-top: 0;
  background-color: white !important;
  height: 100%;
  font-family: $body-font-family;
  font-color: $body-font-color;
  font-size: $body-font-size;

  .page-header{
    margin-bottom: 10px;
    .logo, .logo-placeholder {
      max-height: 68px;
      height: 68px;
      width: auto;
      color: #4a4a4a !important;
    }
    .logo-placeholder {
      margin: 0;
      display: flex;
      align-content: center;
      font-size: 1.35rem;
      font-weight: bold;
      color: #4a4a4a !important;
    }
    .ml-auto{
      align-self: center;
      color: #a0a0a0;
    }
    img{
      margin-left: -5px;
    }
  }

  .page {
    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 80px;
      padding: 4mm $pg-px;
      border-bottom: 2px solid #e9ecef;;
      margin-bottom: 6mm;
    }
    .page-header, .page-footer {
      padding: 7mm 0;
      font-size: 12px;
    }
    .page-footer {
      border-top: 1px solid #e3e3e3;
      color: #909090;
      padding-top: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .pr-v2-header{
    h3{
      font-family: $lvl1-header-font-family;
      font-size: $lvl1-header-font-size !important;
      color: $dark-blue;
      margin-bottom: 50px;
    }
  }

  .fl-caps::first-letter {
    text-transform: uppercase;
  }

  .pr-v2-cover-page{
    padding-top: 60px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    font-size: 1.35rem;
    color: $body-font-color !important;
    img, .cover-logo {
      margin-top: 10%;
      margin-bottom: 70px;
    }
    .cover-logo {
      width: auto;
      height: 75px;
      max-height: 75px;
      font-weight: bold;
    }
    .title-name-logo{
      margin-bottom: 40px;
      h1{
        color: $dark-blue;
        font-size: 4.3rem !important;
        margin-bottom: 50px;
        display: inline;
        font-family: 'Roboto';
        font-weight: 400;
      }
      img{
        display: inline;
        margin: 0 0 0 20px;
      }
      &.layout-2{
        h1{
          float: left;
          width: 100%;
          margin-bottom: 35px;
        }
        img{
          margin: 0 30px 0 0px;
        }
      }
    }
    h1{
      color: $dark-blue;
      font-size: 4.3rem !important;
      margin-bottom: 50px;
      font-family: 'Roboto';
      font-weight: 400;
    }
    .glossary-footer{
      font-size: 1.2rem;
      p{
        margin-bottom: 5px;
      }
    }
  }

  .pr-v2-toc{
    font-size: 1.4rem !important;
    color: $dark-blue;
    tr{
      &:nth-child(odd){
        background: $table-gray;
      }
    }
    td{
      padding-top: 12px;
      padding-bottom: 12px;
      border: none;
      &:first-child{
        padding-left: 40px;
      }
      &:nth-child(2){
        text-align: right;
        padding-right: 40px;
      }
    }
  }

  .pr-v2-glossary{
    padding-top: 60px;
    padding-bottom: 60px;
    font-size: 0.85rem;
  }
  .pr-v2-disclosure{
    padding-top: 20px;
    padding-bottom: 60px;
    font-size: 0.85rem;
  }

}

@media print {
  .shared-report {
    .page {
      height: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      .content {
        flex: 1;
      }
    }
    .pr-v2-glossary{
      page-break-before: always;
    }
    .table td, .table th {
      background-color: transparent !important;
    }
  }
  .recharts-wrapper, .recharts-surface{
    width: inherit !important;
    min-width: inherit !important;
  }
  .reports {
    height: 100%;
    .page {
      height: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      .content {
        flex: 1;
        padding: 0;
      }
    }
    .recharts-wrapper, .recharts-surface{
      width: inherit !important;
      min-width: inherit !important;
    }
  }
}

.coverTitle {
  color: #fff;
  font-size: 3.25rem !important;
  margin-bottom: 32px;
  display: inline;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}