$large-screen-width: 1128px;
$gray-font: #f6f6f6;
#landing-page{
    .free-trade-option{
        max-width: $large-screen-width;
        margin: auto;
        color: $gray-font;
        margin-top: 50px;
        @media screen and (max-width:1199px){
            padding: 0px 36px;
        }
        @media screen and (max-width:768px){
            .free_trade_container{
                width: 100% !important;
            }
            .free_trade_container .free_trade_row{
                display: block !important;
                text-align: center;
            }
            .free_trade_container .col_text{
                width: 100% !important;
                text-align: center;
                h2{
                    width: 100%;
                    margin: auto;
                }
            }
            .free_trade_container .col_button{
                width: 100% !important;
                a{
                    margin: 20px 0 0 0 !important;
                    display: inline-block;
                }
            }
        }
        .free_trade_container.full_w{
            .free_trade_row{
                display: block;
                text-align: center;
            }
            .col_text{
                width: 100%;
                text-align: center;
                h2{
                    // width: 80%;
                    font-size: 40px !important;
                    margin: auto;
                }
            }
            .col_button{
                width: 100%;
                a{
                    margin: 20px 0 0 0 !important;
                    display: inline-block;
                }
            }
        }
        .free_trade_container{
            width: 70%;
            margin: auto;
            padding: 20px 0;
            .free_trade_row{
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .custom_col{
              
            }
            .col_text{
                width: 60%;
            }
            .col_button{
                width: 40%;
            }
            .custom-button{
              text-align: center;
            }
            h2{
              font-size: 34px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.15;
              letter-spacing: normal;
              text-align: center;
              color: #56a9e8;
              margin: 0;
              width: 80%;
            }
          }
    }
    .free-trade-option.full_w_p{
        margin-top: 0;
        margin-bottom: 50px;
    }
}
