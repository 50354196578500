$border-color: #dbdbdb;
$border-color-opacity: rgba(0, 0, 0, 0.1);
$primary-color: #56a9e8;
$sub-header-color: #bababb;
$dark-active-color: #1e5f91;
$in-active-color: #dbdbdb;
$tag-grey-bg-color: #F5F5F5;
$tag-blue-bg-color: #EFF8FF;
$label-black-color: #4a4a4a;
$white-color: #ffffff;
$dark-blue-color: #1d6091;
$indicator-color: #d6d6d6;
$bg-color-light: #f7f7f7;
$lock-color: #c3c3c3;
$label-black-color-2: #424242;
$primary-color-opacity: #61aee9;
$border-color-2: #e2e2e2;
$red-color: #A5685F;
$red-color-opacity: #a5685f26;
$disabled-text-color: #c6c6c6;
$other-grey-color: #a0a0a0;
$other-grey-color-2: #c5c5c5;
$other-dark-grey-color: #707070;
$other-bg-color: #efefef;
$other-bg-color-2: #eaeaea;
$other-active-color: #57a9e8;
$other-red-color:#b07b73;
$orange-color: #e26a05;
$header-grey-bg-color: #c1c1c1;

$primary-color-opacity-2: rgba(86, 169, 232, 0.21);
$primary-color-opacity-05: rgba(86, 169, 232, 0.05);

$primary-font: 'Open Sans';
$secondary-font: 'Roboto';

$font-size-49px: 2.4rem;
$font-size-30px: 1.5rem;

$font-size-28px: 1.6rem;

$font-size-26px: 1.2rem;// 1.35vw;
$font-size-25px: 1.15rem; //1.2vw;
$font-size-23px: 1.12rem;
$font-size-22px: 1.1rem; //1.2vw;
$font-size-20px: 1rem; //1.15vw;
$font-size-18px: 0.9375rem; //1.05vw;

$font-size-17px: 0.925rem; //1.02vw;
$font-size-16px: 0.875rem;//1vw;

$font-size-15px: 0.815rem;//0.91vw;   //13px;
$font-size-14px: 0.8rem;//12px;
$font-size-13px: 0.75rem;
$font-size-12px: 0.625rem; //10px;
$font-size-11px: 0.6rem;

$font-size-10px: 0.575em;
$font-size-9px: 0.5em;
$font-size-8px: 8px;

$font-size-16vw: 1vw;


// $font-size-14px: 0.875rem; //12px;
// $font-size-13px: 0.8125rem
// $font-size-12px: 0.75rem; //10px;
// $font-size-11px: 0.6875rem;
// $font-size-10px: 0.625rem;
// $font-size-8px: 8px;

$clients-table-border-width: 5px;
