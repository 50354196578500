$indicator-color: #d6d6d6;
$in-active-color: #dbdbdb;

 .portfolioMenuWrapper {
    border-radius: 20px;
    position: relative;
    max-height: 220px;
    overflow-y: auto;
    width: 250px;
    .menuContainer {
      height: 32px !important;
      background: red !important;
    }
    .onSearchMenuContainer,
    .onSearchMenuContainerUP {
      height: 58px !important;
      display: flex;
      align-items: center;
      margin: 0;
      margin-bottom: 0 !important;
      color: #1e1e1e !important;
      &:hover:not(.inputContainer) {
        color: #1e1e1e !important;
        background-color: #dff1ff !important;
      }
      div {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        justify-content: space-between;
        width: 100%;
        //color:#1e1e1e !important;
        &:hover {
          color: #1e1e1e !important;
          //background-color: #dff1ff !important;
        }
      }
      .onSearchMenu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        div:empty {
          display: none;
        }
        .resultSearchIcon {
          display: flex;
          color: #1e1e1e !important;
          opacity: 0.4;
          padding-right: 24px;
        }
        .resultDetail {
          width: 100%;
          p {
            white-space: nowrap;
            overflow: hidden;
            padding: 0 !important;
            text-overflow: ellipsis;
            margin: 0 !important;
            margin-left: 0 !important;
          }
          p:nth-child(2) {
            margin-top: 4px;
            font-size: 12px;
          }
        }
      }
    }
    .onSearchMenuContainerUP {
      height: 32px !important;
      //margin-bottom: 0!important;
    }
    .inputContainer {
      background-color: white !important;
      margin: 10px 0;
      margin-bottom: 10px !important;
      &:hover {
        background-color: white !important;
        background: white !important;
      }
      .input {
        padding-left: 0 !important;
        border: none !important;
        border-bottom: 1px solid #1e1e1e !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        &:focus {
          box-shadow: none !important;
        }
      }
    }
    .myPfItems {
      padding: 0 !important;
      height: 32px !important;
      display: flex;
      margin: 0;
      margin-left: 0px !important;
      margin-bottom: 0 !important;
      align-items: center;
      justify-content: space-between;
      .myPfItemsDetail {
        width: 80%;
        height: 100%;
        display: flex;
        padding-left: 16px;
        align-items: center;
        .resultSearchIcon {
          margin-left: -2px;
          margin-right: 8px;
          color: #1e1e1e !important;
          opacity: 0.4;
        }
        p {
          margin: 0;
          //margin-left: 8px;
          color: #1e1e1e !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:hover {
          color: #1e1e1e !important;
          background-color: #dff1ff !important;
        }
      }
      .deleteIcon {
        padding: 4px;
        width: 44px !important;
        height: 32px;
        display: flex !important;
        color: #b7b7b7;
        align-items: center;
        justify-content: center;
        background: white !important;
        background-color: white !important;
        &:hover {
          color: #a5685f !important;
          background-color: #f1e8e7 !important;
          background: #f1e8e7 !important;
        }
      }
    }

    //li:not(.noHighlight):not(.inputContainer):not(.myPfItems):not(.deleteIcon){
    //  display: flex;
    //  align-items: center;
    //  height: 32px;
    //  margin: 0;
    //  //margin-bottom: 0!important;
    //  color:#1e1e1e !important;
    //  &:hover:not(.inputContainer):not(.myPfItems):not(.deleteIcon){
    //    color:#1e1e1e !important;
    //    background-color: #dff1ff !important;
    //  }
    //  div{
    //    max-width: 100%;
    //    white-space: nowrap;
    //    overflow: hidden;
    //    text-overflow: ellipsis;
    //    justify-content: space-between;
    //    width: 100%;
    //    //color:#1e1e1e !important;
    //    &:hover{
    //      color:#1e1e1e !important;
    //      //background-color: #dff1ff !important;
    //    }
    //  }
    //
    //  .menuItem{
    //
    //  }
    //}
    li:first-child {
      margin-top: 10px;
      //margin-bottom: 10px!important;
    }
    li:last-child {
      margin-bottom: 10px !important;
    }
    &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px !important;
      border-radius: 6px;
      background-color: $in-active-color;
    }
  }

  .noResult {
    user-select: none !important;
    opacity: 0.5 !important;
    background-color: white !important;
    cursor: not-allowed !important;
    color: #1a1e21 !important;
    height: 32px !important;
    &:hover {
      background-color: white !important;
    }
  }
  .noHighlight {
    margin-top: 10px;
    margin-bottom: 10px !important;
    &:hover {
      background-color: white !important;
    }
  }

  .popupClass {
    display: flex;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 20px !important;
    background: transparent !important;
    &::before {
      position: unset !important;
      width: 0px !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
    ul:first-child {
      margin: 0 !important;
      background: #fff;
      margin-left: 2px !important;
      border-radius: 10px;
      width: 100%;
      padding: 4px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 250px;
      div {
        height: 32px;
        cursor: pointer;
        width: 100%;
        display: flex;
        padding: 0 20px;
        margin: 0;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          color: #1a1e21 !important;
          background-color: #dff1ff !important;
        }
      }
      &::-webkit-scrollbar {
        width: 4px !important;
        border-radius: 6px;
        background-color: transparent;
      }
  
      &::-webkit-scrollbar-thumb {
        width: 4px !important;
        border-radius: 6px;
        background-color: $in-active-color;
      }
    }
  }

.inputContainer {
    background-color: white !important;
    margin: 10px 0;
    margin-bottom: 10px !important;
    &:hover {
      background-color: white !important;
      background: white !important;
    }
    .input {
      padding-left: 0 !important;
      border: none !important;
      border-bottom: 1px solid #1e1e1e !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      &:focus {
        box-shadow: none !important;
      }
    }
  }

.input {
     color: #1e1e1e !important;
    }

.onSearchMenuContainer{
    height: 58px !important;
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 0 !important;
    color: #1e1e1e !important;
    &:hover:not(.inputContainer) {
      color: #1e1e1e !important;
      background-color: #dff1ff !important;
    }
    div {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      justify-content: space-between;
      width: 100%;
      //color:#1e1e1e !important;
      &:hover {
        color: #1e1e1e !important;
        //background-color: #dff1ff !important;
      }
    }
}

.onSearchMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    div:empty {
      display: none;
    }
  }

.resultSearchIcon {
    display: flex;
    color: #1e1e1e !important;
    opacity: 0.4;
    padding-right: 24px;
  }

  .resultDetail {
    width: 100%;
    p {
      white-space: nowrap;
      overflow: hidden;
      padding: 0 !important;
      text-overflow: ellipsis;
      margin: 0 !important;
      margin-left: 0 !important;
    }
    p:nth-child(2) {
      margin-top: 4px;
      font-size: 12px;
    }
  }

  .subMenu {
    height: 32px !important;
    display: flex;
    align-items: center;
    height: 32px;
    margin: 0;
    //margin-bottom: 0!important;
    color: #1e1e1e !important;
    &:hover {
      color: #1e1e1e !important;
      background-color: #dff1ff !important;
    }
    div {
      height: 32px;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      justify-content: space-between;
      width: 100%;
      //color:#1e1e1e !important;
      &:hover {
        color: #1e1e1e !important;
        //background-color: #dff1ff !important;
      }
    }
  
  }

  
  .modelItemLable  {
    font-family: 'Roboto', serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #4a4a4a;
    white-space: nowrap;
    height: 17px;
    padding-left: 10px;
    margin-bottom: 10px;
  }