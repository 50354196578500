


// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300&display=swap');
$breakpoint-tablet: 768px;
body{
    padding-top:0px!important;
}

#landing-page{
    &.main-join-page #close-icon span {
        color: #424242!important;
        background: #424242!important;
    }
    &.main-join-page #close-icon span:before{
            background: #424242!important;
    }
    &.main-join-page #close-icon span:after{
                background: #424242!important;
    }
    &.main-join-page #close-icon.open span {
        color: #424242!important;
        background: transparent!important;
    }
    &.main-join-page #close-icon.open span:before{
            background: #fff!important;
    }
    &.main-join-page #close-icon.open span:after{
                background: #fff!important;
    }
    &.main-join-page {
        min-height: auto;
        // max-width: 1200px;
        .banner{
            min-height: auto;
            background-image: none;
            background: #f7f7f7;
            &:before{
                display: none!important;
            }
            .landing-page-banner{
                width: 70%;
                margin: auto;
                h1{
                    width: 100%;
                    font-size: 48px !important;
                    @media (min-width: 320px) and (max-width:991px){
                        font-size: 32px !important;
                    }
                }
            }
        }
        .card-deck{
            padding: 36px 36px 36px!important;
            max-width: 1200px;
            margin: auto;
            .single-card{
                margin-right: auto !important;
                margin-left: auto !important;
            }
            .card {
                max-width: 400px;
                margin-right: 10px;
                margin-left: 10px;
                border:0px;
                .card-body{
                    padding: 36px;
                    .card-title {
                        font-size: 48px!important;
                        font-weight: 300;
                        text-align: center;
                        color: #56a9e8 !important;
                    }
                    h6 {
                        font-size: 15px!important;
                        text-align: center;
                        line-height: 1.4em;
                        color: #56a9e8 !important;
                        margin-top: 9px;
                        font-weight: 400;
                        font-family: 'Open Sans', Roboto;
                    }
                    .separator-break{
                        width: 90px!important;
                        border-top-width: 2px!important;
                        border-color: #56a9e8 !important;
                        margin: 32px auto;
                    }
                    .cardContent{
                        .priceAmount {
                            text-align: center;
                            font-size: 36px;
                            font-weight: 300;
                            color: #4a4a4a;
                        }
                        ul {
                            margin-top: 36px;
                            padding-left: 0;
                            li {
                                list-style: none;
                                margin: 0px 0px 0px 0px;
                                vertical-align: top;
                                text-align: center;
                                line-height: 1.4em;
                                font-size: 15px;
                                font-weight: 400;
                                font-family: 'Open Sans', Roboto;
                                color: #4a4a4a;
                                svg{
                                    color: #56a9e8 !important;
                                    fill:#56a9e8 !important;
                                    position: relative;
                                    left: -15px;
                                    top: -4px;
                                }
                            }
                        }
                    }
                    .empty-space{
                        &.space7{
                            min-height: 80px;
                         }
                         &.space3{
                            min-height: 17px;
                         }
                         &.space2{
                            min-height: 38px;
                         }
                    }
                }
                .card-footer{
                    padding: 0;
                    background: #f7f7f7;
                    border: 0;
                    text-align: center;
                    padding-top: 30px;
                    padding-bottom: 36px;
                    .btn{
                        color: #56a9e8 !important;
                        background: #fff;
                        border-color: #56a9e8;
                        border-radius: 10em !important;
                        padding: 10px 50px !important;
                        font-size: 12px;
                        text-transform: uppercase;
                        font-weight: 600;
                        &:hover{
                            background-color: #56a9e8;
                            border-color: #56a9e8;
                            color: #ffffff !important;
                        }
                    }
                }
                .devider {
                    display: flex;
                    align-items: center;
                    padding: 0px 40px;
                    font-family: 'Open Sans', Roboto;
                    font-size: 15px;
                    font-weight: 600;
                    color: #c5c5c5;
                    padding-top: 1em;
                    .separator{
                        min-width: 50px;
                        width: auto;
                        height: 0;
                        border: 1.2px solid  #c5c5c5;
                    }
                }

            }
        }
        .landing-page-banner{
            text-align: center;
            max-width: 70%;
            padding-top:100px!important;
            @media (min-width: 320px) and (max-width:991px){
                max-width: 100% !important;
                width: 100% !important;
            }
            h1{
                font-weight: 300;
                color: #424242!important;
                line-height: 1.2!important;
            }
            h4{
                margin:auto;
                color: #424242!important;
                font-size: 29px !important;
                font-weight: 300;
                line-height: 34px;
                margin-top: 18px!important;
                margin-bottom: 18px;
                strong{
                    font-weight: 600;
                }
            }
            .btn-container{
                margin-top: 18px;
                .custom-link-btn{
                    font-weight: 600;
                    font-family: Roboto, Roboto;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    border-width: 1px;
                    background-color: transparent;
                    color: #56a9e8 !important;
                    border-color: #56a9e8;
                    border-radius: 10em !important;
                    display: inline-block;
                    font-size: 12px;
                    padding: 13px 31px 13px 31px;
                    font-style: normal;
                    text-align: center;
                    vertical-align: middle;
                    margin-top: 1px;
                    margin-bottom: 1px;
                    cursor: pointer;
                    background-image: none;
                    border-style: solid;
                    border-radius: 2px;
                    outline: none;
                    white-space: pre-wrap;
                    &:hover{
                        background-color: #56a9e8;
                        border-color: #56a9e8;
                        color: #ffffff !important;
                    }
                }
            }
        }
        .header-background{
            .header-link.nav-link {
                color: #ffffff !important;
                &:hover{
                    color: #56a9e8!important;
                }
            }
        }
        .header-link.nav-link {
            color: #424242!important;
            &:hover{
                color: #56a9e8!important;
            }
            .custom-button{
                color: #fff!important;
                border: 1px solid #56a9e8;
                background: #56a9e8 !important;
                border-radius: 40px;
                &:hover{
                    color: #56a9e8!important;
                    background: #fff !important;
                }
            }
        }
    }
}

@media (min-width:320px) and (max-width:480px){
    #landing-page{

        &.main-join-page {
            .landing-page-banner {
                padding: 0px 36px;
                margin-bottom: 72px !important;
                h1{
                    font-weight: 300!important;
                    font-size: 28px!important;
                }
                .btn-container {
                    margin-top: 18px;
                    margin-bottom: 11px;
                    display: block;
                    .custom-link-btn{
                        padding: 8px 31px!important;
                    }
                }
            }
            .card-deck {
                padding: 0px 36px !important;
                flex-direction: column;
                .card{
                    margin-bottom: 20px;
                    margin-left: 0;
                    margin-right: 0;
                    .card-body {
                        .card-title {
                            font-size: 28px !important;
                        }
                    }
                    .card-footer {
                        .btn {
                            padding: 8px 31px 8px 31px !important;
                            font-size: 12px;
                            font-weight: 600;
                            text-transform: uppercase;
                            font-family: Roboto, Roboto !important;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width:481px) and (max-width:574px){
    #landing-page{
        &.main-join-page {
            .landing-page-banner {
                padding: 0px 36px;
                max-width: 100%;
                margin-bottom: 72px !important;
                h1{
                    font-weight: 300!important;
                    font-size: 28px!important;
                }
                .btn-container {
                    margin-top: 18px;
                    margin-bottom: 11px;
                    display: block;
                    .custom-link-btn{
                        padding: 8px 31px!important;
                    }
                }
            }
            .card-deck {
                padding: 0px 36px !important;
                flex-direction: column;
                .card{
                    margin-bottom: 20px;
                    margin-left: 0;
                    margin-right: 0;
                    .card-body {
                        .card-title {
                            font-size: 28px !important;
                        }
                    }
                    .card-footer {
                        .btn {

                            padding: 8px 31px 8px 31px !important;
                            font-size: 12px;
                            font-weight: 600;
                            text-transform: uppercase;
                            font-family: Roboto, Roboto !important;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width:575px) and (max-width:679px){
    #landing-page{
        &.main-join-page {
            .landing-page-banner {
                padding: 0px 36px;
                margin-bottom: 72px !important;
                h1{
                    font-weight: 300!important;
                    font-size: 28px!important;
                }
                .btn-container {
                    margin-top: 18px;
                    margin-bottom: 11px;
                    display: block;
                    .custom-link-btn{
                        padding: 8px 31px!important;
                    }
                }
            }
            .card-deck {
                padding: 0px 36px !important;
                flex-direction: column;
                .card{
                    margin-bottom: 20px;
                    margin-left: 0;
                    margin-right: 0;
                    .card-body {
                        .card-title {
                            font-size: 28px !important;
                        }
                    }
                    .card-footer {
                        .btn {

                            padding: 8px 31px 8px 31px !important;
                            font-size: 12px;
                            font-weight: 600;
                            text-transform: uppercase;
                            font-family: Roboto, Roboto !important;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width:680px) and (max-width:767px){
    #landing-page{

        &.main-join-page {
            .landing-page-banner {
                padding: 0px 36px;
                margin-bottom: 72px !important;
                h1{
                    font-weight: 300!important;
                    font-size: 28px!important;
                }
                .btn-container {
                    margin-top: 18px;
                    margin-bottom: 11px;
                    display: block;
                    .custom-link-btn{
                        padding: 8px 31px!important;
                    }
                }
            }
            .card-deck {
                padding: 0px 36px !important;
                flex-direction: column;
                .card{
                    margin-bottom: 20px;
                    margin-left: 0;
                    margin-right: 0;
                    .card-body {
                        .card-title {
                            font-size: 28px !important;
                        }
                    }
                    .card-footer {
                        .btn {

                            padding: 8px 31px 8px 31px !important;
                            font-size: 12px;
                            font-weight: 600;
                            text-transform: uppercase;
                            font-family: Roboto, Roboto !important;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width:768px) and (max-width:959px){
    #landing-page{
        &.main-join-page {
            .landing-page-banner {
                padding: 0px 36px;
                margin-bottom: 72px;
                h1{
                    font-weight: 300!important;
                    font-size: 32px!important;
                }
                .btn-container {
                    margin-top: 18px;
                    margin-bottom: 11px;
                    display: block;
                    .custom-link-btn{
                        padding: 11px 31px!important;
                    }
                }
            }
            .card-deck {
                padding: 0px 36px !important;
                flex-direction: column;
                .card{
                    margin-bottom: 20px;
                    margin-left: 0;
                    margin-right: 0;
                    .card-body {
                        .card-title {
                            font-size: 32px !important;
                        }
                    }
                    .card-footer {
                        .btn {

                            padding: 8px 31px 8px 31px !important;
                            font-size: 12px;
                            font-weight: 600;
                            text-transform: uppercase;
                            font-family: Roboto, Roboto !important;
                        }
                    }
                }
            }
        }
    }

}
@media (min-width:960px) and (max-width:1199px){
    #landing-page{

        &.main-join-page {
            .landing-page-banner {
                padding: 0px 26px;
            }
            .card-deck {
                padding: 0px 36px;
                .card{
                    margin-bottom: 20px;
                }
            }
        }
    }
}
