@import "./Elements/index";
@import "./OnBoardScreen/index";

$border-color: #dbdbdb;
$border-color-opacity: rgba(0, 0, 0, 0.1);
$primary-color: #56a9e8;
$sub-header-color: #bababb;
$dark-active-color: #1e5f91;
$in-active-color: #dbdbdb;
$tag-grey-bg-color: #F5F5F5;
$tag-blue-bg-color: #EFF8FF;
$label-black-color: #4a4a4a;

.ant-tabs-tab-prev, .ant-tabs-tab-next {
  z-index: 0 !important;
}

.enhance-stats-color {
  color: $dark-active-color !important,
}

.current-stats-color {
  color: $primary-color !important,
}

.pd-rt-36 {
  padding-right: 36px !important;
}

.mg-rt-15 {
  margin-right: 15px !important;
}

.grey-bg {
  background-color: $tag-grey-bg-color !important;
  border: 1px solid $tag-grey-bg-color !important;
}

.blue-bg {
  background-color: $tag-blue-bg-color !important;
  border: 1px solid $tag-blue-bg-color !important;
}

.portfolio-summary-btn-wrap {
  margin: 0;
  margin-bottom: 10px;
  .portfolio-summary-pre-text{
    margin-right: 10px ;
    margin-bottom: 0;
    font-weight: bold;
  }
  .portfolio-summary-btn {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: $dark-active-color !important;
    background-color: #ddeefa !important;
    border: 0;
    border-radius: 50px;
    height: 34px;
    color: #fff !important;
    background-color: #1e5f91 !important;
    text-transform: uppercase;
    line-height: normal;

    span {
      margin-bottom: 1px;
    }

    .anticon {
      position: absolute;
      vertical-align: 0;
      font-weight: bold;
    }
  }
}
.homeWrapper {
  position: relative;
  height: 100%;
  overflow: hidden;

  .headerWrapper {
    margin: 0;
    position: fixed;
    top: 0;
    z-index: 9;
    width: 100%;
    margin: 0;
    background: #F7F7F7;
    border-bottom: 1px solid #fff;

    nav {
      width: 100%;

      .container {
        margin: 0;
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
        margin-top: 2px;
        ul {
          li {
            i {
              font-size: 1.3rem;
            }
          }
        }
        .searchInput {
          width: 33%;
          margin-left: -10%;
          // min-width: 600px;
          // margin-left: -127px;
          .search-box {
            border: solid 1px #dbdbdb;
            input {
              width: 100%;
              height: 35px;
              padding-right: 40px;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.35;
              letter-spacing: -0.24px;
              text-align: left;
              color: rgb(112, 112, 112);
            }
            .search-icon {
              right: 12px;
              font-size: 20px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.35;
              letter-spacing: -0.24px;
              text-align: left;
            }
          }
        }
        .search-box-onBoard {
          // position: absolute;
          // right: 164px;
        }
        // .portfolio-tools-wrap{
        //   // position: absolute;
        //   margin-top: 2px !important;
        //   left: 0;
        //   right: 0;
        //   margin: 0 auto;
        //   width: max-content;
        //   display: flex;
        //   align-items: center;
        //   .tool-badge {
        //     &:last-child {
        //       margin: 0 !important;
        //     }
        //   }
        // }
      }

      .user-menu-popover {
        // min-width: 270px !important;
        .navbar-brand {
          margin: 0;
          padding: 0;
        }
        .nav-link {
          i {
            font-size: 1.3rem;
          }
        }
      }
      .referral-wrap {
        position: absolute;
        right: 55px;
        .referral-link {
          font-weight: 700;
          color: #55a9e8;
          padding: 0;
          text-decoration: none;
          position: unset !important;
          margin-right: 20px;
          // right: 73px;

          &:hover {
            color: #55a9e8;
            text-decoration: none;
          }
        }
      }
    }
  }
  .contentWrapper {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    // padding-top: 65px;
    // height: 100vh;
    color: $charcoal-black !important;

    .sideBarContainer {
      height: 100%;
      border-right: 1px solid #fff;
      .sideBarWrapper {
        height: 100%;
        padding: 22px 5px;
        // padding: 22px 0px;
        .loader {
          font-size: 8px;
        }
        .show {
          opacity: 1 !important;
          visibility: visible !important;
        }
        .hide {
          opacity: 0;
          visibility: hidden;
        }
        // .element {
        //   padding-bottom: 20px;
        //   transition: all 0.3s;
        //
        //   h2 {
        //     padding-bottom: 15px;
        //     margin: 0;
        //     font-family: Roboto;
        //     font-size: 20px !important;
        //     font-weight: bold;
        //     color: inherit;
        //     // line-height: 1.5;
        //   }
        //   .label {
        //     font-family: Roboto;
        //     // font-size: 18px;
        //     // font-weight: bold;
        //     // font-size: 20px;
        //     color: inherit;
        //     margin-bottom: 7px;
        //   }
        //   .searchInput {
        //     input {
        //       height: 40px;
        //       padding: 5px 40px 5px 10px;
        //       font-size: 15px;
        //       font-weight: normal;
        //       transition: all 0.3s;
        //       &::-ms-clear {
        //         display: none !important;
        //       }
        //     }
        //     .search-icon {
        //       font-size: 20px;
        //       right: 8px;
        //     }
        //     .clear-icon {
        //       position: absolute;
        //       right: 40px;
        //       line-height: 35px;
        //       width: 15px;
        //       text-align: center;
        //       z-index: 9;
        //       opacity: 0.2;
        //       font-size: 15px;
        //       transition: line-height .5s, width .5s, font-size .5s;
        //       &.active {
        //         opacity: 1;
        //         cursor: pointer;
        //       }
        //     }
        //     // @include respond(phone) {
        //     //   .clear-icon {
        //     //     line-height: 35px;
        //     //     width: 15px;
        //     //     text-align: center;
        //     //     right: 35px;
        //     //   }
        //     // }
        //     //
        //     // @include respond(tab){
        //     //   .clear-icon {
        //     //     line-height: 35px;
        //     //     width: 15px;
        //     //     text-align: center;
        //     //     right: 35px;
        //     //   }
        //     // }
        //
        //     // @include respond(desktop){
        //     //   .clear-icon {
        //     //     line-height: 35px;
        //     //     width: 15px;
        //     //     text-align: center;
        //     //     right: 35px;
        //     //   }
        //     // }
        //   }
        //   .tagWrapper {
        //     display: flex;
        //     flex-direction: row;
        //     flex-wrap: wrap;
        //     overflow: hidden;
        //     font-size: 16px;
        //     max-height: 122px;
        //     color: inherit!important;
        //     .tag {
        //       // min-width: max-content;
        //       max-width: 290px;
        //       overflow: hidden;
        //       margin-right: 10px;
        //       margin-bottom: 10px;
        //       padding: 6px 15px;
        //       color: $tag-blue;
        //       font-size: 12px;
        //       border: 1px solid $tag-blue;
        //       background: #fff;
        //       border-radius: 30px;
        //       transition: all .3s;
        //       white-space: nowrap;
        //       cursor: pointer;
        //       text-overflow: ellipsis;
        //
        //       &:hover {
        //         background: $tag-blue;
        //         color: #fff;
        //       }
        //     }
        //     .tag-question {
        //       color: #55a9e8;
        //       margin-right: 10px;
        //
        //       &:hover {
        //         cursor: pointer;
        //       }
        //     }
        //   }
        //   .cardWrapper {
        //     .card {
        //
        //     }
        //   }
        // }

        .account-nav-header {
          flex-direction: column;

          .dropdown-menu {
            margin-left: 0 !important;
            width: 100%;
            text-align: center;
            height: 100%;
            min-height: 300px;
            overflow-y: scroll;
          }

          ul {
            margin: 5px 0;

            a {
              font-size: 12px;
              padding: 7px !important;
              border-radius: 30px !important;
            }
          }
        }
      }
    }
    .contentContainer {
      position: relative;

      .show {
        display: block !important;
        visibility: visible !important;
        opacity: 1 !important;
      }
      .hide {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
      }
      .spinner-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #f7f7f7;
      }
      .spinnerMessage {
        margin: 50px;
        display: flex;
        flex-direction: column;
        vertical-align: middle;
        height: 55%;
        align-items: center;
        justify-content: center;
      }

      .custom-scroll {
        height: calc(100vh - 200px) !important;
      }

      .account-page-home-wrapper {
        height: calc(100vh - 164px) !important;
        margin-bottom: 0;
        padding-right: 8px !important;

        &::-webkit-scrollbar {
          width: 4px !important;
          border-radius: 6px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          width: 4px !important;
          border-radius: 6px;
          background-color: $in-active-color ;
        }
      }

      .itemListContainer {
        padding: 0 15px 0 15px;
        padding-left: 0;
        padding-right: 0;

        .itemListHeaderWrapper {
          .cardListToolbarWrapper .container {
            max-width: unset;
            padding: unset;
          }
          .cardListToolbarWrapper {
            .container {
              max-width: unset;
              padding: unset;
              .toolbar-wrapper-flex-center {
                align-items: center;
                display: flex;
                .fees-returns-risk-header-container {
                  .FRR-Label {
                    height: 22px;
                  }
                }
              }
             .viewChartsColumn {
              display: flex;
              flex-wrap: wrap;
              align-items: center;

               .viewDropdownContainer {
                 width: 100%;
                 height: 100%;

                 .viewDropdownWrapper {
                   height: 22px;
                   margin-bottom: 2px;
                   .viewBtn {
                      // margin-top: -10px !important;
                      line-height: normal !important;
                   }
                   .viewDropDownList {
                     min-width: unset !important;
                     // padding: 2px !important;
                     .viewDropDownList-Child {
                       // font-size: 10px !important;
                     }
                   }
                 }
                 .view-text-container {
                   margin-bottom: 0;
                   // margin-top: 3px;
                   .view-text-label {
                     // font-size: 8px !important;
                   }
                 }
                 .view-sub-title-container {
                   min-height: 18px;
                   h6 {
                     // font-size: 8px !important;
                     line-height: normal;
                   }
                   .dropdown-menu {
                     min-width: unset !important;
                   }
                 }
               }
             }

             .date-range-slider {
               // padding-right: 0 !important;
               .irs {
                 .irs-from, .irs-to, .irs-single {
                   // font-size: 8px !important;
                 }
               }
               ul {
                 li {
                   // font-size: 10px !important;
                   line-height: 1 !important;
                   button {
                     // font-size: 10px !important;
                   }
                 }
               }
             }
           }
          }
        }
        .itemListArea {
          // height: calc(100vh - (280px))!important;
          // height: calc(100vh - (250px))!important;
          padding-top:1rem;
          height: calc(100vh - (215px))!important;
          margin-right: -12px;
          padding-left: 0 !important;
          padding-right: 8px !important;
          overflow: hidden;
          overflow-y: scroll;
          position: relative;
          //padding-bottom:68px;

          .itemCardWrapper {
            .border-transparent {
              border-color: transparent !important;
            }
            .border-bg-filter-bg-color {
              border-color: $filter-bg-color !important;
            }
            .itemCard {
              height: 120px;
              border-color: transparent;
              border-radius: 8px !important;
              background: transparent !important;
              .app-card-left {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
              }
              .app-card-right {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
              }
              .card-body {
                .row {
                  height: 100%;
                }
              }
              .card-background {
                background: #efefef !important;
              }
              .feesReturnRiskWrapper {
                display: flex;
                align-items: center;
                .search-fees-return-stats-padding {
                  height: 100%;
                  padding: 28px 0px 28px;
                }
                .search-risk-stats-padding {
                  height: 100%;
                  padding: 35px 0px;
                }
                .search-risk-stats-highlighting {
                  .fees-flag-color, .returns-flag-color, .risk-flag-color {
                    color: $tag-blue;
                  }
                }
                .w-100 {
                  display: grid;
                  height: 100%;

                  .fees-flag-color, .returns-flag-color, .risk-flag-color {
                    height: 100%;
                    img {
                      // width: 20px;
                    }
                    i {
                      // font-size: 14px;
                    }
                    .__val {
                      // font-size: 9px !important;
                    }
                    .__state {
                      // font-size: 8px !important;
                    }
                  }
                }
              }

              .paddingTpBtLt {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                padding-left: 0 !important;
              }
              .paddingTpBt {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
              }
              .paddingTpBtRt {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                padding-right: 0 !important;
              }

              .selectBtn {
                padding: 5px;
                padding: 8px 15px !important;
                font-size: 12px !important;
                border-radius: 30px;
                line-height: 1.4;
                background: transparent;
                border-color: $ssf-orange-d1;
                color: $ssf-orange-d1;
                white-space: nowrap;
                &:hover {
                  background: $ssf-orange-d1;
                  color: #fff;
                }
                &.btn-unselect{
                  color: #fff;
                  background-color: #807f7f;
                  border-color: #807f7f;
                }
              }

              .chart-block {
                .primaryTextStyle {
                  left: 50%;
                }
                .highlightTooltip {
                  // font-size: 10px;
                }
                .guage-chart-wrapper {
                  .guage-chart-details {
                    .col {
                      // font-size: 9px !important;
                    }
                  }
                }
                .flip-items {
                  li {
                    text-align: center;
                    h6 {
                      // font-size: 10px !important;
                    }
                  }
                }
              }
            }
            &:last-child {
              // margin-bottom: 48px;
            }
          }

          &::-webkit-scrollbar {
            // display: none !important;
            // width: 0 !important;
          }
        }
      }
    }
  }

  .summaryDrawerWrapper {
    z-index: 10;

    .ant-drawer-title {
      padding-left: 5px;
      font-size: 18px;
      line-height: 30px;
      @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-size: 1rem !important;
      }

      @include respond(tab) {
        font-size: 1rem !important;
      }
    }
    .ant-drawer-body {
      padding-top: 0;
      padding-bottom: 0;
    }
    .content-head-row {
      margin: 0 0 20px 0;
      padding: 0px 15px;
      @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        margin: 0px 0px 5px !important;
      }

      @include respond(tab) {
        margin: 0px 0px 5px !important;
      }
      .col-header-wrap {
        font-family: Roboto;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.14px;
        text-align: left;
        color: #bababb;
        @media only screen and (min-width: 1024px) and (max-width: 1439px) {
          font-size: 0.6rem !important;
        }
        @include respond(tab) {
          font-size: 0.6rem !important;
        }
      }
    }

  }
  .summary-block {
    padding: 1rem 0 1rem;
    border-bottom: 1px solid $border-color-opacity;
    &:last-child {
      border-bottom: 0px;
    }
    &:first-child {
      padding-top: 0px;
    }

    .dark-active-color {
      color: $dark-active-color;
    }

    .element-wrap {
      .label {
        margin-bottom: 1rem;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: -0.22px;
        text-align: left;
        color: $primary-color;
      }
      .content-wrap {
        margin-bottom: 1rem;
        line-height: 1;
        .year {
          margin-bottom: 1rem;
          padding-left: 20px;
          padding-right: 20px;
          font-family: Roboto;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: -0.14px;
          text-align: left;
          color: $dark-active-color;
        }
        .tag-year {
          height: 18px;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: -0.19px;
          text-align: left;
          color: $sub-header-color;
        }
        .value-text {
          margin-top: -2px;
          margin-bottom: 0.6rem;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: -0.19px;
          text-align: left;

          .ant-tag {
            border-radius: 15px;
            padding-left: 20px;
            padding-right: 20px;
            margin-right: 20px;
          }
        }
        .row-bg-left {
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
        }
        .row-bg-right {
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
        }
      }
    }
  }

  .portfolio-impact-card {
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #fbfbfb;

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
      margin-bottom: 1rem !important;
      padding: 0.7rem !important;
    }

    @include respond(tab) {
      margin-bottom: 1rem !important;
      padding: 0.7rem !important;
    }

    .ant-row {
     margin: 0 0 20px 0;
      &:last-child {
        margin: 0 !important;
      }
      @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        margin: 0 !important;
      }

      @include respond(tab) {
        margin: 0 !important;
      }
      .ant-col {
        display: flex;
        height: 30px;
        align-items: center;
        justify-content: flex-start;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.33;
        letter-spacing: -0.22px;
        text-align: left;
        @media only screen and (min-width: 1024px) and (max-width: 1439px) {
          font-size: 0.8rem !important;
        }

        @include respond(tab) {
          font-size: 0.8rem !important;
        }
        &:first-child {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.36;
          letter-spacing: -0.17px;
          text-align: left;
          color: $label-black-color;
          text-transform: uppercase;
          @media only screen and (min-width: 1024px) and (max-width: 1439px) {
            font-size: 0.6rem !important;
          }

          @include respond(tab) {
            font-size: 0.6rem !important;
          }
        }
      }
    }
    .up-down-chart-txt{
      margin-top: 30px !important;
    }
    .up-down-capture-dropdown{
      position: absolute;
      right: 0;
      width: 150px;
      text-align: right;
      border: none!important;
      .ant-select-selector{
        background: transparent;
        border: none!important;
        font-size: 12px !important;
        box-shadow: none!important;
      }
      .ant-select-arrow{
        font-size: 9px !important;
        top: 51% !important;
      }
    }
    .ant-select-dropdown {
      @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-size: 0.6rem !important;
      }
      @include respond(tab) {
        font-size: 0.6rem !important;
      }
      .ant-select-item {
        @media only screen and (min-width: 1024px) and (max-width: 1439px) {
          padding: 5px 6px !important;
          font-size: 0.6rem !important;
        }
        @include respond(tab) {
          padding: 5px 6px !important;
          font-size: 0.6rem !important;
        }
      }
    }
    &.return-card {
      .ant-select {
        height: 100%;
        @media only screen and (min-width: 1024px) and (max-width: 1439px) {
          font-size: 0.8rem !important;
        }
        @include respond(tab) {
          font-size: 0.8rem !important;
        }

        .ant-select-selector {
          height: 100% !important;
          line-height: 1.33;
          padding-right: 20px;
          text-align: right;
          @media only screen and (min-width: 1024px) and (max-width: 1439px) {
            padding-left: 0 !important;
            font-size: 0.6rem !important;
          }
          @include respond(tab) {
            padding-left: 0 !important;
            font-size: 0.6rem !important;
          }

          .ant-select-selection-search {
            line-height: 1.33 !important;
            height: 100% !important;

            .ant-select-selection-search-input {
              height: inherit !important;
              opacity: 0;
            }
          }
          .ant-select-selection-item {
            height: 100% !important;
            padding: 0;
            line-height: 18px !important;
            @media only screen and (min-width: 1024px) and (max-width: 1439px) {
              line-height: 18px !important;
              padding: 0 !important;
            }

            @include respond(tab) {
              line-height: 18px !important;
              padding: 0 !important;
            }
          }
          .ant-select-selection {
            height: 100%;
            border: 0;
            background: transparent;
            box-shadow: unset;
            font-size: 12px;
            border-bottom: 1.3px solid #bababb;
            border-radius: 0;
            .ant-select-selection__rendered {
              height: 100%;
              margin: 0;
              padding: 0px 22px 0px 3px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              line-height: normal;
              color: #1e5f91;
            }
          }
        }
        .ant-select-arrow {
          top: 50%;
          right: 0;
          @media only screen and (min-width: 1024px) and (max-width: 1439px) {
            top: 13px !important;
            right: 0;
            width: 8px;
            height: 8px;
            font-size: 0.45rem !important;
          }
          @include respond(tab) {
            top: 13px !important;
            right: 0;
            width: 8px;
            height: 8px;
            font-size: 0.45rem !important;
          }
        }
      }
    }
  }

  .barchart-block {
    width: 100%;
    height: 150px;
  }

  .linechart-block {
    width: 100%;
    height: 100px;
    // margin: 15px 0 60px;
  }
}

.survey-overlay-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-wrap-margin {
  width: 100%;
  // margin-top: 5px;
  .overlay-container  {
    position: absolute;
    left: 0;
  }

  & .sign-in-securities {
    top: 45%;
    position: absolute;
    left: unset;
    transform: translate(-35%, -50%);
  }
}

.popTheme-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  vertical-align: middle;

  &__item {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 105px;
    margin: 10px 0;
    border-radius: 14px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    overflow: hidden;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      position: absolute;
      border-radius: 9px;
      height: 100%;
      width: 100%;
      background: linear-gradient(to right, transparent 40%, black 107%);
    }
    &:hover {
      transition: all 0.5s;
      -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
      filter: grayscale(0%);
    }

    & img {
      width: 100%;
      height: 100%;
      max-height: inherit;
      object-fit: cover;
    }
  }

  &__item:first-child {
    margin-top: 5px;
  }

  &__item:last-child {
    margin-bottom: 5px;
  }

  &__title {
    position: absolute;
    font-size: .9rem; // or 12px
    font-family: "Open Sans";
    color: #ffffff;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}
.up-down-capture-dropdown {
  width: 100%;
  text-align: right;
}
.up-down-capture-menu{
  border-radius: 10px !important;
  *{
    &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px !important;
      border-radius: 6px;
      background-color: $in-active-color ;
    }
  }
}
// .sidebar-content {
//   height: calc(100vh - (400px));
//   margin-right: -12px !important;
//   padding-left: 0 !important;
//   padding-right: 8px !important;
// }

.relInsight-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  border-radius: 14px;
  background-color: #efefef;

  & .spin-loader {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    display: flex;
    position: relative;
    margin: 15px 15px 0;
    border-radius: 14px;
    height: 96px;
    max-height: 96px;
    background-color: #E4E4E4;
    border: solid 1px #dbdbdb;
    overflow: hidden;

    & img {
      width: 40%;
      min-width: 40%;
      max-width: 110px;
      // height: 100%;
      object-fit: cover;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
  }

  // &__item:last-child {
  //   margin-bottom: 15px;
  // }

  &__content {
    position: relative;
    font-family: 'Open Sans';
    display: flex;
    flex-direction: column;
    margin: 10px;
    height: 48px;
    // justify-content: center;
    overflow: hidden;

    & h5 {
      font-size: 10px !important;
      color: #979797;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.36;
      letter-spacing: normal;
      margin-bottom: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

    }

    & .body__Wrapper {
      width: 100%;
      font-weight: bold;
      font-size: 12px ;
      font-weight: bold;
      color: $label-black-color !important;
    }

    & .more-dots{
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  & .mio-logo {
    text-align: center;
    margin: 5px 0;

    & img {
      width: 50px;
    }
  }
}
//-------------------------For Firefox only
@-moz-document url-prefix() {
  .tagWrapper {
    .tag {
      margin-bottom: 9px !important;
    }
  }
  .contentWrapper{
    .contentContainer{
      .itemListContainer {
        padding: 0;
      }
    }
  }
}

.pf-ench-btn {

  .whiteBg {
    .nav-link {
      border-radius: 2rem;
      &.active{
        font-size: 11px;
        padding: 7px 10px;
        color: #fff !important;
        background-color: #56a9e8 !important;
      }
    }
  }
}

.pf-enhace-option {
  display: flex;
  flex-direction: column;

  label {
    cursor: pointer;
    margin-right: auto;
  }
}

.pf-enhace-save-btn {
  width: 100%;
  font-size: 12px;
  cursor: pointer;
  padding: 5px;
  border-radius: 2rem;
  color: #fff;
  background-color: #56a9e8;
  border: none;
}

.pf-content-header {
  margin: 10px 0;
  padding-top: 5px;
  padding-bottom: 8px;
  font-family: Roboto;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: $dark-active-color;
  border-bottom: 1px solid $border-color;

  .return-home-button {
    height: 32px;
    width: 32px;
    padding: 0px;
    border-color: #1e5f91 !important;
    background-color: #1e5f91 !important;
    box-shadow: none;
  }

  .edit-icon {
    // position: absolute;
    // top: 0px;
    // right: -4px;
    // font-size: 11px;
    // line-height: 25px;
    cursor: pointer;
    @media only screen and (min-width: 320px) and (max-width: 1439px) {
      // top: 0px !important;
      // right: 0 !important;
    }
  }

  hr {
    height: 2px;
    color: black;
    width: 100%;
    margin-bottom: 0;
    margin-top: 10px;
  }
}

.pf-showing-result-label {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.19px;
  color: #4a4a4a;
}

.pf-enhance-cat {
  flex-direction: row;

  label {
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.pft-category-item {
  // margin-left: auto;
  // width: 442px;

  .pft-category-item-wrapper {
    position: relative;
    display: inline-block;
  }

  .check-label{
    font-size: 14px;
    // position: absolute;
    // width: 135px;
    // right: -38px;
    // bottom: -5px;
    cursor: pointer;
    min-width: max-content;
    display: inline-block;
    width: 249px;
    // position: absolute;
    // top: -7px;
    // left: 30px;
    font-weight: 400;
    font-family: 'Roboto';
    // margin-bottom: 0.6rem;
    margin-left: 30px;
    color: #1e5f91;
  }

  .switch {
    width: 25px;
    height: 8px;
    // display: inline-block;
    // position: relative;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    // margin: 0;
    margin-bottom: 0;
    // background-color: #4A4A4A;
  }
  .switch_modal{
    left: 0 !important;
  }
  .switch input {
    display:none;
  }

  .slider {
    background-color: #4A4A4A;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
  }

  .disabled {
    cursor: not-allowed !important;
  }

  .slider:before {
    background-color: #dbdbdb;
    bottom: -3px;
    content: "";
    height: 14px;
    left: -1px;
    position: absolute;
    transition: .4s;
    width: 14px;
  }

  input:checked + .slider {
    background-color: #4A4A4A;
    // background-color: #56a9e8 !important;
  }

  input:checked + .slider:before {
    transform: translateX(12px);
    background-color: #56a9e8;
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.prt-cat-coll-wrapper .ant-dropdown-trigger .top-59-per {
  top: 59% !important;
}

.prt-cat-coll-wrapper {
  display: flex;
  // margin-left: 15px;
  // padding-top: 2px;
  align-items: center;
  justify-content: flex-end;

  .ant-dropdown-trigger{
    font-weight: 400;
    font-size: 16px !important;
    pointer-events: all;
    .anticon.anticon-down, :root .ant-dropdown-link > .anticon.anticon-down {
      vertical-align: 0;
    }

    .anticon.anticon-down, .ant-dropdown-link > .anticon.anticon-down {
      position: absolute !important;
      top: 59% !important;
      transform: translateY(-50%) !important;
      margin-left: 5px !important;
    }
  }

  label:first-child {
    margin-right: 10px;
  }

  .ant-collapse {
    padding-top: 1px;
  }

  .collapseWrapper .indexText {
    color: #4A4A4A;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    width: 150px;
    max-width: 150px;
    padding-top: 0 !important;
    padding: 0 !important;
    margin-top: -2px;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    // top: 40%;
  }

  .ant-collapse .ant-collapse-content .ant-collapse-content-box {
    border-left: none;
    margin-left: 0;
  }

  .ant-card-bordered {
    width: auto;
    max-width: 270px;
    border-radius: 10px;
    margin-top: -15px;

    &:hover {
      box-shadow: 0 1px 6px rgba(0,0,0,.2);
      border-color: rgba(0,0,0,.2);
    }
  }

  .ant-card-body {
    padding: 15px;
  }

}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff !important;
  border-color: #d9d9d9 !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #1890ff !important;
}

.prt-cat-coll-wrapper .ant-dropdown-trigger .anticon.anticon-down, .prt-cat-coll-wrapper .ant-dropdown-trigger .ant-dropdown-link > .anticon.anticon-down
.down-fund-arrow
{
    //top: 22px !important;
    margin-left: 10px !important;
    font-size: 14px;
}

@media only screen and (min-width: 1024px) and (max-width: 1250px) {
  .buy-btn{
    font-size: 0.6rem !important;
  }

  .sell-btn{
    font-size: 0.6rem !important;
  }
}

.market-popup {
  width: max-content;
  max-width: 350px;
  left: 45px !important;
  top: 33px !important;
  // height: 190px !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: visible !important;

  .market-popup-wrapper {
    position: relative;
    // height: 190px !important;
    max-height: 190px !important;
    overflow: hidden !important;
    overflow-y: auto !important;
    font-size: 14px !important;

    &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px !important;
      border-radius: 6px;
      background-color: $in-active-color ;
    }
  }
}

.market_name-label {
  padding: 5px 10px;
  cursor: pointer;
  font-family: "Open Sans" !important;
  font-size: 13px !important;
  color: #545454;
  font-weight: 400;

  &:hover {
    background-color: #dff1ff !important;
  }
}

.market-popup-head {
  margin: 0 !important;
  padding: 0 !important;
  overflow: visible !important;

  ul:first-child {
    margin: 0 !important;
    margin-left: 2px !important;
    overflow: visible !important;
    background: #fff;
  }
}

.enhancer-onboard-screen-dropdown {
  ul {
    &:first-child {
      max-height: 250px;
      overflow: hidden !important;
      overflow-y: scroll !important;
      border-radius: 10px !important;
    }
  }
}

.ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-submenu-arrow {
  display: flex;
  align-items: center;

  .anticon-right {
    margin-right: 0 !important;
  }
}


// .client-portfolio-menu {
//   position: absolute;
//   right: 65px;
//   border: solid 1px #5eade9;
//   border-radius: 50px;
//   background: #fff;
//   .disabled-events {
//     pointer-events: none !important;
//   }
//   .client-portfolio-menu-dropdown {
//     .ant-dropdown-menu {
//       .ant-dropdown-menu-item {
//         position: relative;
//         padding: 8px 15px;
//         font-weight: 400;
//         font-size: 16px;
//         color: #202529;
//         border-bottom: 1px solid rgba(205, 212, 219, 0.5);
//         .menu-label {
//           font-weight: bold;
//           font-size: 16px;
//           color: #5eade9 !important;
//           text-decoration: none;
//         }
//         #uploadCustomPortfolio {
//           position: absolute;
//           left: 0;
//           top: 0;
//           z-index: 1;
//           padding-right: 38px;
//           opacity: 0;
//         }
//         .icon {
//           width: 15px;
//           height: 15px;
//           vertical-align: 0;
//         }
//         .prefix-icon {
//           margin-right: 10px;
//           font-size: inherit;
//           vertical-align: 0;
//         }
//         .suffix-icon {
//           position: absolute;
//           bottom: 13px;
//           right: 15px;
//           z-index: 9;
//           margin: 0;
//           vertical-align: 0;
//         }
//         &:last-child {
//           border: 0;
//         }
//       }
//     }
//   }
//   .client-portfolio-menu-button {
//     position: relative;
//     padding: 0 20px;
//     padding: 0 25px 0 50px;
//     font-size: 16px;
//     font-weight: bold;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.38;
//     letter-spacing: normal;
//     text-align: center;
//     color: #5eade9;
//     border: 0;
//     border-color: #5eade9;
//     border-radius: 50px;
//     i {
//       font-size: 21px;
//       position: absolute;
//       top: 6px;
//       left: 18px;
//       margin: 0 !important;
//     }
//     .anticon {
//       position: absolute;
//       right: 12px;
//       bottom: 5px;
//       margin: 0;
//       vertical-align: 0;
//       line-height: 2;
//     }
//   }
// }


// @media only screen and (max-width: 1023px) and (min-width: 320px) {
//   .client-portfolio-menu {
//     display: none !important;
//     .client-portfolio-menu-button {
//       .button-label {
//         display: none !important;
//       }
//     }
//   }
// }

@media only screen and (max-width: 1280px) and (min-width: 1024px) {
  // .portfolio-tools-wrap{
  //   align-items: center;
  //   .tool-badge {
  //     height: 35px;
  //     width: 100px;
  //     .tools-link{
  //       color: transparent!important;
  //       font-size: 13px;
  //       img{
  //         height: 20px;
  //         width: auto;
  //       }
  //     }
  //     &.selected {
  //       .tools-link{
  //         color: transparent!important;
  //       }
  //     }
  //   }
  // }
  // .client-portfolio-menu {
  //   .client-portfolio-menu-button {
  //     .button-label {
  //       display: none !important;
  //     }
  //   }
  // }
}

// @media only screen and (max-width: 1459px) and (min-width: 1281px) {
//   .client-portfolio-menu {
//     .client-portfolio-menu-button {
//       .button-label {
//         display: none !important;
//       }
//     }
//   }
// }
//
// @media only screen and (min-width: 1920px){
//   .client-portfolio-menu-button {
//     height: 43px !important;
//     i {
//       top: 12px !important;
//     }
//     .anticon {
//       bottom: 10px !important;
//     }
//   }
// }

.portfolio-class-dropdown-container {
  // margin-left: 15px;
  .ant-dropdown-trigger {
    // padding-right: 30px;
    // font-family: Roboto;
    // font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    position: relative;
    .anticon-down  {
      top: 3px !important;
      margin-left: 10px !important;
      position: absolute;
      font-size: 14px !important;
    }
  }

  .similarity-measures {
    .info-icon{
      margin-left: 8px;
      color: #1E5F91;
      cursor: pointer;
      position: absolute;
      left: 119px;
      margin-top: 1px;
      font-size: 9px;
    }
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 40px; // for info icon
        .ant-radio-wrapper {
          margin: 0;
          .ant-radio-inner {
            width: 14px;
            height: 14px;
          }
        }
        .ant-radio-wrapper-checked {
          .ant-radio-checked {
            border-radius: 50%;
            .ant-radio-input {
              border-radius: 50%;
            }
            .ant-radio-inner {
              border-radius: 50%;
              top: 0;
              left: 0;
              width: 14px;
              height: 14px;
              &:after {
                top: 1px;
                left: 1px;
                width: 10px;
                height: 10px;
                background-color: #1790ff;
              }
            }
            @media only screen and (min-width: 320px) and (max-width: 1439px) {
              .ant-radio-inner {
                width: 13px;
                height: 13px;
                &:after {
                  width: 9px;
                  height: 9px;
                }
              }
            }
          }
        }
      }
      .ant-dropdown-menu-item-selected {
        background-color: rgba(197, 197, 197, 0.6) !important;
      }
    }
  }

  .portfolio-class-dropdown-wrapper {

    .ant-dropdown-menu {
      border-radius: 5px;
      .ant-dropdown-menu-item {
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: left;
      }
      .ant-dropdown-menu-item-selected {
        background-color: rgba(197, 197, 197, 0.6);
      }
    }
  }
}
