

.premium-feature-process-modal-backdrop {
  display: block !important;
}

// .premium-msg-modal {
//   max-width: 630px !important;

//   .modal-content {
//     border: 0;
//     border-radius: 15px;
//     background-color: #56a9e8;
//   }
//   .modal-body {
//     padding: 3.5rem;
//   }
// }

@include respond(tab) {
  .premium-feature-process-modal {
    width: 80%;
  }
}

@include respond(phone) {
  .premium-feature-process-modal {
    width: 95%;
  }
}

.premium-feature-process-modal {
  max-width: 936px !important;

  .modal-content {
    border: 0;
    border-radius: 15px;
    background-color: #56a9e8;
  }
  .modal-body {
    padding: 3.5rem;
  }
}

.fs-1 {
  font-size: 1rem !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left !important;
}

.justify-content-left {
  justify-content: left !important;
}

// .premium-feature-process-modal-container {
//   .premium-feature-completed-close {
//     position: absolute;
//     right: 1rem;
//     top: 1rem;
//     z-index: 9;
//     font-size: 1.5rem;
//     color: #fff;
//     cursor: pointer;
//   }

//   .premium-feature-completed-check {
//     font-size: 6rem;
//     text-align: center;
//     color: #fff;
//   }

//   .header-wrapper {
//     h1 {
//       font-size: 1.7rem !important;
//       // font-size: 2rem !important;
//       font-weight: normal;
//       font-stretch: normal;
//       font-style: normal;
//       line-height: 1.31;
//       letter-spacing: normal;
//       color: #ffffff;
//     }
//     h2 {
//       font-size: 13px !important;
//       font-weight: normal;
//       font-stretch: normal;
//       font-style: normal;
//       line-height: 1.38;
//       letter-spacing: normal;
//       color: #ffffff;
//     }
//   }
//   .content-wrapper {
//     font-size: 13px;
//     font-weight: normal;
//     font-style: normal;
//     line-height: 1.38;
//     letter-spacing: normal;
//     text-align: left;
//     color: #ffffff;
//     margin-bottom: 1.5rem;
//   }
//   .cta-wrapper {
//     flex-direction: row;
//     align-items: normal;
//     .btn {
//       width: 140px !important;
//       font-size: 12px;
//       font-weight: normal;
//       padding: 13px 31px !important;
//       line-height: 1;

//       &:first-child {
//         margin-right: 20px;
//       }
//     }
//   }
// }

// @include respond(phone){
//
//   .premium-msg-modal {
//     .modal-content {
//
//     }
//     .modal-body {
//       padding: 1rem;
//     }
//   }
//
//   .premium-feature-process-modal {
//     .modal-content {
//
//     }
//     .modal-body {
//       padding: 1rem;
//     }
//   }
//
//   .premium-feature-process-modal-container {
//     .premium-feature-completed-close {
//
//     }
//
//     .premium-feature-completed-check {
//
//     }
//
//     .header-wrapper {
//       h1 {
//         font-size: 1.4rem !important; // mobile view premium login modal header
//         text-align: center !important;
//         padding: 0 25px;
//       }
//       p{
//         font-size: 12px !important;
//         margin-bottom: 0;
//       }
//       h2 {
//         font-size: 13px !important;
//
//       }
//     }
//     .content-wrapper {
//
//     }
//     .cta-wrapper {
//       .btn {
//         line-height: 1.2;
//
//         &:first-child {
//           margin-right: 0.8rem;
//         }
//       }
//     }
//   }
// }
