$font-color-one: #4a4a4a;
$font-size-22px: 22px;

.open-summary-custom-scroll {
  // height: calc(100vh - 482px) !important;
  height: calc(100vh - 100px) !important;
  padding-bottom: 0 !important;
  padding-left: 10px !important;
  padding-right: 5px !important;
  margin-right: -15px !important;
}

.border-selected-color {
  border: solid 4px $selected-card-border-color !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.apex-bg-1 {
  // background: no-repeat bottom url('/assets/images/apex-bg-1.svg') transparent;
  background-size: 52%;
  background-position: 100% 100%;
}

.apex-bg-2 {
  // background: no-repeat bottom url('/assets/images/apex-bg-2.svg') transparent;
  background-size: 38%;
  background-position: 0px 100%;
}

.accounts-summary-container {
  .accounts-page-container {
    .accounts-page-summary-wrapper {
      .accounts-summary-card-row {
        margin: 0 -8px;
        .card-title {
          padding: 1rem 1rem 0 1rem;
          font-family: $primary-font;
          font-size: $font-size-16px;
          font-weight: normal;
          letter-spacing: -0.32px;
          text-align: left;
          color: $label-black-color;
        }
        .holdings-list-head {
          font-family: $primary-font;
          font-size: $font-size-14px;
          font-weight: normal;
          color: $other-grey-color-3;
          line-height: 1.5;
        }
        .FRR-block-label {
          text-align: center;
          .FRR-daily{
            position: relative;
            max-width: 82px;
            margin: 0 auto;
            padding: 0px 10px;
            .fa-caret-left{
              position: absolute;
              left:0px;
              top:2px;
              color:#9a9a9a;
              padding:3px;
              &:hover{
                color:#5eade9;
                cursor:pointer;
              }
            }
            .fa-caret-right{
              position: absolute;
              right:0px;
              top:2px;
              color:#9a9a9a;
              padding:3px;
              &:hover{
                color:#5eade9;
                cursor:pointer;
              }
            }
          }
        }
        .accounts-card-stats-wrapper {
          // min-height: 94px;
          .custom-stats-switch-btn-wrapper {
            position: absolute;
            right: 14px;
            top: 0px;
            .btn-link {
              text-decoration: none !important;
            }
          }
          .col-block {
            margin: 15px 0px;
            .cta {
              font-size: $font-size-14px !important;
            }
            div {
              font-family: $primary-font;
              font-size: $font-size-16px;
              font-weight: normal;
              letter-spacing: -0.32px;
              text-align: left;
              color: $label-black-color;
            }
            h1 {
              margin: 0 !important;
              font-family: $primary-font;
              font-size: $font-size-30px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.34;
              letter-spacing: -0.64px;
              text-align: left;
              color: $dark-blue-color;
            }
            &:last-child {
              // border-left: 1px solid $other-grey-color-4;
            }
            &:first-child {
              // border-left: 0px solid $other-grey-color-4;
            }
            .break-150{
              max-width: 150px;
              word-break: break-all;
            }
          }
        }
      }
      .accounts-summary-card-col {
        padding: $accounts-summary-card-col;
      }
      .accounts-allocations-chart-container {
        min-height: 122px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        .accounts-allocations-chart-wrapper {
          position: relative;
          width: 100px;
          height: 114px;
          .recharts-responsive-container {
            width: 100px !important;
            height: 100px !important;
          }
          .accounts-allocations-chart-label {
            // margin-top: 10px;
            font-family: $primary-font;
            font-size: $font-size-14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: -0.28px;
            text-align: center;
            color: $other-grey-color-3;
          }
        }
      }
      .accounts-profitloss-chart-container {
        min-height: 122px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        .accounts-profitloss-chart-wrapper {
          position: relative;
          width: 100px;
          height: 114px;
          .recharts-responsive-container {
            width: 100px !important;
            height: 100px !important;
          }
          .accounts-profitloss-chart-label {
            // margin-top: 10px;
            font-family: $primary-font;
            font-size: $font-size-14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: -0.28px;
            text-align: center;
            color: $other-grey-color-3;
          }
        }
      }
      .transactionsWithScrollBar {
        // height: 278px;
        // height: 266px;
        height: 274px;
      }
    }
    .accounts-page-label {
      // margin-bottom: 4px;
      // padding: 0px 8px;
      padding: 0px 3px;
      font-family: $primary-font;
      font-size: $font-size-22px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: left;
      color: $font-color-one ;
      color: inherit;
    }
  }
  .primary-cta-button {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: #1e5f91 !important;
    background-color: #ddeefa !important;
    border: 0;
    border-radius: 50px;
    height: 34px;
    color: #fff !important;
    background-color: #1e5f91 !important;
    text-transform: uppercase;
    line-height: normal;
    .anticon {
      position: absolute;
      top: 8px;
      vertical-align: 0;
      line-height: normal;
    }
  }
  .summary-card-row .accounts-summary-card-col {
    > div {
      display:flex;
      > div {
        flex:1;
      }
    }
    .tx-c3 {
      font-size: 1rem!important;
      color: $font-color-one !important;
      font-family: 'Open Sans';
      margin-bottom: 13px!important;
      line-height: 1.3rem;
    }
    .tx-c2 {
      color: #1d6091!important;
      font-family: 'Open Sans';
      font-size: 2rem!important;
      font-weight: 400;
    }
  }

}

.accounts-summary-holdings-wrapper {
  // min-height: 460px;
  min-height: 265px;
}

.primary-color {
  color: $primary-color !important;
}

.accounts-summary-holdings-card {
  .portfolio-loss {
    color: rgb(235, 74, 74) !important;
  }
  .portfolio-profit {
    color: rgb(86, 162, 76) !important;
  }
  // padding: 0.5rem 0;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid $other-grey-color-4;
  &:first-child {
    padding: 0.5rem 1rem;
  }
  &:last-child {
    // padding-bottom: 0;
    border-bottom: 0;
  }
  .ticker-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    h5 {
      font-family: $primary-font;
      font-size: $font-size-16px;
      font-weight: 600;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: $label-black-color;
    }
    .holdings-weight-font-size {
      font-family: $primary-font;
      font-size: $font-size-16px;
      font-weight: 600;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: $font-color-one;
    }
    .holdings-weight-vehicle {
      font-size: 10px !important;
      font-weight: 600;
      line-height: 1.5;
      color: $vehicle-color;
    }
    .positive-weight {
      color: $primary-color;
    }
    .negative-weight {
      color: $font-color-one;
    }
    .positive-amount{
      color: $light-green-color;
    }
    .negative-amount{
      color: $red-color;
    }
  }
  .name-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 4px;
    h5 {
      font-size: $font-size-14px !important;
      color: $dark-green-color !important;
    }
    .inner-attributes {
      font-family: $primary-font;
      color: $label-black-color;
    }
  }
  .selected {
    color: $primary-color !important;
  }
  .basket-trade-name-block {
    flex-direction: column;
    align-items: inherit;
    justify-content: space-between;
    .ticker-block {
      flex-direction: row;
      justify-content: flex-start;
      h5 {
        font-size: 1.1rem !important;
        color: $primary-color !important;
      }
      h3 {
        font-size: 1.1rem !important;
      }
      .holdings-weight-font-size {
        padding: 0 6px;
      }
    }
    .basket-trade-button-wrapper {
      span {
        font-size: $font-size-12px;
        cursor: pointer;
      }
      i {
        margin: 2px ​2px !important
      }
    }
  }

  .FRR-block {
    padding: 0 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.security-range-filter {
      align-items: flex-end;
      button {
        margin-bottom: 0px !important;
        padding: 0px 0px 0px 10px;

        &:hover {
          text-decoration: none;
        }
      }
      span {
        text-align:right;
      }
    }
    .green-color {
      color: $light-green-color;
    }
    h5 {
      font-size: $font-size-16px !important;
      font-family: $primary-font;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: $label-black-color;
    }
    h3 {
      font-size: $font-size-12px !important;
      font-family: $primary-font;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: $label-black-color;
    }
  }
  .cta-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 0 0 0 4px;
    button {
      width: 100%;
      max-width: 93px;
      height: auto;
      display: flex;
      justify-content: center;
      font-family: $primary-font;
      font-size: $font-size-12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.24px;
      text-align: center;
      color: $orange-color;
      border-radius: 30px;
      border: solid 1px $orange-color;
      background-color: #ffffff;
    }
    .selected {
      color: #fff;
      background-color: $dark-grey-color-2;
      border-color: $dark-grey-color-2;
    }
    .inactive {
      color: rgba(0, 0, 0, 0.25);
      text-shadow: none;
      box-shadow: none;
      background-color: $disbaled-bg-color;
      border-color: $disabled-text-color-2;
    }
  }
  .text-blue {
    color: $primary-color-2 !important;
  }
  .time-Unit {
    padding: 0 4px;
    font-size: $font-size-14px;
    font-family: $primary-font;
    color: $font-color-one;
    text-align: right;
    .ant-select {
      font-size: inherit !important;
      .ant-select-selector {
        height: 20px !important;
        font-size: inherit !important;
        .ant-select-selection-search {
          width: 100%;
          height: 20px !important;
          font-size: inherit !important;
          .ant-select-selection-search-input {
            height: 20px !important;
            font-size: inherit !important;
          }
        }
        .ant-select-selection-item {
          padding-right: 0 !important;
          line-height: 20px !important;
          font-size: inherit !important;
        }
      }
      .ant-select-arrow {
        right: 0 !important;
        .anticon {
          color: $primary-color-2 !important;
        }
      }
    }
  }
}

.accounts-summary-holdings-card-selected {
  background: $selected-card-border-color !important;
}

.accounts-summary-container,
.accounts-model-portfolio-container,
.accounts-funds-portfolio-container {
  h5 {
    &.font-one {
      &.text-heading {
        font-weight: 600;
      }
    }
  }
}

.security-return-range {
  width: 138px;
  height: 19px;
  margin: 6px 0 0;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: -0.28px;
  text-align: left;
  color: #4a4a4a;
}
.align-items-right {
  text-align: right!important;
}

.accounts-summary-card-col{
  .ant-pagination{
    .ant-pagination-item-link{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-pagination-item{
      font-size: 14px;
    }
  }
}

.transactions-pagination-wrapper{
  width: inherit;
  text-align: center;
  .transaction-pagination{
    display: inline-block;
    .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next{
      min-width: 24px;
      height: 24px;
      margin: 0;
      line-height: 22px;
    }
    .ant-pagination-prev, .ant-pagination-next{
      .ant-pagination-item-link{
        line-height: 19px;
      }
      &:hover{
        border: 1px solid #1d6091 !important;
      }
      a:hover, a:focus{
        color: #1d6091 !important;
      }
    }
    .ant-pagination-item-active{
      border: 1px solid #1d6091 !important;
      a:hover, a:focus{
        color: #1d6091 !important;
      }
    }
  }
  .jump-icn{
    width: 16px;
    position: relative;
    top: -8px;
    cursor: pointer;
  }
  .disabled{
    cursor: not-allowed;
  }
  .flip{
    transform: scaleX(-1);
  }
}
