$gutter: 15px;

.maintenance-page-container {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	.maintenance-page-header {
		.magnifi-logo {

		}
	}
	.maintenance-page-wrapper {
		width: 75%;
    min-width: 320px;
    height: calc(100% - 60px);
    margin: 0px auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.maintenance-page-title {
			margin-bottom: $gutter;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 500;
			font-size: $font-size-49px !important;
			text-align: center;
			color: $dark-blue-color-2;
			@media (min-width: 320px) and (max-width: 768px) {
				font-size: $font-size-30px !important;
			}
		}
		.maintenance-page-desc {
			width: 100%;
	    min-width: 320px;
	    max-width: 515px;
			margin-bottom: $gutter;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 500;
			font-size: $font-size-25px;
			text-align: center;
			color: rgba(0, 0, 0, 0.7);
			@media (min-width: 320px) and (max-width: 768px) {
				font-size: $font-size-20px !important;
			}
		}
		.maintenance-page-sub-text {
			margin-bottom: $gutter;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 500;
			font-size: $font-size-18px;
			text-align: center;
			color: rgba(0, 0, 0, 0.5);
			@media (min-width: 320px) and (max-width: 768px) {
				font-size: $font-size-14px !important;
			}
		}
		.maintenance-page-image {
			@media (min-width: 320px) and (max-width: 768px) {
				width: 240px;
				height: 240px;
			}
		}
	}
}
