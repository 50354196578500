@import "./../variables";


// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300&display=swap');
$breakpoint-tablet: 768px;

// body {
//   padding-top:0px!important;
// }

#landing-page {
  .nav_with_drop{
    .forMobile{
      display: none;
    }
    .drop_cont{
      position: absolute;
      display: none;
      align-items: center;
      justify-content: center;
      left: 25px;
      z-index: 999;
      padding-top: 10px;
      .managed_port_dropdown{
        background: #4a4a4a;
        padding: 15px;
        width: 12em;
        ul{
          list-style: none;
          padding: 0;
          li{
            padding: 2px 0;
            a{
              color: #fff;
              font-size: 15px;
              font-family: Roboto, Roboto;
              line-height: 25px;
              font-weight: 500;
              &:hover{
                color: #56a9e8;
              }
            }
          }
        }
      }
      &:hover{
        display: flex;
      }
    }
    &:hover{
      .drop_cont{
        display: flex;
      }
    }
    @media (max-width:992px){
        .forDekstop{
          display: none;
        }
        .forMobile{
          display: block;
        }
        .drop_cont{
          position: initial;
          .managed_port_dropdown{
            text-align: center;
            width: 80vw;
          }
        }
    }
  }
  .navbar-nav .user-profile.dropdown {
    padding: 20px 0px 12px 28px;
    .user-menu__name-badge {
      position: relative;
      .notification-count {
        top: -15px;
      }
    }
  }
  .navbar-nav .user-profile > .dropdown-menu {
    top:30px!important;
  }
  font-family: 'Roboto', sans-serif!important;
  .header-menu{
    width:100%;
  }
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .header-toggle .navbar-toggler-icon{
    // background: url("../../../assets/images/menu.svg")
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba( 255, 255, 255, 0.8 )' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
  }
  .header-brand{
    // margin-right:120px !important;
    padding: 0px!important;
  }

  .banner{
    background-image: url(/assets/images/magnifi-background.jpg);
    min-height:493px;
    background-repeat: no-repeat;
    background-position:top center;
    background-size: cover;
    padding: 0px 100px;
    position: relative;
    z-index: 1;
    @media (min-width:768px) and (max-width:1200px){
      padding: 0px 36px 0px 36px!important;
    }
    @media (min-width:1201px) and (max-width:1300px){
      padding: 0px 80px!important;
    }
  }
  .banner:before{
    border-radius: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    transition: opacity 0.2s;
    background-color: #0000003d;
    content:"";
    left:0;
    z-index: -1;

  }
  .container-navbar{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1128px;
    margin: auto;
  }
  .main-logo{
    position: relative;
    height: 60px;
    width: 136px;
    top: 7px;
    @media (min-width:768px) and (max-width:991px){
      width: auto!important;
      height: 35px!important;
    }
  }
  .header{
    margin-top:12px !important;
    padding: 0px!important;
    @media (min-width:768px) and (max-width:959px){
      margin-top:20px !important;
    }
  }
  .header-background{
    background:#4a4a4a!important;
    margin-top:15px !important;
    @media (min-width:320px) and (max-width:480px){
      ul.navbar-nav {
        padding-top: 50px;
        padding-bottom: 20px;
      }
    }
  }
  .bg-light {
    background-color: transparent !important;
  }
  .header-link {
    color:#ffffff;
    font-weight: 500;
    letter-spacing: 0.05em;
    font-size: 15px;
    // text-align: center;
    padding: 20px 0px 12px 34px!important;
    &.new-link{
      cursor: pointer;
    }
    &.active{
      color: #56a9e8 !important;
    }
      &.custom-button-header{
        padding: 0px!important;
        padding-top: 9px !important;
        margin-left: 34px !important;
        @media screen and (max-width: 991px) {
          margin-left: 12px !important;
        }
      }
      .custom-button{
        padding: 10px 32px;
        // border: 1px solid #fff;
        text-transform: capitalize;
        border: 1px solid #56a9e8;
        background: #56a9e8 !important;
        border-radius: 40px;
        &:hover{
          background: #fff!important;
          color: #56a9e8 !important;
        }
      }
    // }
  }



  @media (max-width: $breakpoint-tablet) {
    .header-link {
      text-align: center;
      padding: 5px 0px !important;
      font-weight: 400;
      font-size: 18px!important;
      padding: 20px 0px 12px 10px !important
    }
    .main-dropdown{
      text-align: center!important;
      &.dropdown-menu{
        padding: 0px 15px!important;
        margin: 0;
      }
    }
  }

  .main-dropdown {
    background: #4a4a4a !important;
    border: none !important;
    border-radius: 0px !important;
    padding: 15px !important;
    min-width: 200px;
    &.dropdown-menu{
      border-radius: 0px!important;
      min-width: 13rem!important;
      right:auto!important
    }
    .dropdown-item:hover{
      color: #56a9e8;
      background: none;
    }
    .dropdown-item{
      color: #fff;
      padding: 5px .5rem;
      font-weight: 600;
      font-family: 'Open Sans', Roboto;
    }
  }

  .header-link:hover{
    color:#56A9E8 !important;
  }

  .banner-items{
    display:block;
    padding-top:100px;
    max-width: 1200px;
    margin: auto;
    text-align: center;
    .inner-addon{
      max-width: 60%;
      margin: auto;
    }
  }
  .banner-items h1 {
    color:#fff;
    font-weight: 500;
    margin-bottom: 10px !important;
    font-size: 48px !important;
    line-height: 1.2;
  }
  .banner-items h4 {
    color:#fff;
    // max-width:700px;
    width: 100%;
    margin-top:0px !important;
    font-size: 20px !important;
    font-weight:300;
    line-height:24px;
    margin-bottom: 0;
  }

  .banner-search {
    width: 100%;
    margin-top: 0px;
    border-radius: 30px;
    height: 50px;
    font-size: 18px;
    padding: 20px;
    color: #66b3ea!important;

  }
  .banner-search::placeholder {
    color: #66b3ea!important;
    opacity: 1;
  }
  .inner-addon {
    position: relative;
    cursor:pointer;
    margin-bottom: 18px;
    form {
      margin-top: 50px;
      width: 100%;
      border-radius: 30px;
      height: 50px;
      font-size: 18px;
      color: #66b3ea!important;
    }
  }
  .inner-addon .glyphicon {
    position: absolute;
    // pointer-events: none;
    color:#66b3ea !important;
  }
  .right-addon .glyphicon {
    // right: 23px;
    // top: 14px;
    border: none;
    background: none;
    height: 24px;
    width: 28px;
  }

  .marq-container{
    display: flex;

    position: fixed;
    bottom: 0;
    z-index: 100;

    width: 100%;

    background: #6CB4EE;
    border-top: 2px solid white;

    @media (min-width: 1920px) and (max-width: 4000px) {
      width: 140%;
      margin-left: -20%;
    }

    .heading-container{
      margin: 5px auto;
      min-width: 110px;
      .marq-heading {
        color: #fff;
        font-family: 'Roboto',sans-serif;
        font-size: 16px !important;
        line-height: 1.2;
      }
    }
  }

  .marq {
    width: 100%;
    margin: 6px auto;
    margin-top: 8px;
    white-space: nowrap;
    @media (min-width: 1920px) and (max-width: 4000px) {
      padding: 4px;
    }
    overflow: hidden;
    span:first-child:before{
      display: none;
    }
  }

  .marq a, .marq div {
    display: inline-block;
    padding-left: 50% !important;
    -webkit-animation: marquee 300s linear infinite;
    animation: marquee 300s linear infinite;
  }

  /* Make it move */
  // @-webkit-keyframes marq {
  //   from { transform: translateX(10); }
  //   to { transform: translateX(-50%); }
  //   // 0%   { transform: translate(0, 0); }
  //   // 100% { transform: translate(-100%, 0); }
  // }
  @keyframes marq {
    from { transform: translateX(10); }
    to { transform: translateX(-50%); }
    // 0%   { transform: translate(0, 0); }
    // 100% { transform: translate(-100%, 0); }
  }

  .marq *:hover {
    -moz-animation-play-state: paused;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .marq span {
    position: relative;
    cursor: pointer;
    font-family: 'Roboto',sans-serif;
    font-size: 17px !important;
    @media (min-width: 1920px) and (max-width: 4000px) {
      font-size: 20px!important;
    }
    // font-weight: bold;
    text-transform: none;
    padding: 0 25px!important;
  }
  .marq span::before {
    content: '|';
    position: absolute;
    left: -2px;
  }

  .marq-text1{
    line-height: 1.2;
    margin: 27px 0 0 !important;
    color:#fff;
    font-size:18px;
    &.disabled{
      cursor: not-allowed !important;
    }
  }
  .marq-text2{
    line-height: 1.2;
    margin: 27px 0 0 !important;
    color:#66b3ea ;
    font-size:18px;
  }

  .popular-search{
    padding: 0px ;
    max-width: 1128px;
    margin: auto;
    h1{
      font-weight: 300;
      font-size: 36px !important;
      font-family: Roboto, Roboto;
      color: #4a4a4a !important;
      line-height: 43px;
      margin-top:55px;
      margin-bottom: 5px;
    }
    .popular-search-button{
      border-radius:30px !important;
      font-size:10px !important;
      padding: 7px 20px!important;
      width: 100%;
      margin: 10px 0;
      font-weight: 600 !important;
      color: #525252!important;
      letter-spacing: 0.1em;
      border: 1px solid;
      text-transform: uppercase;
    }
    @media (min-width:768px) and (max-width:1200px){
      padding: 0px 36px 0px 36px!important;
      h1{
        font-size: 24px!important;
        margin-bottom: 36px;
        line-height: 1.5;
      }
      .popular-search-button{
        max-width: calc(90% + 20px);
      }
    }
    @media (min-width:680px) and (max-width:767px){
      padding: 0px 36px 0px 36px!important;
      h1{
        font-size: 24px!important;
        margin-bottom: 36px;
        line-height: 1.5;
      }
      .popular-search-button{
        max-width: calc(90% + 20px);
      }
    }
  }

  .popular-search > div {
    width: 100%;
    text-align: center;
    max-width: 1052px;
    margin: auto;
  }


  .popular-search-button:hover {
    color: #FFE!important;
    background-color: #4a4a4a !important;
    border-color: #4a4a4a !important;
  }


  .landing-form{
    // padding: 0px 300px;
    margin-top:50px;
    margin-top: 70px;

  }

  .landing-form h2{
    font-size: 1.5em !important;
    font-weight: 400;
    color: #33475b;
    line-height: 24px;
  }

  .landing-form label{
    font-weight: 400;
    text-align: left;
    line-height: 20px;
    font-size: 15px;
    color: #4a4a4a;
  }
  .landing-form sup{
    color:red;
    top: 0;
    font-size: 15px;
  }
  .landing-form-input{
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 16px !important;
    font-weight: normal !important;
    line-height: 22px !important;
    color: #33475b !important;
    border: 1px solid #cbd6e2 !important;
  }
  .landing-form-input::placeholder{
    color: #767676;
    font-weight: 500;
  }
  .landing-form-button{
    display:flex;
    justify-content:center;
  }

  .landing-form-button .btn{
    color: #fff;
    background-color: #56A9E8;
    border-color: #56A9E8;
    border-radius: 10px;
    height: 42px;
    font-weight: 500;
    padding: 0 24px;
  }
  .landing-form-button .btn:hover{
    background-color: #56A9E8;
  }
  .landing-form-message{
    padding: 0px 100px;
    font-size: 1.5em;
    font-weight: bold;
    color: #33475b;
    line-height: 24px;
    display:flex;
    justify-content:center;
    margin-top:50px;
  }

  .trending-theme{
    margin-top: 50px;
    width: 100%;
    max-width: 1128px;
    margin: auto;
    h1{
      text-align: center;
      font-weight: 300;
      font-size: 36px !important;
      font-family: Roboto, Roboto;
      color: #4a4a4a !important;
      line-height: 43px;
      margin-top: 50px;
      margin-bottom: 20px;
      padding: 0 ;
    }


    @media (min-width:680px) and (max-width:767px){
      padding: 50px 36px 0px 36px!important;
      h1{
        font-size: 24px!important;
        margin-bottom: 20px;
        line-height: 1.5;
        margin-top: -15px;
      }
    }
  }


  .trending-theme-container{
    display:flex;
    width:100%;
    justify-content: center;
    .skeleton-container {
      width: 100%;
      height: 355px;
      padding: 10px 18px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-around;
      @media (max-width:768px){
        height: unset;
        display: unset;    
      }
      .skeleton-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        .skeleton-Avatar {
          width: 187px;
          height: 91.5px;
          margin-right: 12px;
          .ant-skeleton-avatar {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .trending-theme-row{
    justify-content:center;
    margin-bottom:30px;
    max-width: 1140px;
    margin: auto;
    margin-bottom: 36px;
  }
  .trending-theme-card{
    border-radius: 8px;
    overflow: hidden;
    // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    // transition: 0.3s;
    height: 91.5px;
    width:187px;
    position: relative;
    background:none;
    margin:0.5px;
    @media (min-width:768px) and (max-width:1200px){
      // height:172px;
      width:172px;
    }
    @media (min-width:680px) and (max-width:767px){
      // height:151px;
      width:151px;
    }

  }
  .trending-theme-card:hover {
    // box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor:pointer;
  }
  .trending-theme-card-image{
    border-radius: 8px;
    width:100%;
    height:100%;
    object-fit: cover;
    transition: transform .5s ease;
  }
  .trending-theme-card:hover {
    // filter:grayscale(1);
    & .trending-theme-card-image {
      // box-shadow: 0 0 0px #000000 !important;
      transform: scale(1.07);
    }

  }
  .t-entry-visual-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    transition: opacity 0.2s ease-in-out;
    .t-entry-visual-overlay-in{
      opacity: 0.5;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to top, #000000 0%, transparent 50%);
    }
  }
  .t-overlay-wrap{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    .t-overlay-inner {
      display: table;
      table-layout: fixed;
      position: absolute;
      width: 100%;
      height: 100%;
      .t-overlay-content{
        text-align: left;
        vertical-align: bottom;
        display: table-cell;
        height: 100%;
        .t-overlay-text {
          display: inline-block;
          width: 100%;
          margin: 0 auto;
          padding: 18px 18px 18px 18px;
          .t-entry-title{
            color: #fff;
            margin: 0px;
            font-family: 'Roboto';
            font-size: 14px !important;
          }
        }
      }
    }
  }
  .bottom-right{
    position: absolute;
    bottom: 16px;
    left: 16px;
    color:#fff;
    font-weight:500;
    font-size: 17px;
    font-family: 'Roboto';
    @media screen and (max-width: 480px) {
      bottom: 14px;
    }
  }

  .trending-theme-button{
    display:flex;
    justify-content:center;
  }

  .trending-theme-button button {
    border-radius : 30px !important;
    font-weight: 700 !important;
    font-family: Roboto, Roboto !important;
    font-size:0.8em !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase;
  }

  .trending-theme-button .btn-outline-secondary:hover {
    color:#4a4a4a !important;
    background-color: #fff !important;
    border-color: #4a4a4a !important;
  }
  .trending-theme-button .btn-outline-secondary {
    color: #FFE !important;
    background-color: #4a4a4a !important;
    border-color: #4a4a4a !important;
    font-weight: 600 !important;
    font-size: 10px !important;
    padding: 0 25px;
    height: 31px;
  }
  .info-section{
    padding: 70px 0 40px 0;
    margin-top: 70px;
    width: 100%;
    max-width: 1128px;
    margin: auto;
  }

  .info-section h1{
    font-weight: 300;
    font-size: 36px !important;
    font-family: Roboto, Roboto;
    color: #4a4a4a !important;
    line-height: 1.2;
    margin-top:50px;
    span{
      display: flex;
    }
  }
  .info-section h2{
    font-size: 15px !important;
    line-height: 1.4em;
    // font-family: Roboto, Roboto;
    font-family: 'Open Sans', Roboto;
    color: #4a4a4a !important;
    font-weight:400 !important;
  }
  .info-image{
    width:100%;
  }
  .info-section-button {
    border-radius : 30px !important;
    font-weight: 700 !important;
    font-family: Roboto, Roboto !important;
    font-size:0.8em !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase;
  }

  .info-section-button.btn-outline-secondary:hover {
    color: #FFE !important;
    background-color: #4a4a4a !important;
    border-color: #4a4a4a !important;
  }
  .info-section-button.btn-outline-secondary {
    color:#4a4a4a !important;
    background-color: #fff0 !important;
    border-color: #4a4a4a !important;
    font-weight: 600 !important;
    font-size: 10px !important;
    margin-top: 34px;
    padding: 0 31px;
    height: 31px;
  }
  .info-section-divider{
    border-top: 1px solid #bbb;
    padding-bottom: 60px;
    margin-top: 60px;
  }
  .info-section-image{
    width:100%;
    max-width: 100%!important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @media (min-width: 1200px) and (max-width: 2500px){
      max-width: 504px !important;
    }
  }
  .section-row {
    margin-right: -7px !important;
    margin-left: -7px !important;
  }
  .footer-container{
    margin-top:50px;
  }
  .footer-blue-section{
    // padding: 0px 100px;
    background-color:#56a9e8;
    padding: 72px 100px 72px 100px;
    @media (min-width:768px) and (max-width:1200px){
      padding: 80px 36px 80px 36px;
    }
    @media (min-width:680px) and (max-width:767px){
      padding: 80px 36px 80px 36px;
    }
    @media (min-width:320px) and (max-width:680px){
      padding: 80px 36px 80px 36px;
    }
  }
  .blue{
    max-width: 1128px;
    margin: auto;}

    .footer-blue-section h3{
      color: white;
      font-size: 24px !important;
      font-weight: 300;
      line-height: 1.2;
      padding-right: 60px;
      margin-bottom: 0px!important;
    }

    .footer-blue-section .title{
      color: white !important;
      margin-bottom: 0.2em !important;
      font-weight:300 !important;
      font-family: 'Open Sans', sans-serif;
      font-size: 48px!important;
      line-height:67px;
      margin-bottom: 0px!important
    }
    .footer-blue-section .footer-column > div:last-child {
      margin-top: 20px;
    }
    .footer-blue-section .subtitle {
      color:white;
      font-weight: 400 !important;
      font-family: 'Open Sans', 'Roboto'!important;
      font-size:15px;
      line-height: 1.4em!important;
      margin-bottom: 0px;
    }

    .footer-white-section{
      background-color:#dddddd;
      padding: 36px 100px 36px 100px;
      @media (min-width:768px) and (max-width:1200px){
        padding: 36px 36px 36px 36px;
      }
      @media (min-width:680px) and (max-width:767px){
        padding: 36px 36px 36px 36px;
      }
      @media (min-width:320px) and (max-width:680px){
        padding: 36px 36px 36px 36px;
      }
    }

    .footer-white-section .title{
      color: #4a4a4a!important;
      margin-bottom: 16px !important;
      font-size: 20px!important;
      font-weight: 300;
      font-family: 'Roboto';
    }
    .footer-white-section .subtitle{
      color: #4a4a4a !important;
      margin-bottom: 0em !important;
      font-size: 0.933em;
      font-family: 'Open Sans', 'Roboto';
    }
    .footer-image img{
      // width:165px;
      width: auto;
      height: 102px;
      float:right;
    }
    .footer-column{
      padding: 0px !important;
    }
    .footer-grey-section{
      background-color:#4a4a4a;
      padding: 26px 100px 60px 100px;
    }
    .footer-grey-section p{
      color:#fff;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 0px;
      font-family: 'Open Sans', Roboto;
    }
    .grey {
      max-width: 1128px;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }
    .media-icon{
      margin-left: 9px;
      margin-right: 9px;
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
    .term-and-conditions-link{
      color: #fff;
      &:hover{
        color: #56a9e8;
      }
    }
    .media-icon:hover{
      color: #56a9e8;
      path{
        fill:  #56a9e8;
      }
    }
    .vid-container{
      padding: 0px 100px;
      text-align: center;
    }
    .vid-container-title{
      font-size: 36px;
      font-family: Roboto, Roboto;
      color: #4a4a4a !important;
      line-height: 1.2;
      margin-top:50px;
      display:flex;
      justify-content:center;
      font-weight: 300;
    }
    .vid-container-subtitile{
      font-size: 15px;
      font-family: 'Open Sans', sans-serif;
      color: #4a4a4a !important;
      line-height: 21px;
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    .vid-container-video{
      margin-top:30px;
    }
    .btn-outline-secondary {
      color: #4a4a4a;
      border-color: #4a4a4a;
      font-size: 10px!important;
    }
    #hubspotForm{
      text-align: center;
      .hubspot-form-titile{
        font-size: 36px;
        font-family: Roboto, Roboto;
        color: #4a4a4a !important;
        line-height: 1.2;
        display: flex;
        justify-content: center;
        font-weight: 300;
        text-align: center;
      }
      .schedule-demo-btn{
        border-radius: 30px !important;
        font-size: 10px !important;
        padding: 7px 20px !important;
        margin: 10px 0;
        font-weight: 600 !important;
        color: #fff !important;
        letter-spacing: 0.1em;
        background-color: #56a9e8;
        border-color: #56a9e8;
      }
    }


    @media (min-width:320px) and (max-width:575px){
      .banner-items #typer {
        font-size: 14px !important;
        line-height: 1;
        width: max-content !important;
        top: 36%;
        z-index: 9999;
        margin: 0 !important;
        left: 7%;
      }
    }

    #typer {
      border-right: solid 1px rgba(0,255,0,.75);
      white-space: nowrap;
      overflow: hidden;
      position: absolute;
      margin: -39px 20px !important;
      // line-height: inherit;
      // font-size: inherit;
      font-weight: normal;
      color: #56a9e8!important;
      font-size: 18px;
      cursor: initial;
    }

    /* Animation */
    #typer {
      animation: animated-text-search 1s steps(35,end) 1s 1 normal both , animated-cursor 800ms steps(35,end) infinite;
    }

    /* text animation */

    @keyframes animated-text-search{
      from{width: 0;}
      to{width: 244px;}
    }

    /* cursor animations */

    @keyframes animated-cursor{
      from{border-right-color: #56a9e8;}
      to{border-right-color: transparent;}
    }
    i.fa.fa-angle-down.fa-dropdown {
      font-weight: 300 !important;
      margin-left: 10px !important;
    }

    .mail:hover {
      cursor: pointer;
      color:#56a9e8 !important;
    }


    /* Navbar Hamburger animation */
    .header-menu .navbar-toggler{
      position: relative;
      top: 7px;
      padding: 0.25rem 0rem;
    }
    #close-icon {
      width: 30px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
    }

    #close-icon span {
      display: block;
      height: 2px;
      width: 100%;
      background: #fff;
      border-radius: 2px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      position: relative;
      &:before {
        width: 100%;
        position: absolute;
        height: 2px;
        background: white!important;
        top: 8px;
        content: "";
        left: 0;
        transition: top 0.5s ease-in-out;

      }
      &:after {
        width: 100%;
        position: absolute;
        height: 2px;
        background: white!important;
        top: -8px;
        content: "";
        transition: top 0.5s ease-in-out;

        left: 0;
      }
    }



    /* Close Icon */

    #close-icon {
      padding: 2px;
    }

    #close-icon.open span{
      transition:0.3s 0s ease-in-out;
      background: transparent !important;
    }
    #close-icon.open span:after{
      top: 0;
      transform: rotate3d(0, 0, 1, -45deg);
      transition: top 0.3s ease-in-out,transform 0.3s 0.5s ease-in-out;

    }
    #close-icon.open span:before{
      top: 0;
      transform: rotate3d(0, 0, 1, 45deg);
      transition: top 0.3s ease-in-out,transform 0.3s 0.5s ease-in-out;

    }






    #close-icon.open span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    #close-icon.open span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 22px;
      left: 1px;
      position: absolute;
    }

    /* Navbar Hamburger animation */

    .window-scrolled .footer-scroll-top {
      opacity: 1;
      display: block;
      animation-name: alpha-in;
      animation-duration: 0.5s;
    }
    .footer-scroll-top {
      z-index: 100;
      // display: none;
      position: fixed;
      right: 22px;
      text-align: center;
      i{
        color: #ffffff !important;
        background-color: #4a4a4a !important;
        border-color: #4a4a4a !important;
        border-radius: 2px;
        width: 2em;
        height: 2em;
        line-height: 2em;
      }
    }
  }


  @media (min-width:768px) and (max-width:1023px){
    #landing-page {
      .banner {
        padding: 0px 36px;
      }
      .landing-form {
        padding: 0px 36px;
        #hubspotForm{
          iframe{
            width: 100%;
          }
        }
      }
      .info-section {
        padding: 0px 36px;
        .info-section-divider {
          padding-bottom: 27px;
        }
        .section-row {
          margin-right: 0px !important;
          margin-left: 0px !important;
          flex-direction: column-reverse;
          .info-section-info {
            padding-left: 0px!important;
            &.pl-5 {
              padding-bottom: 40px;
            }
            h1 {
              font-size: 24px !important;
            }
          }
        }
      }

      .footer-blue-section {
        background-color: #56a9e8;
        padding: 72px 36px 80px 36px;
        h3 {
          padding-right: 0px;
          text-align: center;
        }
        .footer-column {
          text-align: center;
          .title {
            font-size: 32px !important;
            line-height: 36px;
          }
        }
      }
      .footer-grey-section {
        padding: 15px 36px 60px 36px;
        text-align: center;

        p {
          color: #fff;
          font-size: 13px;
          line-height: 1.4em;
          margin-bottom: 0px;
          padding: 27px 0;
          font-family: 'Open Sans', Roboto;
        }
      }
      .social_Icon{
        padding-bottom: 27px;
        padding-top: 27px;
        .media-icon {
          margin-left: 9px;
          margin-right: 8px;
          height: 21px;
          width: 21px;
          cursor: pointer;
        }
      }

      .grey {
        flex-direction: column;
      }
      .vid-container{
        padding: 0px 36px;
        .vid-container-video {
          iframe {
            width: 100%;
          }
        }
      }

      .footer-column {
        padding: 0px !important;
        flex: 1 1 100%!important;
        max-width: 100%;
        padding-top: 18px!important;
      }
      .footer-image {
        img {
          // width: 100%;
          float: right;
          max-width: 444px;
        }
      }
      .footer-white-section {
        padding: 40px 36px 40px 36px;
        .title {
          margin-bottom: 18px !important;

        }
      }

    }
  }




  @media (min-width:320px) and (max-width:480px){
    #landing-page {
      .inner-addon{
        min-width: 100%;
      }
      .marq-container{
        min-width: 100%;
      }
      .user-profile.dropdown{
        padding-left: 0px!important;
        padding-top: 0px!important;
        .user-menu__name-badge {
          background: #585757;
          color: #fff;
          width: 100%!important;
          position: relative;
          margin-bottom: 8px;
          &::before{
            position: absolute;
            display: block;
            content: '\F107';
            border-color: transparent;
            border-style: solid;
            border-width: 0 0.5rem 0.5rem;
            top: 7px;
            right: 81px;
            font-family: 'Font Awesome 5 Pro';
          }
        }
        ul.dropdown-menu.show{
          position: relative!important;
          top: 0px!important;
          background: transparent;
          border: 0;
          left: auto;
          right: auto;
          margin-top: -36px;
          transition: all ease-in-out .3s;
          .user-menu__item{
            color:#fff;
            border-bottom: 0px;
            text-align: center;
            min-height: 40px;
            span{
              font-weight: 500;
              font-size: 15px;
              color: #fff;
              padding: 5px 20px;
              width: 100%;
            }
            a{
              font-weight: 500;
              font-size: 15px;
              color: #fff;
              padding: 5px 20px;
              width: 100%;
              .far{
                display: none;
              }
            }
          }
          &:after,&:before{
            display: none;
          }
        }


      }



      .banner{
        padding:0px 0px!important;
        .header-brand {
          margin-right: 0px !important;
        }
      }
      .banner-search {
        width: 100%;
        margin-top: 0px;
        border-radius: 30px;
        height: 50px;
        padding: 0 20px;
      }
      .banner-items{
        padding:0px 36px;
        padding-top:70px;
      }
      .banner-items h1 {
        color: #fff;
        font-weight: 500 !important;
        margin-bottom: 7px !important;
        font-size: 32px!important;
        line-height: 1.2 !important;
      }
      .banner-items h4 {
        max-width: 700px !important;
        width:100%;
        font-weight: 300;
      }
      .header{
        padding: 0 36px !important;
        .main-logo{
          width:119px;
        }
      }
      .header-brand {
        margin-right: 0 !important;
        padding: 0px !important;
      }
      .header-background {
        background: #4a4a4a !important;
        margin-top: 0px !important;
        padding: 0 36px !important;
        padding-top:9px !important;
        .main-logo {
          position: relative;
          height: 60px;
          width: 119px;
          top: 7px;
        }
      }
      .popular-search h1 {
        font-size: 24px!important;
        margin-bottom: 20px;
      }
      .popular-search {
        padding: 0px 30px;
      }
      .popular-search .popular-search-button {
        margin: 10px 5px 10px 0;
        white-space: normal;
      }
      .landing-form {
        padding: 0px 30px;
        margin-top: 50px;
      }
      .vid-container {
        padding: 0px 30px;
      }
      .vid-container-video iframe {
        max-width: 400px;
        width: 100%!important;
        height: 300px;
      }
      .trending-theme {
        padding: 0px 30px;
        margin-top: 50px;
        width: 100%;
        text-align: left;
      }
      .trending-theme h1 {
        font-weight: 300;
        font-size: 24px!important;
      }
      .trending-theme-container > .row {
        justify-content: center;
      }
      .trending-theme-card{
        height: 69px;
        width:143px;
      }
      .info-section {
        padding: 0px 30px;
        margin-top: 50px;
        width: 100%;
      }
      .info-section > .row {
        flex-direction: column-reverse;
      }
      .info-section h1{
        font-size: 24px!important;
      }
      .info-section h2 {
        font-weight: 300;
      }
      .info-section img {
        width: 100%;
      }
      .info-section-info {
        width: 100%;
        float: left;
        padding-bottom: 50px;
        padding-left: 0px!important;
      }
      .footer-blue-section {
        padding: 50px 30px 80px 30px;
      }
      .footer-blue-section h3 {
        color: white;
        line-height: 30px;
        font-size: 24px !important;
        text-align: center;
        font-weight: 300;
        padding-right: 0px!important
      }
      .footer-white-section {
        background-color: #dddddd;
        padding: 36px 30px 36px 30px;
      }
      .footer-blue-section .title {
        color: white !important;
        margin-bottom: 0.2em !important;
        text-align: center;
        font-size: 32px!important;
        font-weight: 200;
      }
      .footer-blue-section .subtitle {
        color: white;
        text-align: center;
      }
      .footer-white-section .title {
        color: #4a4a4a !important;
        margin-bottom: 16px !important;
        font-size: 20px!important;
        font-weight: 300;
        margin-top: 20px;
      }
      .footer-white-section .subtitle {
        color: #4a4a4a !important;
        margin-bottom: 0em !important;
        font-size: 0.933em;
      }
      .footer-white-section img {
        max-width: 280px;
        // height: 144px;
        height: 102px;
        float: right;
      }
      .btn-outline-secondary {
        color: #4a4a4a;
        border-color: #4a4a4a;
        font-size: 10px!important;
        font-family: 'Roboto', sans-serif!important;
      }
      .vid-container-title {
        font-size: 24px;
        color: #4a4a4a !important;
        font-family: 'Roboto', sans-serif!important;
        margin-bottom: 10px;
        font-weight: 300;
      }
      .vid-container-subtitile {
        font-size: 14px;
        font-family: Roboto, Roboto;
        color: #4a4a4a !important;
        line-height: 1.2;
        display: flex;
        justify-content: center;
        font-weight: 300;
      }
      #hubspotForm{
        text-align: center;
        .hubspot-form-titile{
          font-size: 24px;
          color: #4a4a4a !important;
          font-family: 'Roboto', sans-serif!important;
          font-weight: 300;
          text-align: center;
        }
        .schedule-demo-btn{
          border-radius: 30px !important;
          font-size: 10px !important;
          padding: 7px 20px !important;
          width: 100%;
          margin: 10px 0;
          font-weight: 600 !important;
          color: #fff !important;
          letter-spacing: 0.1em;
          background-color: #56a9e8;
          border-color: #56a9e8;
        }
      }
      .footer-grey-section {
        padding: 36px 15px 60px 15px;
        .grey {
          flex-direction: column;
          text-align: center;

        }
      }
    }
  }
  @media (min-width:481px) and (max-width:574px){
    #landing-page {
      .inner-addon{
        min-width: 100%;
      }
      .marq-container{
        min-width: 100%;
      }
      .user-profile.dropdown{
        padding-left: 0px!important;
        padding-top: 0px!important;
        .user-menu__name-badge {
          background: #585757;
          color: #fff;
          width: 100%!important;
          position: relative;
          margin-bottom: 8px;
          &::before{
            position: absolute;
            display: block;
            content: '\F107';
            border-color: transparent;
            border-style: solid;
            border-width: 0 0.5rem 0.5rem;
            top: 7px;
            right: 81px;
            font-family: 'Font Awesome 5 Pro';
          }
        }
        ul.dropdown-menu.show{
          position: relative!important;
          top: 0px!important;
          background: transparent;
          border: 0;
          left: auto;
          right: auto;
          margin-top: -36px;
          transition: all ease-in-out .3s;
          .user-menu__item{
            color:#fff;
            border-bottom: 0px;
            text-align: center;
            min-height: 40px;
            span{
              font-weight: 500;
              font-size: 15px;
              color: #fff;
              padding: 5px 20px;
              width: 100%;
            }
            a{
              font-weight: 500;
              font-size: 15px;
              color: #fff;
              padding: 5px 20px;
              width: 100%;
              .far{
                display: none;
              }
            }
          }
          &:after,&:before{
            display: none;
          }
        }
        #hubspotForm{
          .hubspot-form-titile{
            font-size: 36px;
            font-family: Roboto, Roboto;
            color: #4a4a4a !important;
            line-height: 1.2;
            display: flex;
            justify-content: center;
            font-weight: 300;
            text-align: center;
          }
          .schedule-demo-btn{
            border-radius: 30px !important;
            font-size: 10px !important;
            padding: 7px 20px !important;
            margin: 10px 0;
            font-weight: 600 !important;
            color: #fff !important;
            letter-spacing: 0.1em;
            background-color: #56a9e8;
            border-color: #56a9e8;
          }
        }


      }
      .banner {
        padding: 0px 0px!important;
      }
      .header {
        padding: 0 36px !important;
      }
      .header-background {

        margin-top: 0px !important;
        padding: 0 36px !important;
        padding-top: 15px !important;
      }
      .banner-items{
        padding:0px 36px;
        padding-top:70px;
        h1{
          font-size: 36px!important;
        }
      }
      .landing-form {
        padding: 0px 36px;
        #hubspotForm{
          .hubspot-form-titile{
            font-size: 36px;
            font-family: Roboto, Roboto;
            color: #4a4a4a !important;
            line-height: 1.2;
            display: flex;
            justify-content: center;
            font-weight: 300;
            text-align: center;
          }
          .schedule-demo-btn{
            border-radius: 30px !important;
            font-size: 10px !important;
            padding: 7px 20px !important;
            margin: 10px 0;
            font-weight: 600 !important;
            color: #fff !important;
            letter-spacing: 0.1em;
            background-color: #56a9e8;
            border-color: #56a9e8;
          }
        }
      }
      .trending-theme {
        padding: 0px 36px;
        margin-top: 50px;
        width: 100%;
        text-align: left;
        h1 {
          font-weight: 300;
          font-size: 24px!important;
        }

        .trending-theme-container > .row {
          justify-content: center;
        }
        // .trending-theme-card{
        //   height:209px;
        //   width:209px;
        // }
      }

      .popular-search {
        padding: 0px 36px;
        h1 {
          font-size: 24px !important;
          margin-bottom: 20px;
        }
        .popular-search-button {
          margin: 10px 5px 10px 0;
          max-width: calc(85% + 0px);
        }
      }
      .info-section {
        padding: 0px 36px;
        .info-section-divider {
          padding-bottom: 27px;
        }
        .section-row {
          margin-right: 0px !important;
          margin-left: 0px !important;
          flex-direction: column-reverse;
          .info-section-info {
            padding-left: 0px!important;
            &.pl-5 {
              padding-bottom: 40px;
            }
            h1 {
              font-size: 24px !important;
            }
          }
        }
      }

      .footer-blue-section {
        background-color: #56a9e8;
        padding: 72px 36px 80px 36px;
        h3 {
          padding-right: 0px;
          text-align: center;
        }
        .footer-column {
          text-align: center;
          .title {
            font-size: 32px !important;
            line-height: 36px;
          }
        }
      }
      .footer-grey-section {
        padding: 36px 30px 60px 30px;
        text-align: center;
        p {
          color: #fff;
          font-size: 13px;
          line-height: 1.4em;
          margin-bottom: 0px;
          padding: 27px 0;
          font-family: 'Open Sans', Roboto;
        }
      }
      .social_Icon{
        padding-bottom: 27px;
        .media-icon {
          margin-left: 9px;
          margin-right: 8px;
          height: 21px;
          width: 21px;
          cursor: pointer;
        }
      }

      .grey {
        flex-direction: column;
      }
      .vid-container{
        padding: 0px 36px;
        .vid-container-video {
          iframe {
            width: 100%;
          }
        }
      }

      .footer-column {
        padding: 0px !important;
        flex: 1 1 100%!important;
        max-width: 100%;
        padding-top: 18px!important;
      }
      .footer-image {
        img {
          // width: 100%;
          float: right;
          max-width: 444px;
        }
      }
      .footer-white-section {
        padding: 40px 36px 40px 36px;
        .title {
          margin-bottom: 18px !important;

        }
      }

    }
  }
  @media (min-width:575px) and (max-width:679px){
    #landing-page {
      .inner-addon{
        min-width: 100%;
      }
      .marq-container{
        min-width: 100%;
      }
      .user-profile.dropdown{
        padding-left: 0px!important;
        padding-top: 0px!important;
        .user-menu__name-badge {
          background: #585757;
          color: #fff;
          width: 100%!important;
          position: relative;
          margin-bottom: 8px;
          &::before{
            position: absolute;
            display: block;
            content: '\F107';
            border-color: transparent;
            border-style: solid;
            border-width: 0 0.5rem 0.5rem;
            top: 7px;
            right: 81px;
            font-family: 'Font Awesome 5 Pro';
          }
        }
        ul.dropdown-menu.show{
          position: relative!important;
          top: 0px!important;
          background: transparent;
          border: 0;
          left: auto;
          right: auto;
          margin-top: -36px;
          transition: all ease-in-out .3s;
          .user-menu__item{
            color:#fff;
            border-bottom: 0px;
            text-align: center;
            min-height: 40px;
            span{
              font-weight: 500;
              font-size: 15px;
              color: #fff;
              padding: 5px 20px;
              width: 100%;
            }
            a{
              font-weight: 500;
              font-size: 15px;
              color: #fff;
              padding: 5px 20px;
              width: 100%;
              .far{
                display: none;
              }
            }
          }
          &:after,&:before{
            display: none;
          }
        }


      }
      .banner {
        padding: 0px 0px!important;
      }
      .header {
        padding: 0 36px !important;
      }
      .header-background {

        margin-top: 0px !important;
        padding: 0 36px !important;
        padding-top: 15px !important;
      }
      .banner-items{
        padding:0px 36px;
        padding-top:70px;
        h1{
          font-size: 36px!important;
        }
      }
      .landing-form {
        padding: 0px 36px;
        #hubspotForm{
          iframe{
            width: 100%!important;
          }
        }
      }
      .trending-theme {
        padding: 0px 36px;
        margin-top: 50px;
        width: 100%;
        text-align: left;
        h1 {
          font-weight: 300;
          font-size: 24px!important;
        }

        .trending-theme-container > .row {
          justify-content: center;
        }
        // .trending-theme-card{
        //   height:209px;
        //   width:209px;
        // }
      }
      .popular-search {
        padding: 0px 36px 0px 36px !important;
        h1 {
          font-size: 24px !important;
          margin-bottom: 36px;
          line-height: 1.5;
        }
        .popular-search-button {
          max-width: calc(90% + 20px);
        }
      }
      .info-section {
        padding: 0px 36px;
        .info-section-divider {
          padding-bottom: 27px;
        }
        .section-row {
          margin-right: 0px !important;
          margin-left: 0px !important;
          flex-direction: column-reverse;
          .info-section-info {
            padding-left: 0px!important;
            &.pl-5 {
              padding-bottom: 40px;
            }
            h1 {
              font-size: 24px !important;
            }
          }
        }
      }

      .footer-blue-section {
        background-color: #56a9e8;
        padding: 72px 36px 80px 36px;
        h3 {
          padding-right: 0px;
          text-align: center;
        }
        .footer-column {
          text-align: center;
          .title {
            font-size: 32px !important;
            line-height: 36px;
          }
        }
      }
      .footer-grey-section {
        padding: 36px 30px 60px 30px;
        text-align: center;
        p {
          color: #fff;
          font-size: 13px;
          line-height: 1.4em;
          margin-bottom: 0px;
          padding: 27px 0;
          font-family: 'Open Sans', Roboto;
        }
      }
      .social_Icon{
        padding-bottom: 27px;
        .media-icon {
          margin-left: 9px;
          margin-right: 8px;
          height: 21px;
          width: 21px;
          cursor: pointer;
        }
      }

      .grey {
        flex-direction: column;
      }
      .vid-container{
        padding: 0px 36px;
        .vid-container-video {
          iframe {
            width: 100%;
          }
        }
      }

      .footer-column {
        padding: 0px !important;
        flex: 1 1 100%!important;
        max-width: 100%;
        padding-top: 18px!important;
      }
      .footer-image {
        img {
          // width: 100%;
          float: right;
          max-width: 444px;
        }
      }
      .footer-white-section {
        padding: 40px 36px 40px 36px;
        .title {
          margin-bottom: 18px !important;

        }
      }

    }
  }
  @media (min-width:680px) and (max-width:767px){
    #landing-page {

      .banner {
        padding: 0px 0px!important;
      }
      .header {
        padding: 0 36px !important;
      }
      .header-background {

        margin-top: 0px !important;
        padding: 0 36px !important;
        padding-top: 15px !important;
      }
      .banner-items{
        padding:0px 36px;
        padding-top:70px;

      }
      .landing-form {
        padding: 0px 36px;
        #hubspotForm{
          iframe{
            width: 100%!important;
          }
        }
      }
      .popular-search {
        padding: 0px 36px 0px 36px !important;
        h1 {
          font-size: 24px !important;
          margin-bottom: 36px;
          line-height: 1.5;
        }
        .popular-search-button {
          max-width: calc(90% + 20px);
        }
      }
      .info-section {
        padding: 0px 36px;

        .info-section-divider {
          padding-bottom: 27px;
        }
        .section-row {
          margin-right: 0px !important;
          margin-left: 0px !important;
          flex-direction: column-reverse;

          .info-section-info {
            padding-left: 0px!important;
            &.pl-5 {
              padding-bottom: 40px;
            }
            h1 {
              font-size: 24px !important;
            }
          }
        }
      }

      .footer-blue-section {
        background-color: #56a9e8;
        padding: 72px 36px 80px 36px;
        h3 {
          padding-right: 0px;
          text-align: center;
        }
        .footer-column {
          text-align: center;
          .title {
            font-size: 32px !important;
            line-height: 36px;
          }
        }
      }
      .footer-grey-section {
        padding: 36px 30px 60px 30px;
        text-align: center;
        p {
          color: #fff;
          font-size: 13px;
          line-height: 1.4em;
          margin-bottom: 0px;
          padding: 27px 0;
          font-family: 'Open Sans', Roboto;
        }
      }
      .social_Icon{
        padding-bottom: 27px;
        .media-icon {
          margin-left: 9px;
          margin-right: 8px;
          height: 21px;
          width: 21px;
          cursor: pointer;
        }
      }

      .grey {
        flex-direction: column;
      }
      .vid-container{
        padding: 0px 36px;
        .vid-container-video {
          iframe {
            width: 100%;
          }
        }
      }

      .footer-column {
        padding: 0px !important;
        flex: 1 1 100%!important;
        max-width: 100%;
        padding-top: 18px!important;
      }
      .footer-image {
        img {
          // width: 100%;
          float: right;
          max-width: 444px;
        }
      }
      .footer-white-section {
        padding: 40px 36px 40px 36px;
        .title {
          margin-bottom: 18px !important;

        }
      }

    }
  }
  @media (min-width:768px) and (max-width:1023px){
    #landing-page {
      .user-profile.dropdown{
        padding-left: 0px!important;
        padding-top: 0px!important;
        .user-menu__name-badge {
          background: #585757;
          color: #fff;
          width: 100%!important;
          position: relative;
          margin-bottom: 8px;
          display: block;
          &::before{
            position: absolute;
            display: block;
            content: '\F107';
            border-color: transparent;
            border-style: solid;
            border-width: 0 0.5rem 0.5rem;
            top: 7px;
            right: 8px;
            font-family: 'Font Awesome 5 Pro';
          }
        }
        ul.dropdown-menu.show{
          position: relative!important;
          top: 0px!important;
          background: transparent;
          border: 0;
          left: auto;
          right: auto;
          margin-top: -36px;
          transition: all ease-in-out .3s;
          .user-menu__item{
            color:#fff;
            border-bottom: 0px;
            text-align: left;
            min-height: 40px;
            span{
              font-weight: 500;
              font-size: 15px;
              color: #fff;
              padding: 5px 20px;
              width: 100%;
            }
            a{
              font-weight: 500;
              font-size: 15px;
              color: #fff;
              padding: 5px 20px;
              width: 100%;
              .far{
                display: none;
              }
            }
          }
          &:after,&:before{
            display: none;
          }
        }


      }
      .banner {
        padding: 0px 0px!important;
        min-height: 478px!important;
      }
      .header {
        padding: 0 36px !important;
        #close-icon {
          padding: 0px;
          width: 22px;
        }
      }
      .header-background {

        margin-top: 0px !important;
        padding: 0 36px !important;
        padding-top: 15px !important;
      }
      .banner-items{
        padding:0px 36px;
        padding-top:80px;
        max-width: 1128px;
        h1{
          font-size: 32px!important;
        }
        h4{
          margin-top: -1px!important;
        }
        .inner-addon {
          form {
            margin-top: 88px;
          }
        }
      }
      .info-section {
        padding: 0px 36px;

        .info-section-divider {
          padding-bottom: 27px;
        }
        .section-row {
          margin-right: 0px !important;
          margin-left: 0px !important;
          flex-direction: column-reverse;
          .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
            position: relative;
            width: 100%;
          }
          .info-section-info {
            padding-left: 0px!important;
            &.pl-5 {
              padding-bottom: 40px;
            }
            h1 {
              font-size: 24px !important;
            }
          }
        }
      }
      .footer-grey-section {
        padding: 26px 36px 60px 36px!important;
      }
    }
  }
  @media (min-width:1024px) and (max-width:1199px){

    #landing-page {

      .banner {
        padding: 0px 0px!important;
      }
      .header {
        padding: 0 36px !important;
        margin-top: 20px!important;

      }
      .header-brand {
        margin-right: auto !important;
        padding: 0px !important;
      }
      .header-background {

        margin-top: 0px !important;
        padding: 0 36px !important;
        padding-top: 15px !important;
      }
      .banner-items{
        padding:0px 36px;
        padding-top:107px;
        max-width: 1128px;
        h1{
          font-size: 48px!important;
          // max-width: 70%;
        }
      }
      .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
        position: relative;
        width: auto;
      }
      .popular-search {
        padding: 0px 36px 0px 36px !important;
        margin: 0!important;
        h1{
          font-size: 36px !important;
        }
      }
      .trending-theme {
        // padding: 50px 36px;
        margin-top: 50px;
        width: 100%;
        max-width: 1128px;
        margin: auto;
      }
      .info-section {
        padding: 70px 36px;

        .info-section-divider {
          padding-bottom: 27px;
        }
        .section-row {
          margin-right: 0px !important;
          margin-left: 0px !important;
          flex-direction: row;
          .info-section-image {
            width: 90%;
          }
          .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
            position: relative;
            width: 100%;
            padding: 0px!important;
            flex: 1 1 50%!important;
            max-width: 50%;
          }
          .info-section-info {
            padding-left: 0px!important;
            &.pl-5 {
              padding-bottom: 40px;
            }
            h1 {
              font-size: 24px !important;
            }
          }
        }
      }
      // .trending-theme-card {
      //   height: 221px!important;
      //   width: 221px!important;
      // }
      .footer-grey-section {
        padding: 15px 36px 60px 36px;
        .grey {
          flex-direction: row;
        }
      }

      .footer-white-section {
        padding: 36px 36px 36px 36px;
        .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
          position: relative;
          width: 100%;
          padding: 0px!important;
          flex: 1 1 25%!important;
          max-width: 100%;
        }
      }
      .footer-blue-section{
        .footer-column {
          padding: 0px !important;
          flex: 1 1 25%!important;
          max-width: 100%;
          padding-top: 18px!important;
          padding-left: 50px!important;
          text-align: left;
          &:first-child{
            padding-left: 0px!important;
          }
          h3 {
            padding-right: 50px;
            text-align: left;
            font-size: 24px;
          }
          .title {
            font-size: 48px !important;
            line-height: 36px;
          }
          .subtitle{
            font-weight: 400!important;
          }
        }

      }

    }
  }
  @media (min-width:1200px) and (max-width:1300px){
    #landing-page{
      .banner {
        padding: 0px 36px !important;
      }
      .banner-items {
        max-width: 1128px;
      }
      .popular-search {
        padding: 0px !important;
        margin: 0 auto!important;
        h1{
          font-size: 36px !important;
        }
      }
      .footer-blue-section {
        background-color: #56a9e8;
        padding: 80px 36px 80px 36px;
        .blue {
          max-width: 1128px;
          margin: auto;
        }
      }
      .footer-white-section {
        background-color: #dddddd;
        padding: 80px 36px 80px 36px;
        .blue {
          max-width: 1128px;
          margin: auto;
        }
      }
      .footer-grey-section {
        background-color: #4a4a4a;
        padding: 26px 36px 60px 36px;
        #landing-page .grey {
          max-width: 1128px;
          margin: auto;
        }
      }
    }
  }

  @media (min-width: 1301px) and (max-width:1499px) {
    #landing-page {
      .header {
        margin-top: 12px !important;
        padding: 0px !important;
      }
      .container-navbar {
        max-width: 1128px!important;
      }
      .banner-items {
        max-width: 1128px!important;
      }
      .popular-search {
        padding: 0px;
        max-width: 1128px!important;
        margin: auto;
        h1{
          margin-bottom: 10px;
        }
      }
      .landing-form{
        max-width: 1128px!important;
        padding: 0px!important;
        margin: 62px auto 25px !important;
        #hubspotForm {
          max-width:828px;
          width: 100%;
          margin: auto;
        }
      }
      .info-section {
        max-width: 1128px!important;
        padding-top: 40px;
      }
      .blue {
        max-width: 1128px!important;
      }
      .grey {
        max-width: 1128px!important;
        flex-direction: row;
      }
      .trending-theme {
        max-width: 1128px!important;
        padding-top: 0px;
      }
      .footer-container {
        margin-top: 0px!important;
      }

    }

  }
  @media (min-width: 1500px) and (max-width:1699px) {
    #landing-page {
      .banner {
        min-height: 547px;
        .container-navbar{
          max-width: 1128px!important;
        }

      }
      .banner-items{
        padding-top: 140px;
        max-width: 1128px!important;
      }
      .landing-form{
        #hubspotForm{
          iframe{
            max-width: 1080px!important;
            margin: auto;
          }
        }
      }

    }
  }

  @media (min-width: 1700px) and (max-width:1920px) {
    #landing-page {
      .banner {
        min-height: 619px;
        .container-navbar{
          max-width: 1128px!important;
        }
      }
      .banner-items{
        padding-top: 177px;
        max-width: 1128px!important;
      }
      .landing-form{
        #hubspotForm{
          iframe{
            max-width: 1080px!important;
            margin: auto;
          }
        }
      }

    }
  }
  @media (min-width: 1921px) and (max-width:2560px) {
    #landing-page {
      .banner {
        min-height: 619px;
        .container-navbar{
          max-width: 1128px!important;
        }
      }
      .banner-items{
        padding-top: 177px;
        max-width: 1128px!important;
      }
      .landing-form{
        #hubspotForm{
          iframe{
            max-width: 1080px!important;
            margin: auto;
          }
        }
      }

    }
  }
