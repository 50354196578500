.modalFeature{
    max-width: 630px !important;
    .header-wrapper-modal{
        h1{
            font-size: 1.4rem !important;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            color: #1E5F91;
            font-family: 'Open Sans';
        }
    }
    .modal-body {
        padding: 3rem 1.5rem !important;
        .premium-feature-completed-close{
            font-size: 22px;
            right: 16px;
            top: 16px;
        }
    }
    .top-icon{
        margin-bottom: 16px;
        text-align: center;
        img{
            height: 50px;
        }
    }
    .modal_title_box{
        margin-bottom: 0px;
    }
    .premium-msg-modal .modal-body {
        padding: 2rem !important;
    }

    .custom-modal-body{
        padding-top: 30px;
        .form-container-act{
            text-align: center;
            .form-check {
              line-height: 1;
            }
            label{
                font-family: "Open Sans";
                font-size: 14px;
                color: #505050;
                line-height: 1.5;
            }
            a{
                color: #1E5F91;
                text-decoration: underline;
                &:hover{
                    color: #1E5F91;
                }
            }
            .form-check-input{
                // position: relative;
                max-width: 16px;
                outline: 1px solid 1px #1e5f91;
                // margin-right: 6px;
                top: -1px;
                height: 14px;
            }
            button{
                background: #1d6091;
                text-transform: uppercase;
                padding: 10px 112px;
                border-radius: 30px;
                margin-top: 15px
            }
        }
    }
}

@media (min-width: 1914px) and (max-width: 4000px) {
    .modalFeature{
        max-width: 858px !important;
        .header-wrapper-modal{
            h1{
                font-size: 32px !important;
            }
        }
        .modal-body {
            padding: 68px 62px !important;
            .premium-feature-completed-close{
                font-size: 30px;
                right: 24px;
                top: 24px;
            }
        }
        .custom-modal-body{
            .form-container-act{
                // text-align: center;
                // label{

                // }
                // a{
                //     color: #1E5F91;
                //     text-decoration: underline;
                //     &:hover{
                //         color: #1E5F91;
                //     }
                // }
                // .form-check-input{
                //     position: relative;
                //     max-width: 20px;
                //     outline: 1px solid 1px #1e5f91;
                //     margin-right: 6px;
                //     height: 15px;
                // }
                button{
                    margin-top: 20px;
                    padding: 17px 135px;
                    height: 56px;
                }
            }
        }
    }
}
