$background: #f7f7f7;
$border: #b7b7b7;
$text-color: #4a4a4a;
$text-color-highlighted: #56a9e8;
$fontRoboto: 'Roboto';
$fontOpenSans: 'Open Sans';
.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: $background;
  color: $text-color;
  font-size: 14px;
  .contactUsContainer{
    span{
      font-family: $fontOpenSans;
    }
  }
}

.link_account_point{
  position: absolute;
  right: -100px;
  font-family: Open Sans;
  color: #56a9e8 !important;
  font-weight: bold;
}
.link_account_point:hover{
  cursor: pointer;
}

.name {
  font-family: $fontRoboto;
  font-size: 30px;
  padding-bottom: 20px;
  text-transform: capitalize;
}

.subHeader {
  font-size: 22px;
  font-family: $fontRoboto;
  //margin-bottom: 10px;
}

.form {
  display: flex;
  flex-direction: column;
}

.dwnContainer{
  display: flex !important;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  i{
    color: #56a9e7;
  }
  &:disabled {
    color: #888888;
    cursor: not-allowed;
  }
}

.btnFlexEnd {
  display:flex!important;
  justify-content:flex-end !important;
}
.formGroup {
  display: flex;
  margin-bottom: 0 !important;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  font-size: 14px;
  padding: 8px 0;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  input:-internal-autofill-selected,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    font-size: 14px;
    box-shadow: none;
    -webkit-text-fill-color: $text-color-highlighted;
    -webkit-box-shadow: 0 0 0px 1000px $background inset;
  }

  .key {
    text-align: left;
    color: $text-color !important;
    margin-bottom: 0;
    font-family: $fontRoboto;
  }

  .value {
    outline: none;
    background: none;
    border: none;
    text-align: right;
    min-width: 80px;
    height: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    font-family: $fontOpenSans;
    color: $text-color-highlighted !important;

    * {
      margin-bottom: 0;
    }
  }

  .addressInfo {
    display: flex;
    flex-direction: column;

    .firstInfo {
      display: flex;
      margin-bottom: 10px;

    }

    .secInfo {
      display: flex;
      justify-content: flex-end;
      position: relative;
    }
  }

  .cursorPointer {
    cursor: pointer;
  }

  .cursorNotAllowed {
    cursor: not-allowed;
  }

  .AccIve {
    cursor: pointer;
    //width: 80px;
    text-align: right;
  }

  .genderSelection {
    display: flex;
    flex-direction: column;

    * label {
      margin-right: 0;
      padding-left: 0;
    }
  }

  .input {
    border: none;
    outline: none !important;
    background: none;
    text-align: right;
    margin: 0 0 0 10px;
    cursor: pointer;
    padding: 0 0 0 4px !important;
    height: 24px;
    font-family: $fontOpenSans;
    font-size: 14px;
    font-weight: 700;
    // width: 130px;
    width: 80px;
    display: flex;
    align-items: center;
    border-radius: 0;
    border-bottom: 0;
    color: $text-color-highlighted;
  }

  .input:hover {
    cursor: pointer;
  }

  .input:focus {
    outline: none;
    background: none;
    box-shadow: none;
    cursor: text;
    border-radius: 0;
    color: $text-color-highlighted;
    border-bottom: 1px solid $border;

  }

  .input2 {
    border: none;
    outline: none !important;
    background: none;
    text-align: right;
    margin: 0 0 0 10px;
    cursor: pointer;
    padding: 0 0 0 4px !important;
    height: 24px;
    font-family: $fontOpenSans;
    font-size: 14px;
    font-weight: 700;
    width: 130px;
    display: flex;
    align-items: center;
    border-radius: 0;
    border-bottom: 0;
    color: $text-color-highlighted;
  }

  .input2:hover {
    cursor: pointer;
  }

  .input2:focus {
    outline: none;
    background: none;
    box-shadow: none;
    cursor: text;
    border-radius: 0;
    color: $text-color-highlighted;
    border-bottom: 1px solid $border;

  }

  .antSelect {
    min-width: 150px;
    width: auto;
    max-width: 220px !important;
    padding: 0 !important;

    div {
      padding: 0 !important;
      background-color: transparent !important;

      span:nth-child(2) {
        padding: 0;
      }
    ;
    }
  }

  .aum {
    max-width: 250px !important;
  }

  .input:active {
    cursor: text;
  }

  .inputInvalid {
    border: none;
    border-bottom: 1px solid #A5685F !important;
    border-radius: 0;
    background-color: transparent;
    width: 160px;
    padding: 0;
    padding-right: 0 !important;
    height: 24px;
    text-align: right;
    font-weight: 700;
    color: $text-color;
    background-image: none !important;
    outline: none !important;
  }

  .inputInvalid:focus {
    outline: none !important;
  }

  .inputInvalid:active {
    cursor: text;
  }

  .baseInputClass {
    background: none;
    height: 24px;
    padding: 0;
    border: none;
    outline: none !important;
    font-size: 14px;
    box-shadow: none;
    color: $text-color-highlighted;
    font-family: $fontOpenSans;
    border-radius: 0;
    font-weight: 700;
  }

  .country {
    width: 120px;
    border-bottom: 1px solid $border;
  }

  .country:focus, .hearAboutUS:focus {
    outline: none;
    background: none;
    box-shadow: none;
  }

  .DOB {
    width: 130px;
    cursor: pointer;
    text-align: right;
    padding-right: 0;
    font-family: $fontOpenSans;

    * label {
      display: none;
    }
  }

  .hearAboutUS {
    width: 210px;
  }
}

.addressGroup {
  height: auto;
}

.divider {
  min-width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: $text-color;
  opacity: 0.4;
}

.update {
  align-self: flex-end;
  min-width: 150px;
  height: 26px;
  border-radius: 24px;
  padding: 0 18px;
  border: solid 1px $text-color-highlighted;
  margin-top: 20px;
  background: none;
  font-size: 14px;
  font-family: $fontRoboto;
  color: $text-color-highlighted;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  &:disabled {
    color: #888888;
    border: solid 1px #888888;
    cursor: not-allowed;
  }
}
.disabled {
  color: #888888;
  border: solid 1px #888888;
  cursor: not-allowed;
}

.updatePlaid{
  padding: 0 18px!important;
  border: solid 1px $text-color-highlighted!important;
  background: none!important;
  border-radius: 24px!important;
}

.requestAgainBtn{
  min-width: 100px;
  position: absolute;
  right: -112px;
  font-size: 10px;
  padding: 0 10px;
}

.formInvalidField{
  color: #a5685f;
}

.changePassword {
  text-align: center;
  display: flex;
  flex-direction: column;

  .title {
    margin: 20px 0;
    font-size: 30px;
    color: $text-color;
    text-align: center;
  }

  .formGroup {
    height: auto;
    display: flex;
    flex-direction: column;

    .input {
      border: 0;
      outline: none !important;
      background: none;
      text-align: left;
      margin: 0 0 10px 0;
      font-weight: normal;
      padding: 10px 0 !important;
      height: 30px;
      font-family: $fontOpenSans;
      font-size: 14px;
      width: 250px;
      border-radius: 0;
      border-bottom: 1px solid $border;
      color: $text-color;
      cursor: text;

    }

    .input:focus {
      outline: none;
      background: none;
      box-shadow: none;
      cursor: text;
      border-radius: 0;
      color: $text-color;
      border-bottom: 1px solid $border;
      box-shadow: none !important;
    }

    .input:active {
      cursor: text;
    }

    .inputInvalid {
      border: none;
      border-bottom: 1px solid #A5685F !important;
      border-radius: 0;
      background-color: transparent;
      padding: 10px 0;
      height: 24px;
      font-size: 14px;
      font-weight: normal;
      box-shadow: none;
      text-align: left;
      width: 250px;
      box-shadow: none !important;
      color: $text-color;
      outline: none !important;
    }

    .inputInvalid:focus {
      box-shadow: none !important;
      outline: none !important;
    }

    .inputInvalid:active {
      cursor: text;
      box-shadow: none;
    }
  }

  .update {
    width: auto;
    height: 34px;
    font-weight: 500;
    padding: 0 18px;
    font-family: $fontRoboto;
    font-size: 14px !important;
  }

}

.errorWithFeedback {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 10px;
}

.dropdownANT {
  div {
    div {
      &::-webkit-scrollbar {
        width: 4px !important;
        border-radius: 6px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        width: 4px !important;
        border-radius: 6px;
        background-color: $border;
      }
    }
  }
}

.toastSuccess {
  background-color: #d4edda !important;
  color: #155724;
}

.toastFail {
  background-color: #f8d7da !important;
  color: #721c24 !important;
}

.whiteBnkInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 16px;
  border: 1px solid #bebebe;
  border-radius: 4px;

  .header{
    font-size: 16px;
    font-weight: 600;
    color: #1A1E21;
    margin-bottom:10px ;
  }
  .key {
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }

  .bankinfo {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;

    .leftBox {
      width: 100%;
      position: relative;
      text-align: left;

      .bankHeding {
        padding: 8px 0;
        font-weight: 600;
      }

      .fromTo {
        width: 100%;
        position: relative;
        display: flex;
        align-items: flex-end;

        .from {
          padding-right: 15px;

          i {
            color: #56A9E8 !important;
            font-size: 18px;
          }
        }
      }

    }

    .rightBox {
      width: 100%;
      position: relative;
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      .btnActive {
        height: 30px;
        line-height: 30px;
        background: #56A9E8 !important;
        color: #fff;
        border-radius: 30px;
        border: 0px;
        padding: 0px 30px;
        cursor: default;
      }

      .close {
        color: #56A9E8 !important;
        font-size: 12px;
        right: -87px;
        top: 7px;
        position: absolute;
        cursor:pointer;
      }

      .created {
        margin-top: 6px;
        font-size: 12px;
      }
    }
  }

}

.recentBankTran {
  width: 100%;
  background-color: white;
  position: relative;
  padding: 16px;
  border: 1px solid #bebebe;
  border-radius: 4px;
  max-height: 609px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px !important;
    border-radius: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px !important;
    border-radius: 6px;
    background-color: #dbdbdb ;
  }
  .recentBankatop {
    display: flex;
    justify-content: space-between;

    .recentBankahead {
      font-weight: 600;
      width:60%;
    }
    .status {
      // color: #56A9E8 !important;
      font-weight: 600;
      width:20%;
      text-align:center;
    }

    .more {
      color: #56A9E8 !important;
      font-weight: 200;

    }
  }

  .recentBankaList {
    display: flex;
    align-items: center;
    padding: 10px 0;
    position:relative;

    justify-content: space-between;
    .left{
      display: flex;
      div{
        font-weight: 600;
        i{
          margin-right:6px;
        }
      }
      .green{
        color: #1a7166!important;
      }
      .red{
        color: #6e0303!important;
      }
    }
    .right{
      flex-direction: column;
      display: flex;
      text-align: right;
      color: #2f2f2f;
      font-size: 12px;
    }
    .depositRightBox {
      position: relative;
    }

    .closeDeposits {
      color: #56A9E8 !important;
      font-size: 12px;
      //right: -87px;
      //top: 18px;
      //position: absolute;
      cursor:pointer;
      i{
        padding: 0 6px;
      }
    }

  }


}

.borderBottom {
  border-bottom: 1px solid #e0e0e0;
}
.notFound {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 260px;
  align-items: center;
  justify-content: center;
}

.notFound * {
  margin-bottom: 10px;
}

.createButton {
  background: transparent;
  text-transform: uppercase;
  padding: 1px 15px;
  border: 1px solid #5eade9;;
  border-radius: 20px;
  color: #5eade9;
  font-weight: 600;
  cursor: pointer;
}

.viewTrBtn{

  button{
    margin-right: 8px;
    margin-bottom: 12px;
    padding: 4px 12px;
    border-radius: 15px;
    border: 1px solid #1b8be0;
    color: #1b8be0!important;
    background-color: white;
    cursor: pointer;
  }
  .active{
    background: #1b8be0!important;
    color: white!important;
  }
}
.tradingTable{
  text-align: center;
  background: white;
  font-family: 'Roboto', serif;
  width: 100%;
  border: 1px solid #bebebe;
  border-radius: 4px;
  max-height: 738px;
  overflow-y: auto;
  overflow-x: hidden;
  table{
    text-align: center;
    margin: 0!important;
    tbody{
      border-top:1px solid #e0e0e0;
    }
  }

  &::-webkit-scrollbar {
    width: 4px !important;
    border-radius: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px !important;
    border-radius: 6px;
    background-color: #dbdbdb ;
  }
}

.downloadSection{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .toggle{
    max-width: 240px;
    width: 300px!important;
    background-color: #f7f7f7 !important;
    border-radius: 0;
    color: #2f2f2f !important;
    border: none;
    text-align: start;
    border-bottom: 1px solid #2f2f2f;
    display: flex;
    box-shadow: none !important;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }
  .listDownload{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    .update{
      margin-top: 0px;
    }
    p{
      margin-bottom: 0px;
    }
  }
}
.commingSoon{
  position: relative;
  pointer-events:none;
  opacity: 0.6;
}
.commingSoon::before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #3131312b;
  z-index: 2;
}

.inActiveSettings{
  position: relative;
  pointer-events:none;
}
.inActiveSettings::before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #3131312b;
  z-index: 2;
}

.removePoliticalRelation{
  color: #6e0303!important;
  padding: 5px;
  position: absolute;
  right: -30px;
}

.addPoliticalRelation{
  margin-top: 10px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  background: #1b8be0;
  color: #fff;
}

.primaryButton {
  min-width: 150px;
  margin-right: 8px;
  height: 26px;
  border-radius: 24px;
  padding: 0 18px;
  border: solid 1px $text-color-highlighted;
  margin-top: 20px;
  background: none;
  font-size: 14px;
  font-family: $fontRoboto;
  background: $text-color-highlighted;
  color: #fff;
  font-weight: 500;
  &:disabled {
    color: #888888;
    border: solid 1px #888888;
    cursor: not-allowed;
  }
}

.secondaryButton {
  min-width: 150px;
  height: 26px;
  border-radius: 24px;
  padding: 0 18px;
  border: solid 1px $text-color-highlighted;
  margin-top: 20px;
  background: none;
  font-size: 14px;
  font-family: $fontRoboto;
  color: $text-color-highlighted;
  font-weight: 500;
  &:disabled {
    color: #888888;
    border: solid 1px #888888;
    cursor: not-allowed;
  }
}
.userInfo{
  display: flex;
  flex-direction: row;
  div{
    &:first-child{
      margin-right: 5px;
    }
  }
  label{
    font-size: 10px;
    color: #888888;
  }
  color: $text-color;
}

.pl_10 {
  padding-left: 10px;
}

.ach-trash {
  position: absolute;
  right: -30px;
}

.sectionHeader{
  display: flex;
  justify-content: space-between;
  align-items:center;
  cursor: pointer;
  margin-bottom: 10px;
}

.achPopup{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .close {
    position: absolute;
    top: -10px;
    right: -4px;
    font-size: 18px;
    opacity: .5;
    cursor: pointer;
  }
  .header {
    margin-top: 14px;
    font-size: 22px;
    text-align: center;
    font-family: 'Open Sans', serif;
    color: #2f2f2f;
    margin-bottom: 16px;
  }
  .confirm {
    background-color: #fff;
    font-family: 'Roboto', serif;
    color: #56a8e8;
    font-weight: 500;
    margin: 0 10px;
    font-size: 16px;
    width: 110px;
    border:1px solid #56a8e8;
    text-transform: uppercase;
    padding: 4px 10px;
    border-radius: 29px;
    height: 40px;
    cursor: pointer;
  }
  .cancel{
    background-color: #56a8e8;
    color: #fff;
  }
}
.PlaidBankSelectModal{
  display: flex;
  flex-direction: column;
  .header {
    margin-top: 14px;
    font-size: 22px;
    text-align: center;
    font-family: 'Open Sans', serif;
    color: #2f2f2f;
    margin-bottom: 16px;
  }
  h5{
    font-size: 18px!important;
    margin-bottom: 16px;
  }
  .actionButtonGroup{
    display: flex;
    margin-top: 20px;
    width: 100%;
    justify-content: center;
  }
  .confirm {
    margin: 0 10px;
    font-family: 'Roboto', serif;
    background-color: #56a8e8;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    width: 110px;
    text-transform: uppercase;
    padding: 4px 10px;
    border:1px solid #56a8e8;
    border-radius: 29px;
    cursor: pointer;
    &:disabled{
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  .cancel{
    background-color: #fff;
    color: #56a8e8;
  }
}
.checkboxInput {
  padding-right: 2px;
  width: 30px; 
}

.trustedContactContainer{
  width: 100%;
  position: relative;
  label{
    min-width: 150px;
  }
  .antSelect {
    min-width: auto;
  }
  .phoneContainer{
    .phone-input-container{
      .input-area{
        border-bottom-color: #c1c1c1 !important;
      }
    }
  }
  .input{
    width: 100%;
    margin-left: 0px;
    max-width: 100%!important;
    text-align: left;
    border-bottom: 1px solid #c1c1c1;
  }
  .inputInvalid{
    text-align: left;
    width: 100%;
    margin-left: 0px;
    max-width: 100%!important;
  }
  .ant-select-single{
    max-width: 100% !important;
  }
}

.trustedPerson{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.formBoxPanel{
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;

}
.formBox_view{
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  .rightPart{
    display: flex;
  }
}

.trustedData{
  display: flex;
  flex-direction: column;
}


.multipleInputs {
    border: none;
    border-radius: 0px!important;
    background-color:transparent!important;
    width: 100%;
    padding: 0;
    padding-right: 0!important;
    height: 40px;
    text-align: left;
    font-weight: 700;
    color: #4a4a4a;
    background-image: none!important;
    outline: none!important;
    box-shadow: none!important;
    border: 0px!important;
    border-bottom: 1px solid #c1c1c1!important;
  }
  .secInfo{
    display: flex;
    .addPoliticalRelation{
      margin-left: auto;

    }
  }

  .styledInput{
    color: #56a8e8 !important;
    &:focus{
      color: #4a4a4a !important;
    }
  }