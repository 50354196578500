$border-color-3: #D9DAD9;

.text-editor {
  .ql-toolbar {
    display: none;
  }
  .ql-container {
    font-size: 12px;
    font-family: 'Roboto';
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 15px;
    background: #fff;
    border-radius: 2px;
    border: 1px solid $border-color-3;
  }
  .ql-editor {
    height: 90px;
    max-height: 90px;
    &::-webkit-scrollbar {
      width: 15px !important;
      background-color: transparent;
      border-left: 1px solid #fff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 25px;
      background-color: #d2d2d2;
      border: 5px solid transparent;
      background-clip: content-box;
    }
    &:before {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
    }
  }
}
