$in-active-color: #dbdbdb;

.indexIconWrapper {
  min-width: 32px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: normal;
  color: #ffffff;
  background: #dbdbdb;
  border-radius: 50%;
}
