.cardListToolbarWrapper {
  // background: #F7F7F7 !important;
  .viewDropdownContainer {
    .view-text-container {
      .view-text-label {
        position: relative;
        z-index: 9;
      }
      .slick-slider {
        // width: 85px !important;
        .slick-slide {
          margin: 0 2px;
        }
        .slick-list .slick-track {
          display: flex;
          align-items: center;
        }
        .slick-arrow {
          font-size: inherit;
          color: #4a4a4a;
          line-height: 1.3;
        }
        .slick-disabled {
          color: #bababb;
          cursor: not-allowed;
        }
        .slick-prev {
          left: -18px !important;
        }
        .slick-next {
          right: -18px !important;
        }
      }
    }
    .viewDropdownWrapper {
      width: 100%;
      .viewDropDownList {
        width: 100%;
        padding: 5px 0 !important;
        border: 0 !important;
        background: transparent;
        margin: 0;
        // pointer-events: none;
        .ghost-child {
          width: 37px;
          height: 30px;
          top: -25px;
          left: 0;
          right: 0;
          z-index: 9999;
          margin: 0 auto;
          position: absolute;
          cursor: pointer;
        }
        .viewDropDownList-Child {
          padding: 5px 0px;
          min-width: max-content;
          text-align: center;
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 10px;
          background: #fff;
        }
      }
    }
    .viewBtn {
      width: 100%;
      margin: 0 !important;
      margin-top: -3px !important;
      padding: 0 !important;
      font-size: 12px !important;
      border: 0 !important;
    }
  }
}
