// Required

$content-height: calc(100vh - 60px);
$scroll-width: 6px;
$select-color: #e26b02;
$blue-color: #1d6091;
$head-color: #4a4a4a;
$active-color: #1e5f91;
$disabled-color: #bababb;
$background-color: #f7f7f7;
$primary-color: #55a9e8;

.enahncement-result-row-container {
  width: 100%;
}

.box-layout{
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e2e2e2;
  background-color: #f7f9fa;
}

.border-top-padding{
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #1e5f91;
  border-top: 1px solid #dee2e6;
  display: flex;
  justify-content: center;
  .cus-link-pointer{
    cursor: pointer;
  }
  div{
    display: flex;
    span{
      padding-top: 5px;
      padding-left: 5px;
    }
  }
}
.learn-more-action-layout{
  cursor: pointer;
  border-radius: 2px;
  border: solid 1px #e3e6e9;
  background-color: #f4faff;
  font-family: 'Roboto';
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #30608d;
  img{
    padding: 4px 4px 4px 6px;
  }
  span{
    padding: 4px 6px 4px 0px;
  }
}


.sponsored-layout{
  border-radius: 4px;
  background-color: #4a4a4a;
  margin-top: 4px;
  line-height: 1.3;
  display: inline-flex;
}
.sponsored-text{
  padding: 2px 6px;
  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.32px;
  text-align: left;
  color: #fff;
}

.enhancer-fund-card {
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.25rem;
}

.enhance-result-page-container .excluded-fund-list-wrapper {
  .excluded-fund-card-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 25%;
    // max-width: 250px;
    margin-right: 20px;
    margin: 0 !important;
    padding: 0 !important;
    .excluded-fund-card-label-wrapper {
      width: 250px;
      height: 100%;
      height: 30px;
      margin: 0 auto 16px auto;
      .excluded-fund-card-label {
        width: max-content;
        position: relative;
        padding: 2px 0px;
        font-family: Roboto;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.19px;
        text-align: left;
        color: $head-color;
        box-sizing: border-box;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom: 2px solid $active-color;
      }
      .fa-plus {
        position: absolute;
        top: 3px;
        bottom: 0;
        right: 0;
        width: 22px;
        height: 22px;
        padding: 3px;
        font-size: 14px;
        text-align: center;
        color: $blue-color;
        background-color: #ffffff;
        border-radius: 50%;
        border: solid 1px $blue-color;
        cursor: pointer;
      }
    }

    .static-card-body {
      background: transparent;
      border: 0;
      .ant-card-body {
        padding: 0 !important;
      }
    }
  }
  .itemlist-carousel-wrap {
    width: 100%;
    position: relative;
    padding-right: 5px;
    padding-bottom: 17px;
    .slick-slider {
      .slick-list {
        .slick-slide {
          // min-width: 270px;
          .enhancer-fund-card {

            .ant-card-body {
              padding: 0 !important;
              .ant-checkbox-wrapper {

              }
            }
          }
        }
      }
      .slick-arrow {
        // z-index: 9;
        font-size: inherit;
        color: $primary-color;
      }
      .slick-prev {
        left: -13px;
      }
      .slick-next {
        right: -13px;
      }
      .slick-disabled {
        color: $disabled-color;
      }
      .slick-dots {
        li button:hover, li button:focus {
          &:before {
            color: $disabled-color;
          }
        }
        li.slick-active button:before {
          color: $primary-color;
          transform: scale(1.5);
        }
      }
    }
  }
}

.itemlist-carousel-wrap {
  width: 100%;
  position: relative;
  padding-bottom: 17px;
  .slick-slider {
    .slick-list {
      .slick-slide {
        .enhancer-fund-card {
          position: relative;
          display: flex;
          flex-direction: column;
          min-width: 0;
          word-wrap: break-word;
          background-color: #fff;
          background-clip: border-box;
          border: 1px solid rgba(0, 0, 0, 0.125);
          border-radius: 0.25rem;
          .ant-card-body {
            padding: 0 !important;
            .ant-checkbox-wrapper {

            }
          }
        }
      }
    }
    .slick-arrow {
      // z-index: 9;
      font-size: inherit;
      color: $primary-color;
    }
    .slick-prev {
      left: -13px;
    }
    .slick-next {
      right: -13px;
    }
    .slick-disabled {
      color: $disabled-color;
    }
    .slick-dots {
      li button:hover, li button:focus {
        &:before {
          color: $disabled-color;
        }
      }
      li.slick-active button:before {
        color: $primary-color;
        transform: scale(1.5);
      }
    }
  }
}

.row.compact {
  margin-left: -.25rem;
  margin-right: -.25rem;
  &> [class*="col-"] {
    padding-left: .25rem;
    padding-right: .25rem;
  }
}

.p-top-right {
  margin: 0 !important;
  position: absolute !important;
  top: 4px;
  right: 0;
}

.pfUpload-container {
  .notapp-status {
    min-height: 90px;
    align-items: center;
    justify-content: center;
  }
}

.custom-control-label::after {
  cursor: pointer;
}

.contentWrapper {
  height: $content-height !important;
}
.contentContainer .custom-scroll {
  position: relative;
  height: $content-height;
  margin-right: -10px;
  padding-right: 6px;
}

.ps-wrap {
  width: 100%;
  position: relative;
  padding-bottom: 14px;
  .ps__rail-x {
    height: $scroll-width;
    opacity: 0.6;
  }
  .ps__thumb-x { bottom: 0px; }

  .ps__rail-x > .ps__thumb-x,
  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: #bababb;
    height: $scroll-width;
  }
}

.custom-checkbox {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $select-color ;
    background-color: $select-color;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba($select-color, 0.25);
  }

  .custom-control-input:not(:checked) ~ .custom-control-label::before,
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: lighten($select-color, 25%);
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    background-color: lighten($select-color, 35%);
    border-color: lighten($select-color, 35%);
  }
}

.pf-upload {
  .nav-pills .nav-link.active {
    background-color: $active-color;
  }
}

.card-background-grey {
  background-color: $background-color !important;
}

.no-funds-wrapper {
  height: calc(100vh - 200px) !important;
  margin: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaaaaa !important;
  font-size: 24px !important;
  span{
    font-weight: bold;
  }
  .ant-card-body{
    text-align: center;
  }
  img{
    padding-bottom: 36px;
  }
}

.enhance-result-page-container {
  width: 100%;
  margin-bottom: 10px !important;
  display: flex;

  .relevance-charts-tab-wrapper {
    height: 105px;
    .ant-tabs {
      .ant-tabs-content {
        .ant-tabs-tabpane {
          .siw-meter {
            margin: 8px 0 4px 0 !important;
            .siw-meter-title {
              display: none !important;
            }
          }
        }
      }
    }
    .ant-tabs-bar {
      margin: 0 !important;
      border: 0 !important;
    }
    .ant-tabs-nav-container {
      margin: 0 !important;
      display: flex;
      justify-content: space-between;
      .ant-tabs-nav-wrap {
        .ant-tabs-nav {
          .ant-tabs-ink-bar {
            height: 0 !important;
            background-color: #1e5f91;
          }
          .ant-tabs-tab {
            height: auto;
            padding: 0 0;
            font-size: 10px;
            font-family: Roboto;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.19px;
            text-align: left;
            color: #bababb;
            border-color: transparent;
            &:first-letter {
              text-transform: capitalize
            }
          }
          .ant-tabs-tab-active {
            color: #1e5f91;
            border-bottom: 2px solid #1e5f91;
          }
        }
      }
    }
  }

  .static-card-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 25%;
    max-width: 264px;
    min-width: 264px;
    margin-right: 20px;
    margin-right: 24px;
    padding: 4px 10px 4px 4px !important;
    .swap-icon-wrapper {
      position: absolute;
      top: calc(50% + 10px);
      left: 260px;
      font-size: 20px;
    }
    .static-card-label {
      width: max-content;
      max-width: 100%;
      height: 100%;
      height: 30px;
      padding: 2px 0px;
      margin: 0 0 16px 0;
      font-family: Roboto;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.19px;
      text-align: left;
      color: $head-color;
      position: relative;
      box-sizing: border-box;
      text-decoration: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: 2px solid $active-color;
    }
    .static-card-body {
      border: 0;
      background-color: $background-color;
      .ant-card-body {
        padding: 1.5rem;
        padding-left: 0;
        padding-right: 0;
      }
      .enhancer-fund-card {
        height: 312px;;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        .ant-card-body {
          padding: 0 !important;
          .ant-checkbox-wrapper {

          }
        }
      }
    }
  }

  .FRR-wrapper {
    .ant-col {
      .returns-flag-color {
        color: #3f7367 !important;
      }
      .risk-flag-color {
        color: #a5685f !important;
      }
      .FRR-container {
        color: #05487D;
        text-align: center;
        img {
          margin: 0 auto !important;
        }
      }
      &:nth-child(2) {
        padding: 0 2px;
      };
    }
  }

  .specify-custom-replacement-tab {
    .custom-dynamic-card-body {
      // height: 321px !important;
      .ant-card-body {
        height: 100%;
        // padding: 1rem !important;
        padding: 1.5rem 1rem 1.5rem 1rem !important;
      }
    }
  }

  #select-card {
    .ant-card-body {
      padding-bottom: 15px !important;
    }
  }

  .dynamic-card-wrapper {
    width: calc(100% - 290px) !important;
    max-width: calc(100% - 290px) !important;

    .dynamic-card-body {
      height: auto;
      min-height: 332px;
      .empty-message-wrapper {
        .ant-card-body {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1rem !important;
        }
        .text-box {
          font-family: Roboto;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.19px;
          text-align: center;
          .text-bold {
            font-weight: bold;
          }
        }
      }
      .enhancer-fund-card {
        //height: 285px;
        height: 312px;
        .ant-card-body {
          padding: 0 !important;
          .ant-checkbox-wrapper {

          }
        }
      }
      .ant-card-body {
        padding: 1.5rem 1rem 10px 1rem;
        .p-top-right {
          .custom-control-input {
            display: none !important;
          }
        }
        .ps-wrap {
          .pr-3 {
            &:last-child {
              padding-right: 0px !important;
            }
          }
        }
        .not-applicable-icon-wrapper {
          height: 66px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .dynamic-card-disable-scroll {
      position: static;
      overflow-x: scroll;
    }
    .dynamic-card-disable-scroll::-webkit-scrollbar {
      width: 2px !important;
      border-radius: 6px;
      background-color:#dbdbdb
    }

    .dynamic-card-disable-scroll::-webkit-scrollbar-thumb {
      width: 2px !important;
      border-radius: 6px;
      background-color: blue ;
    }
  }
  .card-tab-container {
    .relevance-charts-tab-wrapper {
      .ant-tabs-nav-container {
        display: flex;
        justify-content: space-between;
      }
    }

    .ant-tabs-bar {
      border: 0;
      margin-top: 5px;
    }
    .ant-tabs-nav-container {
      .ant-tabs-nav-wrap {
        .ant-tabs-nav {
          width: 100%;
          & > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
        .ant-tabs-tab {
          height: 30px;
          padding: 2px 0;
          font-family: Roboto;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.19px;
          text-align: left;
          color: $disabled-color;
          border-bottom: 3px solid $disabled-color;
        }
        .ant-tabs-tab-active {
          font-family: 'Roboto';
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.19px;
          text-align: left;
          color: $active-color;
          border-bottom: 3px solid $active-color;
        }
        .ant-tabs-ink-bar {
          z-index: 0;
          display: none !important;
          visibility: none !important;
          opacity: 0 !important;
          position: unset !important;
          background-color: $active-color;
        }
      }
    }

  }

}

.global-search-wrapper {
  //padding-right: 50px;
}

.global-search {
  width: 100%;
}

.global-search.ant-select-auto-complete .ant-select-selection--single {
  margin-right: -46px;
}

.global-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 62px;
  border: 0;
}

.global-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: white;
  color: gray;
  border: 0;
  box-shadow: none;
}

.global-search-item {
  display: flex;
}

.global-search-item-desc {
  flex: auto;
  text-overflow: ellipsis;
  overflow: hidden;
}

.global-search-item-count {
  flex: none;
}

input {
  border: 1px solid #999;
  padding: 0.5rem;
  width: 300px;
}

input-search {
  //border-color: ;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

// .suggestions {
//   //border: 1px solid #999;
//   border-top-width: 0;
//   list-style: none;
//   margin-top: 0;
//   max-height: 108px;
//   overflow-y: scroll;
//   padding-left: 0;
//   width: 43%;
//   position:absolute;
//   right: 76px;
//   text-align: left;
//   //width: calc(300px + 1rem);
// }

.suggestions {
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 108px;
  overflow-y: scroll;
  padding-left: 0;
  width: 125px;
  position:absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: left;
  font-size: 14px;
  font-family: 'Roboto';
  background: #fff;
  border: 1px solid #ced4da;
  border-top: 0;
  box-shadow: 0px 2px 12px 0px #ced4da;
}
/* /* To style the document scrollbar, remove `.custom-scrollbar` */
.suggestions::-webkit-scrollbar {
  width: 4px;
}
.suggestions::-webkit-scrollbar-track {
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  //border-radius: 10px;
}
.suggestions::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  //border-radius: 10px;
  background: #dbdbdb;
}

.suggestions li {
  padding: 0.5rem;
}

// .suggestion-active,
.suggestions li:hover {
  background-color: #dff1ff;
  //color:#1e5f91;
  cursor: pointer;
  font-weight: 500;
}

.suggestions li:not(:last-of-type) {
  //border-bottom: 1px solid #999;
}

.slick-track {
  margin-left: 0!important;
  margin-right: 0!important;
}

.text-muted.item-card-text{
  color: #005383!important;
  font-size: .875rem !important;
  margin: 0 !important;
}
