.ant-modal-root{
    .ant-modal-wrap{
        .ant-modal{
            width: 600px;
        }
    }
    .newProfile , .ant-modal-content{
        border-radius: unset!important;
    }

    .ant-modal-content{
        border-radius: unset!important;
        padding: 24px 24px 0px 24px;
        .ant-modal-body{
            padding: 8px 8px 0px 8px;
            line-height: 18px;
            letter-spacing: 1px;
            font-family: 'Roboto';
            &::-webkit-scrollbar{
                width: 15px !important;
                background-color: transparent;
                border-left: 1px solid #fff;
            }
            &::-webkit-scrollbar-thumb{
                border-radius: 25px;
                background-color: #d2d2d2;
                border: 5px solid transparent;
                background-clip: content-box;
            }
        }
    }
}
