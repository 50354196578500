// please use variables from layouts/_variables.scss

$c1 : #56a9e8;
$c2 : #4a4a4a;
$c3 : #bababb;
$c4 : #1e5f91;
$c5 : #f7f7f7;
$c6 : #343a40;
$c7 : #0056b3;
$c8 : #212529;
$universe-text-color: #494949;

.set-default-tooltip-discover {
  font-weight: 600;
  font-size: 14px;
  color: #1e5f91;
}

.magnifi-dashboard-container {
  width: 100%;
  height: 100%;
  margin: 0;
  color: $charcoal-black !important;
  .sidebar-container {
    height: 100%;
    padding-right: 15px;
    border-right: 1px solid #fff;
    .sideBarWrapper {
      padding: 22px 0;
      .loader {
        font-size: 8px;
      }
      .show {
        opacity: 1 !important;
        visibility: visible !important;
      }
      .hide {
        opacity: 0;
        visibility: hidden;
      }
      .element {
        padding-bottom: 20px;
        transition: all 0.3s;
        h2 {
          padding-bottom: 15px;
          margin: 0;
          font-family: Roboto;
          font-size: 20px !important;
          font-weight: bold;
          color: inherit;
          // line-height: 1.5;
        }
        .label {
          line-height: 1.5;
          font-family: Roboto;
          font-size: 18px;
          color: inherit;
          margin-bottom: 7px;
        }
        .sub-label {
          font-size: 0.7rem;
        }
        .searchInput {
          input {
            height: 40px;
            padding: 5px 40px 5px 10px;
            font-size: 15px;
            font-weight: normal;
            transition: all 0.3s;
            border-color: $c1;
            i {
              opacity: 1;
              color: $c1!important;
            }
            &::-ms-clear {
              display: none !important;
            }
          }
          .search-icon {
            font-size: 20px;
            right: 8px;
            color: $c1 !important;
          }
          .clear-icon {
            position: absolute;
            right: 40px;
            line-height: 35px;
            width: 15px;
            text-align: center;
            z-index: 9;
            opacity: 0.2;
            font-size: 15px;
            color: $c1 !important;
            transition: line-height .5s, width .5s, font-size .5s;
            &.active {
              opacity: 1;
              cursor: pointer;
            }
          }
          // @include respond(phone) {
          //   .clear-icon {
          //     line-height: 35px;
          //     width: 15px;
          //     text-align: center;
          //     right: 35px;
          //   }
          // }
          //
          // @include respond(tab){
          //   .clear-icon {
          //     line-height: 35px;
          //     width: 15px;
          //     text-align: center;
          //     right: 35px;
          //   }
          // }

          // @include respond(desktop){
          //   .clear-icon {
          //     line-height: 35px;
          //     width: 15px;
          //     text-align: center;
          //     right: 35px;
          //   }
          // }
        }
        .accounts-sidebar-notification-count {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          display: flex;
          align-items: center;
          cursor: pointer;
          .ant-badge-count {
            padding: 2px 4px;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            font-size: $font-size-15px;
            text-align: center;
            color: #fff;
            background: $primary-color;
            background-color: $primary-color;
          }
        }
        .quick-link-wrapper {
          display: flex;
          flex-wrap: wrap;
          margin-top: -6px;
          a.v2-quick-link {
            font-size: $font-size-16px;
            display: inline-flex;
            align-items: center;
            margin: 6px 25px 6px 0 !important;
            font-family: $primary-font;
            font-weight: 600;
            text-align: left;
            color: $c1;
            img {
              height:25px;
              margin-right:0.5rem;
            }
          }
          a:last-child {
            color: #1A1E21;
            opacity: 0.6;
            cursor: not-allowed;
          }
        }
      }
      .sidebar-scroll-content {
        height: calc(100vh - (340px));
        margin-right: -12px !important;
        padding-left: 0 !important;
        padding-right: 8px !important;
      }
    }
  }
  .dashboard-content-container {
    position: relative;
    padding: 22px 0px 15px 15px;
    // .show {
    //   display: block !important;
    //   visibility: visible !important;
    //   opacity: 1 !important;
    // }
    // .hide {
    //   display: none !important;
    //   visibility: hidden !important;
    //   opacity: 0 !important;
    // }
    .spinner-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #f7f7f7;
    }
    .spinnerMessage {
      margin: 50px;
      display: flex;
      flex-direction: column;
      vertical-align: middle;
      height: 55%;
      align-items: center;
      justify-content: center;
    }
    .custom-scroll {
      height: calc(100vh - 110px) !important;
      padding-right: 8px !important;
      padding-left: 0.4rem !important;
      padding-bottom: 0px !important;
      margin-right: -9px !important;
    }
    .account-page-home-wrapper {
      height: calc(100vh - 164px) !important;
      margin-bottom: 0;
      padding-right: 8px !important;
      &::-webkit-scrollbar {
        width: 4px !important;
        border-radius: 6px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        width: 4px !important;
        border-radius: 6px;
        background-color: $in-active-color ;
      }
    }
    .row.compact {
      margin-left: -.25rem;
      margin-right: -.25rem;
      &> [class*="col-"] {
        padding-left: .25rem;
        padding-right: .25rem;
      }
    }

    .bg-wave1 {
      background: no-repeat bottom url('/assets/images/wave-1.png') transparent;
      background-size: 100%;
    }
    .bg-wave2 {
      background: no-repeat bottom url('/assets/images/wave-2.png') transparent;
      background-size: 100%;
    }


    .tx-c1 { color: $c1; }
    .tx-c2 { color: $c2; }
    .tx-c3 { color: $c3; }
    .fw-b  { font-weight: bold; }
    .fw-n  { font-weight: normal; }

    .fs-l1 {
      font-size: 1.75rem !important;
    }
    .fs-m1 {
      font-size: 1rem !important;
    }
    .fs-s1 {
      font-size: .875rem !important;
    }
    .nav-pills .nav-link.active {
      background-color: $c4;
    }
    .header.dropdown, .btn-link {
      font-size: .875rem !important;
      color: $c1;
      font-family: 'Roboto', sans-serif !important;
    }
    .sfmodal-comb-drpdwn {
      font-size: 1rem !important;
      color: $c8;
      font-family: inherit !important;

      &:hover {
        color: $c7 !important;
      }
    }
    .sfmodal-date, .sfmodal-unit {
      font-size: .75rem !important;
      color: $c6;
      font-family: inherit !important;

      &:hover {
        color: $c7 !important;
      }
    }
    .mx-0p5 {
      margin-left: 0.125rem !important;
      margin-right: 0.125rem !important;
    }

    .bg-c5 { background-color: $c5 !important; }

    .ps-wrap {
      position: relative;
      // height: 484px;
      height: 265px;
      margin-right: 0;
      padding-left: 0;
      padding-bottom: 0 !important;
      // margin-bottom: 1rem !important;
      .ps__rail-y { width: 4px; }
      .ps__thumb-y { right: 0px; }
    }
  }

  // .summaryDrawerWrapper {
  //   z-index: 10;
  //
  //   .ant-drawer-right {
  //     .ant-drawer-content-wrapper {
  //       right: -14px !important;
  //     }
  //   }
  //   .summaryDrawerContainer {
  //     height: calc(100vh - 72px) !important;
  //   }
  //
  //   .ant-drawer-title {
  //     padding-left: 5px;
  //     font-size: 18px;
  //     line-height: 30px;
  //     @media only screen and (min-width: 1024px) and (max-width: 1439px) {
  //       font-size: 1rem !important;
  //     }
  //
  //     @include respond(tab) {
  //       font-size: 1rem !important;
  //     }
  //   }
  //   .ant-drawer-body {
  //     padding-top: 0;
  //     padding-bottom: 0;
  //   }
  //   .content-head-row {
  //     margin: 0 0 20px 0;
  //     padding: 0px 15px;
  //     @media only screen and (min-width: 1024px) and (max-width: 1439px) {
  //       margin: 0px 0px 5px !important;
  //     }
  //
  //     @include respond(tab) {
  //       margin: 0px 0px 5px !important;
  //     }
  //     .col-header-wrap {
  //       font-family: Roboto;
  //       font-size: 12px;
  //       font-weight: normal;
  //       font-stretch: normal;
  //       font-style: normal;
  //       line-height: 1.33;
  //       letter-spacing: -0.14px;
  //       text-align: left;
  //       color: #bababb;
  //       @media only screen and (min-width: 1024px) and (max-width: 1439px) {
  //         font-size: 0.6rem !important;
  //       }
  //       @include respond(tab) {
  //         font-size: 0.6rem !important;
  //       }
  //     }
  //   }
  //
  // }
  .summary-block {
    padding: 1rem 0 1rem;
    border-bottom: 1px solid $border-color-opacity;
    &:last-child {
      border-bottom: 0px;
    }
    &:first-child {
      padding-top: 0px;
    }
    .dark-active-color {
      color: $dark-active-color;
    }
    .element-wrap {
      .label {
        margin-bottom: 1rem;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: -0.22px;
        text-align: left;
        color: $primary-color;
      }
      .content-wrap {
        margin-bottom: 1rem;
        line-height: 1;
        .year {
          margin-bottom: 1rem;
          padding-left: 20px;
          padding-right: 20px;
          font-family: Roboto;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: -0.14px;
          text-align: left;
          color: $dark-active-color;
        }
        .tag-year {
          height: 18px;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: -0.19px;
          text-align: left;
          color: $sub-header-color;
        }
        .value-text {
          margin-top: -2px;
          margin-bottom: 0.6rem;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: -0.19px;
          text-align: left;

          .ant-tag {
            border-radius: 15px;
            padding-left: 20px;
            padding-right: 20px;
            margin-right: 20px;
          }
        }
        .row-bg-left {
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
        }
        .row-bg-right {
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
        }
      }
    }
  }

  //
  // .portfolio-impact-card {
  //   margin-bottom: 30px;
  //   padding: 15px;
  //   border-radius: 10px;
  //   box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  //   background-color: #fbfbfb;
  //   @media only screen and (min-width: 1024px) and (max-width: 1439px) {
  //     margin-bottom: 1rem !important;
  //     padding: 0.7rem !important;
  //   }
  //
  //   @include respond(tab) {
  //     margin-bottom: 1rem !important;
  //     padding: 0.7rem !important;
  //   }
  //
  //   .ant-row {
  //     margin: 0 0 20px 0;
  //     &:last-child {
  //       margin: 0 !important;
  //     }
  //     @media only screen and (min-width: 1024px) and (max-width: 1439px) {
  //       margin: 0 !important;
  //     }
  //
  //     @include respond(tab) {
  //       margin: 0 !important;
  //     }
  //     .ant-col {
  //       display: flex;
  //       height: 30px;
  //       align-items: center;
  //       justify-content: flex-start;
  //       font-family: Roboto;
  //       font-size: 18px;
  //       font-weight: 500;
  //       line-height: 1.33;
  //       letter-spacing: -0.22px;
  //       text-align: left;
  //       @media only screen and (min-width: 1024px) and (max-width: 1439px) {
  //         font-size: 0.8rem !important;
  //       }
  //
  //       @include respond(tab) {
  //         font-size: 0.8rem !important;
  //       }
  //       &:first-child {
  //         font-family: Roboto;
  //         font-size: 14px;
  //         font-weight: 500;
  //         line-height: 1.36;
  //         letter-spacing: -0.17px;
  //         text-align: left;
  //         color: $label-black-color;
  //         text-transform: uppercase;
  //         @media only screen and (min-width: 1024px) and (max-width: 1439px) {
  //           font-size: 0.6rem !important;
  //         }
  //
  //         @include respond(tab) {
  //           font-size: 0.6rem !important;
  //         }
  //       }
  //     }
  //   }
  //   .up-down-chart-txt{
  //     margin-top: 30px !important;
  //   }
  //   .up-down-capture-dropdown{
  //     position: absolute;
  //     right: 0;
  //     width: 150px;
  //     text-align: right;
  //     border: none!important;
  //     .ant-select-selector{
  //       background: transparent;
  //       border: none!important;
  //       font-size: 12px !important;
  //       box-shadow: none!important;
  //     }
  //     .ant-select-arrow{
  //       font-size: 9px !important;
  //       top: 51% !important;
  //     }
  //   }
  //   .ant-select-dropdown {
  //     @media only screen and (min-width: 1024px) and (max-width: 1439px) {
  //       font-size: 0.6rem !important;
  //     }
  //     @include respond(tab) {
  //       font-size: 0.6rem !important;
  //     }
  //     .ant-select-item {
  //       @media only screen and (min-width: 1024px) and (max-width: 1439px) {
  //         padding: 5px 6px !important;
  //         font-size: 0.6rem !important;
  //       }
  //       @include respond(tab) {
  //         padding: 5px 6px !important;
  //         font-size: 0.6rem !important;
  //       }
  //     }
  //   }
  //   &.return-card {
  //     .ant-select {
  //       height: 100%;
  //       @media only screen and (min-width: 1024px) and (max-width: 1439px) {
  //         font-size: 0.8rem !important;
  //       }
  //       @include respond(tab) {
  //         font-size: 0.8rem !important;
  //       }
  //
  //       .ant-select-selector {
  //         height: 100% !important;
  //         line-height: 1.33;
  //         padding-right: 20px;
  //         text-align: right;
  //         @media only screen and (min-width: 1024px) and (max-width: 1439px) {
  //           padding-left: 0 !important;
  //           font-size: 0.6rem !important;
  //         }
  //         @include respond(tab) {
  //           padding-left: 0 !important;
  //           font-size: 0.6rem !important;
  //         }
  //
  //         .ant-select-selection-search {
  //           line-height: 1.33 !important;
  //           height: 100% !important;
  //
  //           .ant-select-selection-search-input {
  //             height: inherit !important;
  //             opacity: 0;
  //           }
  //         }
  //         .ant-select-selection-item {
  //           height: 100% !important;
  //           padding: 0;
  //           line-height: 18px !important;
  //           @media only screen and (min-width: 1024px) and (max-width: 1439px) {
  //             line-height: 18px !important;
  //             padding: 0 !important;
  //           }
  //
  //           @include respond(tab) {
  //             line-height: 18px !important;
  //             padding: 0 !important;
  //           }
  //         }
  //         .ant-select-selection {
  //           height: 100%;
  //           border: 0;
  //           background: transparent;
  //           box-shadow: unset;
  //           font-size: 12px;
  //           border-bottom: 1.3px solid #bababb;
  //           border-radius: 0;
  //           .ant-select-selection__rendered {
  //             height: 100%;
  //             margin: 0;
  //             padding: 0px 22px 0px 3px;
  //             display: flex;
  //             align-items: center;
  //             justify-content: flex-start;
  //             line-height: normal;
  //             color: #1e5f91;
  //           }
  //         }
  //       }
  //       .ant-select-arrow {
  //         top: 50%;
  //         right: 0;
  //         @media only screen and (min-width: 1024px) and (max-width: 1439px) {
  //           top: 13px !important;
  //           right: 0;
  //           width: 8px;
  //           height: 8px;
  //           font-size: 0.45rem !important;
  //         }
  //         @include respond(tab) {
  //           top: 13px !important;
  //           right: 0;
  //           width: 8px;
  //           height: 8px;
  //           font-size: 0.45rem !important;
  //         }
  //       }
  //     }
  //   }
  // }
  // .barchart-block {
  //   width: 100%;
  //   height: 150px;
  // }
  //
  // .linechart-block {
  //   width: 100%;
  //   height: 100px;
  //   // margin: 15px 0 60px;
  // }
  //
  // .up-down-capture-dropdown {
  //   width: 100%;
  //   text-align: right;
  // }
  // .up-down-capture-menu{
  //   border-radius: 10px !important;
  //   *{
  //     &::-webkit-scrollbar {
  //       width: 4px !important;
  //       border-radius: 6px;
  //       background-color: transparent;
  //     }
  //
  //     &::-webkit-scrollbar-thumb {
  //       width: 4px !important;
  //       border-radius: 6px;
  //       background-color: $in-active-color ;
  //     }
  //   }
  // }
}
