
#fundDetailsElement {

}
.fundDetailsWrapper {
	.ant-drawer-content-wrapper {
		height: calc(95vh - 230px) !important;
		.mdrawer-body-wrapper {
			height: calc(95vh - 230px) !important;
		}
	}
}
