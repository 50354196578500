.ssf-datepicker {
  display: flex;
  align-items: center;

  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
}

.react-datepicker-popper{
  z-index: 3;
}

.hidden_datepicker{
  .react-datepicker-popper{
    display: none;
  }
}

.ssf-datepicker__label {
  position: absolute;
  right: 21px;
}

.ssf-datepicker__calender-icon {
  font-size: 20px;
  color: #55a9e8;
}

.ssf_dropdown_datepicker{
  display: block;

 .date_dopdown{
    height: 150px;
    min-width: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      width: 4px !important;
      border-radius: 6px;
      background-color: #dbdbdb ;
    }

 }

  .year_container{
    width: 35%;
  }

  .day_container{
    width: 25%;
  }

  .month_container{
    width: 30%;
  }
}
.ssf_dropdown_datepicker > div {
  display: inline-block;
  margin-right: 3%;
}
