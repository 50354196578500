

.password-process-modal-backdrop {
    display: block !important;
  }

  .password-process-modal {
    .modal-content {
      border: 0;
      border-radius: 15px;
      background-color: #fff;
      width: 100%;
    }
    .modal-body {
      padding: 2.5rem 1.5rem;
    }
  }

  .fs-1 {
    font-size: 1rem !important;
  }

  .text-align-center {
    text-align: center !important;
  }

  .text-align-left {
    text-align: left !important;
  }

  .justify-content-left {
    justify-content: left !important;
  }

  .password-process-modal-container {
    .password-completed-close {
      position: absolute;
      right: 1rem;
      top: 1rem;
      font-size: 1.5rem;
      color: #fff;
      cursor: pointer;
    }

    .password-completed-check {
      font-size: 6rem;
      text-align: center;
      color: #fff;
    }

    .header-wrapper {
      h1 {
        font-size: 1.7rem !important;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        color: #1e5f91;
        font-family: Roboto;
      }
      h2 {
        font-size: 13px !important;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #1e5f91;
      }
    }
    .content-wrapper {
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      margin-bottom: 1.5rem;
    }
    .cta-wrapper {
      flex-direction: row;
      align-items: normal;
      .btn {
        width: 100%;
        font-weight: 600;
        padding: 0 13px;
        text-transform: capitalize;
        border: 1px solid #56a9e8;
        color: #fff;
        background: #56a9e8!important;

        &:first-child {
          // margin-right: 20px;
          font-size: 14px!important;
          height: 32px!important;
        }
      }
      .btn:hover{
        box-shadow: none !important;
        background: #fff!important;
        color: #56a9e8!important;
      }
    }
  }

  @include respond(phone){
    .password-process-modal {
      .modal-content {

      }
      .modal-body {
        padding: 1rem;
      }
    }

    .modal-dialog-centered{
        justify-content: center !important;
    }

    .password-process-modal-container {
      .password-completed-close {

      }

      .password-completed-check {

      }

      .header-wrapper {
        h1 {
          font-size: 1.7rem !important;
        }
       
      }
      .content-wrapper {

      }
      .cta-wrapper {
        .btn {
          line-height: 1.2;

          &:first-child {
            // margin-right: 0.8rem;
          }
        }
      }
    }
  }
