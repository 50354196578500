@import "_shared_reports";

.act-report {

  // General Styles
  .rel-results{
    .result-row{
      align-items: center;
      justify-content: center;
      align-self: center;
      border-left: 1px solid #dbdbdb;
      border-right: 1px solid #dbdbdb;
      border-bottom: 1px solid #dbdbdb;
      &:first-child{
        border-top: 1px solid #dbdbdb;
      }
      .fund-symbol, .fund-name, .fund-gauge{
        font-family: 'Roboto'
      }
      &:nth-child(odd){
        background-color: #f7f7f7;
      }
    }
    &.grouped-results{
      .query-header{
        display: flex;
        align-items: center;
        height: 119px;
        border-bottom: 1px solid #dbdbdb;
        .pr-v2-sub-header{
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
      .result-row{
        &:nth-child(odd){
          background-color: inherit;
        }
        &.odd{
          background-color: #f7f7f7;
        }
      }
    }
  }
  .pr-v2-sub-header{
    color: $dark-blue;
    font-size: $lvl2-header-font-size !important;
    font-family: $lvl2-header-font-family;
  }
  .pr-v2-lvl3-header{
    color: $dark-blue;
    font-size: $lvl3-header-font-size !important;
    font-family: $lvl3-header-font-family;
  }
  .pr-v2-sec-title{
    h3{
      font-family: $lvl1-header-font-family;
      font-size: $lvl1-header-font-size !important;
      color: $dark-blue;
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }
  .pr-v2-sm-title{
    font-family: $lvl1-header-font-family;
    font-size: 1.3rem !important;
    font-weight: 400;
    margin-top: 10px;
    display: inline-block;
  }

  .fund-gauge{
    .siw-meter, .siw-pie-holder{
      padding-bottom: 8px;
    }
    .print-carouse-holder{
      min-height: 110px;
    }
    .print-wrapper .siw-carousel .siw-slide-label {
      font-size: .8rem;
      margin-left: -40px;
      width: 130px;
      display: inline-block;
    }
    .print-wrapper .siw-meter-title, .print-wrapper .siw-pie-title{
      font-size: 0.9rem;
    }
  }

  // Page specific styles
  .pr-v2-ex-summary{
    h4{
      margin-bottom: 15px;
      margin-top: 30px;
    }
  }

  .ex-sum-best-fund{
    p{
      margin: 30px 0px 10px;
    }
  }

  .pr-v2-relevant-funds{
    .attrBar-title{
      span{
        font-size: 1.2rem;
        color: $body-font-color;
        img {
          height: 30px;
        }
      }
      padding-top: 15px;
      padding-left: 10px;
    }
    .chart-wrap {
      height: 85px;
    }
    .chart-wrap-3x{
      height: 300px;
    }
    .bst-line-chart{
      padding: 40px;
      border: 1px solid #dbdbdb;
      .customized-legend{
        display: flex;
        .legend-item{
          span{
            display: flex;
            align-items: center;
            align-self: center;
            height: 20px;
            padding-left: 7px;
            margin-right: 20px;
          }
        }
      }
      svg.recharts-surface{
        width: auto;
      }
    }
    .inline-result{
      display: flex;
      width: 100%;
      height: 105px;
      .chart-title {
        min-width: 200px;
        height: 100%;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        padding:0px 10px;
        flex-direction: column;
        .attrBar-title{
          padding: 0px;
          margin: 0px;
          text-align: center;
          span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 0.9rem;
            .attr-name{
              font-weight: bold;
            }
            .attr-weight{
              font-size: 0.75rem;
              margin-top: 4px;
            }
            i{
              margin: 0 auto !important;
            }
            img{
              padding-bottom: 10px;
            }
          }
        }
        .date-range{
          font-size: 0.75rem;
          margin-top: 4px;
          text-align: center;
        }
      }
      .chart-wrap{
        width: 100%;
        height: 75px;
        display: flex;
        align-self: flex-end;
      }
    }
  }

  .pr-v2-perf-analytics{
    .border-lt-rt{
      border-left: 1px solid #dbdbdb;
      border-right: 1px solid #dbdbdb;
    }
    .border-rt{
      border-right: 1px solid #dbdbdb;
    }
    .perf-component-wrapper{
      margin-bottom: 35px;
      // border: 1px solid #dbdbdb;
    }
    .perf-table{
      font-size: 0.9rem;
      text-align: center;
      max-width: 100%;
      width: 100%;
      th{
        font-weight: normal;
        font-family: $lvl2-header-font-family;
        text-align: center;
        border-bottom: 1px solid #dbdbdb;
        padding: 5px 8px;
      }
      .perf-tbl-header{
        color: $dark-blue;
        font-size: 1.3rem;
        &:first-child{
          p{
            margin: 0;
            font-size: 14px;
          }
        }
        th{
          border: 1px solid #dbdbdb;
        }
      }
      .perf-tbl-sub-header{
        font-size: 1rem !important;
        th{
          min-width: 78px;
          .smallText{
            font-size: 12px;
          }
        }
      }
      tbody{
        border-bottom: 1px solid #dbdbdb;
        tr{
          height: 50px;
          // &:nth-child(-n+1) {
          //   background: #e7f3fc;
          // }
          td{
            &:first-child{
              color: inherit;
              text-align: left;
              padding: 6px 0 6px 10px;
              p{
                margin: 0;
                opacity: 0.5;
                font-size: 12px;
                line-height: 1.1;
              }
            }
            color: $dark-blue;
          }
        }
      }
    }
    // Upside Downside Table Styles
    .updown-table{
      font-size: 0.9rem;
      text-align: center;
      th{
        font-weight: normal;
        font-family: $lvl2-header-font-family;
        text-align: center;
        border-bottom: 1px solid #dbdbdb;
        padding: 5px 10px;
      }
      .updown-tbl-header{
        color: $dark-blue;
        font-size: 1.3rem;
        th{
          border: 1px solid #dbdbdb;
          width: 14%;
          .smallText{
            font-size: 12px;
          }
        }
      }
      .updown-tbl-sub-header{
        font-size: 1rem !important;
        th{
          min-width: 90px;
        }
      }
      tbody{
        border-bottom: 1px solid #dbdbdb;
        tr{
          height: 50px;
          // &:nth-child(-n+1) {
          //   background: #e7f3fc;
          // }
          td{
            &:first-child{
              color: inherit;
              text-align: left;
              padding: 6px 0 6px 10px;
            }
            color: $dark-blue;
          }
        }
      }
    }
    // Scatter Chart Styles
    .pa-chart-wrapper{
      background: #f5f5f5;
      padding: 25px 30px 15px 30px;
      .chart-header{
        color: $dark-blue;
        font-size: 1.4rem !important;
        font-family: $lvl1-header-font-family;
        margin-bottom: 22px;
      }
      .pa-scatter-chart-wrapper{
        font-family: $lvl2-header-font-family;
        text{
          fill: #666;
          font-size: 1rem;
        }
        .line-label{
          font-size: 0.8rem;
        }
        .recharts-legend-wrapper{
          height: calc(100% + 52px) !important;
          top: -52px !important;
          .scatter-legend-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
            margin-left: 35px;
            .axis-definition{
              display: flex;
              flex-direction: column;
              font-size: 1rem;
              .highlight{
                color: $dark-blue;
                font-size: 1.3rem;
                margin-left: 7px;
              }
            }
            .all-legends{
              display: flex;
              margin-bottom: 50px;
              .scatter-legend{
                list-style-type: none;
                padding: 0px;
                margin-right: 30px;
                margin-bottom: 0px;
                &:last-child{
                  margin-right: 0px;
                }
                .legend-item{
                  display: flex;
                  align-items: center;
                  margin-bottom: 15px;
                  font-size: 1rem;
                  &:last-child{
                    margin-bottom: 5px;
                  }
                  span{
                    line-height: 1;
                    font-size: 0.875rem;
                    &:first-child{
                      display: flex;
                      width: 10px;
                      margin-right: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .scatter-custom-legend-wrapper{
      display: flex;
      background: #f5f5f5;
      padding: 5px 30px 20px 30px;
      align-items: center;
      flex-wrap: wrap;
      .single-legend{
        display: flex;
        align-items: center;
        margin-right: 15px;
        .legend-dot{
          display: block;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          margin-right: 8px;
        }
        .legend-text{
          font-size: 1rem;
        }
      }
    }
    // Correlation Table Styles
    .correlation-table{
      font-size: 1rem;
      text-align: center;
      width: 100%;
      thead{
        th{
          font-weight: normal;
          font-family: $lvl2-header-font-family;
          text-align: center;
          border: 1px solid #dbdbdb;
          min-width: 65px;
          padding: 0px 5px;
        }
      }
      tbody{
        tr{
          td{
            border: 1px solid #dbdbdb;
            &:first-child{
              color: inherit;
            }
            color: #ffffff;
            min-width: 65px;
            padding: 0px 5px;
          }
        }
      }
      tr{
        height: 60px;
      }
    }
    // Factor Exposure Table Styles
    .factor-expo-tbl{
      .perf-tbl-sub-header{
        height: 78px;
      }
    }
    .fund-ret-sub-header{
      font-size: 0.9rem;
      margin-top: 5px;
      margin-bottom: 10px;
      display: block;
    }
  }

  .pr-v2-conclusion{
    p{
      font-size: 1.4rem;
    }
  }

  .asteriskDisclaimer{
    font-size: 14px;
    margin: 0;
    opacity: 0.7;
    font-family: 'Roboto', serif
  }

  .date-range-subheading{
    margin: -35px 0 35px 0;
    font-family: 'Roboto', serif;
    font-weight: 500;
  }

  .benchmark-bottom{
    background-color: #f7f7f7;
    display: flex;
    font-size: 11px;
    border: 1px solid #dbdbdb;
    border-top: 0;
    .blank-space{
      min-width: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0, 0, 0, 0.55);
    }
    .data-space{
      width: 100%;
      text-align: center;
      //font-size: 10px;
      color: rgba(0, 0, 0, 0.40);
      display: flex;
      padding: 10px 0;
      align-items: center;
      .data-column{
        display: flex;
        padding: 0 10px;
        align-items: center;
        justify-content: center;
        p{
          margin: 0;
          padding: 0;
        }
      }
    }
  }

}
