.modal-full-screen {
  .modal-dialog, .modal-content {
    height: 100%;
  }
  .modal-header {
    flex-shrink: 0;
  }

  .modal-body {
    overflow-y: auto;
    height: 100%;
    .content-wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      .tab-content {
        flex: 1;
        overflow-y: auto;
        display: flex;
        &>.tab-pane {
            flex: 1;
            width: 100%;
        }
        &>.tab-pane.active {
          display: flex;
        }
      }
    }
  }

  .page {
    display: flex;
    flex-direction: column;
    width: 100%;

    &> .page-content {
      flex: 1;
      display: flex;
      overflow-y: hidden;
    }

    &> .page-header {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.42;
      text-align: center;
      color: #707070;
      padding: 1.25rem 1rem .75rem;
    }
  }

  .accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    .accordion-card.active {
      flex: 1;
    }

    .accordion-collapse {
      flex: 1;
      overflow-y: auto;

      &> .card-body {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: .25rem 1rem .75rem;
        &> .main {
          flex: 1;
          overflow-y: auto;
        }
        &.p-0 > .main { padding: .25rem 1rem .75rem; }  /* else scroll-bar appear within card */
      }
    }
  }

  .accordion-card {
    box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #f5f5f5;
    border-radius: 10px 10px 0 0 !important;
    .card-header {
      background-color: transparent;
      border-color: transparent;
      color: #4a4a4a;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.36;
      text-align: center;
      padding: .75rem 1rem;
      .icon-tr {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    &.active {
      box-shadow: 0 -5px 10px 0 rgba(30, 95, 145, 0.4);
      background-color: #ffffff;
      .card-header {
        color: #1e5f91;
      }
    }
  }
  .accordion-collapse > .card-body > .footer {
    padding: .75rem;
  }
  .collapsing > .card-body > .footer {
    visibility: hidden;
  }

  .accordion > .card:not(.active) {
    overflow: visible;  // else dropdown popup hides behind other elems
  }

  // hacks for funds tab
  .modal-content .modal-body-ssf {
    height: 100% !important;
    max-height: 100% !important;
    display: flex;
    &> div { width: 100%; }
  }
  .modal-body .content-wrap .tab-content > .tab-pane > div {
    width: 100%;
  }
}
