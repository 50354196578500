.archiveFilters {
  .react-datepicker-wrapper {
    width: 188px!important;
    display: block!important;
    margin-bottom: 8px!important;
  }

  .picker {
    width: 188px!important;
    margin-bottom: 8px!important;
  }
  .react-datepicker-popper {
    width: 313px!important;
  }
}
