@import "./../../variables";

$breakpoint-tablet: 768px;
$breakpoint-lg: 968px;
$large-screen-width: 1128px;
$primary-color: #56a9e8;

.trending-searches{
  max-width: $large-screen-width;
  margin: auto;
  color: $primary-color;
  margin-top: 50px;
  @media screen and (max-width:1199px){
    padding: 0px 80px;
  }
  .inner-container{
    border: 1px solid $primary-color;
    width: 70%;
    @media screen and (max-width:1199px){
      width: 100%;
    }
    margin: auto;
    // background: #56a9e8;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    ul{
      margin-bottom: 0px;
      padding-left: 0px;
      li{
        font-size: 15px;
        text-align: center;
        margin-bottom: 4px;
        list-style-type: none;
        // &:hover{
        //   color: #56A9E8;
        // }
        @media(min-width: 320px) and (max-width: 767px){
          font-size: 12px;
        }
        cursor: pointer;
      }
    }
    .row{
      position: relative;
      // @media(min-width: 320px) and (max-width: $breakpoint-lg){
      //   .col-lg-6:last-child{
      //     margin-top: 20px
      //   }
      // }
      .seprator{
        background-color: $primary-color;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        max-width: 1px;
        border-left: 1px solid $primary-color;
        height: 90%;
        top: 0;
        bottom: 0;
        opacity: 0.42;
        @media screen and (max-width: $breakpoint-lg) {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            width: 100%;
            max-height: 2px;
            top: 0;
            bottom: 0;
        }
      }
    }
  }
  h1{
    text-align: center;
    font-weight: 300;
    font-size: 36px !important;
    font-family: Roboto, Roboto;
    line-height: 43px;
    margin-bottom: 20px;
    padding: 0;
    text-transform: capitalize;
    color: #4a4a4a;
    @media(min-width: 320px) and (max-width: 767px){
      font-weight: 300;
      font-size: 24px !important;
    }
  }
}