.learnMoreDropdown_M{
  .learn_more_item:hover{
    cursor: pointer;
  }
  .learn-more-menu{
    border-radius: 10px!important;
  }
  .learn-more-submenu{
    font-size: 12px !important;
  }
}
.learn-more-text-extpad{
  padding-right: 20px;
  .anticon {
    svg{
      height: 14px !important;
      width: 14px !important;
      margin-bottom: 5px ;
    }
  }
}

.learn-more-text{
  .anticon {
    svg{
      height: 14px !important;
      width: 14px !important;
      margin-bottom: 5px ;
    }
  }
}
.learnMoreDropdown{
  position: absolute;
  top: -5px;
  @media (max-width: 768px) {
    top : unset;
  }
  z-index: 1;
  right: 0;
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-end;
  // align-items: center;
  .toggler_button.dropdown-toggle{
    color: #1B8BE0;
    border: 0;
    background-color: transparent;
    padding: 0 8px;
    &:focus{
      box-shadow: none !important;
    }
    &:active{
      color: #1B8BE0 !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }
    &::after{
      vertical-align: 0.1em !important;
    }
  }
  .learn_more_item:hover{
    cursor: pointer;
  }
}
.popover-content-min-wrapper {
  .category-label {
    padding: 0;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.33;
    letter-spacing: -0.24px;
    text-align: left;
    color: #545b61;
    .category-key {

    }
    .category-value {

    }
  }
  .date-range-label {
    padding: 0;
    .date-range-key {
      font-size: 10px;
      font-weight: normal;
      line-height: 1.33;
      letter-spacing: -0.24px;
      text-align: left;
      color: #545b61;
    }
    .date-range-value {
      font-size: 10px;
      font-weight: normal;
      line-height: 1.33;
      letter-spacing: -0.24px;
      text-align: left;
      color: #545b61;
    }
  }
}

.card-Ad-Icon {
  position: absolute;
  // z-index: 99;
  top: 7px;
  right: 7px;
  width: auto;
  height: 18px;
  img {
    width: auto;
    height: 100%;
  }
}

.mgt-neg {
  margin-top: -15px;
}

.ant-dropdown-menu.select-option{
  border-radius: 10px !important;
  li{
    text-align: center !important;
  }
}

@include respond(desktop){
  .card-Ad-Icon {
    top: 7px;
    right: 7px;
    height: 14px;
  }
}

@include respond(tab){
  .card-Ad-Icon {
    top: 5px;
    right: 5px;
    height: 14px;
  }
}

@include respond(phone){
  .card-Ad-Icon {
    top: 7px;
    right: 7px;
    height: 18px;
  }
}

.col-grey {
  .tooltip-inner {
    background-color: #4A4A4A !important;
  }
  .arrow:before {
    border-right-color: #4A4A4A !important;
  }
}
.basket_underlying_holdings{
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    li{
      display: inline-block;
      width: 33%;
      padding: 0 2px;
      span{
        font-size: 12px;
        padding: 0 2px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }
  }
}
.basket-fund-container{
  .media-mycard-desc{
    div{
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .options-btn-body{
    img{
      height: 65px;
    }
  }
  .dark-blue-text{
    // margin: 0 0 8px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.12px;
    text-align: left;
    color: #1d6092;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }
  .external-link-label{
    height: 14px;
    margin: 0 8px 0 0;
    font-family: 'Open Sans';
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #e26a03;
    cursor: pointer;
    img{
      height: 12px;
    }
  }
}
