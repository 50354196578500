
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  // .client-portfolio-menu {
  //   .client-portfolio-menu-button {
  //     i {
  //       width: 15px !important;
  //       height: 15px !important;
  //       font-size: 15px !important;
  //     }
  //   }
  // }
  .sidebar-container{
    .sideBarWrapper{
      .searchInput {
        input {
          font-size: 12px !important;
        }
        .search-icon {
          font-size: 17px !important;
          right: 8px;
        }
      }
    }
  }

  .contentWrapper {
    top: 60px;
    .sideBarContainer {
      .sideBarWrapper {
        padding: 20px 5px;
        .loader {
          font-size: 8px;
        }
        .element {
          padding-bottom: 20px;
          .search-header-label {
            padding-top: 0;
            font-size: 15px !important;
            line-height: 1.8;
          }
          .label {
            font-size: 15px !important;
            .discover-dropdown {
              .multiple-calculator {
                .view-btn {
                  .down-arrow {
                    line-height: 1 !important;
                  }
                }
              }
              .settings-gear {
                .modal-trigger-btn {
                }
              }
              .selector-options {
              }
            }
          }
          .sub-label {
            font-size: 0.49rem !important;
          }
          .searchInput {
            input {
              font-size: 12px !important;
            }
            .search-icon {
              font-size: 17px !important;
              right: 8px;
            }
          }
          .tagWrapper {
            max-height: 98px !important;
            font-size: 13px !important;
            .tag {
              padding: 4px 12px !important;
              font-size: 10px !important;
            }
          }
          .popTheme-wrapper {

            &__item {

              & img {
              }
            }

            &__title {
              font-size: .9rem !important;
            }
          }
        }

        .sidebar-content {
          height: calc(100vh - (400px));
        }

        .universe-section__item {
          // width: 65px;
          width: 90px;

          .check-label{
            font-size: 10px;
            bottom: -3px;
            right: -36px;
          }

          .switch {
            height: 6px;
            width: 20px;
          }

          .slider:before{
            height: 12px;
            width: 12px;
          }
        }
      }
    }
    .contentContainer {
      position: relative;
      .spinnerMessage {

      }
      .topSectionContainer {
        padding: 20px 0;

        .investment-ranked-wrapper {
          width: 83%;
          min-width: 600px !important;
          .ranking-tag-wrapper {
            font-size: 14px !important;
          }
        }

        .user-similar-search-wrapper {
          width: 100% !important;
          max-width: 100% !important;
          .user-similar-search-label {
            font-size: 14px !important;
          }
          .user-similar-search-overflow-wrap {
            font-size: 14px !important;
            .didyoumean-text {
              font-size: 14px !important;
            }
            .user-similar-activate-btn {
              font-size: 10px;
            }
          }
        }

        .did-you-mean-overflow-wrap {
          width: 100% !important;
          font-size: 12px !important;
          .ranking-tag-wrapper {
            font-size: 12px !important;
            .black-tag {
              font-size: 8px !important;
            }
          }
        }

        #did-you-mean {
          .custom-scroll {
            height: auto !important;
            max-height: 53px !important;
            font-size: 10px !important;
            .didyoumean-text {
              font-size: 10px !important;
              &:before {
                height: 73%;
                top: 2px;
              }
            }
          }
        }
        .search-related-wrapper {
          .search-label {
            font-size: 15px !important;
          }
          .share-component {
            &__wrapper {
              height: 24px !important;
            }

            &__item {
              width: 18px !important;
              height: 18px !important;
              padding: 0px !important;
              font-size: 10px !important;
            }
          }
        }

        .toolbarWrapper {
          .sortButtonWrapper {
            .sortbutton {
              font-size: 10px !important;
              line-height: 1 !important;
            }
          }
          .filterButtonWrapper {
            .filterbutton {
              font-size: 10px !important;
              line-height: 1 !important;
            }
          }
          .filter-items-wrapper {
            .filter-items {
              font-size: 10px !important;
            }
          }
        }

      }
      .itemListContainer {
        padding: 10px 15px 0 15px;
        padding-left: 0;
        padding-right: 0;
        .itemListHeaderWrapper {
          .cardListToolbarWrapper {
            .container {
              max-width: unset;
              // min-height: 71px !important;
              padding: unset;

              .toolbar-wrapper-flex-center {
                .fees-returns-risk-header-container {
                  width: 100%;
                  display: flex;
                  // height: 64px !important;
                  padding-top: 6px;

                  .FRR-Label {
                    width: 100%;
                    height: 18px !important;
                    margin: 0 2px;
                    padding: 2px 5px;
                    font-size: 10px !important;
                    line-height: 1.3 !important;
                    .FRR_sort-trigger-wrap {

                    }
                    .sort-state-icon {
                      // right: -3px;
                      margin-top: 1px;
                      font-size: 14px;
                    }
                    .FRR_sort_dropdown_container {
                      .FRR_sort_dropdown_wrapper {
                        .FRR_sort_dropdown_menu-item {
                          font-size: 10px !important;
                          .FRR_sort_dropdown_menu-item-checkbox {
                            font-size: 10px !important;
                            span {
                              font-size: 10px !important;
                            }
                          }
                        }
                      }
                    }
                    .sort-options-wrap {
                      top: -4px;
                      right: -2px;
                      .sort-options-checkbox {
                        height: 7px;
                        i {
                          font-size: 10px !important;
                        }
                      }
                    }
                  }
                  .highlight {
                    // margin: 0;
                    // margin-left: 2px;
                    height: 18px !important;
                    font-size: 10px !important;
                    line-height: 1.3 !important;
                    color: #fff;
                    background: #4A4A4A;
                    transition: all 0.3s;
                  }
                }
              }
              .viewChartsColumn {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                min-height: unset !important;

                .viewDropdownContainer {
                  // width: 100%;
                  // height: auto;
                  // display: grid;

                  .viewDropdownWrapper {
                    // height: 18px;
                    margin-bottom: 0;
                    .viewBtn {
                      height: 22px;
                      margin-top: -10px !important;
                      font-size: 10px !important;
                      line-height: normal !important;
                    }
                    .viewDropDownList {
                      min-width: unset !important;
                      .viewDropDownList-Child {
                        font-size: 10px !important;
                        // min-width: unset !important;
                      }
                    }
                  }
                  .view-text-container {
                    height: 22px;
                    margin-top: 2px !important;

                    .slick-slider {
                      width: 104px !important;
                      .slick-slide {
                        margin: 0 2px;
                      }
                      .slick-list .slick-track {
                        display: flex;
                        align-items: center;
                      }
                      .slick-arrow {
                        font-size: inherit;
                        color: #4a4a4a;
                        line-height: 1.3;
                      }
                      .slick-disabled {
                        color: #bababb;
                      }
                      .slick-prev {
                        left: -13px !important;
                        top: 52% !important;
                        width: 14px;
                        height: 14px;
                        font-size: 11px;
                      }
                      .slick-next {
                        right: -13px !important;
                        top: 52% !important;
                        width: 14px;
                        height: 14px;
                        font-size: 11px;
                      }
                    }

                    .view-text-label {
                      margin: 0 !important;
                      // padding: 3px 5px !important;
                      font-size: 8px !important;
                      overflow-wrap: break-word;
                      &:first-child {
                        // margin-right: 2px !important;
                      }
                    }
                  }
                  .view-sub-title-container {
                    h6 {
                      font-size: 8px !important;
                      line-height: normal;
                      .dropdown {
                        button {
                          font-size: 10px !important;
                        }
                      }
                    }
                    .dropdown-menu {
                      min-width: unset !important;
                      .dropdown-item {
                        font-size: 10px !important;
                      }
                    }
                  }
                }
              }
              .date-range-slider {
                padding-right: 0px !important;
                .irs {
                  .irs-from, .irs-to, .irs-single {
                    font-size: 8px !important;
                  }
                }
                ul {
                  li {
                    font-size: 10px !important;
                    line-height: 1 !important;
                    button {
                      font-size: 10px !important;
                    }
                  }
                }
              }
            }
          }
        }
        .itemListArea {
          height: calc(90vh - (215px));
          padding-bottom: 0px !important;
          .itemCardWrapper {
            height: 125px;
            .itemCard {
              // height: 100%;
              .select-btn-box {
                .options-btn-body .btn-info-txt {
                  width: auto !important;
                  left: -8px !important;
                  font-size: 6px;
                  line-height: 1;
                }
                .options-btn-body .btn-info-txt.cart {
                  left: -10px !important;
                }
                .options-btn-body .clear {
                  left: 0px !important;
                }
                .options-btn-body .clear.cart {
                  left: 0px !important;
                }
                .options-btn-body .clear.builder-clear {
                  width: auto !important;
                  left: 0px !important;
                }

                button {
                  width: 22px !important;
                  height: 22px !important;
                  padding: 0 !important;
                  // padding: 5px !important;
                  font-size: 9px !important;
                  line-height: 1 !important;
                  img {
                    // position: relative;
                    width: 12px !important;
                    // right: 0;
                    // bottom: 0;
                  }
                }
                .retail-cta-card-button-wrapper {
                  width: 100%;
                  .buy-btn {
                    width: 52px !important;
                    min-width: unset !important;
                    height: 26px !important;
                  }
                  .cart-btn {
                    img {
                      width: 20px !important;
                    }
                    width: 52px !important;
                    min-width: unset !important;
                    height: 26px !important;
                  }
                }
                .vehicle-label {
                  font-size: 8px !important;
                }
                .options-btn-body {
                  .retail-cta-card-button-wrapper {
                    .buy-btn {
                      // width: unset !important;
                      // height: unset !important;
                      // padding: 5px 12px !important;
                      // margin-bottom: 5px !important;
                      // font-size: 9px !important;
                      // line-height: 1.07 !important;
                    }
                    .cart-btn {
                      cursor: pointer;
                      // &:hover {
                      //   img {
                      //     transform: scale(1.2);
                      //   }
                      // }
                    }
                  }
                }
              }
              .fund-ticker-box {
                .ticker-size {
                  font-size: 13px !important;
                }
                .text-info {
                  min-width: unset !important;
                  margin: 0 !important;
                  line-height: 20px !important;
                  .ticker-price {
                    font-size: 14px !important;
                  }
                }
              }
              .fund-name-box {
                h5 {
                  font-size: 12px !important;
                }
              }

              .recharts-wrapper {
                .recharts-tooltip-wrapper-bottom {
                  .ssf-tooltip-custom {
                    font-size: 10px;
                  }
                }
              }

              .feesReturnRiskWrapper {
                .search-fees-return-stats-padding {
                  height: 100%;
                  padding: 0;
                  // padding: 15px 0px 22px;
                }
                .search-risk-stats-padding {
                  height: 100%;
                  padding: 0;
                  // padding: 22px 0px;
                }
                .search-risk-stats-highlighting {
                  .fees-flag-color, .returns-flag-color, .risk-flag-color {
                    color: $tag-blue;
                  }
                }
                .w-100 {
                  display: grid;
                  height: 100%;

                  .fees-flag-color, .returns-flag-color, .risk-flag-color {
                    height: 100%;
                    margin-top: -6px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    img {
                      width: 20px;
                      margin-top: 7px !important;
                      margin-bottom: 6px !important;
                    }
                    i {
                      font-size: 14px;
                    }
                    .__val {
                      font-size: 9px !important;
                    }
                    .__state {
                      font-size: 8px !important;
                    }
                  }
                }

              }
              .chart-block {
                .primaryTextStyle {
                  left: 50%;
                  font-size: 9px !important;
                }
                .highlightTooltip {
                  font-size: 10px;
                }
                .guage-chart-wrapper {
                  .guage-chart-details {
                    .col {
                      font-size: 9px !important;
                    }
                  }
                }
                .flip-items {
                  li {
                    text-align: center;
                    h6 {
                      font-size: 10px !important;
                    }
                  }
                }
              }
            }

            // &:last-child {
            //   margin-bottom: 48px;
            // }
          }
          &::-webkit-scrollbar {
            // display: none !important;
            // width: 0 !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  #date-range-slider-wrap {
    margin-top: 9px !important;
  }
  .headerWrapper {
    nav {
      width: 100%;
      .container {
        margin: 0 !important;
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
        .logoWrapper {
          img {
            width: 100px !important;
          }
        }
        ul {
          li {
            .fa-user {
              font-size: 15px !important;
            }
          }
        }
        // .client-portfolio-menu {
        //   .client-portfolio-menu-button {
        //     i {
        //       width: 15px !important;
        //       height: 15px !important;
        //       font-size: 15px !important;
        //     }
        //   }
        // }
      }
    }
  }
  .itemListContainer {
    padding: 10px 15px 0 15px;
    padding-left: 0;
    padding-right: 0;
    .itemListHeaderWrapper {
      .cardListToolbarWrapper {
        .container {
          max-width: unset;
          min-height: 72px !important;
          padding: unset;

          .toolbar-wrapper-flex-center {
            .fees-returns-risk-header-container {
              width: 100%;
              display: flex;
              // height: 64px !important;
              padding-top: 6px;

              .FRR-Label {
                // width: 100%;
                // height: 18px !important;
                // margin: 0 2px;
                padding: 3px 0 !important;
                // font-size: 10px !important;
                // line-height: 1.3 !important;
                .FRR_sort-trigger-wrap {

                }
                .sort-state-icon {
                  // right: -3px;
                  font-size: 14px;
                }
                .FRR_sort_dropdown_container {
                  .FRR_sort_dropdown_wrapper {
                    .FRR_sort_dropdown_menu-item {
                      // font-size: 10px !important;
                      .FRR_sort_dropdown_menu-item-checkbox {
                        // font-size: 10px !important;
                        span {
                          // font-size: 10px !important;
                        }
                      }
                    }
                  }
                }
                .sort-options-wrap {
                  top: -4px;
                  right: -2px;
                  .sort-options-checkbox {
                    height: 7px;
                    i {
                      font-size: 10px !important;
                    }
                  }
                }
              }
              .highlight {
                // height: 18px !important;
                // font-size: 10px !important;
                // line-height: 1.3 !important;
                // color: #fff;
                // background: #4A4A4A;
                // transition: all 0.3s;
              }
            }
          }

        }
      }
    }

  }
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) {
  #date-range-slider-wrap {
    margin-top: 9px !important;
  }
  .headerWrapper {
    nav {
      width: 100%;
      .container {
        margin: 0 !important;
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
        .logoWrapper {
          img {
            width: 115px !important;
          }
        }
        ul {
          li {
            .fa-user {
              font-size: 15px !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  #date-range-slider-wrap {
    margin-top: 8px !important;
  }
  .fees-returns-risk-header-container .FRR-Label .sort-state-icon {
    margin-top: -3px;
  }
  .contentWrapper .contentContainer .itemListContainer .itemListArea .itemCardWrapper .itemCard .feesReturnRiskWrapper .search-risk-stats-padding {
    // padding: 36px 0px !important;
  }
  .contentWrapper .contentContainer .itemListContainer .itemListHeaderWrapper .cardListToolbarWrapper .container .viewChartsColumn .viewDropdownContainer .view-text-container {

    .slick-slider {

      .slick-slide {
        margin: 0 2px;
      }
      .slick-list .slick-track {

      }
      .slick-arrow {

      }
      .slick-disabled {

      }
      .slick-prev {
        left: -11px !important;
        z-index: 99;
      }
      .slick-next {
        right: -11px !important;
        z-index: 99;
      }
    }
  }
  #date-range-slider-wrap {
    margin-left: 10px;
  }
  .basket-fund-container{
    .media-mycard-desc{
      div{
        padding-right: 18px;
      }
    }
    .options-btn-body{
      img{
        height: 56px;
      }
    }
    .dark-blue-text{
      font-size: 10px;
    }
    .external-link-label{
      font-size: 8px;
      img{
        height: 9px;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .headerWrapper {
    nav {
      // width: 100%;

      .container {

        .logoWrapper {
          img {
            width: 160px !important;
          }
        }
        ul {
          li {
            .fa-user {
              // font-size: 15px !important;
            }
          }
        }
      }
    }

    .user-menu-popover {
      // min-width: 270px !important;
    }

  }

  .contentWrapper {
    // top: 48px;
    .sideBarContainer {
      .sideBarWrapper {
        // padding: 20px 5px;
        .loader {
          // font-size: 8px;
        }
        .element {
          // padding-bottom: 20px;

          .search-header-label {
            // padding-top: 0;
            font-size: 28px !important;
            // line-height: 1.8;
          }
          .label {
            font-size: 22px !important;
          }
          .sub-label {
            font-size: 0.89rem !important;
          }
          .searchInput {
            input {
              // font-size: 12px !important;
            }
            .search-icon {
              // font-size: 17px !important;
              // right: 8px;
            }
          }
          .tagWrapper {
            // max-height: 98px !important;
            // font-size: 13px !important;
            .tag {
              // padding: 4px 12px !important;
              font-size: 14px !important;
            }
          }
          .popTheme-wrapper {

            &__item {

              & img {
              }
            }

            &__title {
              font-size: 18px !important;
            }
          }
        }
        .sidebar-content {
          height: calc(100vh - (400px));
        }
      }
    }

    .contentContainer {
      position: relative;
      .spinnerMessage {

      }

      .topSectionContainer {

        .investment-ranked-wrapper {
          width: 83%;
          min-width: 600px !important;
          .ranking-tag-wrapper {
            font-size: 20px !important;
            .black-tag {
              font-size: 16px !important;
            }
          }
        }

        .user-similar-search-wrapper {
          width: 100% !important;
          max-width: 100% !important;
          .user-similar-search-label {
            font-size: 20px !important;
          }
          .user-similar-search-overflow-wrap {
            font-size: 20px !important;
            .didyoumean-text {
              font-size: 20px !important;
            }
            .ranking-tag-wrapper {
              font-size: 18px !important;
            }
            .user-similar-activate-btn {
              font-size: 16px;
            }
          }
        }

        .did-you-mean-overflow-wrap {
          width: 100% !important;
          font-size: 20px !important;
          .ranking-tag-wrapper {
            font-size: 14px !important;
            .black-tag {
              font-size: 8px !important;
            }
          }
        }

        #did-you-mean {
          .custom-scroll {
            font-size: 16px !important;
            height: auto !important;
            max-height: 53px !important;
            .didyoumean-text {
              font-size: 16px !important;
            }
          }
        }

        .search-related-wrapper {
          font-size: 22px !important;
          .search-label {
            font-size: 22px !important;
          }
          .share-component {
            &__wrapper {
              height: 30px !important;
            }

            &__item {
              width: 24px !important;
              height: 24px !important;
              padding: 0px !important;
              font-size: 14px !important;
            }
          }
        }

        .toolbarWrapper {
          .sort-filter-wrapper {
            .sortButtonWrapper {
              .sortbutton {
                font-size: 14px !important;
                // line-height: 1 !important;
              }
            }
            .filterButtonWrapper {
              .filterbutton {
                font-size: 14px !important;
                // line-height: 1 !important;
              }
            }
            .filter-items-wrapper {
              .filter-items {
                font-size: 14px !important;
              }
            }
          }
        }
      }

      .itemListContainer {
        // padding: 10px 15px 0 15px;
        // padding-left: 0;
        // padding-right: 0;
        .itemListHeaderWrapper {
          .cardListToolbarWrapper {
            .container {
              // max-width: unset;
              // min-height: 72px !important;
              // padding: unset;

              .toolbar-wrapper-flex-center {
                .fees-returns-risk-header-container {
                  // width: 100%;
                  // display: flex;
                  // height: 90px;
                  // padding-top: 2px;

                  .FRR-Label {
                    height: 28px !important;
                    font-size: 14px !important;
                    line-height: 1.3;
                    .FRR_sort-trigger-wrap {

                    }
                    .sort-state-icon {
                      // right: -3px;
                      // font-size: 14px;
                    }
                    .FRR_sort_dropdown_container {
                      .FRR_sort_dropdown_wrapper {
                        .FRR_sort_dropdown_menu-item {
                          font-size: 14px !important;
                          .FRR_sort_dropdown_menu-item-checkbox {
                            font-size: 14px !important;
                            span {
                              font-size: 14px !important;
                            }
                          }
                        }
                      }
                    }
                    .sort-options-wrap {
                      top: -4px;
                      right: -4px;
                      height: 20px !important;
                      .sort-options-checkbox {
                        height: 12px !important;
                        i {
                          font-size: 18px !important;
                        }
                      }
                    }
                  }

                  .highlight {
                    // margin: 0;
                    // margin-left: 2px;
                    // height: 18px !important;
                    font-size: 14px !important;
                    // line-height: 1.2 !important;
                    // color: #fff;
                    // background: $ssf-blue-d2;
                    // border-radius: 15px;
                    // transition: all 0.3s;
                  }
                }
              }
              .viewChartsColumn {

                .viewDropdownContainer {

                  .viewDropdownWrapper {
                    // height: 18px;
                    // margin-bottom: 0;
                    .viewBtn {
                      // margin-top: -10px !important;
                      font-size: 14px !important;
                      // line-height: normal !important;
                    }
                    .viewDropDownList {
                      // min-width: unset !important;
                      .viewDropDownList-Child {
                        font-size: 14px !important;
                        // min-width: unset !important;
                      }
                    }
                  }
                  .view-text-container {
                    // height: 22px;
                    // margin-top: 2px !important;
                    .view-text-label {
                      // margin: 0 !important;
                      // padding: 3px 5px !important;
                      font-size: 14px !important;
                      &:first-child {
                        // margin-right: 2px !important;
                      }
                    }
                  }
                  .view-sub-title-container {
                    h6 {
                      font-size: 14px !important;
                      // line-height: normal;
                      .dropdown {
                        button {
                          font-size: 14px !important;
                        }
                      }
                    }
                    .dropdown-menu {
                      // min-width: unset !important;
                      .dropdown-item {
                        font-size: 12px !important;
                      }
                    }
                  }
                }
              }


              .date-range-slider {
                // padding-right: 0px !important;
                .irs {
                  .irs-from, .irs-to, .irs-single {
                    font-size: 10px !important;
                  }
                }
                ul {
                  li {
                    // font-size: 10px !important;
                    // line-height: 1 !important;
                    button {
                      // font-size: 14px !important;
                    }
                  }
                }
              }
            }
          }
        }
        .itemListArea {
          // height: calc(100vh - (205px))!important;
          // overflow: hidden;
          // overflow-y: scroll;
          .itemCardWrapper {
            .itemCard {
              height: 100%;
              .vehicle-label {
                font-size: 14px !important;
              }
              .select-btn-box {
                button {
                  // padding: 5px !important;
                  font-size: 14px !important;
                  // line-height: 1 !important;
                }
                .ad-logo {
                  position: absolute;
                  top: 5px;
                  bottom: 0;
                  width: 70px;
                  line-height: 1;
                  height: 15px;
                  // height: max-content;
                  // width: max-content;
                  padding: 3px 5px;
                  // line-height: normal;
                  font-family: 'Roboto';
                  font-size: 10px;
                  color:#ffffff;
                  text-align: center;
                  // padding: 2px;
                  border-radius: 3px;
                  background-color:#4a4a4a;
                }
              }
              .fund-ticker-box {
                .ticker-size {
                  font-size: 20px !important;
                }
                .text-info {
                  // min-width: unset !important;
                  .ticker-price {
                    font-size: 24px !important;
                  }
                }
              }
              .fund-name-box {
                h5 {
                  font-size: 16px !important;
                }
              }

              .feesReturnRiskWrapper {
                .search-fees-return-stats-padding {
                  // height: 100%;
                  // padding: 15px 0px 22px;
                }
                .search-risk-stats-padding {
                  // height: 100%;
                  // padding: 22px 0px;
                }
                .search-risk-stats-highlighting {
                  .fees-flag-color, .returns-flag-color, .risk-flag-color {
                    // color: $tag-blue;
                  }
                }
                .w-100 {
                  display: grid;
                  height: 100%;

                  .fees-flag-color, .returns-flag-color, .risk-flag-color {
                    height: 100%;
                    img {
                      width: 24px;
                    }
                    i {
                      font-size: 20px;
                    }
                    .__val {
                      font-size: 13px !important;
                    }
                    .__state {
                      font-size: 12px !important;
                    }
                  }
                }

              }
              .chart-block {
                .primaryTextStyle {
                  left: 50%;
                  font-size: 11px !important;
                }
                .highlightTooltip {
                  font-size: 12px;
                }
                .guage-chart-wrapper {
                  .guage-chart-details {
                    .col {
                      font-size: 14px !important;
                    }
                  }
                }
                .flip-items {
                  li {
                    text-align: center;
                    h6 {
                      font-size: 14px !important;
                    }
                  }
                }
              }

              .recharts-wrapper {
                .recharts-tooltip-wrapper-bottom {
                  .ssf-tooltip-custom {
                    font-size: 14px !important;
                  }
                }
              }

            }

            &:last-child {
              // margin-bottom: 48px;
            }
          }
          &::-webkit-scrollbar {
            // display: none !important;
            // width: 0 !important;
          }
        }
      }
    }

  }
}

@media only screen and (min-width: 1281px) and (max-width: 1439px) {
  // .client-portfolio-menu {
  //   .client-portfolio-menu-button {
  //     i {
  //       width: 20px !important;
  //       height: 20px !important;
  //       font-size: 20px !important;
  //     }
  //   }
  // }
  .contentWrapper {
    .sideBarContainer {
      .sideBarWrapper {
        // padding: 20px 5px;
        .loader {
          // font-size: 8px;
        }
        .element {

          .search-header-label {

          }
          .label {
            .discover-dropdown {
              .multiple-calculator {
                .view-btn {
                  .down-arrow {
                    // line-height: 1 !important;
                  }
                }
              }
              .settings-gear {
                .modal-trigger-btn {
                }
              }
              .selector-options {
              }
            }
          }
          .sub-label {
            font-size: 0.7rem !important;
          }
          .searchInput {
            input {
              // font-size: 12px !important;
            }
            .search-icon {
              // font-size: 17px !important;
              // right: 8px;
            }
          }
          .tagWrapper {
            // max-height: 98px !important;
            // font-size: 13px !important;
            .tag {
              // padding: 4px 12px !important;
              // font-size: 14px !important;
            }
          }
          .popTheme-wrapper {

            &__item {

              & img {
              }
            }

            &__title {
              // font-size: 18px !important;
            }
          }
        }
        .sidebar-content {
          height: calc(100vh - (400px));
        }
      }
    }

    .contentContainer {
      .itemListContainer {
        .itemListHeaderWrapper {
          .cardListToolbarWrapper {
            .container {
              .toolbar-wrapper-flex-center {
                .fees-returns-risk-header-container {
                  .FRR-Label {
                    margin: 0;
                    padding: 3px 2px;
                    line-height: 1.3;

                    .FRR_sort-trigger-wrap {

                    }
                    .sort-state-icon {
                      // right: -3px;
                      // font-size: 14px;
                      margin-top: -1px;
                    }
                    .sort-options-wrap {

                      .sort-options-checkbox {
                        height: 7px;
                        i {
                          // font-size: 10px !important;
                        }
                      }
                    }
                  }
                  .highlight {
                    margin: 0;
                    margin-left: 2px;
                  }
                }
              }
              .viewChartsColumn {

                .viewDropdownContainer {
                  .viewDropdownWrapper {
                    // height: 18px;
                    margin-bottom: 0;
                    .viewBtn {
                      // margin-top: -10px !important
                    }
                    .viewDropDownList {

                      .viewDropDownList-Child {

                        // min-width: unset !important;
                      }
                    }
                  }
                  .view-text-container {

                    .slick-slider {

                      .slick-slide {
                        margin: 0 2px;
                      }
                      .slick-list .slick-track {

                      }
                      .slick-arrow {

                      }
                      .slick-disabled {

                      }
                      .slick-prev {
                        left: -10px !important;
                        z-index: 99;
                        // top: 52% !important;
                        // width: 14px;
                        // height: 14px;
                        // font-size: 11px;
                      }
                      .slick-next {
                        right: -10px !important;
                        z-index: 99;
                        // top: 52% !important;
                        // width: 14px;
                        // height: 14px;
                        // font-size: 11px;
                      }
                    }

                    .view-text-label {

                      &:first-child {
                        // margin-right: 2px !important;
                      }
                    }
                  }
                  .view-sub-title-container {
                    h6 {

                      .dropdown {
                        button {

                        }
                      }
                    }
                    .dropdown-menu {

                      .dropdown-item {

                      }
                    }
                  }
                }
              }


              .date-range-slider {
                #date-range-slider-wrap {
                  margin-left: 10px;
                }
                .irs {
                  .irs-from, .irs-to, .irs-single {

                  }
                }
                ul {
                  li {

                    button {

                    }
                  }
                }
              }
            }
          }
        }
        .itemListArea {
          .itemCardWrapper {
            .itemCard {
              height: 100%;
              .select-btn-box {
                .options-btn-body .btn-info-txt {
                  left: -0.6vw;
                }
                .options-btn-body .clear {
                  left: 2px !important;
                }
                .options-btn-body .clear.builder-clear {
                  width: auto !important;
                  left: 2px !important;
                }


                // .options-btn-body .btn-info-txt {
                //   width: auto !important;
                //   left: -8px !important;
                //   font-size: 6px;
                //   line-height: 1;
                // }
                // .options-btn-body .btn-info-txt.cart {
                //   left: -10px !important;
                // }
                // .options-btn-body .clear {
                //   left: 0px !important;
                // }
                // .options-btn-body .clear.cart {
                //   left: 0px !important;
                // }
                // .options-btn-body .clear.builder-clear {
                //   width: auto !important;
                //   left: 0px !important;
                // }
              }
              .fund-ticker-box {
                .ticker-size {
                  font-size: 13px !important;
                }
                .text-info {
                  min-width: unset !important;
                  margin: 0 !important;
                  line-height: 20px !important;
                  .ticker-price {
                    font-size: 14px !important;
                  }
                }
              }
              .fund-name-box {
                h5 {
                  font-size: 12px !important;
                }
              }

            }

          }
        }
      }
    }

  }
}

@media only screen and (min-width: 1281px) and (max-width: 1330px) {
  .contentWrapper .contentContainer .itemListContainer .itemListArea .itemCardWrapper .itemCard .feesReturnRiskWrapper {
    .search-fees-return-stats-padding {
      .__val {
        font-size: 10px !important;
      }
      .__state {
        font-size: 9px !important;
      }
    }
    .search-risk-stats-padding {
      padding: 36px 0px;
      .__val {
        font-size: 10px !important;
      }
      .__state {
        font-size: 9px !important;
      }
    }
  }
}

@media only screen and (min-width: 1331px) and (max-width: 1439px) {
  .contentWrapper .contentContainer .itemListContainer .itemListArea .itemCardWrapper .itemCard .feesReturnRiskWrapper {
    .search-fees-return-stats-padding {
      .__val {
        font-size: 10px !important;
      }
      .__state {
        font-size: 9px !important;
      }
    }
    .search-risk-stats-padding {
      padding: 36px 0px !important;
      .__val {
        font-size: 10px !important;
      }
      .__state {
        font-size: 9px !important;
      }
    }
  }
}

@media only screen and (min-width: 1281px) {
  .ad-logo {
    position: absolute;
    top: 5px;
    bottom: 0;
    width: 70px;
    line-height: 1;
    height: 15px;
    // height: max-content;
    // width: max-content;
    padding: 3px 5px;
    // line-height: normal;
    font-family: 'Roboto';
    font-size: 10px;
    color:#ffffff;
    text-align: center;
    // padding: 2px;
    border-radius: 3px;
    background-color:#4a4a4a;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1024px) {
  .contentWrapper .contentContainer .itemListContainer .itemListArea .itemCardWrapper .itemCard .select-btn-box .ad-logo {
    position: absolute;
    top: 5px;
    bottom: 0;
    width: 70px;
    line-height: 1;
    height: 15px;
    // height: max-content;
    // width: max-content;
    padding: 3px 5px;
    // line-height: normal;
    font-family: 'Roboto';
    font-size: 10px;
    color:#ffffff;
    text-align: center;
    // padding: 2px;
    border-radius: 3px;
    background-color:#4a4a4a;
  }
}

@media only screen and (min-width: 1920px) {
  .select-btn-box {
    position: relative;

    .options-btn-body {

      .selector-btn {
        // margin-right: 10px;
      }
      .unselected {
        width: 42px;
        height: 42px;
        img{
          width: 24px;
          // position: relative;
          // right: 4px;
          // bottom: 2px;
        }
      }
      .btn-info-txt {
        left: -14px;
        font-size: 10px;
        bottom: -20px;
        // font-size: 12px;
        // position: absolute;
        // left: -14px;
        // bottom: -19px;
      }
      .btn-info-txt.cart {
        // position: absolute;
        left: -18px;
        font-size: 10px;
        bottom: -20px;
      }
      .btn-info-txt.builder {
        // position: absolute;
        left: -12px;
        font-size: 10px;
        bottom: -20px;
      }
      .clear {
        left: 0;
        font-size: 10px;
        bottom: -20px;
        // position: absolute;
        // left: 6px;
        // bottom: -21px;
      }
      .clear.cart{
        // position: absolute;
        // left: 68px;
        left: 0;
      }
      .clear.builder-clear {
        // position: absolute;
        // left: 68px;
        left: 0;
      }
    }
  }
}

@media only screen and (min-width: 1460px){
  .standard{
    display: none !important;
  }
  .desktop{
    display: block !important;
  }
}

@media only screen and (max-width: 1459px){
  .standard{
    display: block !important;
  }
  .desktop{
    display: none !important;
  }
}
