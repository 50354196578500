// Configuration settings for PDF
$dark-blue: #101828;
$light-blue: #101828;

$lvl1-header-font-family: 'Inter', sans-serif;
// $lvl1-header-font-color: #1d6091;
$lvl1-header-font-size: 2.2rem;

$lvl2-header-font-family: 'Inter', sans-serif;
// $lvl2-header-font-color: #1d6091;
$lvl2-header-font-size: 1.6rem;

$body-font-family: 'Inter', sans-serif;
$body-font-color: #101828;
$body-font-size: 1.2rem;

$chart-label-grid-color: #b0b0b0;

$pg-px: 12mm;

// .portfolio-reports-v2 .container{
//   width: 21cm !important;
//   min-width: 21cm !important;
// }

.portfolio-reports-v2 {
  padding-top: 0;
  background-color: white !important;
  height: 100%;
  font-family: $body-font-family;
  font-color: $body-font-color;
  font-size: $body-font-size;
  // max-width: 1080px;
  margin: auto;

  .exluded-tickers-style {
    margin-top: 1em;
    font-size: 1.1rem;
    font-weight: 600;
    color: #4a4a4a;
  }

  .page-header {
    margin-bottom: 10px;
    .logo,
    .logo-placeholder {
      max-height: 68px;
      height: 68px;
      width: auto;
    }
    .logo-placeholder {
      margin: 0;
      display: flex;
      align-content: center;
      font-size: 1.35rem;
      font-weight: bold;
    }
    .ml-auto {
      align-self: center;
      color: #a0a0a0;
    }
    img {
      margin-left: -5px;
    }
  }

  .cover {
    background: linear-gradient(180.03deg, #457f8f 0.03%, #1e4854 74.81%);
    padding: 0 4rem;
  }

  .cover-footer {
    padding: 3rem 4rem;
  }

  .page {
    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 80px;
      padding: 4mm $pg-px;
      border-bottom: 2px solid $gray-200;
      margin-bottom: 6mm;

      h3,
      h5 {
        margin: 4px 0;
      }
      h3 {
        font-weight: bold;
      }
      h5 {
        color: $gray-600;
      }

      &.type2 {
        padding: 6mm;
        border-bottom: none;
        background-color: $gray-100;
      }
    }
    .page-header,
    .page-footer {
      // background-color: $gray-700;
      // color: white;
      padding: 7mm 0;
      font-size: 12px;
    }
    .page-footer {
      border-top: 1px solid #e3e3e3;
      color: #909090;
      padding-top: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .pr-v2-header {
    h3 {
      font-family: $lvl1-header-font-family;
      font-size: $lvl1-header-font-size !important;
      font: 500 0.75rem/2rem Inter, sans-serif;
      color: #101828;
      margin-bottom: 50px;
    }
  }

  .pr-v2-light-blue {
    color: $light-blue;
  }

  .pr-v2-dark-blue {
    color: $dark-blue;
  }

  .recharts-cartesian-axis-tick text,
  .recharts-legend-item-text {
    // font-size: 1.2rem;
    font-size: 0.8rem;
    fill: $chart-label-grid-color;
    font-family: 'Roboto';
  }
  .area-chart-label,
  .line-chart-ref-label {
    font-size: 1rem;
    fill: #4a4a4a;
    font-family: 'Roboto';
  }
  .line-chart-label {
    font-size: 1rem;
    font-family: 'Roboto';
  }
  .stackedAreaChart
    .recharts-wrapper
    .recharts-cartesian-grid-horizontal
    line:last-child {
    stroke-opacity: 0;
  }
  .recharts-legend-item path,
  .recharts-bar-rectangle path {
    shape-rendering: crispEdges;
  }
  .recharts-wrapper .recharts-surface {
    width: auto !important;
  }

  .pr-v2-chart-legends {
    // margin-top: 30px;
    width: 100%;
    p {
      padding-right: 60px;
      padding-left: 7px;
      float: left;
      font-size: 1.4rem !important;
      color: #aaaaaa;
      display: flex;
      height: 20px;
      align-self: center;
      align-items: center;
      margin-bottom: 0px;
      &:first-child {
        border-left: 4px solid #56a9e8;
      }
      &:last-child {
        border-left: 4px solid #1d6091;
      }
      span {
        padding-left: 40px;
      }
    }
  }

  .print-wrapper {
    text-align: center;
    margin: 0 auto;
    .recharts-wrapper,
    .d-flex {
      margin: 0 auto !important;
    }
  }

  .pr-v2-medium-paragraph {
    color: #344054;
    font: 500 1rem Inter, sans-serif;
  }

  .pr-v2-small-paragraph {
    font-size: 12px;
  }

  .header-icon-title {
    min-height: 54px;
  }

  .pr-v2-cover-page {
    // padding-top: 60px;
    // padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    font-size: 1.35rem;
    color: $body-font-color !important;
    img,
    .cover-logo {
      margin-top: 10%;
      margin-bottom: 70px;
    }
    .title {
      color: #fff;
      font-size: 3.25rem !important;
      margin-bottom: 32px;
      display: inline;
      font-family: 'Inter', sans-serif;
      font-weight: 600;
    }
    .date {
      color: #fff;
      font-size: 0.875rem;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      margin-top: 32px;
    }
    .para {
      color: #fff;
      font-size: 1rem;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      width: 50%;
    }
    .bold{
      font-weight: 600;
    }
    .glossary-footer {
      font-size: 1.2rem;
      line-height: 1.6;
      span {
        margin-bottom: 5px;
      }
    }
  }

  .pr-v2-toc {
    font-size: 1.4rem !important;
    color: $dark-blue;
    tr {
      &:nth-child(odd) {
        background: $c5;
      }
    }
    td {
      padding-top: 12px;
      padding-bottom: 12px;
      border: none;
      &:first-child {
        padding-left: 40px;
      }
      &:nth-child(2) {
        text-align: right;
        padding-right: 40px;
      }
    }
  }

  .pr-v2-ex-summary {
    h4 {
      font-size: $lvl2-header-font-size !important;
      font-family: $lvl2-header-font-family;
      color: $dark-blue;
      margin-top: 40px;
      margin-bottom: 15px;
    }
    .lt-bl-text {
      color: $dark-blue;
    }
    .pr-v2-ex-sum-table {
      margin-top: 30px;
      font-size: 21px;
      td {
        padding-top: 15px;
        padding-bottom: 15px;
        border: none;
        &:first-child {
          padding-left: 40px;
        }
        &:nth-child(2) {
          color: $light-blue;
        }
        &:last-child {
          padding-right: 40px;
          color: $dark-blue;
        }
      }
    }
  }

  .pr-v2-model-replacement {
    .pr-v2-mr-header {
      color: $dark-blue;
      font-size: $lvl2-header-font-size !important;
      font-family: $lvl2-header-font-family;
      margin-bottom: 20px;
    }
    .model-details {
      display: flex;
      flex-direction: column;
    }
    .holdings-comparison {
      h4 {
        color: $dark-blue;
        font-size: 1.4rem !important;
        font-family: $lvl2-header-font-family;
        margin-top: 30px;
        margin-bottom: 20px;
      }
      .holdings-table-wrapper {
        display: flex;
        .holdings-section {
          display: flex;
          width: 50%;
          table {
            width: 100%;
            th,
            td {
              padding-right: 20px;
              padding-left: 20px;
            }
            thead {
              tr {
                height: 40px;
                &.Current {
                  border: 1px solid $light-blue;
                  color: $light-blue;
                }
                &.Enhanced {
                  background: $dark-blue;
                  border: 1px solid $dark-blue;
                  color: #ffffff;
                }
                th {
                  font-weight: normal;
                  font-size: 0.9rem;
                }
              }
            }
            tbody {
              font-family: $lvl2-header-font-family;
              tr {
                border-bottom: 2px solid #dee2e6;
                height: 100px;
                font-size: 0.9rem;
                font-weight: 500;
                line-height: 1.3;
                padding-top: 10px;
                padding-bottom: 10px;
                td {
                  &:first-child {
                    width: 200px;
                  }
                }
              }
            }
          }
          .holdings-sponsor {
            display: flex;
            flex-direction: column;
            width: inherit;
            img {
              width: auto;
              max-width: max-content;
              margin-bottom: 10px;
              height: 20px;
            }
            span {
              max-height: 35px;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
      }
    }
    .pr-v2-mdl-repl {
      .pr-v2-rec-enh-table {
        tbody {
          tr {
            height: 150px;
          }
        }
        th,
        td {
          padding: 8px;
          &:nth-child(2) {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
        td {
          &:nth-child(2) {
            font-size: 1rem !important;
            font-family: $lvl2-header-font-family;
          }
        }
        th {
          color: $dark-blue;
          font-size: 1.1rem;
          font-weight: bold;
          border: none !important;
          &:first-child {
            width: 5%;
            color: #dbdbdb;
          }
          &:nth-child(2) {
            width: 23% !important;
          }
          &:not(:first-child) {
            width: 11%;
          }
          &:last-child {
            width: 22%;
          }
        }
        .fees-flag-color,
        .returns-flag-color,
        .risk-flag-color {
          font-size: 1rem;
        }
        .md-sponsor {
          img {
            width: auto;
            max-width: -webkit-max-content;
            max-width: -moz-max-content;
            max-width: max-content;
            height: 20px;
          }
        }
        .md-pfName {
          margin-top: 5px;
          margin-bottom: 5px;
          max-height: 50px;
          overflow: hidden;
        }
        .md-category {
          font-size: 0.75rem;
        }
      }
    }
    .model-chart {
      display: flex;
      align-items: center;
      width: inherit;
      .chart-section {
        min-width: 100px;
        max-width: 100px;
        margin-right: 10px;
        margin-left: 10px;
      }
      .legend-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        .total-overlap {
          font-size: 13px;
          text-align: left;
          font-family: $lvl2-header-font-family;
          color: $dark-blue;
          font-weight: 600;
          margin-bottom: 15px;
        }
        .single-legend {
          display: flex;
          font-size: 12px;
          font-family: $lvl2-header-font-family;
          font-weight: 500;
          color: #999999;
          &:first-child {
            margin-bottom: 10px;
          }
          .legend-content {
            display: flex;
            flex-direction: column;
            margin-top: -1px;
            span {
              text-align: left;
              line-height: 1;
            }
            .overlap {
              margin-top: 4px;
            }
          }
          .legend-color {
            width: 10px;
            height: 10px;
            display: block;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .pr-v2-port-performance {
    .pp-value-wrapper {
      font-size: 17px;
      border: 1px solid $dark-blue;
      padding: 10px 25px;
      color: $dark-blue;
      display: inline-block;
      width: 100%;
      .ppv-left {
        text-align: left;
      }
      .ppv-right {
        text-align: right;
      }
    }
    .pr-v2-pp-stats {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .pr-v2-pp-sections {
      h4 {
        color: $dark-blue;
        font-size: $lvl2-header-font-size !important;
        font-family: $lvl2-header-font-family;
        margin-bottom: 15px;
      }
      #portfolio-linechart {
        .recharts-cartesian-axis-line {
          display: none;
        }
      }
    }
    .pr-v2-pp-table {
      margin-top: 40px;
      font-size: 21px;
      .row {
        &:first-child {
          padding-top: 10px;
        }
        padding-top: 25px;
        padding-bottom: 10px;
        &:nth-child(odd) {
          background: $c5;
        }
        .col-8,
        .col-12 {
          padding-left: 40px;
        }
        // .col-2{
        //   color: $light-blue;
        //   &:last-child{
        //     padding-right: 60px;
        //     color: $dark-blue;
        //   }
        // }
        .pp-table-row-desc {
          font-size: 18px;
          line-height: 2;
          padding-top: 15px;
          padding-bottom: 15px;
          padding-right: 40px;
        }
      }
    }
    .bg-chart-block {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 30px 30px;
      margin-bottom: 15px;
      background: #f6f6f6 !important;
    }
  }

  .pr-v2-asset-alloc {
    .pr-v2-aa-header {
      color: $dark-blue;
      font-size: $lvl2-header-font-size !important;
      font-family: $lvl2-header-font-family;
      margin-bottom: 20px;
    }
    h4 {
      font-size: 21px !important;
      margin-bottom: 15px;
    }
    .aa-chart-legend {
      font-size: 1.4rem !important;
      color: #aaaaaa;
      td {
        padding-bottom: 15px;
      }
      .aa-chart-legend-name {
        padding-left: 7px;
        float: left;
        display: flex;
        height: 20px;
        align-self: center;
        align-items: center;
        margin-bottom: 0px;
      }
      .aa-chart-legend-value {
        display: flex;
        align-self: center;
        align-items: center;
        height: 20px;
        margin-bottom: 0px;
      }
    }
  }

  .pr-v2-rec-enh,
  .pr-v2-mdl-repl {
    h4 {
      color: $dark-blue;
      font-size: $lvl2-header-font-size !important;
      font-family: $lvl2-header-font-family;
      margin-bottom: 15px;
    }
    .gen-row-ht {
      height: 100px;
    }
    .blue-bg {
      background: $dark-blue !important;
      color: #fff;
    }
    .lb-border {
      border: 1px solid $light-blue !important;
      color: $light-blue;
    }
    .rec-tt-wrapper {
      position: relative;
      height: 100px;
      width: 50px;
      &.custom-height {
        height: 140px;
      }
      .rec-transform-text {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 1rem;
        -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      }
    }
    .rec-enh-chart-wrapper {
      display: flex;
      height: 100%;
      border: 1px solid #dee2e6 !important;
    }
    .rec-enh-tbl-hdr {
      th {
        vertical-align: middle;
        text-align: center;
        border: none;
        .subtitle {
          color: black !important;
          opacity: 0.7;
          font-weight: normal;
          font-family: 'Roboto', sans-serif;
          font-size: 12px;
          margin: 0;
        }
      }
    }
    .pr-v2-rec-enh-table {
      margin-bottom: 0px;
      border: none !important;
      margin-top: 8px;
      tr {
        &:nth-child(odd) {
          border-collapse: separate;
          border-spacing: 0 15px;
        }
      }
      th {
        color: $dark-blue;
        font-size: 1.1rem;
        font-weight: bold;
        border: none !important;
        &:first-child {
          width: 5%;
          color: #dbdbdb;
        }
        &:not(:first-child) {
          width: 19%;
        }
        //&:last-child{
        //  width: 20%;
        //}
      }
      .rec-enh-ticker-name {
        font-size: 1.1rem;
        color: #4a4a4a;
        span {
          &:first-child {
            display: inline-block;
            margin-bottom: 5px;
          }
        }
      }
      .gauge-chart-report {
        .siw-meter-min,
        .siw-meter-value,
        .siw-meter-max {
          font-size: 1rem;
        }
        .siw-meter-value {
          font-weight: 600;
        }
        .siw-meter-desp {
          font-size: 1rem;
          margin-top: 10px;
          display: block;
          padding: 0px 10px;
          color: #979797;
        }
      }
    }
    .pr-v2-rec-enh-table.tbl-first {
      margin-top: 0px;
    }
    .row-break {
      height: 20px;
    }
    .pr-v2-rec-enh-table td {
      border: 1px solid #dee2e6 !important;
      vertical-align: middle;
      text-align: center;
    }
    .fees-flag-color,
    .returns-flag-color,
    .risk-flag-color {
      font-size: 1.1rem;
      color: #4a4a4a;
      font-weight: normal;
      i {
        color: #999999;
        font-size: 20px;
        margin-bottom: 5px;
      }
    }
    .flag-highlight {
      font-weight: bold;
    }
  }

  .pr-v2-conclusion {
    .pr-v2-conc-desc {
      position: absolute;
      background: #ffffff;
      top: 34px;
      right: 213px;
      border: 1px solid $dark-blue;
      padding: 5px 15px;
      color: $dark-blue;
      span {
        padding-left: 25px;
      }
    }
  }

  .pr-v2-clm-alignAll {
    align-self: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .pr-v2-glossary {
    padding-top: 60px;
    padding-bottom: 60px;
    font-size: 0.85rem;
    h4 {
      font-weight: 500;
      font-family: 'Roboto';
      color: #686868;
      margin-bottom: 30px;
      font-size: 18px !important;
    }
    p {
      font-weight: 400;
      font-family: 'Roboto';
    }
    h5 {
      font-weight: 500;
      font-family: 'Roboto';
      color: #686868;
      font-size: 16px !important;
    }
  }

  .pr-v2-disclosure {
    padding-top: 20px;
    max-height: 650px;
    overflow: hidden;
    b {
      color: #fff;
      font: 600 0.85rem/1rem Inter, sans-serif;
    }

    .text {
      color: #fff;
      font: 500 0.85rem/1rem Inter, sans-serif;
    }
  }

  .pr-v2-chart-legends-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .greyScale {
    filter: grayscale(100%);
  }
}

@media print {
  .portfolio-reports-v2 {
    .page {
      //page-break-after: always;
      height: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      .content {
        flex: 1;
        // padding: 4mm $pg-px;
      }
    }
    .pr-v2-glossary {
      page-break-before: always;
    }
  }

  // .portfolio-reports-v2 .container{
  //   width: 100% !important;
  //   min-width: 100% !important;
  // }

  // .recharts-responsive-container {
  //   margin: 0 auto !important;
  // }

  .recharts-wrapper,
  .recharts-surface {
    width: 100% !important;
    min-width: 100% !important;
  }

  .table td,
  .table th {
    background-color: transparent !important;
  }
}
