@import "./SliderChart/index";

.result-section-container {
  height: 100%;
  // margin-bottom: 15px;
  // background: #fff;
  .result-section-label {
    padding-bottom: 15px;
    font-family: $secondary-font;
    font-size: $font-size-18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $dark-blue-color;
  }

  .result-chart-stats-container {
    padding: 10px 0;
    .result-chart-stats {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .chart-wrap {
        .donut-chart-container {
          width: 100%;
          .donut-chart-center-label {
            .text {
              font-family: $secondary-font;
              font-size: $font-size-16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.17;
              letter-spacing: normal;
              color: $dark-blue-color;
            }
          }
        }
      }
      .chart-legends-wrapper {
        // height: 200px;
        overflow: hidden;
        overflow-y: auto !important;

        &::-webkit-scrollbar {
          width: 4px !important;
          border-radius: 13px;
          background-color: #eceef1;
          margin-left: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #dbdbdb !important;
        }

        .result-section-calc-slider-wrapper {
          width: 95%;
          height: auto;
          padding: 0 0 20px 0;
          .label-wrapper {
            line-height: 1;
            padding: 0;
            padding-bottom: 7px;
            .label-holder {
              font-size: 12px;
            }
            .icon-holder {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              .anticon {
                font-size: 11px;
              }
            }
          }
          .slider-wrapper {
            padding: 0;
            align-items: center;
            .slider-element {
              margin: 6px 15px 6px 6px;
              .ant-slider-rail {
                height: 2px;
                background-color: $indicator-color;
              }
              .ant-slider-handle {
                margin-top: -6px;
                border: solid 1px $dark-blue-color;
              }
              .ant-slider-track {
                height: 2px;
                background-color: #56a9e8;
              }
              .ant-slider-step {
                height: 2px;
              }
            }
            .input-number-element {
              width: 100%;
              margin-left: 0.2rem !important;
              font-size: 10px;
              border: 1px solid $indicator-color;
              border-radius: 3px;
              .ant-input-number-handler-wrap {
                display: none;
              }
              input {
                height: 20px;
                padding: 0 3px;
              }
            }
            .number-element {

            }
          }
          &:last-child {
            padding: 0;
          }
        }
        .ant-row {
          padding: 6px 0px;
          display: flex;
          align-items: baseline;
        }
        .legend-box {
          width: 12px;
          height: 12px;
        }
        .font-style {
          font-family: $secondary-font;
          font-size: $font-size-18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          color: $label-black-color-2;
        }
      }
    }
  }

  .list-container {
    border: 0;
    background: $bg-color-light;
    border-radius: 3px;
    overflow: hidden;
    .legends-body{
      text-align: center;
      padding-bottom: 15px;
      .tag{
        color: #000;
      }
    }
    .list-head-wrapper {
      font-family: $secondary-font;
      font-size: $font-size-18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: $label-black-color-2;
      border-bottom: 2px solid $other-bg-color-2;
      .ant-col {
        padding: 8px;
        padding-left: 5px;
        &:first-child {
          padding: 8px 12px;
        }
        &:last-child {
          text-align: center;
          margin-left: -10px;
          padding-left: 8px;
        }
      }
    }

    .list-body-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      .list-body-content-row {
        width: 100%;
        font-family: $secondary-font;
        font-size: $font-size-14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: left;
        color: $label-black-color-2;
        border-bottom: 2px solid $other-bg-color-2;
        .ant-col {
          padding: 8px;
          &:first-child {
            padding: 8px 12px;
            // padding: 1px;
            // max-width: 8px !important;
          }
          &:last-child {
            text-align: center;
            padding-left: 2%;
          }
        }
        &:last-child {
          // border: 0;
        }
      }
    }
  }

  .result-section-tabs-wrapper {
    width: 100%;
    .result-section-tabs-header-wrap {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .text-label {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-family: $secondary-font  ;
        font-size: $font-size-18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: left;
        color: $label-black-color;
      }
    }
    .ant-tabs-bar {
      margin: 0;
      .ant-tabs-nav-container {
        height: 85px !important ;
      }
      .ant-tabs-nav {
        width: 100%;
        .ant-tabs-tab {
          // width: calc((100% - 0px) / 3);
          width: calc((100% - 12px) / 3);
          margin-right: 5px;
          height: 85px;
          background: #efefef;
          border-bottom: 0;
          border-radius: 3px;
          padding: 15px;
          &:first-child {
            // border-right: 0;
          }
          &:last-child {
            margin-right: 0 !important;
            border-left: 0;
          }
        }
        .ant-tabs-tab-active {
          height: 85px;
          background: $dark-blue-color;
          border-color: transparent;
          .result-section-tabs-header-wrap {
            .text-label {
              color: #fff;
            }
            .selected-funds-text {
              color: #fff;
            }
          }
        }
      }
    }
    .ant-tabs-content {
      margin-top: 15px;
      background: $bg-color-light;
    }
  }

  .analytics-tab-container {
    margin: -14px -15px;
    margin-top: 0;
    .custom-scroll {
      height: calc(88vh - 222px) !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-left: 0px !important;
      padding-right: 5px !important;
      margin-right: 5px !important;
    }
    .analytics-tab-menu-wrapper {
      .tab {
        padding: 21px 10px;
        font-family: $secondary-font;
        font-size: $font-size-18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        color: #1d6091;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        cursor: pointer;
        border-bottom: 2px solid transparent;
      }
      .tab-active {
        font-size: $font-size-20px;
        font-weight: 500;
        border-color: #1d6091;
      }
    }
    .analytics-tab-content-wrapper {
      padding: 15px !important;
      display: flex;
      flex-direction: column;

      .barchart-block {
        width: 100%;
        height: 216px;
      }
      .linechart-block {
        width: 100%;
        height: 214px;
      }
      .summary-stats-list-container {
        background-color: #f7f7f7;
        border-radius: 3px;
        .summary-stats-row {
          display: flex;
          justify-content: space-between;
          padding: 8px 18px;
          border-bottom: 1px solid #eaeaea;
          &:last-child {
            border: 0;
          }
          .summary-stats-col {
            font-family: $secondary-font;
            font-size: $font-size-16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: left;
            color: #424242;
            .stats-name {

            }
            .stats-value {

            }
          }
        }
      }
      .recharts-cartesian-axis-tick {
        .recharts-cartesian-axis-tick-line {
          stroke: rgba(112, 112, 112, 0.16);
          fill: rgba(112, 112, 112, 0.16);
        }
      }
      .recharts-cartesian-axis-tick-value {
        tspan {
          font-family: $secondary-font;
          font-size: $font-size-10px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.1;
          letter-spacing: normal;
          text-align: center;
          fill: rgba(66, 66, 66, 0.42);
        }
      }
      .x-axis-label {
        // opacity: 0.46;
        font-family: $secondary-font;
        font-size: $font-size-10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: normal;
        text-align: center;
        color: #424242;
      }
      .y-axis-label {
        // opacity: 0.46;
        font-family: $secondary-font;
        font-size: $font-size-12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: normal;
        text-align: center;
        fill: #424242;
      }
    }
  }

  .result-section-content-wrapper {
    width: 100%;
    height: 100%;
    min-height: calc(98vh - 224px) !important;
    padding: 15px;
    background: #fff;
    border: 1px solid #eceef1;
    border: 0;
  }

  .allocations-section {
    .multi-chart-center-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-evenly;
    }
    .custom-scroll {
      height: calc(93vh - 214px) !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-left: 0px !important;
      padding-right: 10px !important;
      margin-right: -10px !important;
    }
  }
  .holdings-section {
    .custom-weight-input{
      position: relative;
      min-width: 56px;
      max-width: 66px;
      margin: auto;
      .error{
        border: 1px solid #ea5f4b;
      }
      input{
        height: 28px;
        width: 90%;
        border: none;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 0 12px 0 0;
        text-align: center;
      }

      span{
        top:7px;
        position: absolute;
        right: 9px;
      }
    }
    .custom-scroll {
      // height: calc(100vh - 482px) !important;
      height: calc(95vh - 313px) !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-left: 0px !important;
      padding-right: 5px !important;
      margin-right: 5px !important;
    }
    @media only screen and (min-width: 1920px) {
      .custom-scroll {
        // height: calc(100vh - 485px) !important;
      }
    }
    @media only screen and (max-width: 1024px){
      .list-container{
        .list-head-wrapper{
          .ant-col{
            white-space: nowrap;
            &:last-child {
              padding-left: 8px;
            }
          }
        }
      }
    }
  }

  .single-chart-center-align {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 350px) !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .up-down-capture-dropdown{
    .ant-select-selection-item{
      font-size: 12px!important;
      font-family: 'Roboto', sans-serif;
    }
  }
}
