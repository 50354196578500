$in-active-color: #bababb;

.barchart-block {
  .recharts-cartesian-axis-tick {
    text {
      font-family: 'Open Sans';
      /* font-family: OpenSans; */
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: -0.14px;
      text-align: left;
      fill: $in-active-color;
      text-transform: lowercase;
    }
  }
}

.tree-map-chart-container {
  .tree-map-chart-back-button {
    width: max-content;
    margin-left: auto;
    text-align: right;
    cursor: pointer;
  }
  .tree-map-chart-legends-wrapper {
    stroke: 'black';
  }
}
