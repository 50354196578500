
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .accounts-content-wrapper {
    .magnifi-dashboard-container {
      .sidebar-container {
        .sideBarWrapper {
          .element {
            .label {
              font-size: 1rem !important;
            }
            .sub-label {
              font-size: 0.7rem !important;
            }
            .quick-link-wrapper {
              a {
                font-size: 0.7rem !important;
                img {
                  height: 18px !important;
                }
              }
            }
            .accounts-sidebar-notification-count {
              .ant-badge-count {
                padding: 3px 4px;
                font-size: 0.7rem !important;
              }
            }
          }
        }
      }
      .accounts-summary-container {
        .accounts-page-container {
          .accounts-page-summary-wrapper {
            .accounts-summary-card-row {
              margin: 0 -8px;
              .card-title {
                font-size: $font-size-14px !important;
                h5 {
                  font-size: $font-size-14px !important;
                }
                button {
                  font-size: $font-size-14px !important;
                }
              }
              .holdings-list-head {
                font-size: $font-size-12px !important;
                line-height: 1.5;
              }
              .FRR-block-label {
                text-align: center;
              }
              .accounts-card-stats-wrapper {
                // min-height: 94px;
                .col-block {
                  margin: 15px 0px;
                  .cta {
                    font-size: $font-size-11px !important;
                    line-height: 1.9;
                  }
                  div {
                    font-size: 0.7rem !important;
                  }
                  h1 {
                    font-size: $font-size-26px !important;
                  }
                  &:last-child {

                  }
                  &:first-child {

                  }
                }
              }
            }
            .accounts-summary-card-col {
              padding: $accounts-summary-card-col;
            }
            .accounts-allocations-chart-container {
              .accounts-allocations-chart-wrapper {
                .accounts-allocations-chart-label {
                  font-size: $font-size-12px !important;
                }
              }
            }
          }
          .accounts-page-label {
            font-size: 1rem !important;
            margin-bottom: 3px !important;
          }
        }
        .primary-cta-button {
          font-size: 14px;
          .anticon {

          }
        }
        .accounts-summary-holdings-card {
          .ticker-block {
            h5 {
              font-size: $font-size-14px !important;
            }
            .holdings-weight-font-size {
              font-size: $font-size-14px !important;
            }
          }
          .name-block {
            h5 {
              font-size: $font-size-14px !important;
            }
          }
          .FRR-block {
            h5 {
              margin: 0 !important;
              font-size: $font-size-14px !important;
            }
            h3 {
              margin: 0 !important;
              font-size: $font-size-12px !important;
            }
          }
          .h3-text {
            margin: 0 !important;
            font-size: $font-size-14px !important;
          }
          .cta-block {
            button {
              width: 100%;
              max-width: 58px;
              // height: 23px;
              font-size: $font-size-10px;
              line-height: 1;
              white-space: break-spaces;
              word-break: keep-all;
            }
          }
          .time-Unit {
            font-size: $font-size-10px;
          }
        }
        .summary-card-row .accounts-summary-card-col {
          > div {
            display:flex;
            > div {
              flex:1;
            }
          }
          .tx-c3 {

          }
          .tx-c2 {

          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1330px) {
  .accounts-content-wrapper {
    .magnifi-dashboard-container {
      .sidebar-container {
        .sideBarWrapper {
          .element {
            .label {
              font-size: 0.95rem !important;
            }
            .sub-label {
              font-size: 0.65rem !important;
            }
            .quick-link-wrapper {
              a {
                font-size: 0.7rem !important;
                img {
                  height: 18px !important;
                }
              }
            }
            .accounts-sidebar-notification-count {
              .ant-badge-count {
                padding: 3px 4px;
                font-size: 0.7rem !important;
              }
            }
          }
        }
      }
      .accounts-summary-container {
        .accounts-page-container {
          .accounts-page-summary-wrapper {
            .accounts-summary-card-row {
              margin: 0 -8px;
              .card-title {
                // font-size: 0.7rem !important;
                h5 {
                  // font-size: 0.7rem !important;
                }
                button {
                  // font-size: 0.7rem !important;
                }
              }
              .holdings-list-head {
                font-size: $font-size-12px !important;
                // line-height: 1.5;
              }
              .FRR-block-label {
                text-align: center;
              }
              .accounts-card-stats-wrapper {
                // min-height: 94px;
                .col-block {
                  margin: 15px 0px;
                  .cta {
                    // font-size: 0.6rem !important;
                    // line-height: 1.9;
                  }
                  div {
                    // font-size: 0.7rem !important;
                  }
                  h1 {
                    // font-size: $font-size-26px !important;
                  }
                  &:last-child {

                  }
                  &:first-child {

                  }
                }
              }
            }
            .accounts-summary-card-col {
              padding: $accounts-summary-card-col;
            }
            .accounts-allocations-chart-container {
              .accounts-allocations-chart-wrapper {
                .accounts-allocations-chart-label {
                  // font-size: $font-size-12px !important;
                }
              }
            }
          }
          .accounts-page-label {
            font-size: 1rem !important;
            margin-bottom: 3px !important;
          }
        }
        .primary-cta-button {
          font-size: 14px;
          .anticon {

          }
        }
        .accounts-summary-holdings-card {
          .ticker-block {
            h5 {
              // font-size: $font-size-14px !important;
            }
            .holdings-weight-font-size {
              // font-size: $font-size-14px !important;
            }
          }
          .name-block {
            h5 {
              // font-size: $font-size-14px !important;
            }
          }
          .FRR-block {
            h5 {
              // font-size: $font-size-14px !important;
              // margin: 0 !important;
            }
            h3 {
              // font-size: $font-size-12px !important;
              // margin: 0 !important;
            }
          }
          .h3-text {
            margin: 0 !important;
            font-size: $font-size-13px !important;
          }
          .cta-block {
            button {
              // width: 100%;
              // max-width: 93px;
              // height: 23px;
              // font-size: $font-size-10px;
              // line-height: 1.3;
              line-height: 1;
              white-space: break-spaces;
              word-break: keep-all;
            }
          }
          .time-Unit {
            font-size: $font-size-12px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1331px) and (max-width: 1439px) {
  .accounts-content-wrapper {
    .magnifi-dashboard-container {
      .sidebar-container {
        .sideBarWrapper {
          .element {
            .label {
              font-size: 1rem !important;
            }
            .sub-label {
              font-size: 0.65rem !important;
            }
            .searchInput {
              input {
                font-size: 0.9rem !important;

              }
            }
            .quick-link-wrapper {
              a {
                font-size: 0.7rem !important;
                img {
                  height: 18px !important;
                }
              }
            }
            .accounts-sidebar-notification-count {
              .ant-badge-count {
                padding: 3px 4px;
                font-size: 0.7rem !important;
              }
            }
          }
          .sidebar-dropdown-wrapper {
            .portfolio-class-dropdown-text {
              font-size: 0.75rem !important;
              .anticon-caret-down {
                font-size: 10px !important;
              }
            }
          }
          .discover-dropdown {
            .multiple-calculator {
              .view-btn {
                font-family: Roboto !important;
                font-size: 0.75rem !important;
                color: inherit;
                .anticon-caret-down {
                  font-size: 10px !important;
                }
              }
            }
          }

        }
      }
      .accounts-summary-container {
        .accounts-page-container {
          .accounts-page-summary-wrapper {
            .accounts-summary-card-row {
              margin: 0 -8px;
              .card-title {
                // font-size: $font-size-14px !important;
                h5 {
                  // font-size: $font-size-14px !important;
                }
                button {
                  // font-size: $font-size-14px !important;
                }
              }
              .holdings-list-head {
                // font-size: $font-size-12px !important;
                // line-height: 1.5;
              }
              .FRR-block-label {
                text-align: center;
              }
              .accounts-card-stats-wrapper {
                // min-height: 94px;
                .col-block {
                  margin: 15px 0px;
                  .cta {
                    // font-size: $font-size-11px !important;
                    // line-height: 1.9;
                  }
                  div {
                    // font-size: 0.7rem !important;
                  }
                  h1 {
                    // font-size: $font-size-26px !important;
                  }
                  &:last-child {

                  }
                  &:first-child {

                  }
                }
              }
            }
            .accounts-summary-card-col {
              padding: $accounts-summary-card-col;
            }
            .accounts-allocations-chart-container {
              .accounts-allocations-chart-wrapper {
                .accounts-allocations-chart-label {
                  // font-size: $font-size-12px !important;
                }
              }
            }
          }
          .accounts-page-label {
            font-size: 1rem !important;
            margin-bottom: 3px !important;
          }
        }
        .primary-cta-button {
          font-size: 14px;
          .anticon {

          }
        }
        .accounts-summary-holdings-card {
          .ticker-block {
            h5 {
              // font-size: $font-size-14px !important;
            }
            .holdings-weight-font-size {
              // font-size: $font-size-14px !important;
            }
          }
          .name-block {
            h5 {
              // font-size: $font-size-14px !important;
            }
          }
          .FRR-block {
            h5 {
              // font-size: $font-size-14px !important;
              // margin: 0 !important;
            }
            h3 {
              // font-size: $font-size-12px !important;
              // margin: 0 !important;
            }
          }
          .cta-block {
            button {
              // width: 100%;
              // max-width: 93px;
              // height: 23px;
              // font-size: $font-size-10px;
              // line-height: 1.3;
              line-height: 1;
              white-space: break-spaces;
              word-break: keep-all;
            }
          }
          .time-Unit {
            font-size: 0.7rem !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  .accounts-content-wrapper {
    .magnifi-dashboard-container {
      .sidebar-container {
        .sideBarWrapper {
          .element {
            .label {
              font-size: 1rem !important;
            }
            .sub-label {
              font-size: 0.65rem !important;
            }
            .searchInput {
              input {
                font-size: 0.9rem !important;

              }
            }
            .quick-link-wrapper {
              a {
                font-size: 0.7rem !important;
                img {
                  height: 18px !important;
                }
              }
            }
            .accounts-sidebar-notification-count {
              .ant-badge-count {
                padding: 3px 4px;
                font-size: 0.7rem !important;
              }
            }
          }
          .sidebar-dropdown-wrapper {
            .portfolio-class-dropdown-text {
              font-size: 0.75rem !important;
              .anticon-caret-down {
                font-size: 10px !important;
              }
            }
          }
          .discover-dropdown {
            .multiple-calculator {
              .view-btn {
                font-family: Roboto !important;
                font-size: 0.75rem !important;
                color: inherit;
                .anticon-caret-down {
                  font-size: 10px !important;
                }
              }
            }
          }

        }
      }
      .accounts-summary-container {
        .accounts-page-container {
          .accounts-page-summary-wrapper {
            .accounts-summary-card-row {
              margin: 0 -8px;
              .card-title {
                // font-size: $font-size-14px !important;
                h5 {
                  // font-size: $font-size-14px !important;
                }
                button {
                  // font-size: $font-size-14px !important;
                }
              }
              .holdings-list-head {
                // font-size: $font-size-12px !important;
                // line-height: 1.5;
              }
              .FRR-block-label {
                text-align: center;
              }
              .accounts-card-stats-wrapper {
                // min-height: 94px;
                .col-block {
                  margin: 15px 0px;
                  .cta {
                    // font-size: $font-size-11px !important;
                    // line-height: 1.9;
                  }
                  div {
                    // font-size: 0.7rem !important;
                  }
                  h1 {
                    // font-size: $font-size-26px !important;
                  }
                  &:last-child {

                  }
                  &:first-child {

                  }
                }
              }
            }
            .accounts-summary-card-col {
              padding: $accounts-summary-card-col;
            }
            .accounts-allocations-chart-container {
              .accounts-allocations-chart-wrapper {
                .accounts-allocations-chart-label {
                  // font-size: $font-size-12px !important;
                }
              }
            }
          }
          .accounts-page-label {
            font-size: 1rem !important;
            margin-bottom: 3px !important;
          }
        }
        .primary-cta-button {
          font-size: 14px;
          .anticon {

          }
        }
        .accounts-summary-holdings-card {
          .ticker-block {
            h5 {
              // font-size: $font-size-14px !important;
            }
            .holdings-weight-font-size {
              // font-size: $font-size-14px !important;
            }
          }
          .name-block {
            h5 {
              // font-size: $font-size-14px !important;
            }
          }
          .FRR-block {
            h5 {
              // font-size: $font-size-14px !important;
              // margin: 0 !important;
            }
            h3 {
              // font-size: $font-size-12px !important;
              // margin: 0 !important;
            }
          }
          .cta-block {
            button {
              // width: 100%;
              // max-width: 93px;
              // height: 23px;
              // font-size: $font-size-10px;
              // line-height: 1.3;
              line-height: 1;
              white-space: break-spaces;
              word-break: keep-all;
            }
          }
          .time-Unit {
            font-size: 0.7rem !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .apex-bg-1 {
    background-size: 36% !important;
    background-position: 100% 100%;
  }

  .apex-bg-2 {
    background-size: 30% !important;
    background-position: 0px 100%;
  }

  .accounts-content-wrapper {
    .magnifi-dashboard-container {
      .sidebar-container {
        .sideBarWrapper {
          .element {
            .search-header-label {
              // padding-top: 0;
              font-size: 28px !important;
              // line-height: 1.8;
            }
            .label {
              font-size: 22px !important;
            }
            .sub-label {
              font-size: 0.89rem !important;
            }
            .accounts-sidebar-notification-count {
              .ant-badge-count {
                padding: 3px 4px;
              }
            }
            .popTheme-wrapper {

              &__item {

                & img {
                }
              }

              &__title {
                font-size: 18px !important;
              }
            }
          }
          // .sidebar-dropdown-wrapper {
          //   .portfolio-class-dropdown-text {
          //     font-size: 1rem !important;
          //     .anticon-caret-down {
          //       font-size: 12px !important;
          //       top: 1px;
          //       right: -16px;
          //     }
          //   }
          // }
          // .discover-dropdown {
          //   .multiple-calculator {
          //     .view-btn {
          //       font-size: 1rem !important;
          //       .anticon-caret-down {
          //         font-size: 12px !important;
          //         top: -1px;
          //         right: -9px;
          //       }
          //     }
          //   }
          // }
          .sidebar-scroll-content {
            // height: calc(100vh - (310px)) !important;
          }
        }
      }
      .accounts-summary-container {
        .accounts-page-container {
          .accounts-page-summary-wrapper {
            .accounts-summary-card-row {
              // margin: 0 -8px;
              .card-title {
                // font-size: $font-size-14px !important;
                h5 {
                  // font-size: $font-size-14px !important;
                }
                button {
                  // font-size: $font-size-14px !important;
                }
              }
              .holdings-list-head {
                // font-size: $font-size-12px !important;
                // line-height: 1.5;
              }
              .FRR-block-label {
                text-align: center;
              }
              .accounts-card-stats-wrapper {
                // min-height: 94px;
                .col-block {
                  // margin: 15px 0px;
                  .cta {
                    // font-size: $font-size-11px !important;
                    // line-height: 1.9;
                  }
                  div {
                    // font-size: 0.7rem !important;
                  }
                  h1 {
                    // font-size: $font-size-26px !important;
                  }
                  &:last-child {

                  }
                  &:first-child {

                  }
                }
              }
            }
            .accounts-summary-card-col {
              // padding: $accounts-summary-card-col;
            }
            .accounts-allocations-chart-container {
              .accounts-allocations-chart-wrapper {
                .accounts-allocations-chart-label {
                  // font-size: $font-size-12px !important;
                }
              }
            }
          }
          .accounts-page-label {
            // font-size: 1rem !important;
            font-size: 22px !important;
            margin-bottom: 5px;
          }
        }
        .primary-cta-button {
          // font-size: 14px;
          .anticon {

          }
        }
        .accounts-summary-holdings-card {
          .ticker-block {
            h5 {
              // font-size: $font-size-14px !important;
            }
            .holdings-weight-font-size {
              // font-size: $font-size-14px !important;
            }
          }
          .name-block {
            h5 {
              // font-size: $font-size-14px !important;
            }
          }
          .FRR-block {
            h5 {
              // font-size: $font-size-14px !important;
              // margin: 0 !important;
            }
            h3 {
              // font-size: $font-size-12px !important;
              // margin: 0 !important;
            }
          }
          .cta-block {
            button {
              // width: 100%;
              // max-width: 93px;
              // height: 23px;
              // font-size: $font-size-10px;
              // line-height: 1.3;
            }
          }
          .time-Unit {
            // font-size: 0.7rem !important;
          }
        }
      }
    }
  }
}

// @media only screen and (min-width: 1024px) and (max-width: 1280px) {
//   .unidentified_ticker_suggestion_text {
//     font-size: 15px !important;
//   }
//   .magnifi-dashboard-container {
//     .sidebar-container {
//       .sideBarWrapper {
//         padding: 20px 5px;
//         .loader {
//           font-size: 8px;
//         }
//         .element {
//           padding-bottom: 20px;
//
//           .search-header-label {
//             padding-top: 0;
//             font-size: 15px !important;
//             line-height: 1.8;
//           }
//           .label {
//             font-size: 15px !important;
//           }
//           .sub-label {
//             font-size: 0.49rem !important;
//           }
//           .searchInput {
//             input {
//               font-size: 12px !important;
//             }
//             .search-icon {
//               font-size: 17px !important;
//               right: 8px;
//             }
//           }
//         }
//       }
//     }
//     .accounts-summary-container .accounts-page-container .accounts-page-label {
//       font-size: 15px !important;
//     }
//   }
// }

// @media only screen and (min-width: 1920px) {
//   .unidentified_ticker_suggestion_text {
//     font-size: 22px !important;
//   }
//   .magnifi-dashboard-container {
//     .sidebar-container {
//       .sideBarWrapper {
//         // padding: 20px 5px;
//         .loader {
//           // font-size: 8px;
//         }
//         .element {
//           // padding-bottom: 20px;
//
//           .search-header-label {
//             // padding-top: 0;
//             font-size: 28px !important;
//             // line-height: 1.8;
//           }
//           .label {
//             font-size: 22px !important;
//           }
//           .sub-label {
//             font-size: 0.89rem !important;
//           }
//           .searchInput {
//             input {
//               // font-size: 12px !important;
//             }
//             .search-icon {
//               // font-size: 17px !important;
//               // right: 8px;
//             }
//           }
//           .tagWrapper {
//             // max-height: 98px !important;
//             // font-size: 13px !important;
//             .tag {
//               // padding: 4px 12px !important;
//               font-size: 14px !important;
//             }
//           }
//           .popTheme-wrapper {
//
//             &__item {
//
//               & img {
//               }
//             }
//
//             &__title {
//               font-size: 18px !important;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// @media only screen and (min-height: 500px) and (max-height: 700px) {
//   .accounts-onboard-screen-wrapper {
//     padding: 3rem 2rem !important;
//     .content-body {
//       .buttons-items-wrapper {
//
//       }
//     }
//   }
// }
// @media only screen and (max-width: 1500px) and (min-width: 1200px){
//   .accountsv2-container {
//     .feeds-type-writer{
//       font-size:10px!important;
//     }
//     .top-feeds-wrapper #marquee-feed{
//       max-width:245px;
//     }
//   }
// }

// @media only screen and (max-width: 1200px) and (min-width: 1024px){
//   .accountsv2-container {
//     .top-feeds-wrapper #marquee-feed{
//       max-width:200px;
//     }
//   }
// }
// @media only screen and (max-width: 1024px) and (min-width:767px){
//   .accountsv2-container {
//     .top-feeds-wrapper #marquee-feed{
//       max-width:140px;
//     }
//     a.v2-quick-link{
//       margin-right:0px!important;
//       display:flex!important;
//     }
//     .accountsv2-content-container .accounts-summary-card-col .tx-c2{
//       font-size:1rem!important;
//     }
//     .accounts-summary-holdings-card .ticker-block{
//       min-width:60px;
//     }
//     .accounts-summary-holdings-card .cta-block{
//       flex-direction: row;
//       align-items: baseline;
//       justify-content: center;
//       width: 100%;
//       max-width: 100%;
//
//       button{
//         margin-right:5px;
//       }
//     }
//   }
// }
