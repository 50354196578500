@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.modal-back-arrow {
  z-index: 9999;
  // width: 100%;
  top: 20px;
  // left: 0px !important;
  padding: 12px !important;
}

.revelance-wrapper {
  height: 60vh;
  .view-dropdown-btn {

  }
  .view-text-container {

  }
  .sub-dropdown-btn {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .media-chart-wrapper {

  }
}

.sfm-tab-color {
}
.sfm-modal-footer {
  .footer-delete-icon {
  }
  .secondary-btn {
  }
  .primary-btn {
  }
}

.funds-listing-warapper {
  .slick-slider {
    padding-top: 2px;
    .slick-list {
      .slick-track {
        height: 100% !important;
      }
    }
    .slick-prev {
      left: 5rem;
      top: unset;
      color: red !important;
      bottom: -24px !important;
      transform: rotate(90deg);
      z-index: 99;
      &:before {
        color: #4a4a4a;
        // content: '';
      }
    }
    .slick-next {
      right: 5rem !important;
      bottom: -24px !important;
      top: unset !important;
      transform: rotate(90deg);
      z-index: 99;
      &:before {
        // content: '';
        color: #4a4a4a;
      }
    }
  }
   .ps__rail-x {
     display: none !important;
   }
   .ps__rail-y {
     display: none !important;
   }
}

@media only screen and (max-width:500px){
    padding-left:.6rem !important;
    padding-right:.6rem !important;
}

@include respond(desktop) {

}

@include respond(tab) {

}

@media only screen and (min-width: 320px) and (max-width: 374px) {
  .mainMastHeadVerticalCenter {
    section {
      .container {
        form {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .modal.sfp .modal-dialog {
    right: -55px !important;
  }
}

@include respond(phone) {
  .list-wrap-h100 {
    height: 100% !important;
    .media-scroll-wrapper {
      height: 100%;
      padding: 0 !important;
      .media-fund-card {
        height: 100%;
        .card {
          margin-right: 4px;
        }
      }
    }
  }
  .media-bg-white {
    height: 100%;
  }
  .mainMastHeadVerticalCenter {
    section {
      .container {
        .search-box {
          .form-control::placeholder {
            font-size: .8rem !important;
          }
        }
      }
    }
  }
  .sponsored-info-wrapper, .relevance-info-wrapper  {
    margin-bottom: 5px !important;
    padding: 1rem !important;
    padding-top: 0.5rem !important;
    img {
      margin-top: 3px !important;
      margin-bottom: 5px !important;
    }
  }
  .media-card {
    margin-right: 0 !important;
    .card-body {
      .ssf-spacer {
        // display: none;
      }
      .funds-info-title {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin: 0 0 0.3rem 0 !important;
        img {
          margin-bottom: 5px !important;
        }
        h4 {
          width: 85% !important;
          margin: 0 !important;
          .LinesEllipsis {
            white-space: inherit;
          }
        }
      }
      .container-fluid {
        .chart-wrapper {
          height: auto;
          .chart-legend-wrapper {
            .chart-legend {
              .row {
                line-height: 1.3 !important;
              }
            } 
           }
        }
      }
    }
  }
}
