@import "./colors";
@import "./Elements/index";
@import "./Pages/OnBoardScreen/index";
@import "./Pages/Models/index";
@import "./Pages/Funds/index";
@import "./Pages/Dashboard/index";
@import "./Pages/Summary/index";
@import "./Pages/OpenSummaryPage/index";
@import "./header";
@import "./responsive";

.ant-tabs-tab-prev, .ant-tabs-tab-next {
  z-index: 0 !important;
}

.grey-bg {
  background-color: $tag-grey-bg-color !important;
  border: 1px solid $tag-grey-bg-color !important;
}

.blue-bg {
  background-color: $tag-blue-bg-color !important;
  border: 1px solid $tag-blue-bg-color !important;
}

.ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-submenu-arrow {
  display: flex;
  align-items: center;

  .anticon-right {
    margin-right: 0 !important;
  }
}

.ant-layout {
  background: #f7f7f7 !important;
}

.enhancer-content-wrapper {
  height: calc(100vh - 60px);
  overflow: hidden;
  background: #f7f7f7 !important;
  position: relative;
  margin-top: 0rem !important;
  padding-right: 15px;
  padding-left: 15px;

  .holdings-weight-font-size {
    font-size: 0.8rem !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 14px !important;
    letter-spacing: normal;
    text-align: center;
    font-weight: 600;
    color: #4a4a4a;
  }
  .holdings-value-font-size {
    font-size: 0.8rem !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 12px !important;
    letter-spacing: normal;
    text-align: center;
    font-weight: 600;
    color: #4a4a4a;
  }
}

.portfolio_name_input{
  width: unset !important;
  border: 0 !important;
  border-bottom: 1px solid !important;
  font-size: 20px !important;
  background: transparent !important;
  padding: 0 !important;
}

.portfolio_name_input:focus{
  box-shadow: none !important;
  border: 0 !important;
  border-bottom: 1px solid !important;
  padding: 0 !important;
}

.api-response-notification-class {
  .ant-notification-notice-content {
    .ant-notification-notice-message {
      display: inline-block !important;
    }
  }
}
