$color: #1e5f91;
$active-color: #1e5f91;
$in-active-color: #dbdbdb;
$white-color: #ffffff;

.MCardWrapper {
  max-width: 784px;
  position: relative;
  border-radius: 8px;

  .label_tag {
    width: max-content;
    position: absolute;
    top: -34px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 5px 10px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    color: black;
    border-radius: 2px;
    border-bottom: 5px solid black ;
    background: $white-color;
    font-size: 14px;
  }
}

.mc-container {
  display: flex;
  flex-wrap: wrap;
}
.mc-container:after {
  // content: '';
  // display: inline-block;
  // width: 100%;
}
.mc-container .item1 {
  margin: 0 15px 30px 0;
  &:last-child {
    margin: 0 0 0 0;
  }
}

.mc-container .label {
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
}

.select-portfolio-text {
  padding-bottom: 10px;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  padding: 0;
  font-size: 13px;
}

.select-underline{
  width: 102px;
  margin-top: 7px !important;
  height: 2px;
  color: #4a4a4a;
  background-color: #1e5f91;
}

@media only screen and (min-width: 1024px) and (max-width: 1035px) {
  .mc-container {
    right: 49px !important;
  }
}
