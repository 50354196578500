

.registrations-process-modal-backdrop {
  display: block !important;
}

.registrations-process-modal {
  .modal-content {
    border: 0;
    border-radius: 15px;
    background-color: #56a9e8;
  }
  .modal-body {
    padding: 3.5rem;
  }
}

.fs-1 {
  font-size: 1rem !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left !important;
}

.justify-content-left {
  justify-content: left !important;
}

.registrations-process-modal-container {
  .registrations-completed-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
  }

  .registrations-completed-check {
    font-size: 6rem;
    text-align: center;
    color: #fff;
  }

  .header-wrapper {
    h1 {
      font-size: 2rem !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #ffffff;
    }
    h2 {
      font-size: 13px !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: #ffffff;
    }
  }
  .content-wrapper {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-bottom: 1.5rem;
  }
  .cta-wrapper {
    flex-direction: row;
    align-items: normal;
    .btn {
      width: 100%;
      font-weight: normal;
      text-transform: capitalize;
      border: 1px solid #fff;

      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

@include respond(phone){
  .registrations-process-modal {
    .modal-content {

    }
    .modal-body {
      padding: 1rem;
    }
  }

  .registrations-process-modal-container {
    .registrations-completed-close {

    }

    .registrations-completed-check {

    }

    .header-wrapper {
      h1 {
        font-size: 2rem !important;
      }
      h2 {
        font-size: 13px !important;

      }
    }
    .content-wrapper {

    }
    .cta-wrapper {
      .btn {
        line-height: 1.2;

        &:first-child {
          margin-right: 0.8rem;
        }
      }
    }
  }
}
