// Required
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

$c1: #56a9e8; //change this color code.
$c2: #4a4a4a;
$c3: #bababb;
$c4: #101828;
$c5: #f7f7f7;
$c6: #edf8fc; // light blue for bg
$c7: #918a1e; //change this color code.

$pg-px: 12mm;

.reports {
  padding-top: 0;
  background-color: white !important;
  height: 100%;

  .page {
    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 80px;
      padding: 4mm $pg-px;
      border-bottom: 2px solid $gray-200;
      margin-bottom: 6mm;

      h3,
      h5 {
        margin: 4px 0;
      }
      h3 {
        font-weight: bold;
      }
      h5 {
        color: $gray-600;
      }

      &.type2 {
        padding: 6mm;
        border-bottom: none;
        background-color: $gray-100;
      }
    }
    .page-header,
    .page-footer {
      background-color: $gray-700;
      color: white;
      padding: 2mm $pg-px;
    }
  }

  .row.compact {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    & > [class*='col-'] {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
  }

  .row.wide {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    & > [class*='col-'] {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  .chart-wrap {
    height: 85px;
  }
  .chart-wrap-2x {
    height: 200px;
  }
  .chart-wrap-3x {
    height: 300px;
  }

  h4.title {
    padding: 0.625rem 0.75rem;
    background-color: $gray-300;
    background-color: $c1;
    color: white;
    font-weight: bold;
    margin-bottom: 0;
  }
  h6 {
    padding: 0.5rem;
    color: $c4;
  }
  h3 {
    color: $c4;
  }
  .list-group-item {
    padding: 0.25rem;
  }
  .card-lg {
    min-height: 121px;
    padding: 0.5rem;
  }
  .center-vh {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mt-3-c {
    margin-top: 1rem !important;
    &:first-child {
      margin-top: 0 !important;
    }
  }
  .card-t1 {
    border-width: 2px !important;
    &:first-child {
      border-color: $c1 !important;
      &:before {
        content: 'Best Fit';
        position: absolute;
        font-size: 0.75rem;
        background-color: $c1;
        color: white;
        padding: 1px 6px;
      }
    }
  }
  .card-t2.sm {
    h3 {
      font-size: 1.1rem !important;
    }
    h4 {
      font-size: 0.9rem !important;
    }
    h5 {
      font-size: 0.8rem !important;
    }
  }

  .card-t3 {
    margin-bottom: 1.75rem;
    h1 {
      color: $gray-700;
    }
    h4 {
      margin-bottom: 0;
      padding: 0;
      &.line-1 {
        color: $c4;
        font-weight: bold;
      }
    }
  }

  #ticker-short-name {
    font-size: 1rem !important;
  }

  .list-p {
    .key,
    .value {
      padding: 6px 12px;
    }
  }

  hr {
    margin: 0.5rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
  .bg-c6 {
    background-color: $c6;
  }
  .border-c1 {
    border: 2px solid $c1;
  }
  h6.border-top {
    border-top-width: 2px !important;
  }

  .table th,
  .table td {
    border: none;
    padding: 0.4rem 0;
  }
  .table td.key {
    font-size: 0.75rem;
    color: $gray-700;
    padding-right: 0.5rem;
  }
  .table td.value,
  .text-desc {
    font-size: 0.9rem;
    font-weight: 600 !important;
  }
  .text-desc {
    line-height: 1.5;
  }

  .recharts-cartesian-axis-tick text,
  .recharts-legend-item-text {
    font-size: 13px;
    fill: $c4;
  }
  .recharts-legend-item path,
  .recharts-bar-rectangle path {
    shape-rendering: crispEdges;
  }
  .recharts-wrapper .recharts-surface {
    width: auto !important;
  }

  .wrap-12 {
    h4 {
      padding: 0.625rem 0;
      color: $c4;
      font-weight: bold;
      border-bottom: 2px solid rgba($c4, 0.5);
    }
    h4.type2 {
      color: $c7;
      border-bottom: 1px solid rgba($c7, 0.5);
      font-size: 0.9rem !important;
      padding: 0.5rem 0;
    }
  }

  .wrap-1 {
    .title {
      border-bottom: 2px solid rgba($c4, 0.75);
      margin-bottom: 0.5rem;
    }
    .title.type2 {
      border-bottom: 1px solid rgba($c7, 0.75);
    }
    .title > h4 {
      line-height: 1.75;
      color: $c4;
      font-weight: bold;
      padding: 0.375rem 0.25rem;
      border-bottom: 3px solid rgba($c4, 0.75);
      display: inline-block;
      margin-bottom: 0;
    }
    .title.type2 > h4 {
      color: $c7;
      border-bottom: 2px solid rgba($c7, 0.75);
      font-size: 0.9rem !important;
      padding: 0.25rem 0.25rem;
    }
  }
}

.print-wrapper {
  text-align: center;
  margin: 0 auto;

  .siw-meter-title,
  .siw-pie-title {
    font-size: 0.6875rem;
  }
  .recharts-wrapper,
  .d-flex {
    margin: 0 auto !important;
  }
  .d-flex {
    span {
      font-size: 0.6rem;
    }
  }

  .siw-carousel {
    width: 100%;
    // height: 100%;
    position: relative;
    margin-left: 5px;

    .siw-slide-label {
      font-size: 0.6rem;
    }

    div {
      position: absolute;
      top: 3%;
      margin-top: 0px;
      margin-left: -30px;
      width: 60px;
      height: 60px;
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      transition: all 0.5s ease;

      &:hover {
        cursor: pointer;
      }
    }

    img {
      margin: 0;
      padding: 0;
      display: block;
      // width: 100%;
      width: 50px;
    }
  }

  .siw-slide-m1 {
    left: 25%;
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
    opacity: 0.5;
    z-index: 2;
  }

  .siw-slide-m2 {
    left: 0;
    opacity: 0;
    transform: scale(0.5);
    z-index: 1;
  }

  .siw-slide-0 {
    left: 50%;
    z-index: 3;
  }

  .siw-slide-1 {
    left: 75%;
    opacity: 0.5;
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
    z-index: 2;
  }

  .siw-slide-2 {
    left: 100%;
    transform: scale(0.5);
    z-index: 1;
    opacity: 0;
  }
}

.print-carouse-holder {
  width: 70%;
  height: 100%;
  position: relative;
  min-height: 90px;
  margin: 0 auto;
}

.print-container {
  box-sizing: border-box;
  .notapp-status {
    margin: 0 auto;
    height: 93px;
    align-items: center;
    justify-content: center;
  }
}

.print-pie-holder {
  .recharts-wrapper {
    width: 60px !important;
    height: 80px !important;

    .recharts-surface {
      width: 100% !important;
    }
  }
}

.content {
  .notapp-status {
    margin: 0 auto;
    height: 93px;
    align-items: center;
    justify-content: center;
  }
}

.reports {
  .container {
    width: auto !important;
    // max-width: 100%;
    padding: 0;
  }
}

@media print {
  .reports {
    .container {
      width: auto !important;
      // max-width: 100%;
      padding: 0;
    }
    .page {
      //page-break-after: always;
      height: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      .content {
        flex: 1;
        padding: 4mm $pg-px;
      }
    }
    .table td,
    .table th {
      background-color: transparent !important;
    }

    .recharts-responsive-container {
      margin: 0 auto !important;
    }
  }
  @page {
    size: a4;
  }

  @media print {
    body {
      -webkit-print-color-adjust: exact;
    }
  }
  .page-header {
    page-break-before: always;
  }
}
