.cd-sm-light-modal-backdrop {
  display: block !important;
}
.cd-sm-light {
  max-width: 600px !important;
  .modal-content{
    border-radius: 20px;
  }
  .modal-body{
    padding: 20px;
  }
}

.client-details-wrapper{
  // General Styles
  .text-wrap-ellip{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .cl-dt-header{
    h3{
      color: $dark-blue-color;
      padding-bottom: 5px;
      margin-bottom: 0px;
      font-size: $font-size-30px !important;
      font-weight: normal;
    }
    p{
      font-size: $font-size-22px !important;
      color: $dark-blue-color;
      font-weight: normal;
    }
    padding: 10px 0px 15px 0px;
  }
  // Tab customization starts here
  .ant-tabs {
    margin-top: 10px;
    &-nav {
      & > div{
        display: flex;
        width: 100%;
        border-bottom: 1px solid $other-grey-color-2;
      }
      display: flex;
      .ant-tabs-tab {
        flex-grow: 1;
        margin-right: 0px;
        width: 100%;
        text-align: center;
        color: $other-grey-color-2;
        font-weight: 500;
        font-family: $secondary-font !important;
        border: 1px solid $other-grey-color-2;
        padding: 5px 0px;
        &.ant-tabs-tab-active{
          color: $dark-blue-color;
          border: 1px solid $dark-blue-color !important;
        }
      }
    }
    .ant-tabs-bar{
      margin-bottom: 0px;
      border-bottom: 1px solid #fff;
    }
    .ant-tabs-ink-bar{
      background-color: $dark-blue-color;
    }
    .ant-tabs-top-content{
      height: 300px;
    }
  }
  // Tab customization ends here
  .account-details-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    .pf-val{
      span{
        font-size: $font-size-16px;
        font-family: $primary-font !important;
        font-weight: 600;
        color: $label-black-color;
        &:last-child{
          font-size: $font-size-26px;
          color: $dark-blue-color;
          font-weight: normal;
          padding-left: 10px;
        }
      }
    }
  }
  // Holdings Details
  .gn-tbl-wpr{
    &.tbl-content{
      display: flex;
      flex: 1;
      overflow: hidden;
      height: 100%;
      padding-top: 15px;
      & > div{
        overflow: auto;
        & > div{
          display: flex;
        }
      }
    }
    .rdt_TableBody, .ant-tabs-tabpane{
      //overflow-y: auto !important;
      &::-webkit-scrollbar{
        width: 20px !important;
        background-color: transparent;
        border-left: 1px solid #fff;
      }
      &::-webkit-scrollbar-thumb{
        border-radius: 25px;
        background-color: #d2d2d2;
        border: 7px solid transparent;
        background-clip: content-box;
      }
    }
    .rdt_TableCol {
      font-family: $secondary-font !important;
      font-size: $font-size-16px;
    }
    .rdt_TableCell{
      min-height: inherit;
    }
  }
}
