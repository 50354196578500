@import "./select-view";
@import "./SpoofSfModal/index";
@import "./Registrations/index";
@import "./PremiumFeature/index";
@import "./InviteFriends/index";
@import "./DownloadExcel/index";
@import "./TopFeeds/index";
@import "./FundDetailDrawer/index";

.did-you-mean-overflow-wrap {
  width: 86%;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
}

.stock-new-label {
  font-family: $primary-font;
  font-style: normal;
  font-weight: bold;
  font-size: 6px;
  line-height: 8px;
  text-align: center;
  color: #FFFFFF;
  background: #58879D;
  border-radius: 14px;
  width: 24px;
  padding: 2px 4px;
}

.show-only-stocks-message-text {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  margin-left: 4px;
  padding-left: 3px;
  border-left: 1px solid #9f9f9f;
}

.survey-container .survey-wrapper.dob-wrap{
  .dob-close-icon{
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 20px;
    color: #1e5f91;
    cursor: pointer;
    opacity: 0.7;
  }
  .dropdown-element .dropdown-wrapper .dropdown-toggle::after{
    top: 2.1em;
    right: 8px;
  }
  .header-title{
    color: #1e5f91 !important;
    font-size: 19px !important;
    font-weight: 600 !important;
  }
  .body-wrapper .element-box .input-area{
    color: #4a4a4a !important;
    background-color: #fff !important;
  }
  .body-wrapper .element-box .input-area.disabled{
    background-color: #e1e1e1 !important;
  }
  .dob_sub{
    background: #5eade9;
    border-radius: 60px;
    width: 100%;
    font-weight: 400;
    &:hover{
      background: #5eade9;
    }
    &:focus{
      background: #5eade9 !important;
      box-shadow: none !important;
    }
    &:active{
      background: #5eade9 !important;
      box-shadow: none !important;
    }
  }
  .body-wrapper .element-box .form-invalid-field{
    position: initial !important;
  }
}

.bt_activate{
  background: #5eade9;
  border-radius: 20px;
  font-size: 10px;
  margin-left: 10px;
  &:hover{
    background: #5eade9;
  }
  &:focus{
    background: #5eade9 !important;
    box-shadow: none !important;
  }
  &:active{
    background: #5eade9 !important;
    box-shadow: none !important;
  }
}

.investment-ranked-wrapper {
  // width: 86%;
  width: 100%;
}

.user-similar-search-wrapper {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-top: 2px;
  background: #fff;
  border-radius: 3px;
  .user-similar-search-label {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.51px;
  }
  .user-similar-search-overflow-wrap {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .didumean-tooltip {
    margin-top: 2px;
  }
  .didyoumean-text {
    padding: 0 5px;
    font-size: 16px;
    font-weight: 500;
    color: $primary-color;
    cursor: pointer;
    border-right: 1px solid $primary-color;
    &:last-child {
      border-right: 0;
      &:before {
        content: unset !important;
      }
    }
    &:before {
      content: unset !important;
    }
  }
  .user-similar-activate-btn {
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    border-width: 2px;
    border-radius: 30px;
    border-color: $primary-color;
    background: $primary-color;
    .ant-btn-loading-icon .anticon {
      padding: 8px;
    }
    &:hover, &:focus {
      color: $primary-color;
      border-color: $primary-color;
      background: #fff;
    }
  }
}

.view-name-tooltip {
  margin-top: 8px;
}

.view-name-tooltip-arrow {

}

.ant-tooltip.did-you-mean-tooltip-wrapper {
  padding-top: 0 !important;
  .ant-tooltip-content {
    .ant-tooltip-inner {
      padding: 0.25rem 0.5rem;
      font-family: "Open Sans", "Roboto", sans-serif !important;
      font-size: 0.7rem !important;
      line-height: 1.1;
      text-align: center;
      color: #fff !important;
      // opacity: 0.8;
      background-color: #000;
      border-radius: 0.25rem;
      border: 0 !important;
      background-color: #4a4a4acc !important;
    }
    .ant-tooltip-arrow {
      top: -13px !important;
      &:before {
        background-color: #4a4a4acc !important;
      }
    }
  }
}

#did-you-mean {
  .didumean-tooltip {
    margin-top: 2px;
  }
  .did-you-mean-head {
    font-weight: 600;
    text-align: center;
    line-height: 1.33;
    letter-spacing: -0.71px;
    color: #494949;
  }
  .didyoumean-text {
    padding: 0 4px;
    font-size: 12px;
    font-weight: 500;
    // color: #9f9f9f;
    color: #666666;
    cursor: pointer;
    border-right: 1px solid #9f9f9f;
    &:last-child {
      border-right: 0;
      &:before {
        content: unset !important;
      }
    }
    &:before {
      content: unset !important;
    }
  }
  .custom-scroll {
    font-size: 12px;
    // font-size: 14px;
    margin-top: 4px;
    height: auto !important;
    max-height: 73px !important;
    padding: 5px 10px !important;
    background: #EFEFEF;
    border-radius: 3px;
  }
}

.ranking-tag-wrapper {
  // width: 80%;
  height: auto;
  min-height: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 16px;
  .black-tag {
    font-size: 12px;
  }
  .black-tag:hover{
    cursor:pointer;
  }
}

.ranking-tag-label {
  flex: 1;
  width: 20%;
  max-width: 180px;
  min-width: 132px;
}

.tag-overflow-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 85%;
  overflow: hidden;
  overflow-x: scroll;
}

.black-tag {
  width: auto;
  min-width: max-content;
  margin: 2px 5px;
  padding: 5px 9px;
  font-size: inherit;
  font-weight: 400;
  text-align: center;
  line-height: 1;
  color: #fff;
  border-radius: 3px;
  background: #4a4a4a;
}

.no-result-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 430px;
  margin: auto;
  height: 100%;
  font-family: 'Roboto';
  // margin-top: -50px;

  @include respond(phone) {
    margin-top: -70px;
  }

  .dot-no-result {
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    margin-left: -15px;
  }

  .h3 {
    font-size: 36px !important;
    margin-bottom: 0px;

    @media only screen and (max-width: 1280px) and (min-width: 1024px) {
      font-size: 30px !important;
    }

    @include respond(tab) {
      font-size: 30px !important;
    }

    @include respond(phone) {
      font-size: 20px !important;
    }
  }

  .sub-head {
    font-size: 30px !important;
    font-weight: 300;

    @media only screen and (max-width: 1280px) and (min-width: 1024px) {
      font-size: 24px !important;
    }

    @include respond(tab) {
      font-size: 24px !important;
    }

    @include respond(phone) {
      font-size: 15px !important;
    }
  }

  .mg-btm-no-result {
    margin-bottom: 1.5rem;

    @include respond(phone) {
      margin-bottom: .5rem;
    }

    img {
      @media only screen and (max-width: 1280px) and (min-width: 1024px) {
        width: 100px;
      }

      @include respond(tab) {
        width: 90px;
      }

      @include respond(phone) {
        width: 60px;
      }
    }
  }

  .col-grey{
    color: #4a4a4a;
  }

  .specific-query {
    display: flex;
    justify-content: center;

    ul {
      width: 30%;
      list-style-type: none;
      text-align: left;
      // padding: 0px;

      &:first-child {
        padding-left: 30px;

        @include respond(phone) {
          padding-left: 40px;
        }
      }

      &:last-child {
        padding-left: 50px;

        @include respond(phone) {
          padding-left: 40px;
        }
      }

      @media only screen and (max-width: 1280px) and (min-width: 1024px) {
        width: 35%;
      }

      @include respond(tab) {
        width: 35%;
      }

      @include respond(phone) {
        width: 50%;
      }

      li {
        position: relative;
        margin: 40px 12px;

        &:first-child {
          margin-top: 0px;
        }

        @include respond(phone) {
          margin: 32px 0px;
        }
      }
    }

    .query-name {
      display: inline-block;
      font-size: 28px;
      color: #56a9e8;
      cursor: pointer;
      font-weight: 500;

      // &::before {
      //   content: '\2022  ';
      //   color: #4a4a4a
      // }

      @media only screen and (max-width: 1280px) and (min-width: 1024px) {
        font-size: 22px !important;
      }

      @include respond(tab) {
        font-size: 24px !important;
      }

      @include respond(phone) {
        font-size: 18px !important;
      }
    }

    .eg-query {
      position: absolute;
      bottom: -18px;
      left: 2px;
      font-size: 14px;
      font-weight: 300;

      @media only screen and (max-width: 1280px) and (min-width: 1024px) {
        font-size: 12px !important;
      }

      @include respond(tab) {
        font-size: 12px !important;
      }

      @include respond(phone) {
        font-size: 10px !important;
      }
    }

    .other {
      bottom: -25px;
    }

    #other {
      width: 80%;
      height: auto;
      padding: 7px 0;
      font-size: 14px;
      color: #4a4a4a;
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid #dbdbdb;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
      background-color: transparent !important;

      @media only screen and (max-width: 1280px) and (min-width: 1024px) {
        width: 60%;
        font-size: 12px;
      }

      @include respond(tab) {
        width: 60%;
      }

      @include respond(phone) {
        width: 65%;
      }
    }

    .other-error {
      position: absolute;
      bottom: -20px;
      left: 0;
      color: #a5685f;
    }

    .no-query-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      right: 1px;
      background-color: transparent;
      box-shadow: none;
      border-width: 0;
      border: 1px solid #dbdbdb;
      cursor: pointer;
      width: 26px;
      height: 26px;

      @media only screen and (max-width: 1280px) and (min-width: 1024px) {
        width: 22px;
        height: 22px;
        right: 30px;
      }

      @include respond(tab) {
        width: 22px;
        height: 22px;
        right: 30px;
      }

      @include respond(phone) {
        right: 5px;
      }

      .fa-chevron-right {
        color: #56a9e8;
      }
    }
  }
}

.topSectionContainer .toolbarWrapper .filterButtonWrapper .filterbutton .dropdown-menu .dropleft .second-level-dropdown .dropdown-wrapper  {
  padding: .25rem 0 ;
  box-shadow: 0 0 30px 0 #dbdbdb;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #efefef;
  max-height: 260px !important;
  overflow-x: hidden !important;
}

.topSectionContainer .toolbarWrapper .filterButtonWrapper .filterbutton .dropdown-menu .dropleft .second-level-dropdown .dropdown-wrapper::-webkit-scrollbar {
  width: 4px;
}
.topSectionContainer .toolbarWrapper .filterButtonWrapper .filterbutton .dropdown-menu .dropleft .second-level-dropdown .dropdown-wrapper::-webkit-scrollbar-track {
  background-color: #ffffff;
    -webkit-border-radius: 10px;
}
.topSectionContainer .toolbarWrapper .filterButtonWrapper .filterbutton .dropdown-menu .dropleft .second-level-dropdown .dropdown-wrapper::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
    background: #dbdbdb;
}
