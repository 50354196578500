.addMoney {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .close {
    position: absolute;
    top: -10px;
    right: -4px;
    font-size: 18px;
    opacity: .5;
    cursor: pointer;
  }

  .header {
    margin-top: 14px;
    font-size: 22px;
    font-family: 'Open Sans', serif;
    color: #2f2f2f;
    margin-bottom: 16px;
  }

  .beforeAdd {
    display: flex;
    align-items: center;
    flex-direction: column;

    .addMoneyInput {
      border: none;
      border-bottom: 1px solid #666666;
      color: #1A1E21;
      font-weight: 700;
      font-size: 22px;
      width: 70%;
      padding: 4px 20px;
      text-align: center;
      margin-bottom: 26px;
    }


  }

  .afterMoney {
    display: flex;
    align-items: center;
    justify-content: center;

    p:nth-child(2) {
      text-align: center;
      color: #1A1E21;
      font-weight: 700;
      font-size: 22px;
    }
  }

}

.accountCreatePopupForApp{
  width: 100vw;
  height: 100vh;
  border-radius: 0 !important;
}

.accountCreatePopup{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  .header {
    margin-top: 14px;
    font-size: 26px;
    font-family: 'Open Sans', serif;
    color: #2f2f2f;
    margin-bottom: 16px;
    font-weight: 600;
  }
  .accountCreateError{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .error{
      font-size: 42px;
      padding: 8px;
      color: #943d32;
    }

  }
  .statusText{
    margin: 10px 0;
    font-size: 18px;
    text-align: center;
    opacity: 0.9;
    padding: 0;
  }
  .confirm {
    margin-top: 20px;
    background-color: #56a8e8;
    font-family: 'Open Sans', serif;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    width: 130px;
    border: none;
    text-transform: uppercase;
    padding: 4px 10px;
    border-radius: 29px;
    height: 40px;
    cursor: pointer;
  }
}